import { AxiosInstance } from 'axios';
import { InvitationLink } from 'domain/invitationLink/InvitationLink';
import { AddFriendsForm } from 'primary/Components/Profils/User/ActionButton/Forms/AddFriendForm';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';
import { toInvitationLink } from 'secondary/invitationLink/RestInvitationLink';

import { Pageable } from '../../domain/pageable/Pageable';
import { Relation, RelationFilters } from '../../domain/relation/Relation';
import { IRelationRepository } from '../../domain/relation/relation.repository';
import { RelationTypeEnum } from '../../domain/relation/RelationTypeEnum';
import { toPageable } from '../RestPageable';
import { toRelation } from './RestRelation';

export class RelationRepository
  extends AxiosProviderRepository
  implements IRelationRepository
{
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  inviteUser(userId: number, data: AddFriendsForm): Promise<Relation> {
    return this.axios
      .post(`unpy/api/relation/friend/invite/${userId}`, data)
      .then((resp) => toRelation(resp.data));
  }

  responseInvitation(idRelation: number, accept: boolean): Promise<void> {
    return this.axios.put(`unpy/api/relation/response`, {
      idRelation: idRelation,
      accept: accept,
    });
  }

  searchBlockOfUser(
    idUser: number,
    page: number,
    nbPerPage: number,
  ): Promise<Pageable<Relation>> {
    return this.axios
      .get(`unpy/api/relation/search`, {
        params: {
          params: {
            type: RelationTypeEnum.BLOCK,
            owner: idUser,
            page,
            nbPerPage,
          },
        },
      })
      .then((response) => toPageable(response, toRelation));
  }

  searchFriendsOfUser(
    filters: RelationFilters,
    page: number,
    nbPerPage: number,
  ): Promise<Pageable<Relation>> {
    return this.axios
      .get(`unpy/api/relation/search`, {
        params: {
          ...filters,
          page,
          nbPerPage,
        },
      })
      .then((response) => toPageable(response, toRelation));
  }

  getById(relationId: number): Promise<Relation> {
    return this.axios
      .get(`unpy/api/relation/${relationId}`)
      .then((response) => toRelation(response.data));
  }

  deleteById(relationId: number): Promise<void> {
    return this.axios.delete(`unpy/api/relation/${relationId}`);
  }

  getAllRelationsOfConnectedUser(): Promise<Relation[]> {
    return this.axios
      .get(`unpy/api/relations/all`)
      .then((response) => response.data.map(toRelation));
  }

  blockUser(userId: number): Promise<Relation> {
    return this.axios
      .post(`unpy/api/relation/block/${userId}`)
      .then((response) => toRelation(response.data));
  }

  unBlockUser(userId: number): Promise<void> {
    return this.axios.post(`unpy/api/relation/unblock/${userId}`);
  }

  getOrCreateLinkInvitationFriend(): Promise<InvitationLink> {
    return this.axios
      .post(`unpy/api/relations/link/invitation`)
      .then((response) => toInvitationLink(response.data));
  }
}
