import classNames from 'classnames';
import { getName, getNeonBorderClassName } from 'domain/profil/Profil.func';
import React from 'react';

import { UserProfil } from '../../../../../domain/profil/UserProfil';
import { WrapperOtherProfilPhotoOverview } from '../../../User/WrapperOtherProfilPhotoOverview';

type ProfilPhotoProps = {
  profil: UserProfil;
};

export const ProfilPhoto = ({ profil }: ProfilPhotoProps) => {
  const neonBorderClassName = getNeonBorderClassName(profil);

  return (
    <div className={'userProfilPhoto'}>
      <WrapperOtherProfilPhotoOverview
        size={'medium'}
        displayName={getName(profil)}
        photo={profil.publicPhotoUrl || profil.photo}
        idUser={profil.userId as number}
        className={classNames('photo', neonBorderClassName)}
      />
    </div>
  );
};
