import './ActionModalInfos.scss';

import { HelpOutline } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import classNames from 'classnames';
import { useBooleanToggle } from 'primary/hooks/useBooleanToggle';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React, { ReactNode } from 'react';

interface ActionModalInfosProps {
  className?: string;
  title: string;
  content: ReactNode;
}

export const ActionModalInfos = ({
  className,
  title,
  content,
}: ActionModalInfosProps) => {
  const [isModalOpen, , openModal, closeModal] = useBooleanToggle();

  return (
    <div className={classNames('actionModalInfos', className)}>
      <IconButton title={title} onClick={openModal} className={'actionModalInfos__btn'}>
        <HelpOutline />
      </IconButton>
      <ModalContainer
        handleClose={closeModal}
        isOpen={isModalOpen}
        content={<Box className={'modal-infos'}>{content}</Box>}
      />
    </div>
  );
};
