import { UserProfil } from 'domain/profil/UserProfil';
import { RestUserProfil, toUserProfil } from 'secondary/userProfil/RestUserProfil';

type RestCompareProfil = {
  compareDefault: RestUserProfil;
  compareUp: RestUserProfil;
};

export const toCompareProfil = (restCompareProfil: RestCompareProfil): CompareProfil => {
  return new CompareProfil(
    toUserProfil(restCompareProfil.compareDefault),
    toUserProfil(restCompareProfil.compareUp),
  );
};

export class CompareProfil {
  constructor(
    public readonly compareDefault: UserProfil,
    public readonly compareUp: UserProfil,
  ) {}
}
