import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import classNames from 'classnames';
import { TranslationType } from 'domain/translation/Translation.repository';
import { confNavBar } from 'primary/events/MenuEventViewPage';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { Dispatch, FC, RefObject, SetStateAction } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import { getLink } from './MenusParameters';
import { ConfParametersLink } from './MenusParametersUser.config';

interface BottomNavActionsProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  anchorRef: RefObject<HTMLButtonElement>;
  conf: ConfParametersLink[] | confNavBar[];
  arias: string;
  currentSubConfActive: ConfParametersLink | undefined;
}

export const BottomNavActions: FC<BottomNavActionsProps> = ({
  open,
  setOpen,
  anchorRef,
  conf,
  arias,
  currentSubConfActive,
}) => {
  const t = useTranslate();
  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const { pathname } = useLocation();
  const params = useParams<{ idStructure: string }>();

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      placement="top"
      transition
      disablePortal
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                autoFocusItem={open}
                id={arias}
                aria-labelledby={arias + '-button'}
                onKeyDown={handleListKeyDown}
              >
                <nav className={'bottomNav__nav'}>
                  {conf.map((subMenuItem) => (
                    <MenuItem key={subMenuItem.label}>
                      <Link
                        className={classNames('item', {
                          '-active': currentSubConfActive?.path === subMenuItem.path,
                          '-icon': !!subMenuItem.icon,
                        })}
                        to={getLink(subMenuItem, params)}
                      >
                        {!!subMenuItem.icon && subMenuItem.icon}
                        {t(subMenuItem.label as TranslationType)}
                      </Link>
                    </MenuItem>
                  ))}
                </nav>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
