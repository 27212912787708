import { ValidationStringLengthEnum } from 'domain/enums/ValidationStringLengthEnum';
import { IRelationRepository } from 'domain/relation/relation.repository';
import { CoupleButtonValidCancel } from 'primary/Components/CoupleButtonValidCancel/CoupleButtonValidCancel';
import { sendEventToastMessage } from 'primary/Components/Toast/Toast.helper';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate, UseTranslateReturn } from 'primary/hooks/useTranslate';
import { useYupValidationResolver } from 'primary/hooks/useYupValidationResolver';
import React from 'react';
import { useController, useForm } from 'react-hook-form';
import * as yup from 'yup';

interface AddFriendFormProps {
  userId: string;
  onSubmitted: () => void;
  onCancel: () => void;
}

export type AddFriendsForm = {
  userId: string;
  message?: string;
};

const validationSchema = (t: UseTranslateReturn) =>
  yup.object({
    userId: yup.string().required(t('general.forms.errors.required')),
    message: yup
      .string()
      .max(
        ValidationStringLengthEnum.LONG,
        t('general.forms.errors.tooLong', {
          maxChar: ValidationStringLengthEnum.LONG,
        }),
      )
      .nullable(),
  });

const onSubmitAddFriend =
  (
    relationRepository: IRelationRepository,
    onSubmitted: () => void,
    t: UseTranslateReturn,
  ) =>
  (data: AddFriendsForm) => {
    return relationRepository
      .inviteUser(parseInt(data.userId), data)
      .then(() => {
        sendEventToastMessage(t('modals.addFriend.success'), 'success');
      })
      .finally(() => {
        onSubmitted();
      });
  };

export const AddFriendForm = ({ userId, onSubmitted, onCancel }: AddFriendFormProps) => {
  const { relationRepository } = useContextDependency();
  const resolver = useYupValidationResolver(validationSchema);
  const {
    handleSubmit,
    control,
    register,
    formState: { isSubmitting, errors },
  } = useForm<AddFriendsForm>({
    resolver,
    defaultValues: {
      userId: userId,
    },
  });
  const { field: messageField } = useController({ control: control, name: 'message' });

  if (!userId) return null;

  const t = useTranslate();
  const onSubmit = onSubmitAddFriend(relationRepository, () => onSubmitted(), t);
  return (
    <form className={'-addFriendForm'} onSubmit={handleSubmit(onSubmit)}>
      <CoupleButtonValidCancel isTypeSubmit={true} onClickCancel={onCancel} />
    </form>
  );
};
