import { Business, Campaign, Info, People, QueryStats } from '@mui/icons-material';
import { routesConfig } from 'primary/Configs/Routes.config';
import React from 'react';

import { confNavBarProfil } from './TopBarMenuProfil';

type ListConfNavBar = {
  [key: string]: confNavBarProfil[];
};

export const menusProfilPlayer: ListConfNavBar = {
  main: [
    {
      label: 'topbarMenuProfil.links.details',
      link: 'details',
      path: routesConfig.mercatoUserProfilDetails.userProfilDetails.path,
      params: ['id'],
      icon: <Info />,
    },
    {
      label: 'topbarMenuProfil.links.performances',
      link: 'performances',
      path: routesConfig.mercatoUserProfilDetails.userProfilPerformances.path,
      params: ['id'],
      icon: <QueryStats />,
    },
    {
      label: 'topbarMenuProfil.links.structures',
      link: 'structures',
      path: routesConfig.mercatoUserProfilDetails.userProfilStructures.path,
      params: ['id'],
      icon: <Business />,
    },
  ],
};

export const menusProfilStructure: ListConfNavBar = {
  main: [
    {
      label: 'topbarMenuProfil.links.details',
      link: 'details',
      path: routesConfig.otherStructureProfilDetails.structureProfilDetails.path,
      params: ['id'],
      icon: <Info />,
    },
    {
      label: 'topbarMenuProfil.links.annonces',
      link: 'annonces',
      path: routesConfig.otherStructureProfilDetails.structureProfilAnnonces.path,
      params: ['id'],
      icon: <Campaign />,
    },
    {
      label: 'topbarMenuProfil.links.members',
      link: 'members',
      path: routesConfig.otherStructureProfilDetails.structureProfilMembers.path,
      params: ['id'],
      icon: <People />,
    },
    {
      label: 'topbarMenuProfil.links.performances',
      link: 'performances',
      path: routesConfig.otherStructureProfilDetails.structureProfilPerformances.path,
      params: ['id'],
      icon: <QueryStats />,
    },
  ],
};
