import { Box, Button } from '@mui/material';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { useTranslate } from 'primary/hooks/useTranslate';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React from 'react';
interface ModalWaitingForActionsProps {
  isOpen: boolean;
  handleClose: () => void;
}
export const ModalWaitingForActions = ({
  isOpen,
  handleClose,
}: ModalWaitingForActionsProps) => {
  const t = useTranslate();
  return (
    <ModalContainer
      handleClose={handleClose}
      isOpen={isOpen}
      content={
        <Box className={'modal-edit-role'}>
          <h2>{t('modals.waitingForActions.title', { name: name })}</h2>
          <span>{t('modals.waitingForActions.text')}</span>
          <LoadingScreen loading={true} />
          <Button
            variant={'outlined'}
            color={'primary'}
            onClick={handleClose}
            className={'-btnSubscribe'}
          >
            {t('modals.waitingForActions.closeBtn')}
          </Button>
        </Box>
      }
    />
  );
};
