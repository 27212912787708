import { RestUserProfilView, toUserProfilView } from 'secondary/user/RestUserProfilView';

import { Conversation } from '../../domain/message/Conversation';
import { RestMessage, toMessage } from './RestMessage';

export interface RestConversation {
  id: number;
  participants: RestUserProfilView[];
  messages: RestMessage[];
}

export function toConversation(restConversation: RestConversation) {
  return new Conversation(
    restConversation.id,
    restConversation.participants.map(toUserProfilView),
    restConversation.messages != null
      ? restConversation.messages.map(toMessage)
      : undefined,
  );
}
