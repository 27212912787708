import { MoreVert } from '@mui/icons-material';
import { BottomNavigationAction } from '@mui/material';
import classNames from 'classnames';
import { TranslationType } from 'domain/translation/Translation.repository';
import { BottomNavActions } from 'primary/Components/MenuParameters/BottomNavActions';
import { getLink } from 'primary/Components/MenuParameters/MenusParameters';
import { confNavBar } from 'primary/events/MenuEventViewPage';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { FC, useMemo } from 'react';
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';

interface BottomNavItemEventViewProps {
  conf: confNavBar[];
}

export const BottomNavItemEventView: FC<BottomNavItemEventViewProps> = ({ conf }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const { pathname } = useLocation();
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const t = useTranslate();
  const navigate = useNavigate();
  const params = useParams<{ idStructure: string }>();
  const confBottomNavActions = useMemo(
    () => conf.filter((itemMenu) => !!itemMenu.secondaryLink),
    [conf],
  );

  return (
    <>
      {conf
        .filter((itemMenu) => !itemMenu.secondaryLink)
        .map((itemMenu) => (
          <BottomNavigationAction
            key={itemMenu.label}
            showLabel
            className={classNames('bottomNav__button', {
              '-selected': !!matchPath(
                {
                  path: itemMenu.path as unknown as string,
                  end: true,
                },
                pathname,
              ),
            })}
            ref={anchorRef}
            id={'menu-event-view' + '-button'}
            aria-controls={open ? 'menu-event-view' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={() => navigate(getLink(itemMenu, params))}
            label={
              <div className={'-bottomNavLabel'}>
                {itemMenu?.icon}
                <span className={'-title'}>{t(itemMenu?.label as TranslationType)}</span>
              </div>
            }
            title={t(itemMenu.label as TranslationType)}
          />
        ))}

      {confBottomNavActions?.length != 0 && (
        <>
          <BottomNavActions
            conf={confBottomNavActions}
            open={open}
            setOpen={setOpen}
            anchorRef={anchorRef}
            arias={'menu-event-view'}
            currentSubConfActive={conf.find((confF) =>
              matchPath(
                {
                  path: confF.path as unknown as string,
                  end: false,
                },
                pathname,
              ),
            )}
          />
          <BottomNavigationAction
            showLabel
            className={'bottomNav__button'}
            ref={anchorRef}
            id={'menu-event-view' + '-button'}
            aria-controls={open ? 'menu-event-view' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            label={<MoreVert />}
          />
        </>
      )}
    </>
  );
};
