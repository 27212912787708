import './UserDetailsForms.scss';

import { InputSelectMui } from 'primary/Components/Input/InputSelectMui';
import { UserProfilDetailsFormData } from 'primary/Parameters/user/Profil/UserDetails';
import React, { FC } from 'react';
import { useController, UseFormReturn, UseFormSetValue } from 'react-hook-form';

import { useContextDependency } from '../../hooks/useContextDependency';
import { useFetch } from '../../hooks/useFetch';
import { useTranslate } from '../../hooks/useTranslate';
import { ChangeHandlerTypeMeta, InputWrapper } from '../Input/InputWrapper';

interface TalkLangsFormProps {
  form: UseFormReturn<UserProfilDetailsFormData>;
}

export const _changeHandlerTalkLangs =
  (setValue: UseFormSetValue<UserProfilDetailsFormData>) =>
  (event: ChangeHandlerTypeMeta) => {
    setValue('talkLangs', event.target.value as string[]);
  };

export const TalkLangsFields: FC<TalkLangsFormProps> = ({ form }) => {
  const t = useTranslate();

  const { langRepository } = useContextDependency();

  const [talkLangs] = useFetch(() => langRepository.getAll(), undefined);

  const { field: talkLangsField } = useController({
    control: form.control,
    name: 'talkLangs',
  });

  return (
    <InputWrapper
      label={t('parameters.menus.profil.user.form.talkLangsLabel')}
      errors={form.formState.errors}
      inputClassName={'input-user-details '}
      labelClassName={'label-user-details '}
      Input={InputSelectMui}
      isSubmitting={form.formState.isSubmitting}
      required
      // @ts-ignore
      inputProps={{
        placeholder: t('parameters.menus.profil.user.form.talkLangsPlaceholder'),
        ...talkLangsField,
        options: talkLangs?.map((lang) => ({
          label: t(`enum.lang.${lang.code}`),
          value: lang.id.toString(),
        })),
        onChange: _changeHandlerTalkLangs(form.setValue),
        multiple: true,
      }}
    />
  );
};
