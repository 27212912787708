import { useEffect, useMemo, useState } from 'react';

export const usePaginationFromResource = <R, F extends Array<R>>(
  initialNbPerPage = 30,
  ressources: F,
) => {
  const [nbPerPage, setNbPerPAge] = useState<number>(initialNbPerPage);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const currentIndexMax = useMemo(() => nbPerPage * currentPage, [currentPage]);
  const indexMin = useMemo(() => currentIndexMax - nbPerPage, [currentIndexMax]);
  const indexMax = currentIndexMax;
  const [elementToDisplay, setElementToDisplay] = useState<F>(
    ressources.filter((_, index) => index >= indexMin && index <= indexMax) as F,
  );

  useEffect(() => {
    setElementToDisplay(
      ressources.filter((_, index) => index >= indexMin && index <= indexMax) as F,
    );
  }, [indexMax, indexMin]);

  return {
    elementToDisplay,
    currentPage,
    setCurrentPage,
    setNbPerPAge,
    nbPerPage,
    pageCount: Math.ceil(ressources.length / nbPerPage),
  };
};
