import { UnpySession } from 'domain/payment/UnpySession';
import { UnpySubscription } from 'domain/payment/UnpySubscription';
import { UnpySubscriptionStatus } from 'domain/payment/UnpySubscriptionStatus';
import { toEnum } from 'helper/enum.helper';
import { RestProduct, toProduct } from 'secondary/payment/RestProduct';

export interface RestUnpySession {
  urlCheckout: string;
}

export interface RestUnpySubscription {
  id: number;
  idStripe: string;
  currency: string;
  cancelAtPeriodEnd: boolean;
  currentPeriodStart: string;
  currentPeriodEnd: string;
  status: string;
  description: string;
  clientSecretKey?: string;
  product: RestProduct;
}

export function toUnpySubscription(restUnpySubscription: RestUnpySubscription) {
  return new UnpySubscription(
    restUnpySubscription.id,
    restUnpySubscription.idStripe,
    restUnpySubscription.currency,
    restUnpySubscription.cancelAtPeriodEnd,
    new Date(restUnpySubscription.currentPeriodStart),
    new Date(restUnpySubscription.currentPeriodEnd),
    toEnum(UnpySubscriptionStatus, restUnpySubscription.status),
    restUnpySubscription.description,
    toProduct(restUnpySubscription.product),
    restUnpySubscription.clientSecretKey,
  );
}

export const toUnpySession = (restUnpySession: RestUnpySession) =>
  new UnpySession(restUnpySession.urlCheckout);
