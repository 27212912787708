import './MessagePreview.scss';

import { Message } from '@mui/icons-material';
import { Badge, IconButton } from '@mui/material';
import classNames from 'classnames';
import { NotificationTypeEnum } from 'domain/notification/NotificationTypeEnum';
import {
  incrementNumberUnviewedMessages,
  setNumberUnviewedMessages,
} from 'domain/notification/store/NotificationSlice';
import { UnpyNotification } from 'domain/notification/UnpyNotification';
import { useSubscribeTopic } from 'primary/Components/User/NotificationPreview';
import { MESSAGES_UPDATE_EVENT } from 'primary/pages/messages/MyMessages.page';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from '../../../store';
import { routesConfig } from '../../Configs/Routes.config';
import { useContextDependency } from '../../hooks/useContextDependency';

interface MessagePreviewProps {
  userId: number;
}
export const MessagePreview = ({ userId }: MessagePreviewProps) => {
  const anchorEl = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = React.useState(false);
  const { authRepository, messageRepository } = useContextDependency();
  const dispatch = useDispatch();
  const numberUnviewed = useSelector((state: RootState) =>
    Object.values(state.notification.numberUnviewedMessagesByConversation).reduce(
      (acc, curr) => acc + curr,
      0,
    ),
  );

  useEffect(() => {
    messageRepository.getNumberUnviewed().then((numberUnviewed) => {
      const toDispatch = numberUnviewed.reduce((acc, curr) => {
        return {
          ...acc,
          // @ts-ignore
          [curr.idConversation]: (acc[curr.idConversation] || 0) + curr.countUnviewed,
        };
      }, {});
      return dispatch(setNumberUnviewedMessages(toDispatch));
    });
  }, []);

  useSubscribeTopic<UnpyNotification>(
    `/notification/unviewed/message/${userId}`,
    (message) => {
      if (message.type === NotificationTypeEnum.NEW_MESSAGE) {
        if (!message.resources.CONVERSATION_ID) {
          console.warn("Can't increase unviewed message no conversation ID", message);
          return;
        }
        dispatch(
          incrementNumberUnviewedMessages(parseInt(message.resources.CONVERSATION_ID)),
        );
        const event = new CustomEvent(MESSAGES_UPDATE_EVENT);
        document.dispatchEvent(event);
      }
    },
  );

  const navigate = useNavigate();

  return (
    <>
      <IconButton
        className={classNames('messages-menu', {
          '-someUnviewed': numberUnviewed > 0,
        })}
        onClick={() => navigate(routesConfig.messages.to())}
      >
        <Badge badgeContent={numberUnviewed} color="primary">
          <Message />
        </Badge>
      </IconButton>
    </>
  );
};
