import './ModalConfirmEvent.scss';

import { Cancel } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button, Divider, Grid, TextField } from '@mui/material';
import { ValidationStringLengthEnum } from 'domain/enums/ValidationStringLengthEnum';
import { IEventRepository } from 'domain/event/Event.repository';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { formatDate } from 'helper/date.helper';
import { InputWrapper } from 'primary/Components/Input/InputWrapper';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useYupValidationResolver } from 'primary/hooks/useYupValidationResolver';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Title } from '../../../../Components/Title/Title';
import { useTranslate, UseTranslateReturn } from '../../../../hooks/useTranslate';

interface ModalConfirmEventProps {
  handleClose: () => void;
  event: UnpyEvent | UnpyEventView;
  onConfirmed: (event: UnpyEvent) => void;
}

export type DelayEventStartDateFormData = {
  startDateTime: string;
};

const validationSchema = (t: UseTranslateReturn) =>
  yup.object({
    startDateTime: yup
      .string()
      .max(
        ValidationStringLengthEnum.LONG,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
      )
      .required(t('general.forms.errors.required')),
  });

export const useOnSubmit =
  (
    eventRepository: IEventRepository,
    idEvent: number,
    onSubmitted: (event: UnpyEvent) => void,
    handleClose: () => void,
  ) =>
  (data: DelayEventStartDateFormData) => {
    eventRepository
      .delayEvent(idEvent, data)
      .then((event) => onSubmitted(event))
      .finally(() => handleClose());
  };

const ModalConfirmEvent = ({
  handleClose,
  event,
  onConfirmed,
}: ModalConfirmEventProps) => {
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<DelayEventStartDateFormData>({ resolver });
  const { eventRepository } = useContextDependency();
  const t = useTranslate();
  const onSubmit = useOnSubmit(eventRepository, event.id, onConfirmed, handleClose);
  const onClickConfirm = () => {
    eventRepository
      .confirmStartDate(event.id, { startDateTime: event.startDateTime.toString() })
      .then((event) => onConfirmed(event));
  };

  const onClickCancel = () => {
    eventRepository.cancelEvent(event.id).then((event) => onConfirmed(event));
  };
  return (
    <div className={'modal-confirm-event'}>
      <Title
        threeQuarter
        bordered
        title={t('event.modal.confirmEvent.title')}
        level={2}
      />
      <div className={'confirmEventRow'}>
        <div className={'confirmEventCol'}>
          <h3>Confirmer </h3>
          <span>
            Date de début actuelle :{' '}
            {formatDate(event.startDateTime, 'readable(with hour)')}
          </span>
          <Button
            className={'btn'}
            variant={'contained'}
            color={'primary'}
            onClick={onClickConfirm}
          >
            {t('event.modal.confirmEvent.confirmBtn')}
          </Button>
        </div>
        <Divider orientation="vertical" className={'-divider'} />
        <div className={'confirmEventCol'}>
          <h3>Repousser</h3>
          <Box className={'unpy-form'}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Alert severity="error">{t('event.modal.confirmEvent.alertDelayed')}</Alert>
              <Grid container>
                <Grid item xs={12}>
                  <InputWrapper
                    className={'unpy-form__field'}
                    label={t('event.form.labels.startDateTime')}
                    errors={errors}
                    isSubmitting={isSubmitting}
                    Input={TextField}
                    inputProps={{
                      placeholder: t('event.form.placeholders.startDateTime'),
                      ...register('startDateTime', { required: true }),
                      type: 'datetime-local',
                      variant: 'filled',
                    }}
                    required
                  />
                </Grid>
              </Grid>
              <div className={'unpy-form__buttons'}>
                <Button
                  variant={'outlined'}
                  color={'primary'}
                  onClick={() => handleClose()}
                  className={'cancel'}
                >
                  <Cancel />
                  {t('classement.parameters.generateClassementModalCancelBtn')}
                </Button>
                <LoadingButton
                  variant={'contained'}
                  color={'primary'}
                  type={'submit'}
                  loading={isSubmitting}
                >
                  {t('event.modal.confirmEvent.delayedBtn')}
                </LoadingButton>
              </div>
            </form>
          </Box>
        </div>
        <Divider orientation="vertical" className={'-divider'} />
        <div className={'confirmEventCol'}>
          <h3>Annuler</h3>
          <Button
            className={'btn'}
            variant={'contained'}
            color={'primary'}
            onClick={onClickCancel}
          >
            {t('event.modal.confirmEvent.canceledBtn')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirmEvent;
