import './UserProfilCard.scss';

import React from 'react';

import { UserProfil } from '../../../../domain/profil/UserProfil';
import { useBooleanToggle } from '../../../hooks/useBooleanToggle';
import { UserProfilFrontContent } from './front/UserProfilFrontContent';

type UserProfilCardProps = {
  profil: UserProfil;
  isPreview?: boolean;
};

export type UserProfilContext = UserProfilCardProps;

export const UserProfilCard = ({ profil, isPreview }: UserProfilCardProps) => {
  const [isOpen, toggleOpen, open, close] = useBooleanToggle();
  const styleBackground = !isPreview
    ? {
        backgroundImage: `url("${profil.backgroundImage?.publicUrl}")`,
        backgroundSize: 'cover',
      }
    : {};
  return (
    <div className={'userProfilCard'}>
      <div style={styleBackground} className={'-backgroundUserProfil'}></div>
      <UserProfilFrontContent isPreview={isPreview} profil={profil} />
    </div>
  );
};
