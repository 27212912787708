import axios from 'axios';
import { Auth as AuthRepositoryType } from 'domain/auth/Auth.repository';
import Keycloak from 'keycloak-js';
import { AnnonceRepository } from 'secondary/annonce/Annonce.repository';
import { AuthRepository } from 'secondary/auth/Auth.repository';
import { MatchsRepository } from 'secondary/event/match/Matchs.repository';
import { EventParticipantRepository } from 'secondary/event/participants/EventParticipant.repository';
import { InvitationLinkRepository } from 'secondary/invitationLink/InvitationLink.repository';
import { InvoiceRepository } from 'secondary/payment/Invoice.repository';
//import { FirebaseRepository } from 'secondary/firebase/Firebase.repository';
import { ProductRepository } from 'secondary/payment/Product.repository';
import { SubscriptionRepository } from 'secondary/payment/Subscription.repository';
import { ProfilPrivacyRepository } from 'secondary/privacy/ProfilPrivacy.repository';

import { Environment } from './domain/environment/Environment';
import { EnvironmentRepository } from './domain/environment/Environment.repository';
import { TranslationRepository } from './domain/translation/Translation.repository';
import { ContextDependency } from './primary/contextDependency';
import { AuthManager } from './secondary/auth/AuthManager';
import { BackgroundImageRepository } from './secondary/backgroundImage/BackgroundImage.repository';
import { ClassementRepository } from './secondary/classement/Classement.repository';
import { CountryRepository } from './secondary/country/Country.repository';
import {
  createConnectedAxios,
  createNotConnectedAxios,
} from './secondary/createAxiosInstance.nt';
import { DocumentRepository } from './secondary/documents/Document.repository';
import { SystemEnvironmentRepository } from './secondary/environment/SystemEnvironment.repository';
import { EventRepository } from './secondary/event/Event.repository';
import { GameProfilRepository } from './secondary/gameProfil/GameProfil.repository';
import { LangRepository } from './secondary/lang/Lang.repository';
import { InMemoryRestmarketPlaceAnnouncementView } from './secondary/marketPlaceAnnouncement/InMemoryRestmarketPlaceAnnouncementView';
import { InMemoryRestMercatoAnnouncementView } from './secondary/mercatoAnnouncement/InMemoryRestMercatoAnnouncementView';
import { MessageRepository } from './secondary/message/Message.repository';
import { NeonRepository } from './secondary/neon/Neon.repository';
import { NotificationRepository } from './secondary/notification/Notification.repository';
import { PlatformRepository } from './secondary/platform/Platform.repository';
import { AllProfilRepository } from './secondary/profils/AllProfil.repository';
import { RelationRepository } from './secondary/relation/Relation.repository';
import { RoleStructureRepository } from './secondary/roleStructure/RoleStructure.repository';
import { SocketRepository } from './secondary/socket/Socket.repository';
import { StructureRepository } from './secondary/structure/Structure.repository';
import { StructureMemberRepository } from './secondary/structureMember/StructureMember.repository';
import { StructureProfilRepository } from './secondary/structureProfil/StructureProfil.repository';
import { TeamRepository } from './secondary/team/Team.repository';
import { TranslationI18nRepository } from './secondary/translation/TranslationI18n.repository';
import { UserProfilRepository } from './secondary/userProfil/UserProfil.repository';

export const getDefaultContextDependency = (
  _authRepository: AuthRepositoryType,
  environment: Environment,
  translationRepository: TranslationRepository,
): ContextDependency => {
  const axiosConnected = createConnectedAxios(
    {
      baseURL: environment.apiWebserviceUrl,
    },
    _authRepository,
    translationRepository,
  );
  const axiosPublic = axios.create({
    baseURL: environment.apiWebserviceUrl,
  });

  const socketRepository = new SocketRepository(
    axiosConnected,
    environment.socketUrl,
    _authRepository,
  );

  return {
    authRepository: _authRepository,
    translationRepository: translationRepository,
    eventRepository: new EventRepository(axiosConnected),
    teamRepository: new TeamRepository(axiosConnected),
    mercatoAnnouncementRepository: new InMemoryRestMercatoAnnouncementView(
      axiosConnected,
    ),
    marketPlaceAnnouncementRepository: new InMemoryRestmarketPlaceAnnouncementView(
      axiosConnected,
    ),
    userProfilRepository: new UserProfilRepository(axiosConnected),
    countryRepository: new CountryRepository(axiosConnected),
    documentRepostory: new DocumentRepository(axiosConnected),
    backgroundImageRepostory: new BackgroundImageRepository(axiosConnected),
    neonRepository: new NeonRepository(axiosConnected),
    platformRepository: new PlatformRepository(axiosConnected),
    gameProfilRepoitory: new GameProfilRepository(axiosConnected),
    langRepository: new LangRepository(axiosConnected),
    structureRepository: new StructureRepository(axiosConnected),
    structureProfilRepository: new StructureProfilRepository(axiosConnected),
    roleStructureRepository: new RoleStructureRepository(axiosConnected),
    structureMemberRepository: new StructureMemberRepository(axiosConnected),
    messageRepository: new MessageRepository(axiosConnected),
    notificationRepository: new NotificationRepository(axiosConnected),
    allProfilRepository: new AllProfilRepository(axiosPublic),
    classementRepository: new ClassementRepository(axiosConnected),
    socketRepository: socketRepository,
    relationRepository: new RelationRepository(axiosConnected),
    productRepository: new ProductRepository(axiosConnected),
    subscribtionRepository: new SubscriptionRepository(axiosConnected),
    profilPrivacyRepository: new ProfilPrivacyRepository(axiosConnected),
    invoiceRepository: new InvoiceRepository(axiosConnected),
    annonceRepository: new AnnonceRepository(axiosConnected),
    invitationLinkRepository: new InvitationLinkRepository(axiosConnected),
    matchsRepository: new MatchsRepository(axiosConnected),
    eventParticipantRepository: new EventParticipantRepository(axiosConnected),
    //    messagingRepository: new FirebaseRepository(),
  };
};
export const getDefaultContextDependencyForApp = (): ContextDependency => {
  const environmentRepository: EnvironmentRepository = new SystemEnvironmentRepository();
  const environment: Environment = environmentRepository.get();
  const translationRepository: TranslationRepository = new TranslationI18nRepository();

  const axiosNotConnected = createNotConnectedAxios(
    {
      baseURL: environment.apiWebserviceUrl,
    },
    translationRepository,
  );

  const authRepository: AuthRepository = new AuthRepository(
    new AuthManager(
      new Keycloak({
        url: environment.keycloakUrl,
        realm: environment.keycloakRealm,
        clientId: environment.keycloakClientId,
      }),
    ),
    axiosNotConnected,
  );

  return getDefaultContextDependency(authRepository, environment, translationRepository);
};
