import { UserView } from 'domain/user/UserView';

import { RoleStructure } from '../roleStructure/RoleStructure';
import { Structure } from '../structure/Structure';

export interface StructureMemberFilters {
  idPlayer?: string;
  idStructure?: string;
  isAccepted?: boolean;
  asMember?: boolean;
}
export class StructureMember {
  constructor(
    public readonly user: UserView,
    public readonly role: RoleStructure,
    public readonly accepted: boolean | null,
    public readonly structure?: Structure,
  ) {}
}
