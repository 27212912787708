import { ValidationStringLengthEnum } from 'domain/enums/ValidationStringLengthEnum';
import { RolesStructureEnum } from 'domain/security/RolesStructureEnum';
import { Structure } from 'domain/structure/Structure';
import { CoupleButtonValidCancel } from 'primary/Components/CoupleButtonValidCancel/CoupleButtonValidCancel';
import { InputSelectMui } from 'primary/Components/Input/InputSelectMui';
import React, { FC } from 'react';
import { useController, useForm, UseFormSetValue } from 'react-hook-form';
import * as yup from 'yup';

import { StructureMember } from '../../../domain/structureMember/StructureMember';
import { IStructureMemberRepository } from '../../../domain/structureMember/StructureMember.repository';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useFetch } from '../../hooks/useFetch';
import { useTranslate, UseTranslateReturn } from '../../hooks/useTranslate';
import { useYupValidationResolver } from '../../hooks/useYupValidationResolver';
import { ChangeHandlerTypeMeta, InputWrapper } from '../Input/InputWrapper';
import { sendEventToastMessage } from '../Toast/Toast.helper';

export type MemberFormDataSubmit = {
  role: string;
  user: string;
  structure: string;
};

export type MemberFormData = {
  role?: string;
};

const validationSchema = (t: UseTranslateReturn) =>
  yup.object({
    role: yup
      .string()
      .max(
        ValidationStringLengthEnum.SMALL,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.SMALL }),
      )
      .nullable(),
  });

export const useOnSubmit =
  (
    t: UseTranslateReturn,
    structureMemberRepository: IStructureMemberRepository,
    onSubmitted: (structureMember: StructureMember) => void,
    userId: number,
    structureId: number,
  ) =>
  (data: MemberFormData) => {
    structureMemberRepository
      .updateMember({ ...data, user: userId, structure: structureId })
      .then(onSubmitted)
      .then(() => sendEventToastMessage(t('general.edit.success'), 'success'));
  };

interface MemberFormProps {
  onSubmitted: (structureMember: StructureMember) => void;
  initialValues: MemberFormData;
  userId?: number;
  handleClose?: () => void;
  structure?: Structure;
}

export const _changeHandlerRole =
  (setValue: UseFormSetValue<MemberFormData>) => (event: ChangeHandlerTypeMeta) => {
    setValue('role', event.target.value as string);
  };

export const MemberRoleForm: FC<MemberFormProps> = ({
  onSubmitted,
  initialValues,
  userId,
  structure,
  handleClose,
}) => {
  const { roleStructureRepository, structureMemberRepository, authRepository } =
    useContextDependency();
  const resolver = useYupValidationResolver(validationSchema);
  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    getValues,
    formState: { isSubmitting, errors },
  } = useForm<MemberFormData>({
    resolver,
    defaultValues: initialValues,
  });
  const t = useTranslate();

  if (!userId || !structure) return null;
  const onSubmit = useOnSubmit(
    t,
    structureMemberRepository,
    onSubmitted,
    userId,
    structure.id,
  );

  const isPresident = structure.members.find(
    (member) =>
      member.user.id === authRepository.currentUser?.id &&
      member.role?.code === RolesStructureEnum.PRES,
  );

  const [roles] = useFetch(() => roleStructureRepository.getAll(), undefined);

  const submit = handleSubmit(onSubmit);

  const { field: roleField } = useController({ control: control, name: 'role' });

  return (
    <form onSubmit={submit} name={'member-role-form'}>
      <InputWrapper
        errors={errors}
        Input={InputSelectMui}
        isSubmitting={isSubmitting}
        notice={
          isPresident
            ? structure.members.length > 1
              ? t('structure.members.helperTextChiefCantEditRole')
              : t('structure.members.helperTextCantEditRoleOneMemberPresident')
            : ''
        }
        inputProps={{
          ...roleField,
          options: roles?.map((role) => ({
            label: t(`structure.roles.${role.code}`),
            value: role.id.toString(),
          })),
          onChange: _changeHandlerRole(setValue),
          disabled: isPresident && structure.members.length <= 1,
        }}
      />
      <CoupleButtonValidCancel
        isTypeSubmit={true}
        onClickCancel={() => handleClose && handleClose()}
      />
    </form>
  );
};
