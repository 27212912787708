import './ProfilPhotoOverview.scss';

import { Avatar } from '@mui/material';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../store';
import { useContextDependency } from '../../hooks/useContextDependency';

interface ProfilPhotoOverviewProps {
  className?: string;
}

export const OwnProfilPhotoOverview: FC<ProfilPhotoOverviewProps> = ({ className }) => {
  const { documentRepostory } = useContextDependency();
  const user = useSelector((state: RootState) => state.user.connectedUser);
  return (
    <div>
      {user?.userProfil.publicPhotoUrl && (
        <Avatar
          className={className}
          alt={'Photo de profil utilisateur'}
          src={user?.userProfil.publicPhotoUrl}
        />
      )}
    </div>
  );
};
