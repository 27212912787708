import './InfiniteScroll.scss';

import { CircularProgress } from '@mui/material';
import { debounce } from 'lodash';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { createRef, useEffect, useRef } from 'react';

type InfiniteScrollProps = {
  onScrollBottom?: () => void;
  onScrollTop?: () => void;
  isLoading?: boolean;
  loadingOnTop?: boolean;
  loadingOnBottom?: boolean;
  loadingMessage?: string;
  children: (scrollToTop: () => void, scrollToBottom: () => void) => React.ReactNode;
};
export const InfiniteScroll = ({
  onScrollBottom,
  onScrollTop,
  isLoading,
  loadingMessage,
  children,
  loadingOnBottom,
  loadingOnTop,
}: InfiniteScrollProps) => {
  const t = useTranslate();
  const refScrollableContainer = createRef<HTMLDivElement>();
  const onScrollBottomDebonced = debounce(() => {
    onScrollBottom?.();
  }, 100);

  const onScrollTopDebonced = debounce(() => {
    onScrollTop?.();
  }, 100);
  const isScrolled = useRef(false);

  const scrollToTop = () => {
    refScrollableContainer?.current?.scrollTo(
      0,
      refScrollableContainer?.current?.scrollHeight,
    );
  };

  const scrollToBottom = () => {
    refScrollableContainer?.current?.scrollTo(
      0,
      refScrollableContainer?.current?.scrollHeight,
    );
  };

  useEffect(() => {
    refScrollableContainer?.current?.addEventListener('scroll', () => {
      const scrollBottomEnd =
        refScrollableContainer?.current?.scrollHeight &&
        refScrollableContainer?.current?.offsetHeight &&
        refScrollableContainer?.current?.scrollTop ===
          refScrollableContainer?.current?.scrollHeight -
            refScrollableContainer?.current?.offsetHeight;
      const scrollTopEnd = refScrollableContainer?.current?.scrollTop === 0;

      if (scrollBottomEnd && !isLoading) {
        onScrollBottomDebonced();
      }
      if (scrollTopEnd && !isLoading) {
        onScrollTopDebonced();
      }
    });

    return () => {
      document.getElementsByTagName('main')?.[0]?.removeEventListener('scroll', () => {
        console.log('listener removed');
      });
    };
  }, [isLoading]);

  return (
    <div className={'infiniteScroll --scrollBarContent'} ref={refScrollableContainer}>
      {isLoading && loadingOnTop && <CircularProgress size={30} color={'info'} />}
      {typeof children === 'function' ? children(scrollToTop, scrollToBottom) : children}
      {isLoading && loadingOnBottom && <CircularProgress size={30} color={'info'} />}
    </div>
  );
};
