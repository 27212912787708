import './ModalFilters.scss';

import { ChevronLeft } from '@mui/icons-material';
import { Button, Divider } from '@mui/material';
import { useTranslate } from 'primary/hooks/useTranslate';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React, { ReactNode } from 'react';

type ModalFiltersProps = {
  filtersComp: ReactNode;
  title?: string;
  resetFilters: () => void;
};

export const ModalFitlers = ({ filtersComp, resetFilters, title }: ModalFiltersProps) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const t = useTranslate();
  return (
    <div className={'modalFilterCompContainer'}>
      <Button
        variant={'contained'}
        color={'primary'}
        className={'-openFilterModal'}
        onClick={() => setIsModalOpen(true)}
      >
        {t('event.filters.title')}
      </Button>
      <ModalContainer
        handleClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        fullScreen
        content={
          <div className={'-modalFiltersContent'}>
            <div className={'-header'}>
              <Button
                variant={'outlined'}
                onClick={() => setIsModalOpen(false)}
                color={'info'}
                className={'-goBack'}
                type={'submit'}
              >
                <ChevronLeft />
                {t('event.eventsPage.goBack')}
              </Button>
              {title || t('event.filters.title')}
            </div>
            <Divider flexItem />
            <div className={'-content'}>{filtersComp}</div>
            <div className={'-footer'}>
              <Button onClick={resetFilters} color={'primary'} variant={'outlined'}>
                {t(`layoutPageCommonList.filtersResetBtn`)}
              </Button>
              <Button
                variant={'outlined'}
                onClick={() => setIsModalOpen(false)}
                color={'primary'}
                type={'submit'}
              >
                {t('event.filters.validate')}
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
};
