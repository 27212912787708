import './TagPlayer.scss';

import classNames from 'classnames';
import { getNeonBorderClassName } from 'domain/profil/Profil.func';
import { UserProfil } from 'domain/profil/UserProfil';
import { UserProfilView } from 'domain/user/UserProfilView';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { routesConfig } from '../../Configs/Routes.config';
import { WrapperOtherProfilPhotoOverview } from '../User/WrapperOtherProfilPhotoOverview';

interface TagPlayerProps {
  player: UserProfil | UserProfilView;
  miniature?: boolean;
  embed?: boolean;
}

export const TagPlayer = ({ player, miniature, embed }: TagPlayerProps) => {
  const neonBorderClassName = getNeonBorderClassName(player);
  const navigate = useNavigate();

  const handleClick = () => {
    const id = player instanceof UserProfilView ? player.idProfil : player.id;
    navigate(routesConfig.mercatoUserProfil.to(id));
  };
  return (
    <div
      className={classNames('tagPlayer', { '-miniature': miniature })}
      title={player.idPlayer}
      onClick={handleClick}
    >
      <div className={classNames('tagPlayer__photo', { '-embed': embed })}>
        <WrapperOtherProfilPhotoOverview
          size={'small'}
          displayName={player.idPlayer}
          photo={player.publicPhotoUrl}
          className={classNames('photo', '-photo', neonBorderClassName)}
          idUser={player.id as number}
        />
      </div>
      <span className={'tagPlayer__name'}>
        {!miniature
          ? player.idPlayer
          : player.idPlayer[0].toUpperCase() +
            player.idPlayer[player.idPlayer.length - 1].toUpperCase()}
      </span>
    </div>
  );
};
