import './PageLayout.scss';

import classNames from 'classnames';
import React from 'react';
import { ReactNode } from 'react';
interface PageLayoutProps {
  children: ReactNode;
  withBackground?: boolean;
}

export const PageLayout = ({ children, withBackground }: PageLayoutProps) => {
  return (
    <div className={classNames('pageLayout', { '-withBackground': withBackground })}>
      {children}
    </div>
  );
};
