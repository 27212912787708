import './EventPhotoForm.scss';

import { InputFile } from 'primary/Components/Input/InputFile';
import {
  ChangeHandlerTypeParam,
  InputWrapper,
} from 'primary/Components/Input/InputWrapper';
import { EventAppearenceFormData } from 'primary/events/forms/EventAppearenceForm';
import React, { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { useTranslate } from '../../hooks/useTranslate';

interface BannerFormProps {
  form: UseFormReturn<EventAppearenceFormData>;
  onUploadFile: (fileUploaded?: File) => void;
  fileUploaded?: File;
  isdefaultImage?: boolean;
  initialPath?: string;
  disabled?: boolean;
}

export const EventPhotoForm: FC<BannerFormProps> = ({
  form,
  onUploadFile,
  fileUploaded,
  isdefaultImage,
  disabled,
}) => {
  const t = useTranslate();
  return (
    <div className={'-eventPhotoForm'}>
      <InputWrapper
        label={t('event.form.labels.image')}
        errors={form.formState.errors}
        Input={InputFile}
        inputClassName={'input-profil-photo'}
        labelClassName={'label-user-details '}
        isSubmitting={form.formState.isSubmitting}
        required
        inputProps={{
          ...form.register('image', { required: false }),
          placeholder: t('parameters.menus.profil.user.form.profilPhotoPlaceholder'),
          canDelete: !isdefaultImage || !!fileUploaded,
          onChange: (event: ChangeHandlerTypeParam) => {
            form.setValue('image', event.target.value as string);
            return Promise.resolve();
          },
          onFileUpload: (file?: File) => {
            if (file?.size) {
              const size = Math.round(file.size / 1024);
              if (size > 4096) {
                form.setError('image', {
                  message: t('parameters.menus.profil.user.form.profilPhotoSizeError'),
                });
                return Promise.reject(
                  t('parameters.menus.profil.user.form.profilPhotoSizeError'),
                );
              }
            }
            onUploadFile(file);
          },
          fileName: form.getValues('image') || fileUploaded?.name,
          fileUploadedName: fileUploaded?.name || form.getValues('image'),
          hiddenInputProps: { ...form.register('image', { required: false }) },
          disabled: disabled,
        }}
      />
    </div>
  );
};
