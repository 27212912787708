import { routesConfig } from '../../Configs/Routes.config';

export type ConfParametersLink = {
  label: string;
  link: string | ((...args: any) => string);
  path: string;
  icon?: string;
  params?: string[];
};

export type ConfParameterItem = {
  title: string;
  path: string;
  subMenu: ConfParametersLink[];
};

export type ListConfParametersMenu = {
  parametersItems: ConfParameterItem[];
  defaultTabUrl: string;
};

export const subMenuParametersEventGeneral: ConfParametersLink[] = [
  {
    label: 'Apparence',
    link: routesConfig.parametersDetailsEvent.apparence.to,
    path: routesConfig.parametersDetailsEvent.apparence.path,
    params: ['idEvent'],
  },
  {
    label: 'Paramètrage',
    link: routesConfig.parametersDetailsEvent.parameterize.to,
    path: routesConfig.parametersDetailsEvent.parameterize.path,
    params: ['idEvent'],
  },
  {
    label: 'Timeline',
    link: routesConfig.parametersDetailsEvent.timeline.to,
    path: routesConfig.parametersDetailsEvent.timeline.path,
    params: ['idEvent'],
  },
];

export const subMenuParametersEventHandling: ConfParametersLink[] = [
  {
    label: 'Règles',
    link: routesConfig.parametersDetailsEvent.rules.to,
    path: routesConfig.parametersDetailsEvent.rules.path,
    params: ['idEvent'],
  },
  {
    label: 'Participants',
    link: routesConfig.parametersDetailsEvent.participants.to,
    path: routesConfig.parametersDetailsEvent.participants.path,
    params: ['idEvent'],
  },
  {
    label: 'Classement',
    link: routesConfig.parametersDetailsEvent.classement.to,
    path: routesConfig.parametersDetailsEvent.classement.path,
    params: ['idEvent'],
  },
  {
    label: 'Matchs & Scores',
    link: routesConfig.parametersDetailsEvent.scoreboard.to,
    path: routesConfig.parametersDetailsEvent.scoreboard.path,
    params: ['idEvent'],
  },
];

export const menusParametersEvent: ListConfParametersMenu = {
  defaultTabUrl: routesConfig.parametersEvent.path,
  parametersItems: [
    {
      title: 'Gestion',
      path: '/handling',
      subMenu: subMenuParametersEventHandling,
    },
    {
      title: 'Généralité',
      path: '/general',
      subMenu: subMenuParametersEventGeneral,
    },
  ],
};

export const menusParametersEventMobile: ListConfParametersMenu = {
  defaultTabUrl: routesConfig.parametersEvent.path,
  parametersItems: [
    {
      title: 'Gestion',
      path: '/handling',
      subMenu: subMenuParametersEventHandling,
    },
    {
      title: 'Généralité',
      path: '/general',
      subMenu: subMenuParametersEventGeneral,
    },
  ],
};
