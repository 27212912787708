import './ProfilPhotoForm.scss';

import { ProfilAppearenceFormData } from 'primary/Parameters/user/Profil/UserProfilAppearence';
import React, { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { useTranslate } from '../../hooks/useTranslate';
import { InputFile } from '../Input/InputFile';
import { ChangeHandlerTypeParam, InputWrapper } from '../Input/InputWrapper';

export type ProfilPhotoFormData = {
  fileNameUploaded?: string;
  file?: File;
};

interface ProfilPhotoFormProps {
  form: UseFormReturn<ProfilAppearenceFormData>;
  onUploadFile: (file?: File) => void;
  fileUploaded?: File;
  isDefaultPhoto?: boolean;
  initialPath?: string;
}

export const ProfilPhotoForm: FC<ProfilPhotoFormProps> = ({
  form,
  onUploadFile,
  fileUploaded,
  isDefaultPhoto,
  initialPath,
}) => {
  const t = useTranslate();

  return (
    <div className={'profilPhotoForm'}>
      <h3>{t('parameters.menus.profil.user.form.profilPhotoLabel')} *</h3>
      <InputWrapper
        errors={form.formState.errors}
        Input={InputFile}
        inputClassName={'input-profil-photo'}
        labelClassName={'label-user-details '}
        isSubmitting={form.formState.isSubmitting}
        inputProps={{
          ...form.register('fileNameUploaded'),
          canDelete: !isDefaultPhoto || !!fileUploaded,
          initialPath: initialPath,
          placeholder: t('parameters.menus.profil.user.form.profilPhotoPlaceholder'),
          onFileUpload: (file?: File) => {
            if (file?.size) {
              const size = Math.round(file.size / 1024);
              if (size > 4096) {
                form.setError('fileNameUploaded', {
                  message: t('parameters.menus.profil.user.form.profilPhotoSizeError'),
                });
                return Promise.reject(
                  t('parameters.menus.profil.user.form.profilPhotoSizeError'),
                );
              } else {
                form.setError('fileNameUploaded', {});
              }
            }
            onUploadFile(file);
          },
          onChange: (event: ChangeHandlerTypeParam) => {
            form.setValue('fileNameUploaded', event.target.value as string | undefined);
            return Promise.resolve();
          },
          fileName: fileUploaded?.name || form.getValues('fileNameUploaded'),
        }}
      />
    </div>
  );
};
