import './MenuEventViewPage.scss';

import BottomNavigation from '@mui/material/BottomNavigation/BottomNavigation';
import { confNavBarProfil } from 'primary/Components/Profils/User/TopBarMenuProfil/TopBarMenuProfil';
import { BottomNavItemEventView } from 'primary/events/BottomNavItemEventView';
import { confNavBar } from 'primary/events/MenuEventViewPage';
import React, { FC } from 'react';

interface BottomNavActionsProps {
  confs: confNavBar[] | confNavBarProfil[];
}

export const BottomNavEventView: FC<BottomNavActionsProps> = ({ confs }) => {
  const [value, setValue] = React.useState(0);

  return (
    <BottomNavigation
      showLabels
      value={value}
      className={'bottomNav'}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
    >
      {/*@ts-ignore*/}
      <BottomNavItemEventView key={'menu-event-view'} conf={confs} />
    </BottomNavigation>
  );
};
