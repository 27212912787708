import { StructureProfil } from '../profil/StructureProfil';
import { StructureMember } from '../structureMember/StructureMember';

export interface StructureFilters {
  name?: string;
  asCreator?: boolean;
  asMember?: boolean;
  member?: number;
}
export class Structure {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly members: StructureMember[],
    public readonly structureProfil?: StructureProfil,
  ) {}
}
