import { toast } from 'react-toastify';

import { TypeToast } from './Toast';

export const sendEventToastMessage = (
  message: string,
  type: TypeToast = 'info',
  duration?: number,
) => {
  toast(message, { type, autoClose: duration });
};
