import { Client, IMessage } from '@stomp/stompjs';
import { AxiosInstance } from 'axios';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';

import { Auth } from '../../domain/auth/Auth.repository';
import { ISocketRepository } from '../../domain/socket/ISocket.repository';

export class SocketRepository
  extends AxiosProviderRepository
  implements ISocketRepository
{
  url: string;
  client?: Client;
  token?: string;
  onConnected?: () => void;
  tryReconnect = 0;
  constructor(readonly axios: AxiosInstance, url: string, readonly authRepository: Auth) {
    super(axios);
    this.url = url;
    this.authRepository = authRepository;
  }

  isReady(): boolean {
    return !!this.client?.connected;
  }

  async init(userId: number, onConnected: () => void): Promise<boolean> {
    this.token = await this.authRepository.getToken();
    this.onConnected = onConnected;
    return this.initWithToken(onConnected);
  }

  private async initWithToken(onConnected: () => void): Promise<boolean> {
    if (!this.token) {
      console.error('Cannot init WS without token');
      return Promise.reject('Cannot init WS without token');
    }
    this.client = new Client({
      connectHeaders: {
        ['X-Authorization']: `Bearer ${this.token}`,
      },
      brokerURL: this.url,
      reconnectDelay: 300000,
      heartbeatIncoming: 0,
      heartbeatOutgoing: 0,
      onWebSocketError: (err) => {
        console.error('WS Error', err);
        if (this.tryReconnect < 3) {
          this.tryReconnect++;
          this.reconnect();
        }
      },
      onConnect: onConnected,
    });
    if (!this.client.active) {
      this.client.activate();
    }
    return Promise.resolve(this.client.connected);
  }

  async reconnect(): Promise<boolean> {
    if (this.client) {
      this.client.forceDisconnect();
      this.token = await this.authRepository.getToken();
      if (this.onConnected) {
        return this.initWithToken(this.onConnected);
      } else {
        console.warn('No inited WS founded');
        return Promise.reject(false);
      }
    } else {
      console.warn('No client found to reconnect WS unpy.');
      return Promise.reject(false);
    }
  }

  send(toUserId: string, message: string): void {
    if (this.isReady()) {
      return this.client?.publish({
        destination: `/user/message/${toUserId}`,
        body: message,
      });
    }
  }

  subscribeTo<R>(topic: string, callBack: (message: R) => void) {
    if (this.isReady() && this.client) {
      return this.client?.subscribe(topic, (msg: IMessage) => {
        if (msg.body) {
          const jsonBody = JSON.parse(msg.body);
          if (jsonBody) {
            callBack(jsonBody as R);
          }
        }
      });
    } else {
      console.warn('Cant connect, not ready');
    }
  }
}
