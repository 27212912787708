import { ValidationStringLengthEnum } from 'domain/enums/ValidationStringLengthEnum';
import { IStructureMemberRepository } from 'domain/structureMember/StructureMember.repository';
import { CoupleButtonValidCancel } from 'primary/Components/CoupleButtonValidCancel/CoupleButtonValidCancel';
import { StructureSelector } from 'primary/Components/Ping/StructureSelector';
import { sendEventToastMessage } from 'primary/Components/Toast/Toast.helper';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate, UseTranslateReturn } from 'primary/hooks/useTranslate';
import { useYupValidationResolver } from 'primary/hooks/useYupValidationResolver';
import React from 'react';
import { useController, useForm } from 'react-hook-form';
import * as yup from 'yup';

interface InviteStructureFormProps {
  userId: string;
  onSubmitted: () => void;
  onCancel: () => void;
}

export type InviteStructureFormData = {
  userId: string;
  structure: string;
  message?: string;
};

const validationSchema = (t: UseTranslateReturn) =>
  yup.object({
    userId: yup.string().required(),
    structure: yup.string().required(t('general.forms.errors.required')),
    message: yup
      .string()
      .max(
        ValidationStringLengthEnum.LONG,
        t('general.forms.errors.tooLong', {
          maxChar: ValidationStringLengthEnum.LONG,
        }),
      )
      .nullable(),
  });

export const onSubmitInviteStructure =
  (
    structureMemberRepository: IStructureMemberRepository,
    onSubmitted: () => void,
    userId: number,
    t: UseTranslateReturn,
  ) =>
  async (data: InviteStructureFormData) => {
    return structureMemberRepository
      .createMember({ ...data, user: userId, structure: data.structure })
      .then(() => {
        onSubmitted();
        sendEventToastMessage(t('modals.inviteStructure.success'), 'success');
      });
  };

export const InviteStructureForm = ({
  userId,
  onSubmitted,
  onCancel,
}: InviteStructureFormProps) => {
  const { structureMemberRepository, authRepository } = useContextDependency();
  const resolver = useYupValidationResolver(validationSchema);
  const {
    handleSubmit,
    control,
    register,
    formState: { isSubmitting, errors },
  } = useForm<InviteStructureFormData>({
    resolver,
    defaultValues: {
      userId: userId,
    },
  });
  const { field: messageField } = useController({ control: control, name: 'message' });
  const t = useTranslate();
  const onSubmit = onSubmitInviteStructure(
    structureMemberRepository,
    () => onSubmitted(),
    parseInt(userId),
    t,
  );
  const structureControl = useController({
    control: control,
    name: 'structure',
  });
  if (!userId || !authRepository.currentUser) return null;

  return (
    <form className={'-inviteStructureForm'} onSubmit={handleSubmit(onSubmit)}>
      <StructureSelector
        userId={authRepository.currentUser.id}
        errors={errors}
        control={structureControl}
        userToExclude={parseInt(userId)}
      />
      <CoupleButtonValidCancel isTypeSubmit={true} onClickCancel={onCancel} />
    </form>
  );
};
