import { UnpySubscriptionStatus } from 'domain/payment/UnpySubscriptionStatus';
import { User } from 'domain/user/User';

export const isUserUp = (user?: User) => {
  if (!user?.subscription) return false;
  return (
    user.subscription.status === UnpySubscriptionStatus.ACTIVE &&
    user.subscription.currentPeriodEnd > new Date()
  );
};
