import { MatchGroup } from 'domain/event/match/MatchGroup';
import { MatchParticipant } from 'domain/event/match/MatchParticipant';
import { MatchResultEnum } from 'domain/event/match/MatchResult.enum';
import { PlayerRoleEnum } from 'domain/event/PlayerRoleEnum';
import { formatDate } from 'helper/date.helper';
import { Match } from 'primary/Components/bracket/CustomBracket/types';

export type UnpyMatchFilters = {
  date?: Date;
  participantIds?: number[];
  idEvent?: string | number;
  roundFrom?: number;
  roundTo?: number;
  idGroup?: number;
  withMatchs?: boolean;
  onlyUpper?: boolean;
  onlyLower?: boolean;
  onlyScoreUnset?: boolean;
  lastModifiedBeforeTodayForParticipant?: number;
  textValue?: string;
};
export class UnpyMatch {
  constructor(
    public readonly id: number,
    public readonly group: MatchGroup,
    public readonly participants: MatchParticipant[],
    public readonly looseMatch: boolean,
    public readonly looseMatchId?: number,
    public readonly nextMachId?: number,
    public readonly numberBO?: number,
    public readonly startDate?: Date,
  ) {}
}

export const toMatchLigueModel = (match: UnpyMatch): Match => {
  return {
    id: match.id,
    unpyMatch: match,
    phaseNumber: match.group.phaseOrder,
    nextMatchId: match.nextMachId ?? null,
    tournamentRoundText: match.group.title,
    startTime: match.startDate
      ? formatDate(match.startDate, 'readable(with hour)')
      : '--/--/--s',
    state: match.participants.find((p) => p.result != null) ? 'completed' : 'pending',
    participants:
      match.participants?.map((participant) => ({
        id: participant.id,
        resultText: participant.result,
        result: participant.result,
        score: participant.score,
        isWinner: participant.result === MatchResultEnum.WIN,
        isForfait: participant.result === MatchResultEnum.FORFAIT,
        isLoose: participant.result === MatchResultEnum.LOOSE,
        status: null,
        name: participant.eventParticipant.team.name,
        team: participant.eventParticipant.team,
        picture: participant.eventParticipant.team.structure
          ? participant.eventParticipant.team.structure?.structureProfil
              ?.bannerImagePublicUrl || null
          : participant.eventParticipant.team.players.find(
              (p) => p.role === PlayerRoleEnum.CHIEF,
            )?.player?.publicPhotoUrl || null,
      })) || [],
  };
};

export const toBracketTournamentModel = (match: UnpyMatch): Match => {
  return {
    id: match.id,
    isLoserMatch: match.looseMatch,
    phaseNumber: match.group.phaseOrder,
    nextMatchId: match.nextMachId ?? null,
    nextLooserMatchId: match.looseMatchId ?? undefined,
    tournamentRoundText: match.group.title,
    unpyMatch: match,
    startTime: match.startDate ? formatDate(match.startDate, 'readable(with hour)') : '',
    state: match.participants.find((p) => p.result != null) ? 'completed' : 'pending',
    participants:
      match.participants?.map((participant) => ({
        id: participant.id,
        resultText: participant.result,
        result: participant.result,
        score: participant.score,
        isWinner: participant.result === MatchResultEnum.WIN,
        isForfait: participant.result === MatchResultEnum.FORFAIT,
        isLoose: participant.result === MatchResultEnum.LOOSE,
        status: null,
        name: participant.eventParticipant.team.name,
        team: participant.eventParticipant.team,
        picture: participant.eventParticipant.team.structure
          ? participant.eventParticipant.team.structure?.structureProfil
              ?.bannerImagePublicUrl || null
          : participant.eventParticipant.team.players.find(
              (p) => p.role === PlayerRoleEnum.CHIEF,
            )?.player?.publicPhotoUrl || null,
      })) || [],
  };
};
