import { AxiosInstance } from 'axios';
import { IProfilPrivacyRepository } from 'domain/privacy/IProfilPrivacyRepository.repository';
import { ProfilPrivacy } from 'domain/privacy/ProfilPrivacy';
import { PrivacyFormData } from 'primary/Parameters/structure/Profil/StructureProfilNetworkForm';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';
import { RestPrivacy, toProfilPrivacy } from 'secondary/privacy/RestPrivacy';

export class ProfilPrivacyRepository
  extends AxiosProviderRepository
  implements IProfilPrivacyRepository
{
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  updateProfilPrivacies(data: PrivacyFormData[]): Promise<ProfilPrivacy[]> {
    return this.axios
      .put(`/unpy/api/privacy/edit`, { restEditPrivacyValidations: data })
      .then((resp) =>
        resp.data.map((restPrivacy: RestPrivacy) => toProfilPrivacy(restPrivacy)),
      );
  }

  deletePrivacy(idPrivacy: number): Promise<void> {
    return this.axios
      .delete(`/unpy/api/privacy/${idPrivacy}`)
      .then((resp) =>
        resp.data.map((restPrivacy: RestPrivacy) => toProfilPrivacy(restPrivacy)),
      );
  }

  getAllPrivaciesOfProfil(idProfil: number): Promise<ProfilPrivacy[]> {
    return this.axios
      .get(`/unpy/api/privacy/profil/${idProfil}/all`)
      .then((resp) =>
        resp.data.map((restPrivacy: RestPrivacy) => toProfilPrivacy(restPrivacy)),
      );
  }
}
