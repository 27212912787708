import './App.scss';

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { loadStripe } from '@stripe/stripe-js';
import classNames from 'classnames';
import { setDefaultOptions } from 'date-fns';
import { fr } from 'date-fns/locale';
import { setRelation } from 'domain/relation/store/RelationSlice';
import { setReady } from 'domain/socket/store/SocketSlice';
import { selectCurrentLanguage } from 'domain/translation/store/TranslationSlice';
import {
  setConnectedUser,
  setCurrentToken,
  setIsAccountLoaded,
} from 'domain/user/store/UserSlice';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { StructureAnnoncesTabs } from 'primary/Components/Profils/User/tabs/ProfilTabsAnnonces';
import { ProfilTabsDetails } from 'primary/Components/Profils/User/tabs/ProfilTabsDetails';
import { ProfilTabsMembers } from 'primary/Components/Profils/User/tabs/ProfilTabsMembers';
import { ProfilTabsStructures } from 'primary/Components/Profils/User/tabs/ProfilTabsStructures';
import { RightPanel } from 'primary/Components/RightPanel/RightPanel';
import { Toast } from 'primary/Components/Toast/Toast';
import { menus } from 'primary/Components/TopBar/Menus.config';
// eslint-disable-next-line unused-imports/no-unused-imports
import { TopBar } from 'primary/Components/TopBar/TopBar';
import { ModalDisconnected } from 'primary/Components/User/ModalDisconnected';
import { routesConfig } from 'primary/Configs/Routes.config';
import { RightPanelContentTeamCreating } from 'primary/events/components/RightPanelContentTeamCreating';
import { EventListPage } from 'primary/events/EventListPage';
import { EventsGamePage } from 'primary/events/EventsGamePage';
import { Annonces } from 'primary/events/EventViewPageContents/Annonces';
import { ClassementView } from 'primary/events/EventViewPageContents/ClassementView';
import { Infos } from 'primary/events/EventViewPageContents/Infos';
import { MatchsView } from 'primary/events/EventViewPageContents/MatchsView';
import { ParticipantsView } from 'primary/events/EventViewPageContents/ParticipantsView';
import {
  TimelineParamMobile,
  TimelineView,
} from 'primary/events/EventViewPageContents/TimelineView';
import { EventViewPage } from 'primary/events/item/EventViewPage';
import { Home } from 'primary/Home/Home';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { ModalConsumeLinkInvitation } from 'primary/InvitationLink/Modal/ModalConsumeLinkInvitation';
import { MercatoPageRefonte } from 'primary/Mercato/MercatoPage';
import { MyActivities } from 'primary/pages/activities/MyActivities.page';
import { PageLogin } from 'primary/pages/login/Login.page';
import { MarketplacePage } from 'primary/pages/marketplace/MarkerplacePage';
import { PageMyMessages } from 'primary/pages/messages/MyMessages.page';
import { MyEventsPage } from 'primary/pages/myEvents/MyEvents.page';
import { MyStructuresPage } from 'primary/pages/myStructures/MyStructures.page';
import { NotificationPage } from 'primary/pages/notification/Notification.page';
import { EventParametersPage } from 'primary/pages/parameters/event/EventParameters.page';
import { StructureParametersPage } from 'primary/pages/parameters/structure/StructureParameters.page';
import { UserParametersPage } from 'primary/pages/parameters/user/UserParameters.page';
import { OtherStructureProfilPage } from 'primary/pages/profil/OtherStructureProfilPage';
import { OtherUserProfilPage } from 'primary/pages/profil/OtherUserProfilPage';
import { RelationsPage } from 'primary/pages/relations/Relations.page';
import { UnpyUpPage } from 'primary/pages/Up/UnpyUp.page';
import { AppearenceEvent } from 'primary/Parameters/event/Appearence';
import { Classement } from 'primary/Parameters/event/Classement';
import { Parameterize } from 'primary/Parameters/event/Parameterize';
import { Participants } from 'primary/Parameters/event/Participants';
import { Rules } from 'primary/Parameters/event/Rules';
import { MembersStructure } from 'primary/Parameters/structure/Admin/MembersStructure';
import { StructureDetails } from 'primary/Parameters/structure/Admin/StructureDetails';
import { StructureProfilNetworkForm } from 'primary/Parameters/structure/Profil/StructureProfilNetworkForm';
import { UnpyStructureProfilStatsParameters } from 'primary/Parameters/structure/Profil/UnpyStructureProfilStatsParameters';
import { Payments } from 'primary/Parameters/user/Account/Payments';
import { UserProfilPersonnalData } from 'primary/Parameters/user/Account/UserProfilPersonnalData';
import { UnpyProfilStatsParameters } from 'primary/Parameters/user/Profil/UnpyProfilStatsParameters';
import { UserDetails } from 'primary/Parameters/user/Profil/UserDetails';
import { UserProfilAppearence as UserProfilAppearence } from 'primary/Parameters/user/Profil/UserProfilAppearence';
import { UserProfilNetworkForm } from 'primary/Parameters/user/Profil/UserProfilNetworkForm';
import { Account } from 'primary/Parameters/user/Security/Account';
import { RedirectCheckout } from 'primary/Stripe/RefirectCheckout';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { RootState } from 'store';

const ProfilTabsPerf = React.lazy(() =>
  import('primary/Components/Profils/User/tabs/ProfilTabsPerf').then((module) => ({
    default: module.ProfilTabsPerf,
  })),
);

const EventMatchsParameters = React.lazy(() =>
  import('primary/Parameters/event/EventMatchsParameters').then((module) => ({
    default: module.EventMatchsParameters,
  })),
);

const StructureProfilAppearence = React.lazy(() =>
  import('primary/Parameters/structure/Profil/StructureProfilAppearence').then(
    (module) => ({
      default: module.StructureProfilAppearence,
    }),
  ),
);

setDefaultOptions({ locale: fr });
const darkTheme = () =>
  createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#ff7800',
      },
      secondary: {
        main: '#202020',
      },
      warning: {
        main: '#ffd036',
      },
      info: {
        main: '#c2c2c2',
      },
    },
    components: {
      MuiFilledInput: {
        styleOverrides: {
          input: {
            paddingTop: '4px',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          colorInherit: 'white',
          disabled: {},
          root: {
            '&.Mui-disabled.-clearBackground': {
              backgroundColor: '#737373',
              color: '#424242',
            },
          },
        },
      },
      MuiPopper: {
        styleOverrides: {
          root: {
            zIndex: 9999,
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          root: {
            zIndex: 9999,
          },
        },
      },
    },
  });

export const useConnectedUser = () => {
  const { authRepository } = useContextDependency();
  const isConnected = authRepository.isConnected();
  const [socketReady] = useState(false);
  const connectedUser = useSelector((state: RootState) => state.user.connectedUser);
  const dispatch = useDispatch();
  const { relationRepository } = useContextDependency();
  const isAccountLoaded = useSelector((state: RootState) => state.user.isAccountLoaded);
  const onUpdateToken = (token?: string) => {
    dispatch(setCurrentToken(token));
  };

  useEffect(() => {
    authRepository
      .init(onUpdateToken)
      .then(() => {
        if (authRepository.currentUser) {
          dispatch(setConnectedUser(authRepository.currentUser));
          onUpdateToken(authRepository.getTokenUnsecured());
        }
      })
      .finally(() => {
        dispatch(setIsAccountLoaded(true));
      });
    // messagingRepository.init();
  }, []);

  useEffect(() => {
    if (authRepository.currentUser) {
      relationRepository.getAllRelationsOfConnectedUser().then((relations) => {
        dispatch(setRelation(relations));
      });
    }
  }, [authRepository.currentUser]);
  //
  // useEffect(() => {
  //   if (!!authRepository.currentUser && !!messagingRepository.currentToken) {
  //     console.log('UPDATE FCM TOKEN', messagingRepository.currentToken);
  //     authRepository.updateActiveFcmtoken(messagingRepository.currentToken);
  //   }
  // }, [messagingRepository.currentToken, authRepository.currentUser]);

  return {
    connectedUser,
    dispatch,
    isLoadingAccount: !isAccountLoaded,
    isConnected,
    socketReady,
  };
};

interface SocketSupportProps {
  userId: number;
  token: string;
}

const SocketSupport = ({ userId }: SocketSupportProps) => {
  const { socketRepository } = useContextDependency();
  const dispatch = useDispatch();

  useEffect(() => {
    socketRepository.init(userId, () => {
      dispatch(setReady());
    });
  }, []);

  return <></>;
};

export const stripePromise = loadStripe(
  import.meta.env.VITE_STRIPE_KEY ??
    'pk_test_51MgCtWHdWfj7XXHBUSIE9DOhyidOaFxSuKfmjHUPTh058iLkyzD3ysgdcA8EmCR4MSw16O02nTZbsqpgxeW9muPa00CmEE6vtT',
);

export const publicPath = [
  routesConfig.home.path,
  routesConfig.login.path,
  routesConfig.up.path,
  routesConfig.none.path,
];
export const internalScrollPath = [
  routesConfig.parametersEvent.path,
  routesConfig.parametersDetailsEvent.path,
  routesConfig.relationPage.path,
  routesConfig.myStructures.path,
  routesConfig.myStructures.created.path,
  routesConfig.myStructures.member.path,
];

function App() {
  const { authRepository } = useContextDependency();
  const { isLoadingAccount, isConnected, socketReady } = useConnectedUser();
  const location = useLocation();
  const token = authRepository.getTokenUnsecured();
  const currentLanguage = useSelector(selectCurrentLanguage);
  const [keyLangChanged] = useState(currentLanguage);
  const isInternalScrollPath = internalScrollPath.find((value) =>
    matchPath(value, location.pathname),
  );
  // useEffect(() => {
  //   if (!currentLanguage) {
  //     dispatch(setCurrentLanguage(i18n.language as LANG));
  //   }
  //   if (i18n.language !== currentLanguage) {
  //     i18n.changeLanguage(currentLanguage).then(() => {
  //       setKeyLangChanged(currentLanguage);
  //     });
  //   }
  // }, [currentLanguage]);
  useEffect(() => {
    if (
      !location.pathname.includes('mercato/player') &&
      !location.pathname.includes('mercato/structure')
    ) {
      window.document.getElementsByTagName('main')?.[0]?.scrollTo(0, 0);
    }
  }, [location.pathname]);
  const teamCreating = useSelector((state: RootState) => state.teamCreating.teamCreating);
  const theme = useMemo(() => darkTheme(), []);
  return (
    <ThemeProvider theme={theme}>
      <ModalConsumeLinkInvitation />
      {authRepository.currentUser && token && (
        <SocketSupport userId={authRepository.currentUser.id} token={token} />
      )}
      <CssBaseline />
      <div
        className={classNames('app-root-container')}
        key={`key-lang-${keyLangChanged}`}
      >
        <TopBar
          isConnected={!isLoadingAccount && isConnected}
          socketReady={socketReady}
          confs={menus.main}
        />
        <main className={classNames('main', { '-withoutScroll': isInternalScrollPath })}>
          <Routes location={location}>
            <Route
              index
              element={<Navigate to={routesConfig.replaceDefault.to} replace />}
            />
            <Route
              path={routesConfig.confirmPayment.path}
              element={<RedirectCheckout />}
            />
            <Route path={routesConfig.home.path} element={<Home />} />
            <Route path={routesConfig.marketplace.path} element={<MarketplacePage />} />
            <Route
              path={routesConfig.notifications.path}
              element={<NotificationPage />}
            />
            <Route path={routesConfig.up.path} element={<UnpyUpPage />} />
            <Route path={routesConfig.messages.path} element={<PageMyMessages />} />
            <Route path={routesConfig.myStructures.path} element={<MyStructuresPage />}>
              <Route
                path={routesConfig.myStructures.created.path}
                element={<MyStructuresPage />}
              />
              <Route
                path={routesConfig.myStructures.member.path}
                element={<MyStructuresPage />}
              />
            </Route>
            <Route path={routesConfig.myEvents.path} element={<MyEventsPage />}>
              <Route
                path={routesConfig.myEvents.handled.path}
                element={<MyEventsPage />}
              />
              <Route
                path={routesConfig.myEvents.registered.path}
                element={<MyEventsPage />}
              />
            </Route>
            <Route path={routesConfig.activities.path} element={<MyActivities />}>
              <Route
                path={routesConfig.activities.eventList.path}
                element={<MyActivities />}
              />
              <Route
                path={routesConfig.activities.teamList.path}
                element={<MyActivities />}
              />
            </Route>
            <Route path={routesConfig.relationPage.path} element={<RelationsPage />}>
              <Route
                path={routesConfig.relationPage.friendsList.path}
                element={<RelationsPage />}
              />
              <Route
                path={routesConfig.relationPage.blockedList.path}
                element={<RelationsPage />}
              />
            </Route>
            <Route path={routesConfig.login.path} element={<PageLogin />} />
            <Route path={routesConfig.mercato.path} element={<MercatoPageRefonte />}>
              <Route
                path={routesConfig.mercatoSubPath.mercatoPlayer.path}
                element={<MercatoPageRefonte />}
              />
              <Route
                path={routesConfig.mercatoSubPath.mercatoStructure.path}
                element={<MercatoPageRefonte />}
              />
            </Route>
            <Route
              path={routesConfig.mercatoUserProfil.path}
              element={<OtherUserProfilPage />}
            >
              <Route
                index
                element={
                  <Navigate
                    to={routesConfig.mercatoUserProfilDetails.replaceDefault.to}
                    replace
                  />
                }
              />
              <Route
                path={routesConfig.mercatoUserProfilDetails.userProfilDetails.path}
                index
                element={<ProfilTabsDetails />}
              />
              <Route
                path={routesConfig.mercatoUserProfilDetails.userProfilPerformances.path}
                index
                element={
                  <React.Suspense fallback={<LoadingScreen loading />}>
                    <ProfilTabsPerf />
                  </React.Suspense>
                }
              />
              <Route
                path={routesConfig.mercatoUserProfilDetails.userProfilStructures.path}
                index
                element={<ProfilTabsStructures />}
              />
            </Route>
            <Route
              path={routesConfig.otherStructureProfil.path}
              element={<OtherStructureProfilPage />}
            >
              <Route
                index
                element={
                  <Navigate
                    to={routesConfig.otherStructureProfilDetails.replaceDefault.to}
                    replace
                  />
                }
              />
              <Route
                path={
                  routesConfig.otherStructureProfilDetails.structureProfilDetails.path
                }
                index
                element={<ProfilTabsDetails />}
              />
              <Route
                path={
                  routesConfig.otherStructureProfilDetails.structureProfilPerformances
                    .path
                }
                index
                element={
                  <React.Suspense fallback={<LoadingScreen loading />}>
                    <ProfilTabsPerf />
                  </React.Suspense>
                }
              />
              <Route
                path={
                  routesConfig.otherStructureProfilDetails.structureProfilAnnonces.path
                }
                index
                element={<StructureAnnoncesTabs />}
              />
              <Route
                path={
                  routesConfig.otherStructureProfilDetails.structureProfilMembers.path
                }
                index
                element={<ProfilTabsMembers />}
              />
            </Route>

            <Route
              path={routesConfig.parametersUser.path}
              element={<UserParametersPage />}
            >
              <Route
                index
                element={
                  <Navigate
                    to={routesConfig.parametersDetails.replaceDefault.to}
                    replace
                  />
                }
              />
              <Route
                path={routesConfig.parametersDetails.profilUtilisateur.path}
                element={<UserDetails />}
              />

              <Route
                path={routesConfig.parametersDetails.profilAppearence.path}
                element={<UserProfilAppearence />}
              />
              <Route
                path={routesConfig.parametersDetails.interests.path}
                element={<UnpyProfilStatsParameters />}
              />
              <Route
                path={routesConfig.parametersDetails.profilNetwork.path}
                element={<UserProfilNetworkForm />}
              />
              <Route
                path={routesConfig.parametersDetails.accountPersonnalData.path}
                element={<UserProfilPersonnalData />}
              />
              <Route
                path={routesConfig.parametersDetails.accountTransaction.path}
                element={<Payments />}
              />
              <Route
                path={routesConfig.parametersDetails.account.path}
                element={<Account />}
              />
            </Route>
            <Route
              path={routesConfig.parametersStructure.path}
              element={<StructureParametersPage />}
            >
              <Route
                index
                element={
                  <Navigate
                    to={routesConfig.parametersDetailsStructure.replaceDefault.to}
                    replace
                  />
                }
              />
              <Route
                path={routesConfig.parametersDetailsStructure.profilAppearence.path}
                index
                element={
                  <React.Suspense fallback={<LoadingScreen loading />}>
                    <StructureProfilAppearence />
                  </React.Suspense>
                }
              />
              <Route
                path={routesConfig.parametersDetailsStructure.interests.path}
                element={<UnpyStructureProfilStatsParameters />}
              />
              <Route
                path={routesConfig.parametersDetailsStructure.profilNetwork.path}
                element={<StructureProfilNetworkForm />}
              />
              <Route
                path={routesConfig.parametersDetailsStructure.adminStructure.path}
                element={<StructureDetails />}
              />
              <Route
                path={routesConfig.parametersDetailsStructure.adminMembers.path}
                element={<MembersStructure />}
              />
            </Route>
            <Route
              path={routesConfig.parametersEvent.path}
              element={<EventParametersPage />}
            >
              <Route
                index
                element={
                  <Navigate
                    to={routesConfig.parametersDetailsEvent.replaceDefault.to}
                    replace
                  />
                }
              />
              <Route
                path={routesConfig.parametersDetailsEvent.apparence.path}
                index
                element={<AppearenceEvent />}
              />
              <Route
                path={routesConfig.parametersDetailsEvent.rules.path}
                index
                element={<Rules />}
              />
              <Route
                path={routesConfig.parametersDetailsEvent.parameterize.path}
                index
                element={<Parameterize />}
              />
              <Route
                path={routesConfig.parametersDetailsEvent.participants.path}
                index
                element={<Participants />}
              />
              <Route
                path={routesConfig.parametersDetailsEvent.timeline.path}
                index
                element={<TimelineParamMobile />}
              />
              <Route
                path={routesConfig.parametersDetailsEvent.classement.path}
                index
                element={<Classement />}
              />
              <Route
                path={routesConfig.parametersDetailsEvent.scoreboard.path}
                index
                element={
                  <React.Suspense fallback={<LoadingScreen loading />}>
                    <EventMatchsParameters />
                  </React.Suspense>
                }
              />
            </Route>
            <Route path={routesConfig.events.path} index element={<EventListPage />} />
            <Route
              path={routesConfig.eventsPageContent.path}
              index
              element={<EventsGamePage />}
            />
            <Route path={routesConfig.eventView.path} element={<EventViewPage />}>
              <Route
                index
                element={
                  <Navigate
                    to={routesConfig.eventViewDetails.replaceDefault.to}
                    replace
                  />
                }
              />
              <Route
                path={routesConfig.eventViewDetails.eventViewAnnonces.path}
                index
                element={<Annonces />}
              />
              <Route
                path={routesConfig.eventViewDetails.eventViewInfos.path}
                index
                element={<Infos />}
              />
              <Route
                path={routesConfig.eventViewDetails.eventViewTimeline.path}
                index
                element={<TimelineView />}
              />
              <Route
                path={routesConfig.eventViewDetails.eventViewPlayers.path}
                element={<ParticipantsView />}
              />
              <Route
                path={routesConfig.eventViewDetails.eventViewScoreboard.path}
                element={<ClassementView />}
              />
              <Route
                path={routesConfig.eventViewDetails.eventViewMatchs.path}
                element={<MatchsView />}
              />
            </Route>
          </Routes>
          <ModalDisconnected />
        </main>
        <RightPanel showButton={!!teamCreating}>
          {teamCreating && <RightPanelContentTeamCreating />}
        </RightPanel>
        <Toast />
      </div>
    </ThemeProvider>
  );
}

export default App;
