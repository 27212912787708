export enum ProductCodeEnum {
  ABO_UP_1 = 'ABO_UP_1',
  ABO_UP_6 = 'ABO_UP_6',
  ABO_UP_12 = 'ABO_UP_12',
  ABO_UP_PREMIUM_1 = 'ABO_UP_PREMIUM_1',
  ABO_UP_PREMIUM_6 = 'ABO_UP_PREMIUM_6',
  ABO_UP_PREMIUM_12 = 'ABO_UP_PREMIUM_12',
  PROD_BOOST = 'PROD_BOOST',
  PASS = 'PASS',
  ABO_UP_STRUCTURE = 'ABO_UP_STRUCTURE',
  ABO_UP_EVENT = 'ABO_UP_EVENT',
}
