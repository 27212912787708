export enum PlatformCodeEnum {
  XBO = 'XBO',
  PC = 'PC',
  PLA = 'PLA',
}

export class Platform {
  constructor(
    public readonly id: number,
    public readonly libelle: string,
    public readonly code: PlatformCodeEnum,
  ) {}
}
