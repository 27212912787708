import { AxiosInstance } from 'axios';
import { IMatchRepository } from 'domain/event/match/Match.repository';
import { MatchGroup } from 'domain/event/match/MatchGroup';
import {
  MatchParticipant,
  UnpyMatchParticipantsFilters,
} from 'domain/event/match/MatchParticipant';
import { UnpyMatch, UnpyMatchFilters } from 'domain/event/match/UnpyMatch';
import { Pageable } from 'domain/pageable/Pageable';
import { GenerateMatchesFormData } from 'primary/classements/Ligue/Modal/GenerateClassement/GenerateMatchesForm';
import { MatchUpdateDetailsFormData } from 'primary/events/components/matchs/edit/ModalEditDetailsMatchBR';
import { MatchUpdateFormData } from 'primary/events/components/matchs/edit/ModalEditScoreMatch';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';
import { toMatchGroup } from 'secondary/event/match/RestMatchGroup';
import { toMatchParticipant } from 'secondary/event/match/RestMatchParticipant';
import { toUnpyMatch } from 'secondary/event/match/RestUnpyMatch';
import { toPageable } from 'secondary/RestPageable';

export class MatchsRepository
  extends AxiosProviderRepository
  implements IMatchRepository
{
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  generateMatchs(data: GenerateMatchesFormData): Promise<void> {
    return this.axios.post('/unpy/api/matchs/generate', data);
  }

  update(data: MatchUpdateFormData): Promise<UnpyMatch> {
    return this.axios.put('/unpy/api/match/update', data);
  }

  updateDetails(data: MatchUpdateDetailsFormData): Promise<UnpyMatch> {
    return this.axios.put('/unpy/api/match/update', data);
  }
  getMatchsParticipantsByMatchId(matchId: number): Promise<MatchParticipant[]> {
    return this.axios
      .get(`/unpy/api/match/${matchId}/participants`)
      .then((response) => response.data.map(toMatchParticipant));
  }

  getPodiumBracket(eventId: number): Promise<MatchGroup[]> {
    return this.axios
      .get(`/unpy/api/matchs/podium/${eventId}`)
      .then((response) => response.data.map(toMatchGroup));
  }

  completeScoreToRound(eventId: number, roundToComplete: number): Promise<void> {
    return this.axios.get(
      `/unpy/api/matchs/${eventId}/admin/complete/${roundToComplete}`,
    );
  }

  search(
    filters: UnpyMatchFilters,
    page: number,
    nbPerPage: number,
  ): Promise<Pageable<UnpyMatch>> {
    return this.axios
      .get('/unpy/api/matchs/search', {
        params: {
          ...filters,
          page,
          nbPerPage,
        },
      })
      .then((response) => toPageable(response, toUnpyMatch));
  }

  searchGroup(
    filters: UnpyMatchFilters,
    page: number,
    nbPerPage: number,
    withMatch?: boolean,
  ): Promise<Pageable<MatchGroup>> {
    return this.axios
      .get('/unpy/api/matchs/groups/search', {
        params: {
          ...{
            ...filters,
            idEvent: filters?.idEvent?.toString(),
          },
          page,
          nbPerPage,
          withMatch: withMatch,
        },
      })
      .then((response) => toPageable(response, toMatchGroup));
  }

  getCurrentRound(eventId: number): Promise<MatchGroup[]> {
    return this.axios
      .get(`/unpy/api/matchs/groups/current/${eventId}`)
      .then((response) => response.data.map(toMatchGroup));
  }

  searchParticipants(
    filters: UnpyMatchParticipantsFilters,
    page: number,
    nbPerPage: number,
  ): Promise<Pageable<MatchParticipant>> {
    return this.axios
      .get('/unpy/api/match/participants/search', {
        params: {
          ...{
            ...filters,
            matchId: filters?.matchId?.toString(),
          },
          page,
          nbPerPage,
        },
      })
      .then((response) => toPageable(response, toMatchParticipant));
  }
}
