import { ArrowLeft } from '@mui/icons-material';
import { Button } from '@mui/material';
import { Title } from 'primary/Components/Title/Title';
import { EventList } from 'primary/events/list/EventList';
import React from 'react';
type MyEventsProps = {
  goBack: () => void;
};
export const MyEvents = ({ goBack }: MyEventsProps) => {
  return (
    <div className={'myEvents'}>
      <div className={'-header'}>
        <Button
          color={'primary'}
          style={{ justifySelf: 'flex-end' }}
          variant={'text'}
          onClick={goBack}
        >
          <ArrowLeft />
          Retour
        </Button>
        <Title title={'Gestion dévennement'} level={3} compensatePadding accent />
      </div>
      <div className={'-content'}>
        <EventList
          typeSearch={'PUBLIC'}
          listProps={{ withoutBorder: true, transparent: true }}
        />
      </div>
    </div>
  );
};
