import { PriceType } from 'domain/payment/PriceType';

export class Price {
  constructor(
    public readonly id: number,
    public readonly amount: number,
    public readonly idStripe: string,
    public readonly currency: string,
    public readonly type: PriceType,
  ) {}
}
