import { AxiosInstance } from 'axios';
import { ISubscriptionRepository } from 'domain/payment/ISubscription.repository';
import { UnpySession } from 'domain/payment/UnpySession';
import { UnpySubscription } from 'domain/payment/UnpySubscription';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';
import {
  toUnpySession,
  toUnpySubscription,
} from 'secondary/payment/RestUnpySubscription';

export class SubscriptionRepository
  extends AxiosProviderRepository
  implements ISubscriptionRepository
{
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  init(idProduit: number): Promise<UnpySubscription> {
    return this.axios
      .post('/unpy/api/subscription/init', { idProduct: idProduit })
      .then((response) => toUnpySubscription(response.data));
  }

  initSession(idProduit: number): Promise<UnpySession> {
    return this.axios
      .post('/unpy/api/session/init', { idProduct: idProduit })
      .then((response) => toUnpySession(response.data));
  }

  cancel(isImmediately: boolean): Promise<void> {
    return this.axios.put('/unpy/api/subscription/cancel', {
      isImmediately: isImmediately,
    });
  }

  undoCancel(): Promise<void> {
    return this.axios.put('/unpy/api/subscription/cancel/undo');
  }
}
