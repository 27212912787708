import { Button, Grid, TextField } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { GamingProfilEnum } from 'domain/enums/GamingProfil.enum';
import { ValidationStringLengthEnum } from 'domain/enums/ValidationStringLengthEnum';
import { getInitialValuesProfilStructureDetails } from 'domain/profil/StructureProfil.func';
import { IStructureProfilRepository } from 'domain/structure/IStructureProfil.repository';
import { InputSelectMui } from 'primary/Components/Input/InputSelectMui';
import { InputTextArea } from 'primary/Components/Input/InputTextArea';
import {
  ChangeHandlerTypeMeta,
  InputWrapper,
} from 'primary/Components/Input/InputWrapper';
import { PageLayout } from 'primary/Components/PageLayout/PageLayout';
import { Title } from 'primary/Components/Title/Title';
import { PlatformsFields } from 'primary/Components/UserDetailsForms/PlatformsFields';
import { TalkLangsFields } from 'primary/Components/UserDetailsForms/TalkLangsFields';
import { useTranslate, UseTranslateReturn } from 'primary/hooks/useTranslate';
import { useYupValidationResolver } from 'primary/hooks/useYupValidationResolver';
import { useStructureParameters } from 'primary/Parameters/user/useUserStructureParameters.hook';
import React, { useMemo } from 'react';
import { useController, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { themeParameters } from '../../parameters.helper';

export type StructureProfilDetailsFormData = {
  name?: string;
  description?: string;
  talkLangs?: string[];
  platforms?: string[];
  gamingProfil?: string;
};

const validationSchema = (t: UseTranslateReturn) =>
  yup.object({
    name: yup
      .string()
      .max(
        ValidationStringLengthEnum.DEFAULT,
        t('general.forms.errors.tooLong', {
          maxChar: ValidationStringLengthEnum.DEFAULT,
        }),
      )
      .required(t('general.forms.errors.required')),
    description: yup
      .string()
      .nullable()
      .max(
        ValidationStringLengthEnum.XL,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.XL }),
      ),
    talkLangs: yup.array().of(yup.mixed()),
    platforms: yup.array().of(yup.mixed()),
    gamingProfil: yup.string().nullable(),
  });

export const useOnSubmit =
  (
    structureProfilRepository: IStructureProfilRepository,
    onSubmitted: () => void,
    idProfilStructure: number,
  ) =>
  (data: StructureProfilDetailsFormData) => {
    structureProfilRepository
      .updateProfilStructureDetails(data, idProfilStructure)
      .then(onSubmitted);
  };

export const StructureDetails = () => {
  const { structure, onSubmitted, structureProfilRepository } = useStructureParameters();
  const t = useTranslate();
  const resolver = useYupValidationResolver(validationSchema);

  const form = useForm<StructureProfilDetailsFormData>({
    resolver,
    defaultValues: getInitialValuesProfilStructureDetails(t, structure?.structureProfil),
  });

  const onSubmit = useMemo(
    () =>
      useOnSubmit(
        structureProfilRepository,
        onSubmitted,
        structure?.structureProfil?.id as number,
      ),
    [structureProfilRepository, onSubmitted],
  );
  const { field: gamingExperienceField } = useController({
    control: form.control,
    name: 'gamingProfil',
  });
  const { field: descriptionControl } = useController({
    control: form.control,
    name: 'description',
  });
  if (!structure) return null;

  return (
    <div className={'userDetails'}>
      <form name={'user-details-profil'} onSubmit={form.handleSubmit(onSubmit)}>
        <PageLayout>
          <>
            <Title
              title={t('user.parameters.userProfilDetails.title')}
              level={1}
              compensatePadding
              threeQuarter
              bordered
            />
            <ThemeProvider theme={themeParameters}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <InputWrapper
                    label={t('parameters.menus.profil.structure.form.nameLabel')}
                    errors={form.formState.errors}
                    Input={TextField}
                    inputClassName={'input-user-details '}
                    labelClassName={'label-user-details '}
                    isSubmitting={form.formState.isSubmitting}
                    required
                    inputProps={{
                      placeholder: t(
                        'parameters.menus.profil.structure.form.namePlaceholder',
                      ),
                      disabled: form.formState.isSubmitting,
                      ...form.register('name', { required: true }),
                      variant: 'filled',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputWrapper
                    inputClassName={'input-user-details '}
                    labelClassName={'label-user-details '}
                    label={t('parameters.menus.profil.user.form.descriptionLabel')}
                    errors={form.formState.errors}
                    Input={InputTextArea}
                    isSubmitting={form.formState.isSubmitting}
                    inputProps={{
                      placeholder: t(
                        'parameters.menus.profil.user.form.descriptionPlaceholder',
                      ),
                      ...descriptionControl,
                      minRows: 3,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TalkLangsFields form={form} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputWrapper
                    label={t('parameters.menus.profil.user.form.gamingExperienceLabel')}
                    errors={form.formState.errors}
                    inputClassName={'input-user-details '}
                    labelClassName={'label-user-details '}
                    Input={InputSelectMui}
                    isSubmitting={form.formState.isSubmitting}
                    required
                    inputProps={{
                      placeholder: t(
                        'parameters.menus.profil.user.form.gamingExperiencePlaceholder',
                      ),
                      ...gamingExperienceField,
                      options: Object.values(GamingProfilEnum).map((value) => ({
                        label: t(`enum.gamingExperience.${value}`),
                        value: value,
                      })),
                      onChange: (event: ChangeHandlerTypeMeta) => {
                        form.setValue('gamingProfil', event.target.value as string);
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <PlatformsFields form={form} />
                </Grid>
                <Grid item xs={12} sx={{ justifyContent: 'center' }}>
                  <Button
                    color={'primary'}
                    style={{ justifySelf: 'flex-end' }}
                    variant={'contained'}
                    type={'submit'}
                  >
                    {t('parameters.menus.profil.user.form.submit')}
                  </Button>
                </Grid>
              </Grid>
            </ThemeProvider>
          </>
        </PageLayout>
      </form>
    </div>
  );
};
