import { Platform, PlatformCodeEnum } from '../../domain/platform/Platform';
import { toEnum } from '../../helper/enum.helper';

export interface RestPlatform {
  id: number;
  libelle: string;
  code: string;
}

export function toPlatform(restPlatform: RestPlatform) {
  return new Platform(
    restPlatform.id,
    restPlatform.libelle,
    toEnum(PlatformCodeEnum, restPlatform.code),
  );
}
