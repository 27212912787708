import { TypeBo } from '../../domain/event/TypeBo';
import { TypeBoCodeEnum } from '../../domain/event/TypeBoCodeEnum';
import { toEnum } from '../../helper/enum.helper';

export interface RestTypeBo {
  id: number;
  code: string;
}

export function toTypeBo(restTypeBo: RestTypeBo) {
  return new TypeBo(restTypeBo.id, toEnum(TypeBoCodeEnum, restTypeBo.code));
}
