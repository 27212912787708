import { TypeEvent } from '../../domain/event/TypeEvent';
import { TypeEventCodeEnum } from '../../domain/event/TypeEventCodeEnum';
import { toEnum } from '../../helper/enum.helper';

export interface RestTypeEvent {
  id: number;
  code: string;
}

export function toTypeEvent(restTypeEvent: RestTypeEvent) {
  return new TypeEvent(restTypeEvent.id, toEnum(TypeEventCodeEnum, restTypeEvent.code));
}
