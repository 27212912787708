import './DurationOffer.scss';

import { Button, Grid } from '@mui/material';
import classNames from 'classnames';
import { Product } from 'domain/payment/Product';
import { TranslationType } from 'domain/translation/Translation.repository';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

type CardOfferSubscribeProps = {
  product?: Product;
  subscribeType: 'normal' | 'premium';
  duration: 1 | 6 | 12;
  embedded?: boolean;
  onClickCheckout: () => void;
  isAlreadySubscribed?: boolean;
  canChange?: boolean;
};

export const CardDurationOffer = ({
  product,
  subscribeType,
  duration,
  onClickCheckout,
  embedded,
  isAlreadySubscribed,
  canChange,
}: CardOfferSubscribeProps) => {
  const t = useTranslate();
  return (
    <Grid
      container
      className={classNames('cardDurationOffer', { '-embedded': embedded })}
    >
      <h3 className={'-productTitleAccent'}>
        {t(`products.${subscribeType}.label`)} <br />
        <small>{t('products.subscribeLabel')}</small>
      </h3>
      <div className={'-productPrice'}>
        <span className={'-price'}>
          {!product && <LoadingScreen loading={true} />}
          {product?.price?.amount} {t(product?.price?.currency as TranslationType)}
        </span>
        <div className={'-priceDuration'}>{t(`products.duration.${duration}`)}</div>
      </div>
      {!canChange && (
        <Button
          disabled={isAlreadySubscribed}
          variant={'contained'}
          onClick={onClickCheckout}
          color={isAlreadySubscribed ? 'secondary' : 'primary'}
          className={'-btnSubscribe -clearBackground'}
        >
          {isAlreadySubscribed
            ? t('products.alreadySubscribeLabel')
            : t('products.getSubscribeBtnLabel')}
        </Button>
      )}
      {canChange && (
        <Button
          variant={'contained'}
          onClick={onClickCheckout}
          color={'primary'}
          className={'-btnSubscribe'}
        >
          {t('products.upgradeSubscribe')}
        </Button>
      )}
      {!embedded && (
        <div className={'-advantages'}>{t(`products.${subscribeType}.advantages`)}</div>
      )}
      <div className={'-seeMoreContainer'}>
        <Button variant={'contained'} color={'primary'} className={'-btnSeeMore'}>
          {t('products.seeMoreBtn')}
        </Button>
      </div>
    </Grid>
  );
};
