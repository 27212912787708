import './KeyValue.scss';

import classNames from 'classnames';
import { HelperPopper } from 'primary/helpers/HelperPopper';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { ReactElement, ReactNode } from 'react';

interface KeyValueProps {
  className?: string;
  keyValue: string;
  value?: string | ReactElement;
  accent?: boolean;
  color?: 'green' | 'orange';
  tagged?: boolean;
  alignStart?: boolean;
  helper?: ReactNode;
}

export const KeyValue = ({
  className,
  keyValue,
  value,
  accent,
  color = 'orange',
  tagged,
  helper,
  alignStart,
}: KeyValueProps) => {
  const t = useTranslate();
  return (
    <div
      className={classNames('keyValue', className, {
        '-tagged': tagged,
        '-alignStart': alignStart,
      })}
    >
      <span
        className={classNames('keyValue__key', {
          '-accent': accent,
          '-green': color === 'green',
          '-orange': color === 'orange',
        })}
      >
        {keyValue}
      </span>
      <span className={'keyValue__value'}>{value || t('general.keyValueNoValue')}</span>
      {helper && <HelperPopper>{helper}</HelperPopper>}
    </div>
  );
};
