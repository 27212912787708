import { MatchResultEnum } from 'domain/event/match/MatchResult.enum';
import { UnpyMatch } from 'domain/event/match/UnpyMatch';
import { EventParticipant } from 'domain/event/participants/EventParticipant';
export type UnpyMatchParticipantsFilters = {
  matchId?: number;
  onceModified?: boolean;
  matchPlayerId?: number;
};
export class MatchParticipant {
  constructor(
    public readonly id: number,
    public readonly eventParticipant: EventParticipant,
    public readonly match: UnpyMatch,
    public readonly result: MatchResultEnum,
    public readonly score: number,
  ) {}
}
