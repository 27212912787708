import { TypeTeamCodeEnum } from './TypeTeamCodeEnum';

export const PlayersNumberByTypeTeams: { [key in TypeTeamCodeEnum]: number[] } = {
  [TypeTeamCodeEnum.SOL]: [1],
  [TypeTeamCodeEnum.DUO]: [1, 2],
  [TypeTeamCodeEnum.TRI]: [1, 2, 3],
  [TypeTeamCodeEnum.QUA]: [1, 2, 3, 4],
  [TypeTeamCodeEnum.FIV]: [1, 2, 3, 4, 5],
  [TypeTeamCodeEnum.SQU]: [1, 2, 3, 4, 5],
};

export class TypeTeam {
  constructor(public readonly id: number, public readonly code: TypeTeamCodeEnum) {}
}
