export enum SystemPointsEnum {
  PERSO = 'PERSO',
  DEFAULT = 'DEFAULT',
}

export type DEFAULT_SCORE_KEY = 'WIN' | 'LOOSE' | 'NULL';

export const DEFAULT_SCORE_VALUE: { [key in DEFAULT_SCORE_KEY]: number } = {
  WIN: 3,
  LOOSE: -1,
  NULL: 0,
};
