import './PreviewCardProfil.scss';

import { Profil } from 'domain/profil/Profil';
import { isStructureProfil } from 'domain/profil/Profil.func';
import { UserProfil } from 'domain/profil/UserProfil';
import { StructurePreviewCardProfil } from 'primary/Components/Profils/structure/StructurePreviewCardProfil';
import { UserPreviewCardProfil } from 'primary/Components/Profils/User/UserPreviewCardProfil';
import React, { useMemo } from 'react';

interface PreviewCardProfilProps {
  profil: Profil;
  className?: string;
  inPage?: boolean;
  noAction?: boolean;
}

export const PreviewCardProfil = ({
  profil,
  className,
  inPage,
  noAction,
}: PreviewCardProfilProps) => {
  const isStructure = useMemo(() => isStructureProfil(profil), [profil]);
  if (isStructure) {
    return (
      <StructurePreviewCardProfil
        className={className}
        profil={profil}
        inPage={inPage}
        userName={isStructureProfil(profil) ? profil.name || '' : ''}
        noAction={noAction}
      />
    );
  }
  return (
    <UserPreviewCardProfil
      className={className}
      profil={profil as UserProfil}
      inPage={inPage}
      noAction={noAction}
      userName={(profil as UserProfil).idPlayer}
    />
  );
};
