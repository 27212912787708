import './RowCard.scss';

import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

interface RowCardProps {
  children: ReactNode;
  className?: string;
}

export const RowCard: FC<RowCardProps> = ({ className, children }) => {
  return <div className={classNames('row-card', className)}>{children}</div>;
};
