import './NoMatchInfosBloc.scss';

import { Cancel, InfoOutlined } from '@mui/icons-material';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { formatDate } from 'helper/date.helper';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

export const NoMatchInfosBloc = ({ event }: { event: UnpyEvent | UnpyEventView }) => {
  const t = useTranslate();
  return (
    <div className={'-noMatch'}>
      {event.status === 'PUBLISH' && (
        <div className={'-noticeContainer'}>
          <InfoOutlined />
          <span className={'-notice'}>
            {t('event.viewPage.matchs.noMatchNotice.PUBLISH', {
              date: formatDate(event.startDateTime, 'readable(with hour)'),
            })}
          </span>
        </div>
      )}
      {event.status === 'ON_GOING' && (
        <div className={'-noticeContainer'}>
          <InfoOutlined />
          <span className={'-notice'}>
            {t('event.viewPage.matchs.noMatchNotice.ON_GOING')}
          </span>
        </div>
      )}
      {event.status === 'CANCELED' && (
        <div className={'-noticeContainer'}>
          <Cancel />
          <span className={'-notice'}>
            {t('event.viewPage.matchs.noMatchNotice.CANCELED')}
          </span>
        </div>
      )}
    </div>
  );
};
