import { GAME_ACTIVE_LIST } from './GameActiveList';

export class Game {
  constructor(
    public readonly id: number,
    public readonly libelle: string,
    public readonly code: GAME_ACTIVE_LIST,
    public readonly active: boolean,
  ) {}
}
