import './FilterTalkLang.scss';

import { insertEmptyOpt } from 'helper/options.helper';
import { InputSelectMui } from 'primary/Components/Input/InputSelectMui';
import {
  ChangeHandlerTypeMeta,
  InputWrapper,
} from 'primary/Components/Input/InputWrapper';
import React from 'react';

import { GamingProfilEnum } from '../../../domain/enums/GamingProfil.enum';
import { useTranslate } from '../../hooks/useTranslate';

type FilterTalkLangProps = {
  onSelectExperience: (values?: string) => void;
  selectedExperience?: string;
};

export const FilterExperience = ({
  onSelectExperience,
  selectedExperience,
}: FilterTalkLangProps) => {
  const t = useTranslate();

  return (
    <div>
      <InputWrapper
        inline
        errors={{}}
        Input={InputSelectMui}
        isSubmitting={false}
        label={t('mercato.filters.player.experiences')}
        key={selectedExperience}
        inputProps={{
          options: insertEmptyOpt(
            Object.values(GamingProfilEnum)?.map((exp: string) => ({
              label: t(`enum.gamingExperience.${exp as GamingProfilEnum}`),
              value: exp,
            })) || [],
          ),
          onChange: (event: ChangeHandlerTypeMeta) => {
            //ts-ignore
            onSelectExperience(event.target.value as string);
          },
          menuPortalTarget: document.body,
          value: selectedExperience,
        }}
      />
    </div>
  );
};
