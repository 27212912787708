import { RestUserProfilView, toUserProfilView } from 'secondary/user/RestUserProfilView';

import { Message } from '../../domain/message/Message';
import { MessageTypeEnum } from '../../domain/message/MessageTypeEnum';
import { toEnum } from '../../helper/enum.helper';
import { RestConversation, toConversation } from './RestConversation';

export interface RestMessage {
  id: number;
  conversation: RestConversation;
  creationDate: Date;
  sender: RestUserProfilView;
  type: MessageTypeEnum;
  viewed: boolean;
  content: string;
}

export function toMessage(restMessage: RestMessage): Message {
  return new Message(
    restMessage.id,
    new Date(restMessage.creationDate),
    toUserProfilView(restMessage.sender),
    toEnum(MessageTypeEnum, restMessage.type),
    restMessage.viewed,
    restMessage.content,
    restMessage.conversation ? toConversation(restMessage.conversation) : undefined,
  );
}
