import { AxiosInstance } from 'axios';
import { CompareProfil, toCompareProfil } from 'domain/home/CompareProfils';
import { CountHome, toCountHome } from 'domain/home/CountHome';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';

import { IAllProfilRepository } from '../../domain/profil/IAllProfilRepository.repository';
import { Profil } from '../../domain/profil/Profil';
import { toProfilFromAllProfil } from './RestProfil';

export class AllProfilRepository
  extends AxiosProviderRepository
  implements IAllProfilRepository
{
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  getProfilsOverview(): Promise<Profil[]> {
    return this.axios
      .get('/unpy/api/users/profils/overview')
      .then((resp) => toProfilFromAllProfil(resp.data));
  }

  countProfils(): Promise<CountHome> {
    return this.axios
      .get('/unpy/api/users/profils/count')
      .then((resp) => toCountHome(resp.data));
  }

  compareProfil(): Promise<CompareProfil> {
    return this.axios
      .get('/unpy/api/user/profils/compare')
      .then((resp) => toCompareProfil(resp.data));
  }
}
