import { AxiosInstance } from 'axios';
import { CountConsume, toCountConsume } from 'domain/event/CountConsume';
import { TypeBo } from 'domain/event/TypeBo';
import { TypeBracket } from 'domain/event/TypeBracket';
import { TypeEvent } from 'domain/event/TypeEvent';
import { TypeRonde } from 'domain/event/TypeRonde';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { CountHome, toCountHome } from 'domain/home/CountHome';
import { Team } from 'domain/team/Team';
import { DelayEventStartDateFormData } from 'primary/classements/Ligue/Modal/ConfirmEvent/ModalConfirmEvent';
import { GenerateMatchesFormData } from 'primary/classements/Ligue/Modal/GenerateClassement/GenerateMatchesForm';
import { EventRegisterFormData } from 'primary/Components/Event/RegisterEventFormStep/FormStepPlayers';
import { EventPublishFormData } from 'primary/events/forms/EventPublishForm';
import { EventReopenSubscribeFormData } from 'primary/events/forms/EventReopenSubscribeForm';
import { EventUnpyAdminFormData } from 'primary/events/forms/EventUnpyAdminForm';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';
import { toUnpyEventView } from 'secondary/event/RestEventView';
import { toTeam } from 'secondary/team/RestTeam';

import { IEventRepository } from '../../domain/event/Event.repository';
import { PlayerRoleEnum } from '../../domain/event/PlayerRoleEnum';
import { TypeTeam } from '../../domain/event/TypeTeam';
import { UnpyEvent, UnpyEventFilters } from '../../domain/event/UnpyEvent';
import { Pageable } from '../../domain/pageable/Pageable';
import { formatDate } from '../../helper/date.helper';
import { EventAppearenceFormData } from '../../primary/events/forms/EventAppearenceForm';
import { EventFormData } from '../../primary/events/forms/EventCreateForm';
import { EventParameterizeFormData } from '../../primary/events/forms/EventParameterizeForm';
import { EventRulesFormData } from '../../primary/events/forms/EventRulesForm';
import { FixtureEventFormData } from '../../primary/Parameters/user/AdminArea/EventsAdmin';
import { toPageable } from '../RestPageable';
import { RestEvent, toUnpyEvent } from './RestEvent';
import { RestTypeBo, toTypeBo } from './RestTypeBo';
import { RestTypeBracket, toTypeBracket } from './RestTypeBracket';
import { RestTypeEvent, toTypeEvent } from './RestTypeEvent';
import { RestTypeRonde, toTypeRonde } from './RestTypeRonde';
import { RestTypeTeam, toTypeTeam } from './RestTypeTeam';

export class EventRepository extends AxiosProviderRepository implements IEventRepository {
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  generateFixture(): Promise<void> {
    return this.axios.post('/unpy/api/fixtures/add');
  }

  create(eventForm: EventFormData): Promise<UnpyEvent> {
    return this.axios
      .post('/unpy/api/event/create', {
        ...eventForm,
        startDateTime: formatDate(
          new Date(eventForm.startDateTime),
          'back(datetime) UTC',
        ),
        endSubscribeDate: formatDate(
          new Date(eventForm.endSubscribeDate),
          'back(datetime) UTC',
        ),
        typeBracket: null,
        typeRonde: null,
        typeBo: null,
        teams: null,
        admins: null,
      })
      .then((response) => toUnpyEvent(response.data));
  }

  createFixtures(eventFixtureForm: FixtureEventFormData): Promise<UnpyEvent> {
    return this.axios
      .post('/unpy/api/fixtures/add', eventFixtureForm)
      .then((response) => toUnpyEvent(response.data));
  }

  updateEventAppearence(
    appearenceFormData: EventAppearenceFormData,
    idEvent: number,
    file: File,
  ): Promise<UnpyEvent> {
    const bodyFormData = new FormData();

    bodyFormData.set('image', file);
    bodyFormData.set(
      'appearenceForm',
      new Blob(
        [
          JSON.stringify({
            name: appearenceFormData.name,
            image: appearenceFormData.image,
            twitter: appearenceFormData.twitter,
            facebook: appearenceFormData.facebook,
            youtube: appearenceFormData.youtube,
            instagram: appearenceFormData.instagram,
            discord: appearenceFormData.discord,
            twitch: appearenceFormData.twitch,
            resizeProps: appearenceFormData.resizeProps,
          }),
        ],
        {
          type: 'application/json',
        },
      ),
    );

    return this.axios
      .put(`/unpy/api/event/${idEvent}/update/apparence`, bodyFormData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => toUnpyEvent(response.data));
  }

  getAllTypeBo(): Promise<TypeBo[]> {
    return this.axios
      .get('/unpy/api/event/codifs/type/bo/all')
      .then((response) =>
        response.data.map((restTypeBo: RestTypeBo) => toTypeBo(restTypeBo)),
      );
  }

  getAllTypeBracket(): Promise<TypeBracket[]> {
    return this.axios
      .get('/unpy/api/event/codifs/type/bracket/all')
      .then((response) =>
        response.data.map((restTypeBracket: RestTypeBracket) =>
          toTypeBracket(restTypeBracket),
        ),
      );
  }

  getAllTypeEvent(): Promise<TypeEvent[]> {
    return this.axios
      .get('/unpy/api/event/codifs/type/event/all')
      .then((response) =>
        response.data.map((restTypeEvent: RestTypeEvent) => toTypeEvent(restTypeEvent)),
      );
  }

  getAllTypeRonde(): Promise<TypeRonde[]> {
    return this.axios
      .get('/unpy/api/event/codifs/type/ronde/all')
      .then((response) =>
        response.data.map((restTypeRonde: RestTypeRonde) => toTypeRonde(restTypeRonde)),
      );
  }

  getAllTypeTeam(): Promise<TypeTeam[]> {
    return this.axios
      .get('/unpy/api/event/codifs/type/team/all')
      .then((response) =>
        response.data.map((restTypeTeam: RestTypeTeam) => toTypeTeam(restTypeTeam)),
      );
  }

  getEventById(idEvent: number | string): Promise<UnpyEvent> {
    return this.axios
      .get(`/unpy/api/event/${idEvent}`)
      .then((response) => toUnpyEvent(response.data));
  }

  getEventConnectedUserOwner(): Promise<RestEvent[]> {
    return this.axios
      .get(`/unpy/api/events/me`)
      .then((response) => response.data.map((event: RestEvent) => toUnpyEvent(event)));
  }

  getEventsOverviewConnectedUser(): Promise<UnpyEvent[]> {
    return this.axios
      .get(`/unpy/api/events/overview/me`)
      .then((response) => response.data.map((event: RestEvent) => toUnpyEvent(event)));
  }

  searchOwn(
    filters: UnpyEventFilters,
    page: number,
    nbPerPage: number,
  ): Promise<Pageable<UnpyEventView>> {
    return this.axios
      .get('/unpy/api/events/search/own', {
        params: {
          ...filters,
          page,
          nbPerPage,
        },
      })
      .then((response) => toPageable(response, toUnpyEventView));
  }

  searchRegistered(
    filters: UnpyEventFilters,
    page: number,
    nbPerPage: number,
  ): Promise<Pageable<UnpyEventView>> {
    return this.axios
      .get('/unpy/api/events/search/registered', {
        params: {
          ...filters,
          page,
          nbPerPage,
        },
      })
      .then((response) => toPageable(response, toUnpyEventView));
  }

  canCreateAnnoncement(eventId: number): Promise<boolean> {
    return this.axios
      .get(`/unpy/api/events/${eventId}/annoncement/check`)
      .then((response) => response.data.canCreate ?? false);
  }

  searchPublic(
    filters: UnpyEventFilters,
    page: number,
    nbPerPage: number,
  ): Promise<Pageable<UnpyEventView>> {
    return this.axios
      .get('/unpy/api/events/search/public', {
        params: {
          ...filters,
          page,
          nbPerPage,
        },
      })
      .then((response) => toPageable(response, toUnpyEventView));
  }

  getOverview(): Promise<UnpyEvent[]> {
    return this.axios.get(`/unpy/api/events/overview`).then((response) => {
      return response.data.map((event: RestEvent) => toUnpyEvent(event));
    });
  }

  updateEventRules(data: EventRulesFormData, idEvent: number): Promise<UnpyEvent> {
    return this.axios
      .put(`/unpy/api/event/${idEvent}/update/rules`, data)
      .then((response) => toUnpyEvent(response.data));
  }

  updateEventParameterize(
    data: EventParameterizeFormData,
    idEvent: number,
  ): Promise<UnpyEvent> {
    return this.axios
      .put(`/unpy/api/event/${idEvent}/update/parameterize`, {
        ...data,
        startDateTime: formatDate(new Date(data.startDateTime), 'back(datetime) UTC'),
        endSubscribeDate: formatDate(
          new Date(data.endSubscribeDate),
          'back(datetime) UTC',
        ),
      })
      .then((response) => toUnpyEvent(response.data));
  }

  openEvent(data: EventPublishFormData, idEvent: number): Promise<UnpyEvent> {
    return this.axios
      .put(`/unpy/api/event/${idEvent}/step/open`, data)
      .then((response) => toUnpyEvent(response.data));
  }

  closeSubscriptionEvent(idEvent: number): Promise<UnpyEvent> {
    return this.axios
      .put(`/unpy/api/event/${idEvent}/step/subscription/close`)
      .then((response) => toUnpyEvent(response.data));
  }

  confirmStartDate(
    idEvent: number,
    data: DelayEventStartDateFormData,
  ): Promise<UnpyEvent> {
    return this.axios
      .put(`/unpy/api/event/${idEvent}/confirm`, data)
      .then((response) => toUnpyEvent(response.data));
  }
  startEvent(idEvent: number, data: GenerateMatchesFormData): Promise<UnpyEvent> {
    return this.axios
      .put(`/unpy/api/event/${idEvent}/step/start`, data)
      .then((response) => toUnpyEvent(response.data));
  }

  finishEvent(idEvent: number): Promise<UnpyEvent> {
    return this.axios
      .put(`/unpy/api/event/${idEvent}/step/finish`)
      .then((response) => toUnpyEvent(response.data));
  }

  cancelEvent(idEvent: number): Promise<UnpyEvent> {
    return this.axios
      .put(`/unpy/api/event/${idEvent}/step/cancel`)
      .then((response) => toUnpyEvent(response.data));
  }

  createTeam(data: EventRegisterFormData, idEvent: number): Promise<Team> {
    return this.axios
      .post(`/unpy/api/participant/event/${idEvent}/create`, {
        name: data.name,
        idEvent: idEvent,
        idStructure: data.structure,
        players: Object.keys(data.players).map((key, index) => {
          return {
            idUnpy: data.players[key].idPlayer,
            role: index === 0 ? PlayerRoleEnum.CHIEF : PlayerRoleEnum.TITULAR,
          };
        }),
      })
      .then((response) => toTeam(response.data));
  }

  updateEventAsAsmin(data: EventUnpyAdminFormData, idEvent: number): Promise<UnpyEvent> {
    return this.axios.put(`/unpy/api/event/${idEvent}/admin/unpy/update`, data);
  }

  launchGarabageEventAsAdmin(): Promise<UnpyEvent> {
    return this.axios.get(`/unpy/api/event/admin/unpy/garabage`);
  }

  delayEvent(idEvent: number, data: DelayEventStartDateFormData): Promise<UnpyEvent> {
    return this.axios
      .put(`/unpy/api/event/${idEvent}/step/delay`, data)
      .then((response) => toUnpyEvent(response.data));
  }

  countEvents(): Promise<CountHome> {
    return this.axios
      .get(`/unpy/api/events/count`)
      .then((response) => toCountHome(response.data));
  }

  countEventsCreationConsume(): Promise<CountConsume> {
    return this.axios
      .get(`/unpy/api/events/count/created/consume`)
      .then((response) => toCountConsume(response.data));
  }

  reopenSubscriptions(
    data: EventReopenSubscribeFormData,
    idEvent: number,
  ): Promise<UnpyEvent> {
    return this.axios
      .put(`/unpy/api/event/${idEvent}/step/subscription/reopen`, data)
      .then((response) => toUnpyEvent(response.data));
  }
}
