import { Button } from '@mui/material';
import { canRegister, useGetPlayerOfEvent } from 'domain/event/UnpyEvent.func';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import ModalTeamEvent from 'primary/events/modals/ModalTeamEvent';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React, { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { UnpyEvent } from '../../../domain/event/UnpyEvent';
import { User } from '../../../domain/user/User';
import { routesConfig } from '../../Configs/Routes.config';
import { useTranslate } from '../../hooks/useTranslate';

interface EventActionProps {
  event: UnpyEvent | UnpyEventView;
  currentUser?: User;
  openModalRegister: () => void;
  className: string;
  retry: () => void;
}

export const EventActions = ({
  event,
  currentUser,
  openModalRegister,
  className,
  retry,
}: EventActionProps) => {
  const navigate = useNavigate();
  const t = useTranslate();
  const { authRepository } = useContextDependency();
  const teamRegistered = useGetPlayerOfEvent(event.id, currentUser?.userProfil?.id);
  const isCanRegister = canRegister(event);
  const userId =
    typeof event === UnpyEventView.name
      ? event.creator.id
      : (event as UnpyEvent).creator.userId;
  const [searchParams, setSearchParams] = useSearchParams();
  const register = searchParams.get('register');
  const isRegisterOpenedFromRedirect = useRef(false);
  useEffect(() => {
    if (!!currentUser && !isRegisterOpenedFromRedirect.current && register === 'true') {
      openModalRegister();
      isRegisterOpenedFromRedirect.current = true;
    }
  }, [register, openModalRegister, isRegisterOpenedFromRedirect.current, currentUser]);

  const [isModalTeamOpen, setIsModalTeamOpen] = React.useState(false);
  return (
    <div className={className}>
      {userId === currentUser?.id && (
        <Button
          variant={'contained'}
          onClick={() =>
            navigate(routesConfig.parametersDetailsEvent.parameterize.to(event.id))
          }
          color={'primary'}
          className={'-registerBtn'}
        >
          {t('event.eventViewPage.parametersBtn')}
        </Button>
      )}
      {currentUser?.id && userId !== currentUser.id && isCanRegister && (
        <Button
          variant={'contained'}
          onClick={teamRegistered ? () => setIsModalTeamOpen(true) : openModalRegister}
          color={'primary'}
          className={'-registerBtn'}
        >
          {teamRegistered
            ? t('event.eventViewPage.seeTeam')
            : t('event.eventViewPage.registerBtn')}
        </Button>
      )}

      {!currentUser?.id && isCanRegister && (
        <Button
          variant={'contained'}
          onClick={() =>
            authRepository.login(
              window.location.origin + window.location.pathname + '?register=true',
            )
          }
          color={'primary'}
          className={'-registerBtn'}
        >
          {t('event.eventViewPage.registerBtn')}
        </Button>
      )}

      <ModalContainer
        handleClose={() => setIsModalTeamOpen(false)}
        isOpen={isModalTeamOpen}
        content={
          <>
            {teamRegistered && (
              <ModalTeamEvent
                event={event}
                teamRegistered={teamRegistered}
                afterChanges={retry}
                handleClose={() => setIsModalTeamOpen(false)}
              />
            )}
          </>
        }
      />
    </div>
  );
};
