import { AxiosInstance } from 'axios';
import { IProductRepository } from 'domain/payment/IProduct.repository';
import { Product } from 'domain/payment/Product';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';
import { toProduct } from 'secondary/payment/RestProduct';

export class ProductRepository
  extends AxiosProviderRepository
  implements IProductRepository
{
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  getAll(): Promise<Product[]> {
    return this.axios.get(`unpy/api/products/all`).then((resp) => {
      return resp.data.map(toProduct);
    });
  }

  getOneById(id: string): Promise<Product> {
    return this.axios.get(`unpy/api/product/${id}`).then((resp) => toProduct(resp.data));
  }

  sync(): Promise<void> {
    return this.axios.get(`unpy/api/products/sync`);
  }
}
