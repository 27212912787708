import { ProfilPrivacy } from 'domain/privacy/ProfilPrivacy';
import { UnpyStatistiquesProfil } from 'domain/profil/UnpyStatistiquesProfil';

import { BackgroundImageView } from '../backgroundImage/BackgroundImageView';
import { GameProfil } from '../gameProfil/GameProfil';
import { Lang } from '../lang/Lang';
import { Neon } from '../neon/Neon';
import { Platform } from '../platform/Platform';

export class Profil {
  constructor(
    public readonly id: number,
    public readonly games: GameProfil[],
    public readonly neon: Neon,
    public readonly backgroundImage?: BackgroundImageView,
    public readonly description?: string,
    public readonly gamingProfil?: string,
    public readonly lang?: string,
    public readonly platforms?: Platform[],
    public readonly video?: string,
    public readonly ping?: boolean,
    public readonly twitter?: string,
    public readonly facebook?: string,
    public readonly youtube?: string,
    public readonly instagram?: string,
    public readonly discord?: string,
    public readonly twitch?: string,
    public readonly talkLangs?: Lang[],
    public readonly privacies?: ProfilPrivacy[],
    public readonly unpyStatistiques?: UnpyStatistiquesProfil,
  ) {}
}
