import './EventList.scss';

import { Grid } from '@mui/material';
import { IEventRepository } from 'domain/event/Event.repository';
import { UnpyEventFilters } from 'domain/event/UnpyEvent';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { GAME_ACTIVE_LIST } from 'domain/game/GameActiveList';
import { debounce } from 'lodash';
import { AutocompleteEvent } from 'primary/Components/AutoCompleteEvent/AutocompleteEvent';
import { ListElements } from 'primary/Components/ListElements/ListElements';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

import { EventCard } from '../../Components/Event/EventCard';
import { EventsFilters } from '../../Components/Filters/Events/EventsFilters';
import { LayoutList, LayoutListProps } from '../../Components/Layout/Lists/LayoutList';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useFetchWithFilterPage } from '../../hooks/useFetchWithFilterPage';

export type TypeSearchEvent = 'PUBLIC' | 'OWN' | 'REGISTERED';
interface EventListProps {
  title?: string;
  gamesSelected?: GAME_ACTIVE_LIST[];
  typeSearch: TypeSearchEvent;
  listProps?: Partial<LayoutListProps<any>>;
}

const methodByTypeSearch = {
  ['PUBLIC']: 'searchPublic',
  ['OWN']: 'searchOwn',
  ['REGISTERED']: 'searchRegistered',
};

export const getCallApi =
  (eventRepository: IEventRepository, typeSearch: TypeSearchEvent) =>
  (filters: UnpyEventFilters, page: number) => {
    if (typeSearch === 'PUBLIC') {
      return eventRepository.searchPublic(filters, page, 32);
    }
    if (typeSearch === 'OWN') {
      return eventRepository.searchOwn(filters, page, 32);
    }
    return eventRepository.searchRegistered(filters, page, 32);
  };

export const EventList = ({
  gamesSelected = [],
  typeSearch,
  title,
  listProps,
}: EventListProps) => {
  const { eventRepository } = useContextDependency();
  const defaultFilters: UnpyEventFilters = {
    games: gamesSelected,
    startDateTime: undefined,
  };
  // @ts-ignore
  const {
    fetchedResource,
    goToPage,
    loading,
    activeFilters,
    changeNbPerPage,
    setFilters,
  } = useFetchWithFilterPage<UnpyEventView, UnpyEventFilters>({
    keyStoreFilters: 'event',
    filters: defaultFilters,
    callApi: getCallApi(eventRepository, typeSearch),
  });
  const setFiltersDebonced = debounce((name: string) => {
    setFilters({ ...activeFilters, name: name });
  }, 100);
  const t = useTranslate();
  return (
    <LayoutList
      filtersTag={activeFilters?.filters.games}
      onDeleteFilter={(filter) => {
        //ts-ignore
        const keyGame = Object.keys(GAME_ACTIVE_LIST).find(
          (g) =>
            //@ts-ignore
            t(`filtersValues.${GAME_ACTIVE_LIST[g] as GAME_ACTIVE_LIST}`) ===
            filter.value,
        );
        setFilters({
          ['games']: activeFilters.filters.games?.filter((g) => g !== keyGame),
        });
      }}
      title={t('event.eventsListPage.titleEventList')}
      tablePagination
      filtersBarComponent={
        <EventsFilters
          typeSearch={typeSearch}
          setFilters={setFilters}
          resetFilters={() => setFilters({})}
        />
      }
      keyDomainFilter={'event'}
      paginationProps={{
        goToPage: goToPage,
        currentPage: fetchedResource?.currentPage,
        numberOfItem: fetchedResource?.totalElementsCount,
        setRowPerPage: changeNbPerPage,
        numberOfItemPerPage: fetchedResource?.pageSize,
        pagesCount: fetchedResource?.pagesCount || 0,
      }}
      pagination
      resetFilters={() => setFilters({})}
      searchTextComponent={
        <AutocompleteEvent
          onChangeText={setFiltersDebonced}
          options={fetchedResource?.content?.map((option) => option.name) ?? []}
        />
      }
      listComponent={
        <ListElements loading={loading} empty={fetchedResource?.content.length === 0}>
          <Grid container>
            {fetchedResource?.content.map((event) => (
              <Grid
                item
                key={event.id}
                xs={12}
                sm={12}
                lg={4}
                xl={3}
                className={'list__elements'}
              >
                <EventCard className={'-item'} key={event.id} event={event} />
              </Grid>
            ))}
          </Grid>
        </ListElements>
      }
      {...(listProps ?? {})}
    />
  );
};
