import './NoGamesIllustration.scss';

import logoNoGame from '@assets/Icon/logoNoGame.png';
import React from 'react';

export const NoGamesIllustration = () => {
  return (
    <div className={'noGameIllustration'}>
      <img src={logoNoGame} alt={'-no-game-icon'} />
    </div>
  );
};
