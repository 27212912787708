import { Structure } from 'domain/structure/Structure';
import { StructureView } from 'domain/structure/StructureView';

import {
  RestStructureMember,
  toStructureMember,
} from '../structureMember/RestStructureMember';
import {
  RestStructureProfil,
  toStructureProfil,
} from '../structureProfil/RestStructureProfil';

export interface RestStructure {
  id: number;
  name: string;
  members: RestStructureMember[];
  structureProfil?: RestStructureProfil;
}

export function toStructure(restStructure: RestStructure): Structure {
  const { id, name, structureProfil } = restStructure;
  return new Structure(
    id,
    name,
    restStructure.members
      ? restStructure.members.map((value) => toStructureMember(value))
      : [],
    structureProfil ? toStructureProfil(structureProfil) : undefined,
  );
}

export interface RestStructureView {
  id: number;
  name: string;
  idProfil?: number;
}

export const toStructureView = (restStructureView: RestStructureView): StructureView => {
  return new StructureView(
    restStructureView.id,
    restStructureView.name,
    restStructureView.idProfil,
  );
};
