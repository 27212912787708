import { NeonType } from 'domain/neon/NeonType';

export enum NeonCodeEnum {
  WHITE_DEFAULT = 'WHITE_DEFAULT',
  YELLOW_GOLD = 'YELLOW_GOLD',
  PURPLE_PINK = 'PURPLE_PINK',
  GREEN_CYAN = 'GREEN_CYAN',
  TURQ = 'TURQ',
  BLUE = 'BLUE',
  GREEN = 'GREEN',
  RED = 'RED',
  ORAN = 'ORAN',
}

export type NeonClassName =
  | 'default'
  | 'jaune_gold'
  | 'violet_rose'
  | 'vert_cyan'
  | 'turquoise'
  | 'bleu'
  | 'vert'
  | 'rouge'
  | 'orange';

export const NeonClassNameByCode: { [key in NeonCodeEnum]: NeonClassName } = {
  WHITE_DEFAULT: 'default',
  YELLOW_GOLD: 'jaune_gold',
  PURPLE_PINK: 'violet_rose',
  GREEN_CYAN: 'vert_cyan',
  TURQ: 'turquoise',
  BLUE: 'bleu',
  GREEN: 'vert',
  RED: 'rouge',
  ORAN: 'orange',
};

export class Neon {
  constructor(
    public readonly id: number,
    public readonly code: NeonCodeEnum,
    public readonly color: string,
    public readonly lightColor: string,
    public readonly isUp: boolean,
    public readonly type: NeonType,
  ) {}

  getNeonBorderClassName() {
    return `borderNeons borderNeons__${NeonClassNameByCode[this.code]}`;
  }
  getNeonColorSvgClassName() {
    return `neonSvg__${NeonClassNameByCode[this.code]}`;
  }
}
