import './ListMessagesConversation.scss';

import classNames from 'classnames';
import { moinsNumberUnviewedMessages } from 'domain/notification/store/NotificationSlice';
import { InfiniteScroll } from 'primary/Components/InfiniteScroll/InfiniteScroll';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { Conversation } from '../../../../domain/message/Conversation';
import { Message, MessageFilters } from '../../../../domain/message/Message';
import { RestMessage } from '../../../../secondary/message/RestMessage';
import { useContextDependency } from '../../../hooks/useContextDependency';
import { useFetchWithFilterPage } from '../../../hooks/useFetchWithFilterPage';
import { useTranslate } from '../../../hooks/useTranslate';
import { useSubscribeTopic } from '../../User/NotificationPreview';
import { MessageItem } from '../messageItem/MessageItem';
import { MessageForm } from './MessageForm';

interface ListMessagesConversationProps {
  conversation: Conversation;
}

interface WrapperFetchListMessagesConversationProps {
  conversationId: number;
  retryConvListe?: () => void;
}
export const WrapperFetchListMessagesConversation = ({
  conversationId,
  retryConvListe,
}: WrapperFetchListMessagesConversationProps) => {
  const { messageRepository } = useContextDependency();
  const [conversation, , , retry] = useRetrieveFromDomain(
    () =>
      messageRepository
        .getConversationById(conversationId)
        .catch(() => retryConvListe && retryConvListe()),
    undefined,
  );
  const t = useTranslate();
  if (!conversation) {
    return <LoadingScreen loading={true} message={t('message.conversation.loading')} />;
  }
  return <ListMessagesConversation conversation={conversation} key={conversation.id} />;
};

interface MessageListContentProps {
  scrollToTop: () => void;
  allContents: Message[] | undefined;
  loading: boolean;
}

function MessageListContent({
  scrollToTop,
  allContents,
  loading,
}: MessageListContentProps) {
  const t = useTranslate();
  const { authRepository } = useContextDependency();
  const isScrolled = useRef(false);
  useEffect(() => {
    if (!isScrolled.current && allContents != undefined) {
      scrollToTop();
      isScrolled.current = true;
    }
  });
  return (
    <>
      {!loading && (!allContents || allContents.length === 0) && (
        <span>{t('message.list.noMessages')}</span>
      )}
      {allContents &&
        allContents
          .sort((a, b) => a.id - b.id)
          .map((message) => (
            <MessageItem
              key={message.id}
              className={classNames('-item', {
                '-isOwner': message.sender.id === authRepository.currentUser?.id,
              })}
              message={message}
              isPreview={false}
            />
          ))}
    </>
  );
}

export const ListMessagesConversation = ({
  conversation,
}: ListMessagesConversationProps) => {
  const { messageRepository, authRepository } = useContextDependency();
  const {
    allContents,
    goToPage,
    nextPage,
    loading,
    activeFilters,
    setFilters,
    retry,
    addToCurrentPage,
  } = useFetchWithFilterPage<Message, MessageFilters>({
    filters: { conversation: conversation.id },
    callApi: (filters, page) =>
      messageRepository.getMessagesOfConversation(conversation.id, page || 0),
    keepPageResult: true,
  });
  const t = useTranslate();

  useSubscribeTopic<RestMessage>(
    `/conversation/message/${conversation.id}`,
    (message) => {
      retry();
    },
  );

  const dispatch = useDispatch();

  useEffect(() => {
    messageRepository.toViewedConversation(conversation.id).then((countMsgViewed) => {
      dispatch(
        moinsNumberUnviewedMessages({
          idConversation: conversation.id,
          unviewToDecrease: countMsgViewed,
        }),
      );
    });
  }, []);

  return (
    <div className={'listMessagesConversation'}>
      <div className={'listMessagesConversation__listMessages'}>
        <InfiniteScroll onScrollTop={nextPage} isLoading={loading} loadingOnTop>
          {(scrollToTop) => (
            <MessageListContent
              loading={loading}
              allContents={allContents}
              scrollToTop={scrollToTop}
            />
          )}
        </InfiniteScroll>
      </div>
      <div className={'listMessagesConversation__formSend'}>
        <MessageForm onSubmitted={retry} idConversation={conversation.id} />
      </div>
    </div>
  );
};
