import './Helpers.scss';

import { HelperPopper } from 'primary/helpers/HelperPopper';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
export const HelperEventType = () => {
  const t = useTranslate();
  return (
    <HelperPopper color={'white'}>
      <div className={'helpers -helperTypeEvent'}>
        <div className={'-blocTypeEvent'}>
          <h4 className={'-accent'}>{t('helpers.events.type.ligue.title')}</h4>
          <p>{t('helpers.events.type.ligue.content')}</p>
        </div>
        <div className={'-blocTypeEvent'}>
          <h4 className={'-accent'}>{t('helpers.events.type.ligueBR.title')}</h4>
          <p>{t('helpers.events.type.ligueBR.content')}</p>
        </div>
        <div className={'-blocTypeEvent'}>
          <h4 className={'-accent'}>{t('helpers.events.type.tournament.title')}</h4>
          <p>{t('helpers.events.type.tournament.content')}</p>
        </div>
        <div className={'-blocTypeEvent'}>
          <h4 className={'-accent'}>
            {t('helpers.events.type.tournamentDoubleElem.title')}
          </h4>
          <p>{t('helpers.events.type.tournamentDoubleElem.content')}</p>
        </div>
      </div>
    </HelperPopper>
  );
};
