import i18next from 'i18next';
import { translationEN } from 'locales/fr/en';
import { translationFR } from 'locales/fr/fr';

export enum LANG {
  FR = 'fr',
  EN = 'en',
}

export enum LANG_ALT {
  FR = 'fr-FR',
  EN = 'en-EN',
}
const resources = {
  [LANG.FR]: {
    translation: translationFR,
  },
  'fr-FR': {
    translation: translationFR,
  },
  [LANG.EN]: {
    translation: translationEN,
  },
  'en-EN': {
    translation: translationEN,
  },
};

i18next.init({
  // the translations
  // (tip move them in a JSON file and import them,
  // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
  resources,
  fallbackLng: LANG.FR,
  keySeparator: '.',
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
  compatibilityJSON: 'v3',
});

export const i18n = i18next;
