import { Annonce } from 'domain/annonce/Annonce';
import { AnnonceTypeEnum } from 'domain/annonce/AnnonceTypeEnum';
import { RestEventView, toUnpyEventView } from 'secondary/event/RestEventView';
import { RestStructureView, toStructureView } from 'secondary/structure/RestStructure';
import { RestUserView, toUserView } from 'secondary/user/RestUser';

import { toEnum } from '../../helper/enum.helper';
export interface RestAnnonce {
  id: number;
  typeAnnonce: string;
  createDate: string;
  isPublic: boolean;
  text: string;
  eventView?: RestEventView;
  structureView?: RestStructureView;
  creator: RestUserView;
  image?: string;
}
export function toAnnonce(restBracketMatchParticipant: RestAnnonce): Annonce {
  const {
    id,
    typeAnnonce,
    createDate,
    creator,
    isPublic,
    text,
    eventView,
    structureView,
    image,
  } = restBracketMatchParticipant;
  return new Annonce(
    id,
    toEnum(AnnonceTypeEnum, typeAnnonce),
    new Date(createDate),
    isPublic,
    text,
    toUserView(creator),
    eventView ? toUnpyEventView(eventView) : undefined,
    structureView ? toStructureView(structureView) : undefined,
    image,
  );
}
