import './ModalTeamEvent.scss';

import { Cancel, Check, Edit } from '@mui/icons-material';
import { Button, Chip, IconButton } from '@mui/material';
import classNames from 'classnames';
import { EventStatusEnum } from 'domain/event/EventStatusEnum';
import { EventParticipant } from 'domain/event/participants/EventParticipant';
import { PlayerRoleEnum } from 'domain/event/PlayerRoleEnum';
import { PlayerTeam } from 'domain/event/PlayerTeam';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { Team } from 'domain/team/Team';
import { ITeamRepository } from 'domain/team/Team.repository';
import { TranslationType } from 'domain/translation/Translation.repository';
import { InputSelectMui } from 'primary/Components/Input/InputSelectMui';
import {
  ChangeHandlerTypeParam,
  InputWrapper,
} from 'primary/Components/Input/InputWrapper';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { TagPlayer } from 'primary/Components/TagPlayer/TagPlayer';
import { sendEventToastMessage } from 'primary/Components/Toast/Toast.helper';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import { useTranslate, UseTranslateReturn } from 'primary/hooks/useTranslate';
import { useYupValidationResolver } from 'primary/hooks/useYupValidationResolver';
import React, { useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { Title } from '../../Components/Title/Title';
import { useContextDependency } from '../../hooks/useContextDependency';

interface ModalTeamEventProps {
  handleClose: () => void;
  afterChanges: () => void;
  teamRegistered: EventParticipant;
  event: UnpyEventView | UnpyEvent;
}

const ModalTeamEvent = ({
  event,
  teamRegistered,
  handleClose,
  afterChanges,
}: ModalTeamEventProps) => {
  const t = useTranslate();

  const { authRepository, eventParticipantRepository } = useContextDependency();
  const [teamParticipant, , , retry] = useRetrieveFromDomain(
    () => eventParticipantRepository.getEventParticipantById(teamRegistered.id),
    undefined,
  );
  const isEditable =
    teamParticipant &&
    event.status === EventStatusEnum.PUBLISH &&
    teamParticipant.team.players.find((p) => p.role === PlayerRoleEnum.CHIEF)?.player
      .id === authRepository?.currentUser?.userProfil?.idProfil;

  const handleSubmitted = () => {
    afterChanges();
    retry();
  };
  const navigate = useNavigate();
  const handleDeleteTeam = () => {
    if (!teamParticipant) return;

    eventParticipantRepository
      .deleteEventParticipantForEvent(event.id, teamParticipant.team.id)
      .then(() => {
        sendEventToastMessage('toast.event.unregister', 'success');
        handleClose();
        afterChanges();
        navigate(routesConfig.eventList.to);
      });
  };
  if (!teamParticipant) return <LoadingScreen loading />;
  return (
    <div className={'modal-team-event'}>
      <Title threeQuarter bordered title={t('event.myTeamModal.title')} level={2} />
      <div className={'-unsubscribeBtnContainer'}>
        <Button
          className={'-button'}
          variant={'contained'}
          color={'error'}
          disabled={!isEditable}
          onClick={handleDeleteTeam}
        >
          {t('event.myTeamModal.unregister')}
        </Button>
      </div>

      <div className={'-content'}>
        <div className={'-header'}>
          <div className={'-titleChip'}>
            <h2>{teamParticipant.team.name}</h2>
            <Chip
              className={classNames('-tagStateTeam')}
              label={t(
                `enum.teamStatus.${teamParticipant.team.status}` as TranslationType,
              )}
            />
          </div>
        </div>
        <div className={'-playerInTeam'}>
          {teamParticipant.team.players
            .sort((p, p2) => p.player.id - p2.player.id)
            .map((player) => (
              <PlayerItemBloc
                isEditable={isEditable}
                key={player.player.id}
                player={player}
                team={teamParticipant.team}
                onSubmitted={handleSubmitted}
              />
            ))}
        </div>
      </div>
      <div className={'-closeBtnContainer'}>
        <Button className={'-button'} variant={'contained'} onClick={handleClose}>
          {t('modals.waitingForActions.closeBtn')}
        </Button>
      </div>
    </div>
  );
};

type PlayerItemBlocProps = {
  player: PlayerTeam;
  team: Team;
  onSubmitted: () => void;
  isEditable?: boolean;
};

const PlayerItemBloc = ({
  player,
  team,
  onSubmitted,
  isEditable,
}: PlayerItemBlocProps) => {
  const [editRole, setEditRole] = useState(false);
  const t = useTranslate();
  return (
    <div className={'-playersContainer'}>
      <div className={'-player'}>
        <TagPlayer key={player.player.idPlayer} player={player.player} />
        <div className={'-roleEdit'}>
          {!editRole && (
            <div className={'-noRoleEdit'}>
              <span>{t(`enum.playerRole.${player.role}`)}</span>
              {isEditable && (
                <IconButton onClick={() => setEditRole(true)} className={'-editRole'}>
                  <Edit />
                </IconButton>
              )}
            </div>
          )}
          {editRole && (
            <FormEditRoleField
              key={player.player.id}
              player={player}
              team={team}
              onSubmitted={() => {
                onSubmitted();
                setEditRole(false);
              }}
              onCancel={() => setEditRole(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalTeamEvent;

export type PlayerRoleEdit = {
  playerId?: number;
  role?: PlayerRoleEnum;
};

const validationSchema = (t: UseTranslateReturn) =>
  yup.object({
    playerId: yup.number().required(t('general.forms.errors.required')),
    role: yup.string().required(t('general.forms.errors.required')),
  });

const useOnSubmit =
  (
    teamRepository: ITeamRepository,
    setLoading: (teamCreatingLoading: boolean) => void,
    onSubmitted: (player: PlayerTeam) => void,
    idTeam: number,
  ) =>
  (data: PlayerRoleEdit) => {
    setLoading(true);
    teamRepository
      .editRole(idTeam, data.playerId as number, data.role?.toString() as string)
      .then((player: PlayerTeam) => {
        onSubmitted(player);
      })
      .finally(() => {
        setLoading(false);
      });
  };

type FormEditRoleFieldProps = {
  player: PlayerTeam;
  team: Team;
  onSubmitted: (player: PlayerTeam) => void;
  onCancel: () => void;
};

const FormEditRoleField = ({
  player,
  team,
  onSubmitted,
  onCancel,
}: FormEditRoleFieldProps) => {
  const { teamRepository } = useContextDependency();
  const t = useTranslate();
  const resolver = useYupValidationResolver(validationSchema);
  const [loading, setLoading] = useState(false);
  const onSubmit = useOnSubmit(teamRepository, setLoading, onSubmitted, team.id);

  const form = useForm<PlayerRoleEdit>({
    resolver,
    defaultValues: {
      playerId: player.player.idProfil,
      role: player.role,
    },
  });
  const optRole = Object.values(PlayerRoleEnum).map((roleOpt) => ({
    label: t(`enum.playerRole.${roleOpt as PlayerRoleEnum}`),
    value: roleOpt,
    disabled:
      player.role === PlayerRoleEnum.CHIEF &&
      team.players.find(
        (p) => p.player.id !== player.player.idProfil && p.role === PlayerRoleEnum.CHIEF,
      ),
  }));

  const { field: playerRoleField } = useController({
    control: form.control,
    name: `role`,
  });

  return (
    <div className={'-inputRoleContainer'}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <InputWrapper
          className={'-playersField__role'}
          errors={form.formState.errors}
          Input={InputSelectMui}
          inputProps={{
            //@ts-ignore
            options: optRole,
            placeholder: t('event.form.placeholders.role'),
            menuPortalTarget: document.getElementById('root'),
            ...playerRoleField,
            //@ts-ignore
            onChange: (event: ChangeHandlerTypeParam) => {
              form.setValue(`role`, event.target.value as PlayerRoleEnum);
            },
          }}
        />
        <div className={'-btnEditRolesForm'}>
          <IconButton onClick={onCancel} className={'-editRole'}>
            <Cancel />
          </IconButton>
          <Button type={'submit'} className={'-editRole'}>
            <Check />
          </Button>
        </div>
      </form>
    </div>
  );
};
