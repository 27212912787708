import { AxiosInstance } from 'axios';
import { Pageable } from 'domain/pageable/Pageable';
import { StructureProfil, StructureProfilFilters } from 'domain/profil/StructureProfil';
import { IStructureProfilRepository } from 'domain/structure/IStructureProfil.repository';
import { StructureProfilDetailsFormData } from 'primary/Parameters/structure/Admin/StructureDetails';
import { StructureProfilNetworkFormFormData } from 'primary/Parameters/structure/Profil/StructureProfilNetworkForm';
import { ProfilAppearenceFormData } from 'primary/Parameters/user/Profil/UserProfilAppearence';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';

import { toPageable } from '../RestPageable';
import { toStructureProfil } from './RestStructureProfil';

export class StructureProfilRepository
  extends AxiosProviderRepository
  implements IStructureProfilRepository
{
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  getStructureProfilById(idProfilStructure: string | number): Promise<StructureProfil> {
    return this.axios
      .get(`/unpy/api/structure/profil/${idProfilStructure}`)
      .then((resp) => toStructureProfil(resp.data));
  }

  search(
    filters: StructureProfilFilters,
    page: number,
    nbPerPage: number,
  ): Promise<Pageable<StructureProfil>> {
    return this.axios
      .get('/unpy/api/structure/profil/search', {
        params: {
          ...filters,
          page,
          nbPerPage,
        },
      })
      .then((response) => toPageable(response, toStructureProfil));
  }

  updateProfilPhoto(data: File, idStructureProfil?: number): Promise<StructureProfil> {
    const bodyFormData = new FormData();

    bodyFormData.set('photo', data);
    return this.axios
      .put(`/unpy/api/structure/profil/${idStructureProfil}/update/photo`, bodyFormData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((resp) => toStructureProfil(resp.data));
  }

  updateBanner(data: File, idStructureProfil?: number): Promise<StructureProfil> {
    const bodyFormData = new FormData();

    bodyFormData.set('banner', data);
    return this.axios
      .put(
        `/unpy/api/structure/profil/${idStructureProfil}/update/banner`,
        bodyFormData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      )
      .then((resp) => toStructureProfil(resp.data));
  }

  updateName(
    data: { name: string },
    idStructureProfil?: number,
  ): Promise<StructureProfil> {
    return this.axios
      .put(`/unpy/api/structure/profil/${idStructureProfil}/update/name`, data)
      .then((resp) => toStructureProfil(resp.data));
  }

  updateStructureProfilAppearence(
    data: ProfilAppearenceFormData,
    idStructureProfil: number,
    file?: File,
  ) {
    const bodyFormData = new FormData();
    bodyFormData.set(
      'form',
      new Blob([JSON.stringify(data)], {
        type: 'application/json',
      }),
    );
    bodyFormData.set('profilId', idStructureProfil.toString());
    bodyFormData.set('file', file || '');
    return this.axios
      .put(`/unpy/api/structure/profil/update/appearence`, bodyFormData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((resp) => toStructureProfil(resp.data));
  }

  updateProfilStructureDetails(
    data: StructureProfilDetailsFormData,
    idStructureProfil: number,
  ): Promise<StructureProfil> {
    return this.axios
      .put(`/unpy/api/structure/profil/${idStructureProfil}/update/details`, data)
      .then((resp) => toStructureProfil(resp.data));
  }

  updateProfilNetwork(
    data: StructureProfilNetworkFormFormData,
    idStructureProfil: number,
  ): Promise<StructureProfil> {
    const mappedData = Object.values(data.privacies).map((d) => ({
      code: d.code,
      profilId: d.profilId,
      isChecked: d.isChecked,
    }));

    return this.axios
      .put(`/unpy/api/structure/profil/${idStructureProfil}/update/network`, {
        ...data,
        privacies: mappedData,
      })
      .then((resp) => toStructureProfil(resp.data));
  }
}
