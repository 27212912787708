import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';

type FormStepperProps = {
  conf: FormStepperConf;
  generalProps: any;
  embedded?: boolean;
};

export type FormStepperConf = {
  steps: FormStepperConfStep[];
};

export type FormStepperConfStep = {
  id: string;
  title: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  component: Function;
  optional?: boolean;
};

type UseFormStepState = {
  steps: {
    [key: string]: FormStep;
  };
};

type FormStep = {
  stepId: string;
  datas: any;
  isActive?: boolean;
  state: 'done' | 'pending';
};

const useFormStep = (conf: FormStepperConf, defaultActiveStep?: string) => {
  const stepsIds = useMemo(() => conf.steps.map((step) => step.id), [conf.steps]);
  const [formStepStates, setFormStepStates] = React.useState<UseFormStepState>(
    conf.steps.reduce((acc, step, index) => {
      return {
        ...acc,
        steps: {
          ...acc.steps,
          [step.id]: {
            stepId: step.id,
            datas: {},
            isActive: defaultActiveStep ? defaultActiveStep === step.id : index === 0,
            state: 'pending',
          },
        },
      };
    }, {} as UseFormStepState),
  );
  const currentActiveStepIdIndex = useMemo(
    () =>
      stepsIds.indexOf(
        Object.values(formStepStates.steps).find((step) => step.isActive)?.stepId || '',
      ),
    [formStepStates],
  );

  const nextStep = (data?: any) => {
    if (currentActiveStepIdIndex === -1 || currentActiveStepIdIndex + 1 > stepsIds.length)
      return;
    const newActiveStepId = stepsIds[currentActiveStepIdIndex + 1];
    if (!newActiveStepId) return;
    setFormStepStates((old) => ({
      ...old,
      steps: {
        ...old.steps,
        [stepsIds[currentActiveStepIdIndex]]: {
          ...old.steps[stepsIds[currentActiveStepIdIndex]],
          stepId: stepsIds[currentActiveStepIdIndex],
          datas: data,
          isActive: false,
          state: 'done',
        },
        [newActiveStepId]: {
          ...old.steps[newActiveStepId],
          isActive: true,
        },
      },
    }));
  };

  const previousStep = (data: any) => {
    if (currentActiveStepIdIndex === -1 || currentActiveStepIdIndex - 1 > 0) return;
    const newActiveStepId = stepsIds[currentActiveStepIdIndex - 1];
    if (!newActiveStepId) return;
    setFormStepStates((old) => ({
      ...old,
      [currentActiveStepIdIndex]: {
        datas: data,
        isActive: false,
      },
      [newActiveStepId]: {
        isActive: true,
      },
    }));
  };

  return {
    activeStep: formStepStates.steps[stepsIds[currentActiveStepIdIndex]],
    nextStep,
    previousStep,
    combinedData: Object.values(formStepStates.steps).reduce((acc, step) => {
      return {
        ...acc,
        ...step.datas,
      };
    }, {}),
  };
};

export const FormStepper = ({ conf, generalProps, embedded }: FormStepperProps) => {
  const { combinedData, activeStep, nextStep, previousStep } = useFormStep(conf);
  const activeStepIndex = useMemo(
    () => conf.steps.findIndex((step) => step.id === activeStep?.stepId),
    [activeStep, conf.steps],
  );
  const StepComp = useMemo(
    () => conf.steps[activeStepIndex].component,
    [activeStepIndex],
  );
  return (
    <div className={'formStepper'}>
      <div className={'formStepper__header'}>
        <Stepper
          orientation={embedded ? 'vertical' : 'horizontal'}
          activeStep={activeStepIndex}
        >
          {conf.steps.map((step) => {
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            if (step.optional) {
              labelProps.optional = <Typography variant="caption">Optional</Typography>;
            }
            return (
              <Step key={step.title} className={'formStepper__step'}>
                <StepLabel className={'formStepper__step-label'} {...labelProps}>
                  {step.title}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      <div className={'formStepper__step'}>
        <StepComp
          nextStep={nextStep}
          previousStep={previousStep}
          initialValues={activeStep.datas}
          data={combinedData}
          {...generalProps}
        />
      </div>
    </div>
  );
};
