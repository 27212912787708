import './ProfilTabs.scss';

import { Grid } from '@mui/material';
import { StructureProfil } from 'domain/profil/StructureProfil';
import { Structure, StructureFilters } from 'domain/structure/Structure';
import { ListElements } from 'primary/Components/ListElements/ListElements';
import { NoContentBloc } from 'primary/Components/NoContentBloc/NoContentBloc';
import { PreviewCardProfil } from 'primary/Components/Profils/PreviewCardProfil';
import { UserProfilContext } from 'primary/Components/Profils/User/UserProfilCard';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetchWithFilterPage } from 'primary/hooks/useFetchWithFilterPage';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { useOutletContext } from 'react-router-dom';

export const ProfilTabsStructures = () => {
  const t = useTranslate();
  const { profil } = useOutletContext<UserProfilContext>();
  const { structureRepository } = useContextDependency();

  const {
    fetchedResource,
    goToPage,
    loading,
    activeFilters,
    setFilters,
    fetch,
    nextPage,
    changeNbPerPage,
    retry,
    allContents,
  } = useFetchWithFilterPage<Structure, StructureFilters>({
    filters: {},
    staticFilters: { member: profil.userId },
    callApi: (filters, page) => structureRepository.search(filters, page, 30),
    keepPageResult: true,
    keyStoreFilters: 'structures-list-profil',
  });

  return (
    <div className={'-tabsStructures'}>
      {fetchedResource?.content?.length != 0 && (
        <ListElements loading={loading} empty={fetchedResource?.content.length === 0}>
          <Grid container>
            {!loading &&
              fetchedResource?.totalElementsCount !== 0 &&
              fetchedResource?.content?.map((structure) => (
                <PreviewCardProfil
                  className={'-itemLayoutList'}
                  key={profil.id}
                  profil={structure.structureProfil as StructureProfil}
                />
              ))}
          </Grid>
        </ListElements>
      )}
      {!loading && !fetchedResource?.content?.length && (
        <NoContentBloc text={t('profil.structures.noStructures')} />
      )}
    </div>
  );
};
