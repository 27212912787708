import './EventImage.scss';

import classNames from 'classnames';
import React, { FC } from 'react';

interface EventImageProps {
  className?: string;
  image?: string;
}

export const EventImage: FC<EventImageProps> = ({ className, image }) => {
  const isDraft = image?.includes('blob');

  return (
    <div className={classNames('eventImage')}>
      <img className={className} src={image} alt={`${image ?? 'default-event-image'}`} />
    </div>
  );
};
