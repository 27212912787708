import { AxiosInstance } from 'axios';
import { UnviewedMessageByConversation } from 'domain/message/UnviewedMessageByConversation';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';
import { toUnviewedMessageByConversation } from 'secondary/message/RestUnviewedMessageByConversation';

import { Conversation, ConversationFilters } from '../../domain/message/Conversation';
import { Message } from '../../domain/message/Message';
import { IMessageRepository } from '../../domain/message/Message.repository';
import { Pageable } from '../../domain/pageable/Pageable';
import { toPageable } from '../RestPageable';
import { toConversation } from './RestConversation';
import { toMessage } from './RestMessage';

export class MessageRepository
  extends AxiosProviderRepository
  implements IMessageRepository
{
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  getMessagesOfConversation(
    idConversation: number,
    page: number,
  ): Promise<Pageable<Message>> {
    return this.axios
      .get(`/unpy/api/messages/conversation/${idConversation}/${page}`)
      .then((response) => toPageable(response, toMessage));
  }

  getConversations(
    filters: ConversationFilters,
    page: number,
    nbPerPage: number,
  ): Promise<Pageable<Conversation>> {
    return this.axios
      .get('unpy/api/messages/conversations', {
        params: {
          ...filters,
          page,
          nbPerPage,
        },
      })
      .then((response) => toPageable(response, toConversation));
  }

  createConversation(userTo: number): Promise<Conversation> {
    return this.axios
      .post(`/unpy/api/conversation`, { userTo: userTo })
      .then((response) => toConversation(response.data));
  }

  sendMessageToConversation(
    content: string,
    conversationId?: number,
    userToId?: number,
  ): Promise<Message> {
    return this.axios
      .post(`/unpy/api/message/conversation`, {
        conversation: conversationId,
        userTo: userToId,
        content: content,
      })
      .then((response) => toMessage(response.data));
  }

  sendMessageToStructure(content: string, structureId?: number): Promise<Message> {
    return this.axios
      .post(`/unpy/api/message/conversation`, {
        structureId: structureId,
        content: content,
      })
      .then((response) => toMessage(response.data));
  }

  deleteConversationById(idConversation: number): Promise<void> {
    return this.axios.delete(`/unpy/api/conversation/${idConversation}`);
  }

  getNumberUnviewed(): Promise<UnviewedMessageByConversation[]> {
    return this.axios
      .get(`/unpy/api/message/unviewed/count`)
      .then((response) => response.data.map(toUnviewedMessageByConversation));
  }

  toViewedMessage(idMessage: number): Promise<Message> {
    return this.axios
      .put(`/unpy/api/message/unviewed/${idMessage}`)
      .then((response) => toMessage(response.data));
  }

  toViewedConversation(idconversation: number): Promise<number> {
    return this.axios
      .put(`/unpy/api/message/conversation/viewed/${idconversation}`)
      .then((response) => response.data);
  }

  getConversationById(conversationId: number): Promise<Conversation> {
    return this.axios
      .get(`/unpy/api/conversation/${conversationId}`)
      .then((response) => toConversation(response.data));
  }
}
