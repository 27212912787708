import './TopBarMenuProfil.scss';

import { Container } from '@mui/material';
import classNames from 'classnames';
import { ConfParametersLink } from 'primary/Components/MenuParameters/MenusParametersUser.config';
import { NeonText } from 'primary/Components/NeonText/NeonText';
import { BottomNavEventView } from 'primary/events/BottomNavEventView';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { FC, ReactElement, ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, matchPath, Path, useLocation } from 'react-router-dom';
import { RootState } from 'store';

export type LabelHomeTopBarMenuProfil =
  | undefined
  | 'topbarMenuProfil.links.details'
  | 'topbarMenuProfil.links.annonces'
  | 'topbarMenuProfil.links.performances'
  | 'topbarMenuProfil.links.members'
  | 'topbarMenuProfil.links.structures'
  | 'topbarMenuProfil.links.network';

export type confNavBarProfil = {
  label: LabelHomeTopBarMenuProfil;
  link: string;
  icon?: ReactElement | string;
  path: string | Path;
} & ConfParametersLink;

type NavBarProps = {
  confs: confNavBarProfil[];
  title: string;
  setIsFixed: (isFixed: boolean) => void;
  isFixed: boolean;
  actionsButons?: ReactNode;
};

export const TopBarMenuProfil: FC<NavBarProps> = ({
  confs,
  title,
  setIsFixed,
  isFixed,
  actionsButons,
}) => {
  const { authRepository } = useContextDependency();
  const user = useSelector((state: RootState) => state.user.connectedUser);
  const t = useTranslate();
  const { pathname } = useLocation();
  const subRoutePath = pathname.split('/')[4];
  const ref = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    document.getElementsByTagName('main')?.[0]?.addEventListener('scroll', () => {
      const position = ref?.current?.getBoundingClientRect()?.top
        ? ref?.current?.getBoundingClientRect()?.top + window.scrollY
        : undefined;
      if (position && position <= 65 && !isFixed) {
        setIsFixed(true);
      } else if (position && position > 65 && isFixed) {
        setIsFixed(false);
      }
    });

    return () => {
      document.getElementsByTagName('main')?.[0]?.removeEventListener('scroll', () => {
        console.log('unlisten');
      });
    };
  }, [isFixed]);
  return (
    <>
      <div className={classNames('topbarMenuProfil', { '-isFixed': isFixed })}>
        <Container maxWidth="xl" className={'-desktop'}>
          <nav className={classNames('topbarMenuProfil__navbar')} id={'main-nav-bar'}>
            <ul>
              {confs.map((conf: confNavBarProfil) => (
                <Link
                  className={classNames('-link', {
                    '-active': matchPath(
                      {
                        path: conf.path as unknown as string,
                        end: false,
                      },
                      pathname,
                    ),
                    '-icon': !!conf.icon,
                  })}
                  key={conf.link}
                  to={conf.link}
                >
                  {!!conf.icon && conf.icon}
                  {matchPath(
                    {
                      path: conf.path as unknown as string,
                      end: false,
                    },
                    pathname,
                  ) && (
                    <NeonText variant={'orange'} text={conf.label ? t(conf.label) : ''} />
                  )}
                  {!matchPath(
                    {
                      path: conf.path as unknown as string,
                      end: false,
                    },
                    pathname,
                  ) &&
                    subRoutePath !== conf.link &&
                    (conf.label ? t(conf.label) : '')}
                </Link>
              ))}
            </ul>
          </nav>
        </Container>
        <Container maxWidth="xl" className={'-mobile'}>
          <BottomNavEventView confs={confs} />
        </Container>
      </div>
    </>
  );
};
