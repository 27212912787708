import './Neon.scss';

import classNames from 'classnames';
import React, { FC } from 'react';

import { NeonClassName } from '../../../domain/neon/Neon';

export type NeonColor = 'blue' | 'violet';

interface NeonProps {
  className?: string;
  variant?: NeonClassName;
  width?: string;
}

export const Neon: FC<NeonProps> = ({ className, variant = 'default', width }) => {
  return (
    <div
      className={classNames('neon', `neon__${variant}`, className)}
      style={width ? { width: width } : undefined}
    ></div>
  );
};
