import './NotificationListItem.scss';

import { Card } from '@mui/material';
import classNames from 'classnames';
import { TranslationType } from 'domain/translation/Translation.repository';
import { formatDate } from 'helper/date.helper';
import { TagPlayer } from 'primary/Components/TagPlayer/TagPlayer';
import { NotificationEventGeneralContent } from 'primary/MessageList/contents/NotificationEventGeneralContent';
import { NotificationGeneralContent } from 'primary/MessageList/contents/NotificationGeneralContent';
import React, { MouseEventHandler, useState } from 'react';
import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';

import { NotificationTypeEnum } from '../../domain/notification/NotificationTypeEnum';
import { decrementNumberUnviewed } from '../../domain/notification/store/NotificationSlice';
import { UnpyNotification } from '../../domain/notification/UnpyNotification';
import { KeyValue } from '../Components/KeyValue/KeyValue';
import { useContextDependency } from '../hooks/useContextDependency';
import { useTranslate } from '../hooks/useTranslate';
import { NotificationInvitationFriendContent } from './contents/NotificationInvitationFriendContent';
import { NotificationInvitationStructureContent } from './contents/NotificationInvitationStructureContent';
import { NotificationInvitationTeamEventContent } from './contents/NotificationInvitationTeamEventContent';
import { NotificationJoinStructureContent } from './contents/NotificationJoinStructureContent';
import { NotificationParticipationEventContent } from './contents/NotificationParticipationEventContent';

interface notificationListItemProps {
  notification: UnpyNotification;
  reFetchNotification: () => void;
  className?: string;
}

// @ts-ignore
const CONTENT_COMP_BY_NOTIFICATION_TYPE: {
  [key in NotificationTypeEnum]: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
    isResponse?: boolean,
  ) => ReactNode;
} = {
  [NotificationTypeEnum.INVITATION_STRUCTURE]: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => (
    <NotificationInvitationStructureContent
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  JOIN_STRUCTURE: (notification: UnpyNotification, reFetchNotification: () => void) => (
    <NotificationJoinStructureContent
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  JOIN_STRUCTURE_FROM_INVITATION_LINK: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => (
    <NotificationJoinStructureContent
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  PARTICIPATION_EVENT: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => (
    <NotificationParticipationEventContent
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  INVITATION_TEAM_PARTICIPATION_EVENT: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => (
    <NotificationInvitationTeamEventContent
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  INVITATION_FRIEND: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => (
    <NotificationInvitationFriendContent
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  INVITATION_FRIEND_RESPONSE: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => (
    <NotificationInvitationFriendContent
      isResponse
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  INVITATION_STRUCTURE_RESPONSE: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => (
    <NotificationInvitationStructureContent
      isResponse
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  REVERT_EVENT_PLACE: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => (
    <NotificationInvitationFriendContent
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  MAJ_CONFIRM_EVENT: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => (
    <NotificationEventGeneralContent
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  MAJ_DECLINE_EVENT: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => (
    <NotificationEventGeneralContent
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  NEW_MESSAGE: (notification: UnpyNotification, reFetchNotification: () => void) => (
    <NotificationInvitationFriendContent
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  INVITATION_RESPONSE_DECLINE: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => (
    <NotificationGeneralContent
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  INVITATION_RESPONSE_ACCEPT: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => (
    <NotificationGeneralContent
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  EVENT_TO_CONFIRM: (notification: UnpyNotification, reFetchNotification: () => void) => (
    <NotificationEventGeneralContent
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  EVENT_CANCELED: (notification: UnpyNotification, reFetchNotification: () => void) => (
    <NotificationEventGeneralContent
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  EVENT_START: (notification: UnpyNotification, reFetchNotification: () => void) => (
    <NotificationEventGeneralContent
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  EVENT_DELAYED: (notification: UnpyNotification, reFetchNotification: () => void) => (
    <NotificationEventGeneralContent
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  EVENT_FINISHED: (notification: UnpyNotification, reFetchNotification: () => void) => (
    <NotificationEventGeneralContent
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  EVENT_CANCEL_DELAYED: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => (
    <NotificationEventGeneralContent
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  EVENT_SUBSCRIPTION_CLOSE: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => (
    <NotificationEventGeneralContent
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  [NotificationTypeEnum.ABONNEMENT_UPDATE]: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => (
    <NotificationGeneralContent
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
};

export const NotificationListItem = ({
  notification,
  className,
  reFetchNotification,
}: notificationListItemProps) => {
  const t = useTranslate();
  const { notificationRepository } = useContextDependency();
  const dispatch = useDispatch();
  const [alreadyViewed, setAlreadyViewed] = useState<boolean>(notification.viewed);
  const setViewedNotification: MouseEventHandler<HTMLDivElement> = (e) => {
    if (alreadyViewed || !notification.id) return e;
    setAlreadyViewed(true);
    notificationRepository.toViewed(notification.id).then(() => {
      dispatch(decrementNumberUnviewed());
    });
    return e;
  };

  const msg = notification.content
    ? t(notification.content as TranslationType, {}, undefined)
    : undefined;

  return (
    <Card
      className={classNames('notificationItem', className)}
      onMouseEnter={!notification.viewed ? setViewedNotification : undefined}
    >
      <div className={'notificationItem__header'}>
        <KeyValue
          className={'-margedRight'}
          accent
          keyValue={''}
          value={<TagPlayer embed player={notification.userFrom} />}
        />
        <KeyValue
          accent
          keyValue={''}
          value={formatDate(notification.creationDate, 'readable(with hour)')}
        />
        {!alreadyViewed && <div className={'view-indicator'} />}
      </div>
      <KeyValue
        accent
        className={'-topMarged'}
        keyValue={''}
        value={t(
          `notificationList.item.objectByType.${notification.type}` as TranslationType,
          {
            user: notification.userFrom.idPlayer,
          },
        )}
      />
      {msg && (
        <KeyValue
          accent
          className={'-topMarged'}
          keyValue={''}
          value={notification.content || t('notification.noContent')}
        />
      )}
      <div className={'notificationItem__content -topMarged'}>
        {CONTENT_COMP_BY_NOTIFICATION_TYPE[notification.type](
          notification,
          reFetchNotification,
        )}
      </div>
    </Card>
  );
};
