import './Section.scss';

import classNames from 'classnames';
import React, { forwardRef, Ref } from 'react';

interface SectionProps {
  id?: string;
  className?: string;
  onAnimationEnd?: () => void;
  children?: React.ReactNode;
}

export const Section = forwardRef(function InputSelectMuiForwarded(
  { children, id, className, onAnimationEnd }: SectionProps,
  ref: Ref<HTMLElement>,
) {
  return (
    <section
      onAnimationEnd={onAnimationEnd}
      className={classNames('section', className)}
      id={id}
      ref={ref}
    >
      {children}
    </section>
  );
});
