import { Divider } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { NotificationResourceTypeEnum } from '../../../domain/notification/NotificationResourceTypeEnum';
import { UnpyNotification } from '../../../domain/notification/UnpyNotification';
import { routesConfig } from '../../Configs/Routes.config';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useTranslate } from '../../hooks/useTranslate';

interface NotificationEventGeneralContentProps {
  notification: UnpyNotification;
  reFetchNotification: () => void;
  isResponse?: boolean;
}

export const NotificationEventGeneralContent = ({
  notification,
}: NotificationEventGeneralContentProps) => {
  const t = useTranslate();
  const { eventRepository } = useContextDependency();

  return (
    <div>
      <span>{notification.content}</span>
      <Divider className={'-topMarged'} />
      <Link
        className={classNames('-linkNotification')}
        to={routesConfig.eventView.to(
          notification.resources[NotificationResourceTypeEnum.EVENT_ID],
        )}
      >
        {t(`notification.content.event.goToEvent`)}
      </Link>
    </div>
  );
};
