import { LangEnum } from '../../domain/enums/Lang.enum';
import { Lang } from '../../domain/lang/Lang';
import { toEnum } from '../../helper/enum.helper';

export interface RestLang {
  id: number;
  libelle: string;
  code: string;
}

export function toLang(restLang: RestLang) {
  return new Lang(restLang.id, restLang.libelle, toEnum(LangEnum, restLang.code));
}
