import { StructureProfilView } from 'domain/profil/StructureProfilView';
import { RestPrivacy, toProfilPrivacy } from 'secondary/privacy/RestPrivacy';
import {
  RestUnpyStatistiquesProfil,
  toUnpyStatistiquesProfil,
} from 'secondary/profils/RestProfil';

import { StructureProfil } from '../../domain/profil/StructureProfil';
import {
  RestBackgroundImageView,
  toBackgroundImageView,
} from '../backgroundImage/RestBackgroundImageView';
import { RestGameProfil, toGameProfil } from '../gameProfil/RestGameProfil';
import { RestLang, toLang } from '../lang/RestLang';
import { RestNeon, toNeon } from '../neon/RestNeon';
import { RestPlatform, toPlatform } from '../platform/RestPlatform';
import { RestStructure, toStructure } from '../structure/RestStructure';

export interface RestStructureProfil {
  id: number;
  backgroundImage?: RestBackgroundImageView;
  bannerImage?: string;
  description?: string;
  gamingProfil?: string;
  name?: string;
  lang?: string;
  neon: RestNeon;
  platforms?: RestPlatform[];
  video?: string;
  pingActive?: boolean;
  twitter?: string;
  facebook?: string;
  youtube?: string;
  instagram?: string;
  discord?: string;
  twitch?: string;
  games?: RestGameProfil[];
  talkLangs?: RestLang[];
  structure?: RestStructure;
  bannerImagePublicUrl?: string;
  defaultBanner?: boolean;
  privacies?: RestPrivacy[];
  unpyStatistiques?: RestUnpyStatistiquesProfil;
}

export function toStructureProfil(
  restStructureProfil: RestStructureProfil,
): StructureProfil {
  return new StructureProfil(
    restStructureProfil.id,
    restStructureProfil.games
      ? restStructureProfil.games.map((game) => toGameProfil(game))
      : [],
    toNeon(restStructureProfil.neon),
    restStructureProfil.backgroundImage
      ? toBackgroundImageView(restStructureProfil.backgroundImage)
      : undefined,
    restStructureProfil.description,
    restStructureProfil.gamingProfil,
    restStructureProfil.lang,
    restStructureProfil.platforms
      ? restStructureProfil.platforms.map((platform) => toPlatform(platform))
      : undefined,
    restStructureProfil.video,
    restStructureProfil.pingActive,
    restStructureProfil.twitter,
    restStructureProfil.facebook,
    restStructureProfil.youtube,
    restStructureProfil.instagram,
    restStructureProfil.discord,
    restStructureProfil.twitch,
    restStructureProfil.talkLangs
      ? restStructureProfil.talkLangs.map((lang) => toLang(lang))
      : undefined,
    restStructureProfil.privacies
      ? restStructureProfil.privacies.map((privacy) => toProfilPrivacy(privacy))
      : undefined,
    restStructureProfil.unpyStatistiques
      ? toUnpyStatistiquesProfil(restStructureProfil.unpyStatistiques)
      : undefined,
    restStructureProfil.name,
    restStructureProfil.structure
      ? toStructure(restStructureProfil.structure)
      : undefined,
    restStructureProfil.bannerImage,
    restStructureProfil.bannerImagePublicUrl,
    restStructureProfil.defaultBanner,
  );
}

export interface RestStructureProfilView {
  id: number;
  name: string;
  structureId: number;
  neon?: RestNeon;
  bannerImage?: string;
  isDefaultBanner?: boolean;
  privacies?: RestPrivacy[];
}

export function toStructureProfilView(
  restStructureProfilView: RestStructureProfilView,
): StructureProfilView {
  return new StructureProfilView(
    restStructureProfilView.id,
    restStructureProfilView.name,
    restStructureProfilView.structureId,
    restStructureProfilView.neon ? toNeon(restStructureProfilView.neon) : undefined,
    restStructureProfilView.bannerImage,
    restStructureProfilView.isDefaultBanner,
    restStructureProfilView.privacies
      ? restStructureProfilView.privacies.map((privacy) => toProfilPrivacy(privacy))
      : undefined,
  );
}
