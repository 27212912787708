import { Divider } from '@mui/material';
import { ITeamRepository } from 'domain/team/Team.repository';
import { ActionButtonsForContent } from 'primary/MessageList/contents/ActionButtonsForContent';
import React from 'react';
import { Link } from 'react-router-dom';

import { NotificationResourceTypeEnum } from '../../../domain/notification/NotificationResourceTypeEnum';
import { UnpyNotification } from '../../../domain/notification/UnpyNotification';
import {
  TranslationOption,
  TranslationType,
} from '../../../domain/translation/Translation.repository';
import { KeyValue } from '../../Components/KeyValue/KeyValue';
import { routesConfig } from '../../Configs/Routes.config';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useRetrieveFromDomain } from '../../hooks/useRetrieveFromDomain';
import { useTranslate } from '../../hooks/useTranslate';

interface NotificationInvitationTeamEventContentProps {
  notification: UnpyNotification;
  reFetchNotification: () => void;
}

export const getOnClickResponse = (
  teamRepository: ITeamRepository,
  t: (key: TranslationType, option?: TranslationOption) => string,
  response: boolean,
  reFetchNotification: () => void,
  teamId: number,
  eventId: number,
  setLoading: (loading: boolean) => void,
  accept: boolean,
) => {
  return () => {
    setLoading(true);
    if (accept) {
      teamRepository.acceptTeamInvitationEvent(response, teamId, eventId).then(() => {
        reFetchNotification();
        setLoading(false);
      });
    } else {
      teamRepository.declineTeamInvitationEvent(response, teamId).then(() => {
        reFetchNotification();
        setLoading(false);
      });
    }
  };
};

export const NotificationInvitationTeamEventContent = ({
  notification,
  reFetchNotification,
}: NotificationInvitationTeamEventContentProps) => {
  const t = useTranslate();
  const { eventParticipantRepository, teamRepository, eventRepository, authRepository } =
    useContextDependency();

  const [eventParticipant] = useRetrieveFromDomain(
    () =>
      eventParticipantRepository.getEventParticipantById(
        parseInt(
          notification.resources[NotificationResourceTypeEnum.EVENT_PARTICIPANT_ID],
        ),
      ),
    undefined,
  );

  const [event] = useRetrieveFromDomain(
    () =>
      eventRepository.getEventById(
        parseInt(notification.resources[NotificationResourceTypeEnum.EVENT_ID]),
      ),
    undefined,
  );
  const [loading, setLoading] = React.useState(false);

  if (!eventParticipant || !event) return null;
  const teamPlayer = eventParticipant.team.players.find(
    (value) => value.player.idPlayer === authRepository.currentUser?.userProfil.idPlayer,
  );

  return (
    <div>
      <KeyValue
        className={'-margedRight'}
        accent
        keyValue={t('notificationList.item.invitationTeamEvent.event')}
        value={
          <Link
            key={routesConfig.eventView.to(
              notification.resources[NotificationResourceTypeEnum.EVENT_ID],
            )}
            to={routesConfig.eventView.to(
              notification.resources[NotificationResourceTypeEnum.EVENT_ID],
            )}
          >
            {event.name}
          </Link>
        }
      />
      <Divider className={'-topMarged'} />
      {teamPlayer?.status === 'DECLINED' && (
        <span>{t('notification.content.joinStructure.alreadyRefused')}</span>
      )}
      {teamPlayer?.status === 'ACCEPTED' && (
        <span>{t('notification.content.joinStructure.alreadyAccepted')}</span>
      )}
      {teamPlayer?.status === 'PENDING' && (
        <ActionButtonsForContent
          loading={loading}
          onClickCancel={getOnClickResponse(
            teamRepository,
            t,
            false,
            reFetchNotification,
            eventParticipant.team.id,
            event.id,
            setLoading,
            false,
          )}
          onClickValid={getOnClickResponse(
            teamRepository,
            t,
            true,
            reFetchNotification,
            eventParticipant.team.id,
            event.id,
            setLoading,
            true,
          )}
        />
      )}
    </div>
  );
};
