import { MatchParticipant } from 'domain/event/match/MatchParticipant';
import { MatchResultEnum } from 'domain/event/match/MatchResult.enum';
import { RestUnpyMatch, toUnpyMatch } from 'secondary/event/match/RestUnpyMatch';
import {
  RestEventParticipant,
  toEventParticipant,
} from 'secondary/event/participants/RestEventParticipant';

export interface RestMatchParticipant {
  id: number;
  eventParticipant: RestEventParticipant;
  match: RestUnpyMatch;
  result: MatchResultEnum;
  score: number;
}

export function toMatchParticipant(
  restMatchParticipant: RestMatchParticipant,
): MatchParticipant {
  const { id, eventParticipant, match, result, score } = restMatchParticipant;
  return new MatchParticipant(
    id,
    toEventParticipant(eventParticipant),
    toUnpyMatch(match),
    result,
    score,
  );
}
