import './MarketPlacePage.scss';

import { Divider } from '@mui/material';
import React from 'react';

import { useContextDependency } from '../../hooks/useContextDependency';
import { useRetrieveFromDomain } from '../../hooks/useRetrieveFromDomain';
export const MarketplacePage = () => {
  const { eventRepository } = useContextDependency();

  const [eventOverview] = useRetrieveFromDomain(
    () => eventRepository.getOverview(),
    undefined,
  );

  return (
    <div className={'marketPlacePage'}>
      <div className={'-over'}>
        <h1 className={'-title'}>
          Marketplace Unpy
          <Divider color={'#ff7800'} />
          <small>Coming soon . . </small>
        </h1>
      </div>
    </div>
  );
};
