import './MyStructuresPage.scss';

import { Button } from '@mui/material';
import classNames from 'classnames';
import { KeyDomainFilters } from 'domain/filters/FiltersSlice';
import { Structure, StructureFilters } from 'domain/structure/Structure';
import { InfiniteScroll } from 'primary/Components/InfiniteScroll/InfiniteScroll';
import { LeftRight } from 'primary/Components/Layout/LeftRight/LeftRight';
import { StructureProfilCard } from 'primary/Components/Profils/structure/StructureProfilCard';
import { ModalAddContent } from 'primary/Components/User/ModalAddContent';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetchWithFilterPage } from 'primary/hooks/useFetchWithFilterPage';
import { useTranslate } from 'primary/hooks/useTranslate';
import { PreviewStructureItem } from 'primary/Structure/PreviewStructureItem';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { UnpyTabs } from '../../Components/Tabs/UnpyTabs';

interface StructurePreviewListProps {
  asMember?: boolean;
  asCreator?: boolean;
  setStructureSelected: (structure: Structure | null) => void;
  selectedStructure: Structure | null;
  domainFilter: KeyDomainFilters;
}

export const NoContentPreviewProfil = () => {
  return (
    <div className={'-noContentPreviewProfil'}>
      <span>Sélectionnez une structure pour afficher son profil</span>
    </div>
  );
};
export const StructurePreviewList = ({
  asMember,
  asCreator,
  setStructureSelected,
  selectedStructure,
  domainFilter,
}: StructurePreviewListProps) => {
  const { structureRepository } = useContextDependency();

  const {
    fetchedResource,
    goToPage,
    loading,
    activeFilters,
    setFilters,
    fetch,
    nextPage,
    retry,
    allContents,
  } = useFetchWithFilterPage<Structure, StructureFilters>({
    filters: {},
    staticFilters: { asCreator: !!asCreator, asMember: !!asMember },
    callApi: (filters, page) => structureRepository.search(filters, page, 30),
    keepPageResult: true,
    keyStoreFilters: domainFilter,
  });

  return (
    <div className={classNames('myStructuresPage__structuresList')}>
      <InfiniteScroll onScrollBottom={() => nextPage()} isLoading={loading}>
        {(scrollToTop) =>
          allContents?.map((structure) => (
            <PreviewStructureItem
              key={structure.id}
              structure={structure}
              isSelected={structure.id === selectedStructure?.id}
              onClick={(structure) =>
                setStructureSelected(
                  structure.id === selectedStructure?.id ? null : structure,
                )
              }
            />
          ))
        }
      </InfiniteScroll>
    </div>
  );
};

export const MyStructuresPage: FC = () => {
  const [selectedStructure, setSelectedStructure] = useState<Structure | null>(null);
  const { authRepository } = useContextDependency();
  const [isModalCreateStructureOpen, setIsModalCreateStructureOpen] = useState(false);
  const [structureChoosen, setStructureChoosen] = useState<number | undefined>();
  const t = useTranslate();
  const navigate = useNavigate();

  const tabsConf = [
    {
      label: t(`myStructures.tabs.created`),
      component: (
        <StructurePreviewList
          setStructureSelected={setSelectedStructure}
          selectedStructure={selectedStructure}
          key={'myStructuresPage-created'}
          asCreator={true}
          domainFilter={'myStructuresPage-created'}
          asMember={false}
        />
      ),
      to: routesConfig.myStructures.created.to,
    },
    {
      label: t(`myStructures.tabs.member`),
      component: (
        <StructurePreviewList
          setStructureSelected={setSelectedStructure}
          key={'myStructuresPage-joined'}
          selectedStructure={selectedStructure}
          domainFilter={'myStructuresPage-joined'}
          asMember={true}
          asCreator={false}
        />
      ),
      to: routesConfig.myStructures.member.to,
    },
  ];

  if (!authRepository.currentUser) return null;

  return (
    <div className={'myStructuresPage'}>
      <LeftRight
        sizeLeft={'big'}
        close={() => setSelectedStructure(null)}
        transparency={'opaq'}
        isOpen={!!selectedStructure}
        scrollInside
        left={
          <div className={'myStructuresPage__listStructures'}>
            <div className={'myStructuresPage__listStructures-header'}>
              <h3>{t('myStructures.pageTitle')}</h3>
              <Button
                variant={'contained'}
                onClick={() => setIsModalCreateStructureOpen(true)}
                color={'primary'}
                type={'submit'}
                className={'-actionButton'}
              >
                {t('myStructures.createStructure')}
              </Button>
              <Button
                className={'-actionButton'}
                variant={'contained'}
                onClick={() =>
                  navigate(routesConfig.mercatoSubPath.mercatoStructure.to())
                }
                color={'primary'}
                type={'submit'}
              >
                {t('myStructures.searchStructure')}
              </Button>
            </div>
            <div className={'myStructuresPage__listStructures-list'}>
              <UnpyTabs tabsConf={tabsConf} center fullHeight />
            </div>
          </div>
        }
        right={
          <>
            {selectedStructure?.structureProfil && (
              <div className={'-containerProfil --scrollBarContent'}>
                <StructureProfilCard
                  isPreview
                  profil={selectedStructure?.structureProfil}
                />
              </div>
            )}
            {!selectedStructure?.structureProfil && <NoContentPreviewProfil />}
          </>
        }
      />
      <ModalAddContent
        isOpen={isModalCreateStructureOpen}
        handleClose={() => setIsModalCreateStructureOpen(false)}
        modalCreateType={'STRUCTURE'}
      />
    </div>
  );
};
