import './EventFilters.scss';

import { insertEmptyOpt } from 'helper/options.helper';
import { InputSelectMui } from 'primary/Components/Input/InputSelectMui';
import {
  ChangeHandlerTypeMeta,
  InputWrapper,
} from 'primary/Components/Input/InputWrapper';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetch } from 'primary/hooks/useFetch';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

type FilterTypeEventProps = {
  onSelectTypeEvent: (value?: string) => void;
  selectedTypeEvent?: string;
};

export const FilterTypeEvent = ({
  onSelectTypeEvent,
  selectedTypeEvent,
}: FilterTypeEventProps) => {
  const { eventRepository } = useContextDependency();
  const [eventType] = useFetch(() => eventRepository.getAllTypeEvent(), undefined);
  if (!eventType) return null;
  const t = useTranslate();
  return (
    <div>
      <InputWrapper
        inline
        className={'unpy-form__field'}
        label={t('event.filters.typeEvent')}
        errors={{}}
        Input={InputSelectMui}
        isSubmitting={false}
        key={selectedTypeEvent}
        inputProps={{
          onChange: (event: ChangeHandlerTypeMeta) => {
            onSelectTypeEvent(event.target.value as string);
          },
          value: selectedTypeEvent,
          options: insertEmptyOpt(
            eventType.map((eventType) => ({
              value: eventType.code,
              label: t(`enum.typeEventCode.${eventType.code}`),
            })),
          ),
        }}
      />
    </div>
  );
};
