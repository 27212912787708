import { RestUserProfilView, toUserProfilView } from 'secondary/user/RestUserProfilView';

import { NotificationResourceTypeEnum } from '../../domain/notification/NotificationResourceTypeEnum';
import { NotificationTypeEnum } from '../../domain/notification/NotificationTypeEnum';
import { UnpyNotification } from '../../domain/notification/UnpyNotification';
import { toEnum } from '../../helper/enum.helper';

export interface RestNotification {
  id: number;
  userTo: RestUserProfilView;
  userFrom: RestUserProfilView;
  creationDate: string;
  type: string;
  resources: Record<NotificationResourceTypeEnum, string>;
  viewed: boolean;
  content?: string;
}

export function toNotification(restNotification: RestNotification) {
  return new UnpyNotification(
    restNotification.id,
    toUserProfilView(restNotification.userTo),
    toUserProfilView(restNotification.userFrom),
    new Date(restNotification.creationDate),
    toEnum(NotificationTypeEnum, restNotification.type),
    restNotification.resources,
    restNotification.viewed,
    restNotification.content,
  );
}
