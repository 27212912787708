import './MenuEventViewPage.scss';

import classNames from 'classnames';
import { TranslationType } from 'domain/translation/Translation.repository';
import { ConfParametersLink } from 'primary/Components/MenuParameters/MenusParametersUser.config';
import { NeonText } from 'primary/Components/NeonText/NeonText';
import { LabelHomeTopBarMenuProfil } from 'primary/Components/Profils/User/TopBarMenuProfil/TopBarMenuProfil';
import { BottomNavEventView } from 'primary/events/BottomNavEventView';
import { getLinkMenu } from 'primary/events/MenusEventViewPage.config';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { FC, ReactElement } from 'react';
import { Link, matchPath, Path, useLocation, useParams } from 'react-router-dom';

type LabelMenuEventViewPage =
  | undefined
  | 'event.eventViewPage.menu.links.infos'
  | 'event.eventViewPage.menu.links.rules'
  | 'event.eventViewPage.menu.links.network'
  | 'event.eventViewPage.menu.links.annonces'
  | 'event.eventViewPage.menu.links.players'
  | 'event.eventViewPage.menu.links.timeline'
  | 'event.eventViewPage.menu.links.matchs'
  | 'event.eventViewPage.menu.links.scoreboard';

export type confNavBar = {
  label: LabelMenuEventViewPage | LabelHomeTopBarMenuProfil;
  link: string;
  icon?: ReactElement | string;
  secondaryLink?: boolean;
  path: string | Path;
} & ConfParametersLink;

type NavBarProps = {
  confs: confNavBar[];
};

export const MenuEventViewPage: FC<NavBarProps> = ({ confs }) => {
  const t = useTranslate();
  const { pathname } = useLocation();
  const subRoutePath = pathname.split('/')[3];
  const params = useParams();
  return (
    <>
      <div className={classNames('menuEventViewPage', '-desktop')}>
        <nav className={classNames('menuEventViewPage__navbar')} id={'main-nav-bar'}>
          <ul>
            {confs.map((conf: confNavBar) => (
              <Link
                className={classNames('-link', {
                  '-selected': !!matchPath(
                    {
                      path: conf.path as unknown as string,
                      end: true,
                    },
                    pathname,
                  ),
                  '-icon': !!conf.icon,
                })}
                key={conf.link}
                to={getLinkMenu(conf, params)}
              >
                {!!conf.icon && conf.icon}
                {subRoutePath === conf.link && (
                  <NeonText
                    variant={'orange'}
                    text={conf.label ? t(conf.label as TranslationType) : ''}
                  />
                )}
                {subRoutePath !== conf.link &&
                  (conf.label ? t(conf.label as TranslationType) : '')}
              </Link>
            ))}
          </ul>
        </nav>
      </div>
      <div className={classNames('menuEventViewPage', '-mobile')}>
        <div className={'menuParameters__mobile'}>
          <BottomNavEventView confs={confs} />
        </div>
      </div>
    </>
  );
};
