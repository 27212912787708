export const translationDateFR = {
  shortDayOfWeek: {
    '0': 'DIM',
    '1': 'LUN',
    '2': 'MAR',
    '3': 'MER',
    '4': 'JEU',
    '5': 'VEN',
    '6': 'SAM',
  },
  today: "Aujourd'hui",
  dayFirstCapitalize: {
    '0': 'Dimanche',
    '1': 'Lundi',
    '2': 'Mardi',
    '3': 'Mercredi',
    '4': 'Jeudi',
    '5': 'Vendredi',
    '6': 'Samedi',
  },
  month: {
    '0': 'JAN',
    '1': 'FEV',
    '2': 'MAR',
    '3': 'AVR',
    '4': 'MAI',
    '5': 'JUIN',
    '6': 'JUIL',
    '7': 'AOU',
    '8': 'SEP',
    '9': 'OCT',
    '10': 'NOV',
    '11': 'DEC',
  },
  monthComplete: {
    '0': 'Janvier',
    '1': 'Février',
    '2': 'Mars',
    '3': 'Avril',
    '4': 'Mai',
    '5': 'Juin',
    '6': 'Juillet',
    '7': 'Août',
    '8': 'Septembre',
    '9': 'Octobre',
    '10': 'Novembre',
    '11': 'Décembre',
  },
};
