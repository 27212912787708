import { ArrowLeft } from '@mui/icons-material';
import { Button } from '@mui/material';
import { Title } from 'primary/Components/Title/Title';
import React from 'react';
type MyMatchsProps = {
  goBack: () => void;
};
export const MyMatchs = ({ goBack }: MyMatchsProps) => {
  return (
    <div className={'myEvents'}>
      <div className={'-header'}>
        <Button
          color={'primary'}
          style={{ justifySelf: 'flex-end' }}
          variant={'text'}
          onClick={goBack}
        >
          <ArrowLeft />
          Retour
        </Button>
        <Title title={'Prochains matchs'} level={3} compensatePadding accent />
      </div>
    </div>
  );
};
