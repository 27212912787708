import { Hidden } from '@mui/material';
import { TimelineEventComp } from 'primary/Components/Event/TimelineEvent';
import { Title } from 'primary/Components/Title/Title';
import { EVENT_CONTEXT } from 'primary/context/event/EventContext';
import { EventViewPageContext } from 'primary/events/item/EventViewPage';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { useContext } from 'react';
import { useOutletContext } from 'react-router-dom';

export const TimelineView = () => {
  const t = useTranslate();
  const { event } = useOutletContext<EventViewPageContext>();
  return (
    <div className={'eventViewPageBloc eventViewPageTimeline'}>
      <Title
        title={t('event.eventViewPage.infos.timelineTitle')}
        level={1}
        threeQuarter
        bordered
      />
      <Hidden smDown>
        <TimelineEventComp readonly={true} event={event} orientation={'horizontal'} />
      </Hidden>
      <Hidden smUp>
        <TimelineEventComp
          readonly={true}
          event={event}
          orientation={'vertical'}
          mobile
        />
      </Hidden>
    </div>
  );
};

export const TimelineParamMobile = () => {
  const t = useTranslate();
  const { event } = useContext(EVENT_CONTEXT);
  return (
    <div className={'eventViewPageBloc eventViewPageTimeline'}>
      <Title
        title={t('event.eventViewPage.infos.timelineTitle')}
        level={1}
        threeQuarter
        bordered
      />
      <Hidden smDown>
        <TimelineEventComp event={event} orientation={'horizontal'} />
      </Hidden>
      <Hidden smUp>
        <TimelineEventComp event={event} orientation={'vertical'} mobile />
      </Hidden>
    </div>
  );
};
