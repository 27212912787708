export enum EventStatusEnum {
  FINISH = 'FINISH',
  PUBLISH = 'PUBLISH',
  ON_GOING = 'ON_GOING',
  DRAFT = 'DRAFT',
  CANCELED = 'CANCELED',
}

export enum EventSubStatusEnum {
  SUBSCRIBE_OPEN = 'SUBSCRIBE_OPEN',
  SUBSCRIBE_CLOSE = 'SUBSCRIBE_CLOSE',
  MAX_TEAM = 'MAX_TEAM',
}

type SubSteps = { name: EventSubStatusEnum };
type MainSteps = { name: EventStatusEnum; subSteps?: SubSteps[]; action?: () => void };
type STEPPER_CONF_TYPE = { steps: MainSteps[] };

export const STEPPER_CONF: STEPPER_CONF_TYPE = {
  steps: [
    {
      name: EventStatusEnum.DRAFT,
    },
    {
      name: EventStatusEnum.PUBLISH,
      subSteps: [
        {
          name: EventSubStatusEnum.SUBSCRIBE_OPEN,
        },
        {
          name: EventSubStatusEnum.SUBSCRIBE_CLOSE,
        },
      ],
    },
    {
      name: EventStatusEnum.ON_GOING,
    },
    {
      name: EventStatusEnum.FINISH,
    },
    {
      name: EventStatusEnum.CANCELED,
    },
  ],
};
