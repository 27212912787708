import { Facebook, Instagram, X, YouTube } from '@mui/icons-material';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { Discord, Twitch } from '../../assets/Icon';
import { Icon } from '../Components/Icon/Icon';

export type networkValues =
  | 'twitter'
  | 'facebook'
  | 'youtube'
  | 'instagram'
  | 'discord'
  | 'twitch';

export type Network = {
  name: string;
  url: string;
  value: networkValues;
  icon: ({
    onClick,
    active,
    disabled,
  }: {
    onClick?: () => void;
    active?: boolean;
    disabled?: boolean;
  }) => ReactNode;
};

export const socialNetworkList: Network[] = [
  {
    name: 'Twitter',
    url: '/twitter',
    value: 'twitter',
    icon: ({ onClick, active, disabled }) => (
      <X
        className={classNames({ active: active, disabled: disabled })}
        onClick={onClick}
      />
    ),
  },
  {
    name: 'Facebook',
    url: '/facebook',
    value: 'facebook',
    icon: ({ onClick, active, disabled }) => (
      <Facebook
        className={classNames({ active: active, disabled: disabled })}
        onClick={onClick}
      />
    ),
  },
  {
    name: 'Youtube',
    url: '/youtube',
    value: 'youtube',
    icon: ({ onClick, active, disabled }) => (
      <YouTube
        className={classNames({ active: active, disabled: disabled })}
        onClick={onClick}
      />
    ),
  },
  {
    name: 'Instagram',
    url: '/intagram',
    value: 'instagram',
    icon: ({ onClick, active, disabled }) => (
      <Instagram
        className={classNames({ active: active, disabled: disabled })}
        onClick={onClick}
      />
    ),
  },
  {
    name: 'Discord',
    url: '/discord',
    value: 'discord',
    icon: ({ onClick, active, disabled }) => (
      <Icon
        svg={'Discord'}
        onClick={onClick}
        className={classNames({ active: active, disabled: disabled })}
      />
    ),
  },
  {
    name: 'Twitch',
    url: '/twitch',
    value: 'twitch',
    icon: ({ onClick, active, disabled }) => (
      <Icon
        svg={'Twitch'}
        onClick={onClick}
        className={classNames({ active: active, disabled: disabled })}
      />
    ),
  },
];

export const socialNetworkIconByCode: { [key in networkValues]: ReactNode } = {
  twitter: <X />,
  facebook: <Facebook />,
  youtube: <YouTube />,
  instagram: <Instagram />,
  discord: <Discord />,
  twitch: <Twitch />,
};

export type SocialNetworkFormFormData = {
  [key in networkValues]?: string;
};
