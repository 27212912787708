import './InputWrapper.scss';

import { FormLabel, TextFieldVariants } from '@mui/material';
import classNames from 'classnames';
import { get } from 'lodash';
import React, { ForwardedRef, ReactElement, ReactNode, Ref, RefObject } from 'react';
import { ChangeHandler, FieldError } from 'react-hook-form';

export type ClassNameProps = {
  className?: string;
};

type VoidFunction = () => void;
export type ChangeHandlerTypeParam = { target: { value?: string | number } };
export type ChangeHandlerTypeMeta = {
  type?: string;
  target: { value?: string | string[] | Option[] | number; meta?: object };
};
export type ChangeHandlerType = (
  e: ChangeHandlerTypeParam | ChangeHandlerTypeMeta,
) => void;

export type InputProps<T = string | number | string[]> = {
  id?: string;
  label?: string;
  placeholder?: string;
  value?: T | number | string[];
  onChange?: ChangeHandler | ChangeHandlerType;
  onBlur?: ChangeHandler | VoidFunction;
  name?: string;
  defaultValue?: string;
  isError?: boolean;
  readonly?: boolean;
  checked?: boolean;
  onFileUpload?: (file?: File) => void;
  fileName?: string;
  onChangeSelected?: (value: string | number) => void;
  disabled?: boolean;
  variant?: TextFieldVariants;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
} & ClassNameProps;

export type ValueType = string | number | boolean | string[];
export type Option<U extends ValueType = string, M = object> = {
  label: string | ReactNode;
  value: string | number;
  inputProps?: InputProps<U> & Record<string, unknown>;
  meta?: M;
  disabled?: boolean;
};

type InputRender<PI, R> = (
  props: PI & {
    isError?: boolean;
    ref?: Ref<any> | RefObject<any> | ForwardedRef<any>;
  },
) => ReactElement | null;

type NewInputWrapper2022Props<PI, PW extends PI, E, R> = {
  Input: InputRender<PI, R>;
  required?: boolean;
  inputProps: PW;
  hiddenInputProps?: InputProps;
  errors?: E;
  notice?: string;
  label?: string;
  shortLabel?: string;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  helperText?: string;
  isSubmitting?: boolean;
  inline?: boolean;
  placeholder?: string;
  noFullWidth?: boolean;
  helper?: ReactNode;
};

// interface InputWrapperProps<PI, PW extends PI, E, R> {
//   label: string;
//   inputProps: InputRender<PI, R>;
//   input: () => ReactElement;
//   errors: E;
//   labelClassName?: string;
//   inputClassName?: string;
//   isSubmitting?: boolean;
// }

export const InputWrapper = <
  PI extends { name?: string; ref?: Ref<R> },
  PW extends PI,
  E,
  R = HTMLElement,
>({
  label,
  Input,
  errors,
  inputProps,
  required,
  inputClassName,
  labelClassName,
  isSubmitting,
  noFullWidth,
  notice,
  className,
  helper,
  inline,
}: NewInputWrapper2022Props<PI, PW, E, R>) => {
  const selectedError: FieldError | undefined =
    inputProps.name && get(errors, inputProps.name);

  return (
    <div className={classNames('input-wrapper', className, { '-inline': inline })}>
      <FormLabel className={classNames('-label', labelClassName)} id={inputProps.name}>
        {label} {required && '*'}
        {notice && (
          <small>
            <span className={'notice'}>{notice}</span>
          </small>
        )}
        {helper && helper}
      </FormLabel>
      <Input
        {...inputProps}
        className={classNames('input', inputClassName, { '-fullWidth': !noFullWidth })}
        error={!!selectedError}
      />
      {selectedError && (
        <div className={'message'}>
          <span className={'error'}>{selectedError.message}</span>
        </div>
      )}
    </div>
  );
};
