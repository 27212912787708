import { Box } from '@mui/material';
import { setRelation } from 'domain/relation/store/RelationSlice';
import { AddFriendForm } from 'primary/Components/Profils/User/ActionButton/Forms/AddFriendForm';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React from 'react';
import { useDispatch } from 'react-redux';
interface ModalAddFriendProps {
  isOpen: boolean;
  handleClose: () => void;
  userId?: number;
  name: string;
}
export const ModalAddFriend = ({
  isOpen,
  handleClose,
  userId,
  name,
}: ModalAddFriendProps) => {
  const t = useTranslate();
  const { relationRepository } = useContextDependency();
  const dispatch = useDispatch();
  const onSubmitted = () => {
    handleClose();
    relationRepository.getAllRelationsOfConnectedUser().then((relations) => {
      dispatch(setRelation(relations));
    });
  };
  if (!userId) return null;
  return (
    <ModalContainer
      handleClose={handleClose}
      isOpen={isOpen}
      content={
        <Box className={'modal-edit-role'}>
          <h2>{t('modals.addFriend.title', { name: name })}</h2>
          <AddFriendForm
            onSubmitted={onSubmitted}
            userId={userId?.toString()}
            onCancel={() => handleClose()}
          />
        </Box>
      }
    />
  );
};
