import { AxiosInstance } from 'axios';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';

import { GameProfil } from '../../domain/gameProfil/GameProfil';
import { IGameProfilRepository } from '../../domain/gameProfil/GameProfil.repository';
import { GamesFormDataSubmit } from '../../primary/Components/UserStatsGameForms/GamesForm';
import { RestGameProfil, toGameProfil } from './RestGameProfil';

export class GameProfilRepository
  extends AxiosProviderRepository
  implements IGameProfilRepository
{
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  updateUserGameProfil(data: GamesFormDataSubmit): Promise<GameProfil[]> {
    return this.axios
      .put('/unpy/api/games/profil/user', data)
      .then((resp) => resp.data.map((item: RestGameProfil) => toGameProfil(item)));
  }

  updateStructureGameProfil(
    data: GamesFormDataSubmit,
    idStructure: number,
  ): Promise<GameProfil[]> {
    return this.axios
      .put(`/unpy/api/games/profil/structure/${idStructure}`, data)
      .then((resp) => resp.data.map((item: RestGameProfil) => toGameProfil(item)));
  }
}
