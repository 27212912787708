import { sendEventToastMessage } from 'primary/Components/Toast/Toast.helper';
import { useTranslate } from 'primary/hooks/useTranslate';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  setOneConnectedUserStructure,
  setOneConnectedUserStructureProfil,
} from '../../../domain/user/store/UserSlice';
import { RootState } from '../../../store';
import { useContextDependency } from '../../hooks/useContextDependency';

export const useStructureParameters = () => {
  const { idStructure } = useParams<{ idStructure: string }>();
  const structureOfProfil = useSelector(
    (state: RootState) => state.user.structuresOfUser,
  );

  const dispatch = useDispatch();
  const { structureProfilRepository, structureRepository } = useContextDependency();
  const t = useTranslate();

  useEffect(() => {
    if (!structureOfProfil) {
      structureRepository
        .getStructureById(idStructure as unknown as number)
        .then((value) => dispatch(setOneConnectedUserStructure(value)));
    }
  }, [structureOfProfil, idStructure]);

  const onSubmitted = () => {
    sendEventToastMessage(t('general.edit.success'), 'success');
    structureRepository
      .getStructureById(idStructure as unknown as number)
      .then((value) => {
        dispatch(setOneConnectedUserStructure(value));
        value.structureProfil &&
          dispatch(setOneConnectedUserStructureProfil(value.structureProfil));
      });
  };

  const refrehStructure = (idStructure?: number) => {
    structureRepository
      .getStructureById(idStructure as unknown as number)
      .then((value) => dispatch(setOneConnectedUserStructure(value)));
  };

  const structure = useMemo(
    () =>
      structureOfProfil?.find((value) => value.id === parseInt(idStructure as string)),
    [structureOfProfil, idStructure],
  );

  return {
    onSubmitted,
    refrehStructure,
    structure,
    structureProfilRepository,
  };
};
