import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LANG } from 'secondary/translation/i18n.nt';
import { RootState } from 'store';

type InitialBackgroundImageSliceState = {
  currentLanguage?: LANG;
};

export const translationSlice = createSlice({
  name: 'translation',
  initialState: {
    currentLanguage: undefined,
  } as InitialBackgroundImageSliceState,
  reducers: {
    setCurrentLanguage: (state, action: PayloadAction<LANG>) => {
      state.currentLanguage = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCurrentLanguage } = translationSlice.actions;

export default translationSlice.reducer;

const selectSelf = (state: RootState) => state;

export const selectCurrentLanguage = createSelector(selectSelf, (state) =>
  state.translation.currentLanguage ? state.translation.currentLanguage : undefined,
);
