import './LoginPage.scss';

import { Button } from '@mui/material';
import { UnpyLogo } from 'primary/Components/UnpyLogo/UnpyLogo';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import React, { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { routesConfig } from '../../Configs/Routes.config';
import { useTranslate } from '../../hooks/useTranslate';

export const PageLogin: FC = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const { authRepository } = useContextDependency();
  const t = useTranslate();
  const navigate = useNavigate();

  useEffect(() => {
    if (authRepository.isConnected()) {
      navigate(query.get('redirect') ?? routesConfig.home.to());
    }
  }, []);

  return (
    <div className="loginPage">
      <div className={'loginBlockContainer'}>
        <div className={'logoContainer'}>
          <UnpyLogo size={'bigger'} />
        </div>
        <h1>{t('login.title')}</h1>
        <div className={'-actions'}>
          <Button
            className={'-button'}
            variant={'outlined'}
            onClick={() => navigate(routesConfig.home.to())}
          >
            {t('login.btnReturnLabel')}
          </Button>
          <Button
            className={'-button'}
            variant={'contained'}
            onClick={() =>
              authRepository.login(query.get('redirect') ?? routesConfig.home.to())
            }
          >
            {t('login.btnLoginLabel')}
          </Button>
        </div>
      </div>
    </div>
  );
};
