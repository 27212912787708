import { AxiosInstance } from 'axios';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';

import { IBackgroundImageRepository } from '../../domain/backgroundImage/BackgroundImage.repository';
import { BackgroundImageView } from '../../domain/backgroundImage/BackgroundImageView';
import {
  RestBackgroundImageView,
  toBackgroundImageView,
} from './RestBackgroundImageView';

export class BackgroundImageRepository
  extends AxiosProviderRepository
  implements IBackgroundImageRepository
{
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  getAllBackgroundImages(): Promise<BackgroundImageView[]> {
    return this.axios
      .get(`/unpy/api/background/all`)
      .then((response: any) =>
        response.data.map((background: RestBackgroundImageView) =>
          toBackgroundImageView(background),
        ),
      );
  }

  getBackgroundImageById(id: number): Promise<BackgroundImageView> {
    return this.axios
      .get(`/unpy/api/background/${id}`)
      .then((response: any) => toBackgroundImageView(response.data));
  }
}
