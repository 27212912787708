import { AxiosInstance } from 'axios';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';

import { Lang } from '../../domain/lang/Lang';
import { ILangRepository } from '../../domain/lang/Lang.repository';
import { RestLang, toLang } from './RestLang';

export class LangRepository extends AxiosProviderRepository implements ILangRepository {
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  getAll(): Promise<Lang[]> {
    return this.axios
      .get('/unpy/api/lang/all')
      .then((resp) => resp.data.map((restLang: RestLang) => toLang(restLang)));
  }
}
