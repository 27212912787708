import { ValidationStringLengthEnum } from 'domain/enums/ValidationStringLengthEnum';
import { IMessageRepository } from 'domain/message/Message.repository';
import { CoupleButtonValidCancel } from 'primary/Components/CoupleButtonValidCancel/CoupleButtonValidCancel';
import { InputTextArea } from 'primary/Components/Input/InputTextArea';
import { InputWrapper } from 'primary/Components/Input/InputWrapper';
import { sendEventToastMessage } from 'primary/Components/Toast/Toast.helper';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate, UseTranslateReturn } from 'primary/hooks/useTranslate';
import { useYupValidationResolver } from 'primary/hooks/useYupValidationResolver';
import React from 'react';
import { useController, useForm } from 'react-hook-form';
import * as yup from 'yup';

interface SendMessageProps {
  userId: string;
  onSubmitted: () => void;
  onCancel: () => void;
}

export type SendMessageFormData = {
  userId: string;
  message: string;
};

const validationSchema = (t: UseTranslateReturn) =>
  yup.object({
    userId: yup.string().required(t('general.forms.errors.required')),
    message: yup
      .string()
      .max(
        ValidationStringLengthEnum.LONG,
        t('general.forms.errors.tooLong', {
          maxChar: ValidationStringLengthEnum.LONG,
        }),
      )
      .required(),
  });

const onSubmitSendMessage =
  (
    messageRepository: IMessageRepository,
    onSubmitted: () => void,
    t: UseTranslateReturn,
  ) =>
  (data: SendMessageFormData) => {
    return messageRepository
      .sendMessageToConversation(data.message, undefined, parseInt(data.userId))
      .then(() => {
        sendEventToastMessage(t('modals.sendMessage.success'), 'success');
      })
      .finally(() => {
        onSubmitted();
      });
  };

export const SendMessageForm = ({ userId, onSubmitted, onCancel }: SendMessageProps) => {
  const { messageRepository } = useContextDependency();
  const resolver = useYupValidationResolver(validationSchema);
  const {
    handleSubmit,
    control,
    register,
    formState: { isSubmitting, errors },
  } = useForm<SendMessageFormData>({
    resolver,
    defaultValues: {
      userId: userId,
    },
  });
  const { field: messageField } = useController({ control: control, name: 'message' });

  if (!userId) return null;

  const t = useTranslate();
  const onSubmit = onSubmitSendMessage(messageRepository, () => onSubmitted(), t);
  return (
    <form className={'-SendMessage'} onSubmit={handleSubmit(onSubmit)}>
      <InputWrapper
        errors={errors}
        Input={InputTextArea}
        isSubmitting={isSubmitting}
        label={t('message.input.placeholder')}
        inputProps={{
          ...messageField,
          minRows: 3,
        }}
      />
      <CoupleButtonValidCancel isTypeSubmit={true} onClickCancel={onCancel} />
    </form>
  );
};
