import { Product } from 'domain/payment/Product';
import { UnpySubscriptionStatus } from 'domain/payment/UnpySubscriptionStatus';

export class UnpySubscription {
  constructor(
    public readonly id: number,
    public readonly idStripe: string,
    public readonly currency: string,
    public readonly cancelAtPeriodEnd: boolean,
    public readonly currentPeriodStart: Date,
    public readonly currentPeriodEnd: Date,
    public readonly status: UnpySubscriptionStatus,
    public readonly description: string,
    public readonly product: Product,
    public readonly clientSecretKey?: string,
  ) {}
}
