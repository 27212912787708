import BandeBleu from '@assets/Home/bandes/bande_bleu.png';
import BandeViolette from '@assets/Home/bandes/bande_violette.png';
import BandeViolette2 from '@assets/Home/bandes/bande_violette_2.png';
import { Divider, Hidden } from '@mui/material';
import classNames from 'classnames';
import { GAME_ACTIVE_LIST, GAME_IMAGES } from 'domain/game/GameActiveList';
import { routesConfig } from 'primary/Configs/Routes.config';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';

import { Section } from '../../Components/Section/Section';
import { useTranslate } from '../../hooks/useTranslate';
import { HomeSectionProps } from '../Home';

const responsiveConf = [
  {
    breakpoint: 1024,
    settings: {
      slidesPerRow: 1,
      slidesToShow: 1,
      rows: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesPerRow: 1,
      slidesToShow: 1,
      rows: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesPerRow: 1,
      slidesToShow: 1,
      rows: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
];

export const HomeSlidersSection = ({ className }: HomeSectionProps) => {
  const navigate = useNavigate();

  const t = useTranslate();
  return (
    <Section className={className}>
      <div className={'-home-slider-event-with-mercato'}>
        <div className={'-diagonal-effect'}></div>
        <div className={'-content'}>
          <h1 className={'-title'}>{t('home.mercatoWithEvent.title')}</h1>
          <div className={'-row'}>
            <Link to={routesConfig.mercato.to()} className={'-mercato'}>
              <div className={'-top--title'}>
                <img
                  alt={'bande-violette'}
                  src={BandeViolette}
                  className={'-img-bande -violette'}
                />
                <img
                  alt={'bande-violette-variant'}
                  src={BandeViolette2}
                  className={'-img-bande -violette2'}
                />
              </div>
              <div className={'-title'}>
                <h2>{t('home.mercato.title')}</h2>
                <span>{t('home.mercato.subtitle')}</span>
              </div>
              <div className={'-bottom--title'}>
                <img alt={'bande-bleu'} src={BandeBleu} className={'-img-bande -bleu'} />
              </div>
            </Link>
            <Hidden lgDown>
              <Divider orientation={'vertical'} className={'-divider'} flexItem />
            </Hidden>
            <Hidden lgUp>
              <Divider orientation={'horizontal'} className={'-dividerHorizontal'} />
            </Hidden>
            <div className={'-events'}>
              <Link
                to={routesConfig.events.to()}
                className={classNames('-title', '-linkWithoutDecoration')}
              >
                <h2>{t('home.events.title')}</h2>
                <span>{t('home.events.subtitle')}</span>
              </Link>
              <Slider
                speed={500}
                slidesToScroll={2}
                rows={1}
                slidesPerRow={1}
                slidesToShow={4}
                swipeToSlide
                arrows
                infinite
                centerMode
                responsive={responsiveConf}
                autoplay
              >
                {Object.values(GAME_ACTIVE_LIST).map((key: GAME_ACTIVE_LIST) => (
                  <Link
                    to={routesConfig.eventListWithGameFilter.to(key)}
                    className={classNames(
                      'gameSelectionCaroussel__item',
                      '-linkWithoutDecoration',
                    )}
                    key={key}
                  >
                    <div className={'-paddingWrapperSliderHome'}>
                      <img
                        src={GAME_IMAGES[key as GAME_ACTIVE_LIST] as string}
                        alt={key}
                      />
                    </div>
                  </Link>
                ))}
              </Slider>
            </div>
          </div>
        </div>
        <Hidden smUp>
          <Divider
            orientation={'vertical'}
            className={'-dividerHomeEventMercato -vertical'}
          />
        </Hidden>
        <Hidden smDown>
          <Divider
            orientation={'horizontal'}
            className={'-dividerHomeEventMercato -horizontal'}
          />
        </Hidden>
      </div>
    </Section>
  );
};
