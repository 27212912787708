import { Button, Grid, TextField } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { ValidationStringLengthEnum } from 'domain/enums/ValidationStringLengthEnum';
import { getInitialValuesUserProfilPersonnalData } from 'domain/profil/UserProfil.func';
import { IUserProfilRepository } from 'domain/user/UserProfil.repository';
import { InputSelectMui } from 'primary/Components/Input/InputSelectMui';
import {
  ChangeHandlerTypeMeta,
  InputWrapper,
} from 'primary/Components/Input/InputWrapper';
import { PageLayout } from 'primary/Components/PageLayout/PageLayout';
import { Title } from 'primary/Components/Title/Title';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetch } from 'primary/hooks/useFetch';
import { useTranslate, UseTranslateReturn } from 'primary/hooks/useTranslate';
import { useYupValidationResolver } from 'primary/hooks/useYupValidationResolver';
import React, { useMemo } from 'react';
import { useController, useForm, UseFormSetValue } from 'react-hook-form';
import * as yup from 'yup';

import { themeParameters } from '../../parameters.helper';
import { useUserParameters } from '../useUserParameters.hook';

export type UserProfilPersonnalDataFormData = {
  birthdate?: string;
  country?: string;
};

const validationSchema = (t: UseTranslateReturn) =>
  yup.object({
    birthdate: yup
      .string()
      .max(
        ValidationStringLengthEnum.LONG,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
      )
      .nullable(),
    country: yup
      .string()
      .max(
        ValidationStringLengthEnum.DEFAULT,
        t('general.forms.errors.tooLong', {
          maxChar: ValidationStringLengthEnum.DEFAULT,
        }),
      )
      .nullable(),
  });

export const useOnSubmit =
  (userProfilRepository: IUserProfilRepository, onSubmitted: () => void) =>
  (data: UserProfilPersonnalDataFormData) => {
    userProfilRepository.updateProfilUserPersonnalData(data).then(onSubmitted);
  };

const _changeHandlerCountry =
  (setValue: UseFormSetValue<UserProfilPersonnalDataFormData>) =>
  (event: ChangeHandlerTypeMeta) => {
    setValue('country', event.target.value as string);
  };

export const UserProfilPersonnalData = () => {
  const { user, onSubmitted, userProfilRepository } = useUserParameters();
  const { countryRepository } = useContextDependency();
  const t = useTranslate();
  const resolver = useYupValidationResolver(validationSchema);
  const [countries] = useFetch(() => countryRepository.getAll(), undefined);

  const form = useForm<UserProfilPersonnalDataFormData>({
    resolver,
    defaultValues: getInitialValuesUserProfilPersonnalData(user?.userProfil),
  });

  const onSubmit = useMemo(
    () => useOnSubmit(userProfilRepository, onSubmitted),
    [userProfilRepository, onSubmitted],
  );
  const { field: countryField } = useController({
    control: form.control,
    name: 'country',
  });

  if (!user) return null;
  return (
    <div className={'userDetails'}>
      <form name={'user-details-profil'} onSubmit={form.handleSubmit(onSubmit)}>
        <PageLayout>
          <>
            <Title
              title={t('user.parameters.userProfilDetails.title')}
              level={1}
              compensatePadding
              threeQuarter
              bordered
            />
            <ThemeProvider theme={themeParameters}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <InputWrapper
                    label={t('parameters.menus.profil.user.form.birthdateLabel')}
                    errors={form.formState.errors}
                    inputClassName={'input-user-details '}
                    labelClassName={'label-user-details '}
                    isSubmitting={form.formState.isSubmitting}
                    Input={TextField}
                    inputProps={{
                      placeholder: t(
                        'parameters.menus.profil.user.form.birthdatePlaceholder',
                      ),
                      disabled: form.formState.isSubmitting,
                      ...form.register('birthdate'),
                      type: 'datetime-local',
                      variant: 'filled',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputWrapper
                    label={t('parameters.menus.profil.user.form.countryLabel')}
                    errors={form.formState.errors}
                    inputClassName={'input-user-details '}
                    labelClassName={'label-user-details '}
                    Input={InputSelectMui}
                    isSubmitting={form.formState.isSubmitting}
                    inputProps={{
                      placeholder: t(
                        'parameters.menus.profil.user.form.countryPlaceholder',
                      ),
                      ...countryField,
                      options: countries?.map((country) => ({
                        label: country.libelle,
                        value: country.id.toString(),
                      })),
                      onChange: _changeHandlerCountry(form.setValue),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ justifyContent: 'center' }}>
                  <Button
                    color={'primary'}
                    style={{ justifySelf: 'flex-end' }}
                    variant={'contained'}
                    type={'submit'}
                  >
                    {t('parameters.menus.profil.user.form.submit')}
                  </Button>
                </Grid>
              </Grid>
            </ThemeProvider>
          </>
        </PageLayout>
      </form>
    </div>
  );
};
