import { UserProfilView } from 'domain/user/UserProfilView';

import { RelationStatusEnum } from './RelationStatusEnum';
import { RelationTypeEnum } from './RelationTypeEnum';

export interface RelationFilters {
  name?: string;
  type?: RelationTypeEnum;
  owners?: number[];
  inviteds?: number[];
  user?: number;
  status?: number[];
}

export class Relation {
  constructor(
    public readonly id: number,
    public readonly owner: UserProfilView,
    public readonly invited: UserProfilView,
    public readonly status: RelationStatusEnum,
    public readonly type: RelationTypeEnum,
  ) {}
}
