import { UserProfilView } from 'domain/user/UserProfilView';
import {
  RestBackgroundImageView,
  toBackgroundImageView,
} from 'secondary/backgroundImage/RestBackgroundImageView';
import { RestCountry, toCountry } from 'secondary/country/RestCountry';
import { RestPlatform, toPlatform } from 'secondary/platform/RestPlatform';

import { RestNeon, toNeon } from '../neon/RestNeon';

export interface RestUserProfilView {
  id: number;
  idProfil: number;
  idPlayer: string;
  photo: string;
  publicPhotoUrl: string;
  defaultPhoto?: boolean;
  backgroundImage: RestBackgroundImageView;
  neon?: RestNeon;
  country?: RestCountry;
  platforms?: RestPlatform[];
}

export function toUserProfilView(restUserView: RestUserProfilView): UserProfilView {
  const {
    id,
    idProfil,
    country,
    platforms,
    idPlayer,
    publicPhotoUrl,
    photo,
    defaultPhoto,
    backgroundImage,
    neon,
  } = restUserView;
  return new UserProfilView(
    id,
    idProfil,
    idPlayer,
    photo || publicPhotoUrl,
    !!defaultPhoto,
    backgroundImage ? toBackgroundImageView(backgroundImage) : undefined,
    neon ? toNeon(neon) : undefined,
    country ? toCountry(country) : undefined,
    platforms ? platforms.map(toPlatform) : undefined,
  );
}
