import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Collapse, Grid, Switch, TextField } from '@mui/material';
import { ValidationStringLengthEnum } from 'domain/enums/ValidationStringLengthEnum';
import { InputWrapper } from 'primary/Components/Input/InputWrapper';
import { StructureSelector } from 'primary/Components/Ping/StructureSelector';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate, UseTranslateReturn } from 'primary/hooks/useTranslate';
import { useYupValidationResolver } from 'primary/hooks/useYupValidationResolver';
import React from 'react';
import { useController, useForm } from 'react-hook-form';
import * as yup from 'yup';

export type FormStepNameData = {
  name?: string;
  participationAsStructure?: boolean;
  structure?: string;
};

const validationSchema = (t: UseTranslateReturn) =>
  yup.object({
    name: yup
      .string()
      .max(
        ValidationStringLengthEnum.DEFAULT,
        t('general.forms.errors.tooLong', {
          maxChar: ValidationStringLengthEnum.DEFAULT,
        }),
      )
      .required(t('general.forms.errors.required')),
    structure: yup.string().when('participationAsStructure', {
      is: (val?: boolean) => !!val,
      then: yup
        .string()
        .max(
          ValidationStringLengthEnum.SMALL,
          t('general.forms.errors.tooLong', {
            maxChar: ValidationStringLengthEnum.SMALL,
          }),
        )
        .required(t('general.forms.errors.required')),
      otherwise: yup.string().nullable(),
    }),
  });

type FormStepNameProps = {
  nextStep: (data: FormStepNameData) => void;
  previousStep: (data: FormStepNameData) => void;
  initialValues: FormStepNameData;
};
export const FormStepName = ({
  nextStep,
  previousStep,
  initialValues,
}: FormStepNameProps) => {
  const resolver = useYupValidationResolver(validationSchema);
  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    register,
    getValues,
    watch,
    formState: { isSubmitting, errors },
  } = useForm<FormStepNameData>({
    resolver,
    defaultValues: initialValues,
  });
  const t = useTranslate();
  const { authRepository } = useContextDependency();

  const structureControl = useController({
    control: control,
    name: 'structure',
  });

  const isParticipationAsStructure = watch('participationAsStructure');
  return (
    <div>
      <form onSubmit={handleSubmit(nextStep)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <InputWrapper
              className={'eventAppearenceForm__field'}
              label={t('event.modal.nameLabel')}
              errors={errors}
              isSubmitting={isSubmitting}
              Input={TextField}
              inputProps={{
                placeholder: t('event.modal.namePlaceholder'),
                disabled: isSubmitting,
                ...register(`name`),
                variant: 'filled',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWrapper
              label={t('event.modal.selectStructureHelper')}
              errors={errors}
              className={'unpy-form__field'}
              isSubmitting={isSubmitting}
              Input={Switch}
              noFullWidth
              inputProps={{
                ...register('participationAsStructure', { required: true }),
                defaultChecked: !!getValues('participationAsStructure'),
              }}
            />
            <Collapse in={isParticipationAsStructure && !!authRepository.currentUser?.id}>
              <StructureSelector
                userId={authRepository?.currentUser?.id as number}
                errors={errors}
                control={structureControl}
              />
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant={'outlined'}
              color={'primary'}
              onClick={() => previousStep(getValues())}
              className={'cancel'}
            >
              <NavigateBefore />
              {t('formStepper.previousLabel')}
            </Button>
            <LoadingButton variant={'contained'} color={'primary'} type={'submit'}>
              <NavigateNext />
              {t('formStepper.nextLabel')}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
