export const frB = {
  perfs: {
    noPerf: 'Aucune performance',
    result: 'Résultat :',
    nbVictories: 'Victoires :',
    nbDefeats: 'Défaites :',
    totalScore: 'Score total :',
    top1: 'Top 1 :',
    top3: 'Top 3 :',
    topHalf: 'Top middle :',
  },
  homeMercatoSection: {
    titlePlayer: 'JOUEURS',
    titleStructure: 'STRUCTURES',
  },
  homeCertifLabel: 'Obtiens ton badge gamer certifié',
  homeSearchMercatoLabel: 'Tu recherches une équipe, ou un joueur ?',
  homeSearchMercatoLabel2nd: 'La section mercato est faite pour toi !',
  goToPageEvent: "Voir l'événement",
  myEvents: {
    pageTitle: 'Mes événements',
    noContent: {
      handled: 'Aucun événement géré',
      registered: "Vous n'êtes inscris à aucun événement",
    },
    tabs: {
      handled: 'Gérés',
      registered: 'Inscris',
    },
    createEvent: 'Créer un événement',
    searchEvents: "S'inscrire à un événement",
  },
  eventNotBegun: "L'événement n'a pas encore commencé",
  homeUpDescription: {},
  mercatoTabs: {
    structures: 'Structures',
    players: 'Joueurs',
  },
};
