import { TypeRonde } from '../../domain/event/TypeRonde';
import { TypeRondeCodeEnum } from '../../domain/event/TypeRondeCodeEnum';
import { toEnum } from '../../helper/enum.helper';

export interface RestTypeRonde {
  id: number;
  code: string;
}

export function toTypeRonde(restTypeRonde: RestTypeRonde) {
  return new TypeRonde(restTypeRonde.id, toEnum(TypeRondeCodeEnum, restTypeRonde.code));
}
