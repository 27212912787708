import './ProfilTabs.scss';

import {
  StructureMember,
  StructureMemberFilters,
} from 'domain/structureMember/StructureMember';
import { ListElements } from 'primary/Components/ListElements/ListElements';
import { StructureProfilContext } from 'primary/Components/Profils/structure/StructureProfilCard';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetchWithFilterPage } from 'primary/hooks/useFetchWithFilterPage';
import { useTranslate } from 'primary/hooks/useTranslate';
import { PreviewCardProfilWithRole } from 'primary/Parameters/structure/Admin/PreviewCardProfilWithRole';
import React from 'react';
import { useOutletContext } from 'react-router-dom';

export const ProfilTabsMembers = () => {
  const t = useTranslate();
  const { profil } = useOutletContext<StructureProfilContext>();
  const { structureMemberRepository, roleStructureRepository } = useContextDependency();

  const staticFilters = {
    idStructure: profil?.structure?.id?.toString(),
    accepted: true,
  };
  const useFetchReturn = useFetchWithFilterPage<StructureMember, StructureMemberFilters>({
    keyStoreFilters: 'structureMember',
    staticFilters: staticFilters,
    filters: {},
    callApi: (filters, page, nbPerPage) =>
      structureMemberRepository.search(filters, page, nbPerPage),
  });
  const valueOrNoContent = (value: string | number | undefined) =>
    value?.toString() || t('general.keyValueNoValue');
  return (
    <div className={'-tabsMembers'}>
      {profil?.structure && useFetchReturn.fetchedResource?.content && (
        <ListElements
          loading={useFetchReturn.loading}
          empty={useFetchReturn.fetchedResource?.content.length === 0}
        >
          <div className={'-profilListMember'}>
            {!useFetchReturn.loading &&
              useFetchReturn.fetchedResource?.totalElementsCount !== 0 &&
              useFetchReturn.fetchedResource?.content.map((member) => (
                <PreviewCardProfilWithRole
                  readonly={true}
                  key={member.user.id}
                  className={'-item'}
                  profil={member.user.userProfil}
                  structure={profil.structure}
                  userId={member.user.id}
                  role={member.role}
                  isTemp={member.accepted === null}
                />
              ))}
          </div>
        </ListElements>
      )}
    </div>
  );
};
