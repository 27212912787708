import { UnpyMatch } from 'domain/event/match/UnpyMatch';
import { UnpyEventView } from 'domain/event/UnpyEventView';

export class MatchGroup {
  constructor(
    public readonly id: number,
    public readonly title: string,
    public readonly event: UnpyEventView,
    public readonly phaseOrder: number,
    public readonly matchs: UnpyMatch[],
    public readonly totalMatchsInGroup?: number,
  ) {}
}
