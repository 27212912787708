import { RestUserProfilView, toUserProfilView } from 'secondary/user/RestUserProfilView';

import { Relation } from '../../domain/relation/Relation';
import { RelationStatusEnum } from '../../domain/relation/RelationStatusEnum';
import { RelationTypeEnum } from '../../domain/relation/RelationTypeEnum';
import { toEnum } from '../../helper/enum.helper';

export interface RestRelation {
  id: number;
  owner: RestUserProfilView;
  invited: RestUserProfilView;
  status: string;
  type: string;
}

export function toRelation(restRelation: RestRelation) {
  return new Relation(
    restRelation.id,
    toUserProfilView(restRelation.owner),
    toUserProfilView(restRelation.invited),
    toEnum(RelationStatusEnum, restRelation.status),
    toEnum(RelationTypeEnum, restRelation.type),
  );
}
