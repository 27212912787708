import { BackgroundImageType } from 'domain/backgroundImage/BackgroundImageType';
import { toEnum } from 'helper/enum.helper';

import { BackgroundImageView } from '../../domain/backgroundImage/BackgroundImageView';
export interface RestBackgroundImageView {
  id: number;
  code: string;
  up: boolean;
  path: string;
  fileName: string;
  publicUrl: string;
  publicUrlSm: string;
  isDefault: boolean;
  type: string;
}

export function toBackgroundImageView(
  restBackgroundImageView: RestBackgroundImageView,
): BackgroundImageView {
  const { id, code, up, path, fileName, publicUrl, publicUrlSm, isDefault, type } =
    restBackgroundImageView;
  return new BackgroundImageView(
    id,
    code,
    up,
    path,
    fileName,
    publicUrl,
    publicUrlSm,
    isDefault,
    toEnum(BackgroundImageType, type),
  );
}
