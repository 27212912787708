type RestCountHome = {
  count: number;
};

export const toCountHome = (restCountHome: RestCountHome): CountHome => {
  return new CountHome(restCountHome.count);
};

export class CountHome {
  constructor(public readonly count: number) {}
}
