import { UserProfil } from 'domain/profil/UserProfil';
import { useGenerateDraftFromAppearence } from 'domain/profil/UserProfil.func';
import { UserPreviewCardProfil } from 'primary/Components/Profils/User/UserPreviewCardProfil';
import { useTranslate } from 'primary/hooks/useTranslate';
import { ProfilAppearenceFormData } from 'primary/Parameters/user/Profil/UserProfilAppearence';
import React from 'react';

type PreviewEditUserProfilProps = {
  profil?: UserProfil;
  draftedValues: ProfilAppearenceFormData;
  draftPhoto?: File;
};
export const PreviewEditUserProfil = ({
  profil,
  draftedValues,
  draftPhoto,
}: PreviewEditUserProfilProps) => {
  const draft = useGenerateDraftFromAppearence(draftedValues, profil);
  const t = useTranslate();
  if (!draft) return null;
  return (
    <div className={'editUserProfil'}>
      <div className={'-blocCompare'}>
        <h3>{t('previewEditProfil.mercatoView')}</h3>
        <UserPreviewCardProfil
          profil={draft}
          userName={draft.idPlayer}
          draftPhoto={draftPhoto}
        />
      </div>
    </div>
  );
};
