import { ClassementParticipant } from 'domain/classement/ClassementParticipant';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import { useReducer } from 'react';

export type UnpyEventAction =
  | { type: 'SET_EVENT'; event: UnpyEvent }
  | { type: 'SET_CLASSEMENT_OF_EVENT'; classement: ClassementParticipant };

type UserReducerState = {
  event?: UnpyEvent;
};

const reducer = (
  { event }: UserReducerState,
  action: UnpyEventAction,
): UserReducerState => {
  switch (action.type) {
    case 'SET_EVENT':
      return {
        event: action.event,
      };
    case 'SET_CLASSEMENT_OF_EVENT': {
      if (!event) {
        return { event };
      }
      return {
        event: {
          ...event,
          classement: action.classement,
        },
      };
    }
    default:
      return { event };
  }
};

const initialState: UserReducerState = {
  event: undefined,
};

export const useUnpyEventReducer = (event?: UnpyEvent) => {
  return useReducer(reducer, { event });
};
