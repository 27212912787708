import { Grid, TextField } from '@mui/material';
import { ValidationStringLengthEnum } from 'domain/enums/ValidationStringLengthEnum';
import { IUserProfilRepository } from 'domain/user/UserProfil.repository';
import { CoupleButtonValidCancel } from 'primary/Components/CoupleButtonValidCancel/CoupleButtonValidCancel';
import { InputWrapper } from 'primary/Components/Input/InputWrapper';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate, UseTranslateReturn } from 'primary/hooks/useTranslate';
import { useYupValidationResolver } from 'primary/hooks/useYupValidationResolver';
import { useUserParameters } from 'primary/Parameters/user/useUserParameters.hook';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export type AccountInfosFormData = {
  username?: string;
  email?: string;
};

const validationSchema = (t: UseTranslateReturn) =>
  yup.object({
    username: yup.string().max(
      ValidationStringLengthEnum.DEFAULT,
      t('general.forms.errors.tooLong', {
        maxChar: ValidationStringLengthEnum.DEFAULT,
      }),
    ),
    email: yup.string().max(
      ValidationStringLengthEnum.DEFAULT,
      t('general.forms.errors.tooLong', {
        maxChar: ValidationStringLengthEnum.DEFAULT,
      }),
    ),
  });

const useOnSubmit =
  (userProfilRepoitory: IUserProfilRepository, onSubmitted: () => void) =>
  (data: AccountInfosFormData) => {
    userProfilRepoitory.updateUserProfilAccountInfos(data).then(() => onSubmitted());
  };
export const AccountInfosForm = () => {
  const resolver = useYupValidationResolver(validationSchema);
  const { user, onSubmitted } = useUserParameters();
  const {
    register,
    handleSubmit,
    control,
    setError,
    trigger,
    watch,
    formState: { isSubmitting, errors },
  } = useForm<AccountInfosFormData>({
    resolver,
    mode: 'onSubmit',
    defaultValues: {
      username: user?.username,
      email: user?.email,
    },
  });
  const { userProfilRepository } = useContextDependency();

  const t = useTranslate();
  const onSubmit = useOnSubmit(userProfilRepository, onSubmitted);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={'-accountInfosForm'}>
      <Grid container>
        <Grid item xs={12}>
          <h3>{t('user.parameters.account.editEmail')}</h3>
          <InputWrapper
            errors={errors}
            label={t('user.parameters.account.editEmail')}
            Input={TextField}
            className={'-input'}
            inputProps={{
              ...register('email'),
              variant: 'filled',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <h3>{t('user.parameters.account.editUsername')}</h3>
          <InputWrapper
            errors={errors}
            label={t('user.parameters.account.editUsername')}
            Input={TextField}
            className={'-input'}
            inputProps={{
              ...register('username'),
              variant: 'filled',
            }}
          />
        </Grid>
        <CoupleButtonValidCancel isTypeSubmit={true} />
      </Grid>
    </form>
  );
};
