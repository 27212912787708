import './Icon.scss';

// @ts-ignore
import { ReactComponent as Discord } from '@assets/Icon/Discord.svg';
// @ts-ignore
import { ReactComponent as France } from '@assets/Icon/France.svg';
// @ts-ignore
import { ReactComponent as Pc } from '@assets/Icon/PC.svg';
// @ts-ignore
import { ReactComponent as Playstation } from '@assets/Icon/Playstation.svg';
// @ts-ignore
import { ReactComponent as Twitch } from '@assets/Icon/Twitch.svg';
// @ts-ignore
import { ReactComponent as UnitedStates } from '@assets/Icon/UnitedStates.svg';
// @ts-ignore
import { ReactComponent as Xbox } from '@assets/Icon/Xbox.svg';
import classNames from 'classnames';
import React, { FC } from 'react';

type IconName = 'PLA' | 'XBO' | 'PC' | 'France' | 'UnitedStates' | 'Twitch' | 'Discord';

interface IconProp {
  className?: string;
  svg: IconName;
  onClick?: () => void;
  title?: string;
  variant?: 'contained' | 'outlined';
}

const svgMap: Record<IconName, any> = {
  PLA: Playstation,
  XBO: Xbox,
  PC: Pc,
  France: France,
  UnitedStates: UnitedStates,
  Twitch: Twitch,
  Discord: Discord,
};

export const Icon: FC<IconProp> = ({
  title,
  className,
  svg,
  onClick,
  variant = 'contained',
}) => {
  const Icon = svgMap[svg];
  return (
    <i
      className={classNames('icon', className, variant)}
      onClick={onClick}
      title={title || 'icon'}
    >
      <Icon className={className} />
    </i>
  );
};
