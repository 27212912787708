import './ModalStartEvent.scss';

import { Alert, Divider, Grid } from '@mui/material';
import { EventSubStatusEnum } from 'domain/event/EventStatusEnum';
import { TypeEventCodeEnum } from 'domain/event/TypeEventCodeEnum';
import { TypeTeamCodeEnum } from 'domain/event/TypeTeamCodeEnum';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import {
  getSubStatusOfEvent,
  maxMatchBySessionLigueRobin,
} from 'domain/event/UnpyEvent.func';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { GenerateMatchesForm } from 'primary/classements/Ligue/Modal/GenerateClassement/GenerateMatchesForm';
import { CoupleButtonValidCancel } from 'primary/Components/CoupleButtonValidCancel/CoupleButtonValidCancel';
import { KeyValue } from 'primary/Components/KeyValue/KeyValue';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import React, { useState } from 'react';

import { Title } from '../../../../Components/Title/Title';
import { useTranslate } from '../../../../hooks/useTranslate';

interface ModalStartEventProps {
  handleClose: () => void;
  event: UnpyEvent | UnpyEventView;
  onStarted: () => void;
  regenerate?: boolean;
}

const ModalStartEvent = ({
  handleClose,
  event,
  onStarted,
  regenerate,
}: ModalStartEventProps) => {
  const t = useTranslate();
  const [loading, setLoading] = useState(false);
  const { eventRepository, matchsRepository } = useContextDependency();

  const generateBracket = () => {
    setLoading(true);
    if (!regenerate) {
      eventRepository
        .startEvent(event.id)
        .then(() => {
          onStarted();
          handleClose();
        })
        .finally(() => setLoading(false));
    } else {
      matchsRepository
        .generateMatchs({ eventId: event.id })
        .then(() => {
          onStarted();
          handleClose();
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <div className={'modal-start-event'}>
      <Title
        threeQuarter
        bordered
        title={
          regenerate
            ? t('classement.parameters.regenerate')
            : getSubStatusOfEvent(event) === EventSubStatusEnum.SUBSCRIBE_OPEN
            ? t('event.modal.closeSubscribeAndStartEvent.title')
            : t('event.modal.startEvent.title')
        }
        level={2}
      />
      <div className={'modal-start-event__content'}>
        {regenerate && (
          <Alert severity="warning">{t('classement.parameters.regenerateNotice')}</Alert>
        )}
        {!regenerate && (
          <Alert severity="warning" className={'-alertDelayed'}>
            {t('classement.parameters.beginNotice')}
          </Alert>
        )}
        <div className={'-containerKeyValue'}>
          <h3>{t('event.modal.generateClassement.form.title')}</h3>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <KeyValue
                alignStart
                keyValue={t('event.modal.generateClassement.typeEvent.label')}
                value={t(`enum.typeEventCode.${event.typeEvent.code}`)}
                className={'-keyValue'}
                accent
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <KeyValue
                alignStart
                keyValue={t('event.modal.generateClassement.game.label')}
                value={t(`games.libelle.${event.game.code}`)}
                className={'-keyValue'}
                accent
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <KeyValue
                alignStart
                keyValue={t('event.modal.generateClassement.typeTeam.label')}
                value={t(`enum.typeTeamCode.${event.typeTeam?.code as TypeTeamCodeEnum}`)}
                className={'-keyValue'}
                accent
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <KeyValue
                alignStart
                keyValue={t('event.modal.generateClassement.participants.label')}
                value={`${event.totalParticipants ?? 0} / ${event?.maxTeam}`}
                className={'-keyValue'}
                accent
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {event.typeEvent.code === TypeEventCodeEnum.LIGUE && (
                <KeyValue
                  alignStart
                  keyValue={t('event.modal.generateClassement.ronde.label')}
                  value={t('event.modal.generateClassement.ronde.robinLabel')}
                  className={'-keyValue'}
                  accent
                />
              )}
              {event.typeEvent.code === TypeEventCodeEnum.TOURNAMENT && (
                <KeyValue
                  alignStart
                  keyValue={t('event.modal.generateClassement.typeTournois.label')}
                  value={t('event.modal.generateClassement.typeTournois.simpleElem')}
                  className={'-keyValue'}
                  accent
                />
              )}
              {event.typeEvent.code === TypeEventCodeEnum.TOURNAMENT_DOUBLE_ELEM && (
                <KeyValue
                  alignStart
                  keyValue={t('event.modal.generateClassement.typeTournois.label')}
                  value={t('event.modal.generateClassement.typeTournois.doubleElem')}
                  className={'-keyValue'}
                  accent
                />
              )}
            </Grid>
          </Grid>
          <div className={'-containerKeyValue__info'}>
            <Alert severity={'info'}>
              <span>
                {
                  //@ts-ignore */
                  t(`event.modal.generateClassement.${event.typeEvent.code}.explanation`)
                }
              </span>
            </Alert>
          </div>
        </div>
        <Divider />
        {(event.typeEvent.code === TypeEventCodeEnum.LIGUE ||
          event.typeEvent.code === TypeEventCodeEnum.LIG_BR) && (
          <div className={'-form'}>
            <h3>{t(`event.modal.generateClassement.titleParams`)}</h3>
            <div className={'generateMatchFormContainer'}>
              <GenerateMatchesForm
                event={event}
                totalMatch={maxMatchBySessionLigueRobin(event)}
                handleClose={handleClose}
                onSubmitted={onStarted}
                isForStart={!regenerate}
              />
            </div>
          </div>
        )}
        {(event.typeEvent.code === TypeEventCodeEnum.TOURNAMENT ||
          event.typeEvent.code === TypeEventCodeEnum.TOURNAMENT_DOUBLE_ELEM) && (
          <CoupleButtonValidCancel
            onClickCancel={handleClose}
            loading={loading}
            onClickValid={generateBracket}
          />
        )}
      </div>
    </div>
  );
};

export default ModalStartEvent;
