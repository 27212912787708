import './MatchsViewTournament.scss';

import { BracketView } from 'primary/events/components/matchs/view/BracketView';
import { EventViewPageContext } from 'primary/events/item/EventViewPage';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { useOutletContext } from 'react-router-dom';

export const MatchsViewTournament = () => {
  const { event } = useOutletContext<EventViewPageContext>();
  const t = useTranslate();
  if (!event) return null;
  return (
    <div className={'matchViewTournament'}>
      <BracketView event={event} />
    </div>
  );
};
