import './UserProfilFrontContent.scss';

import { MoreVert, Settings } from '@mui/icons-material';
import { Grid, Hidden, IconButton } from '@mui/material';
import classNames from 'classnames';
import { NeonClassName } from 'domain/neon/Neon';
import { getNeonBorderClassName } from 'domain/profil/Profil.func';
import { UserProfil } from 'domain/profil/UserProfil';
import { Neon } from 'primary/Components/Neon/Neon';
import { PopperButton } from 'primary/Components/PopperButton/PopperButton';
import { ProfilHeaderLeft } from 'primary/Components/Profils/User/front/ProfilHeaderLeft';
import { ProfilTabsDetailComp } from 'primary/Components/Profils/User/tabs/ProfilTabsDetails';
import { TeamCreatingAction } from 'primary/Components/Profils/User/TeamCreatingActions';
import { menusProfilPlayer } from 'primary/Components/Profils/User/TopBarMenuProfil/MenusProfil.config';
import { TopBarMenuProfil } from 'primary/Components/Profils/User/TopBarMenuProfil/TopBarMenuProfil';
import React, { useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { Outlet } from 'react-router-dom';

import { ProfilNetworkPing, useProfilPingBtn } from './ProfilNetworkPing';
import { ProfilPhoto } from './ProfilPhoto';

type FrontContentProps = {
  profil: UserProfil;
  inView?: boolean;
  isPreview?: boolean;
};

type UserProfilCardHeaderProps = {
  profil: UserProfil;
};

const UserProfilCardHeader = ({ profil }: UserProfilCardHeaderProps) => {
  const { handleGoParam, isOwn, conf, neonBorderClassName, isDisabled } =
    useProfilPingBtn(profil);
  return (
    <>
      <Grid item xs={12} md={4} className={'frontCardUserProfilContent__header-block'}>
        <ProfilHeaderLeft profil={profil} neon={profil.neon} />
      </Grid>
      <Grid item xs={12} md={4} className={'frontCardUserProfilContent__header-block'}>
        <ProfilPhoto profil={profil} />
      </Grid>
      <Grid item xs={12} md={4} className={'frontCardUserProfilContent__header-block'}>
        <ProfilNetworkPing profil={profil} />
      </Grid>

      <div className={'-idPlayerContainer'}>
        <div className={'-playerWithBtnContainer'}>
          <h1 className={'-idPlayer'}>{profil.idPlayer}</h1>
          <Hidden smUp>
            <div className={'-settings'}>
              {isOwn && (
                <IconButton
                  className={classNames('-settingsBtn', neonBorderClassName)}
                  onClick={handleGoParam}
                >
                  <Settings />
                </IconButton>
              )}
              {!isDisabled && !isOwn && (
                <>
                  <PopperButton
                    conf={conf}
                    popperName={'user-profil-preview'}
                    placement={'right-start'}
                    button={(togglePopper, ref) => (
                      <IconButton
                        ref={ref}
                        className={classNames(neonBorderClassName)}
                        onClick={togglePopper}
                      >
                        <MoreVert />
                      </IconButton>
                    )}
                  />
                  <TeamCreatingAction profil={profil} expanded />
                </>
              )}
            </div>
          </Hidden>
        </div>
        <div className={'-divider'}>
          <Neon variant={neonBorderClassName as NeonClassName} />
        </div>
      </div>

      <div
        className={'-overlay'}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      ></div>
    </>
  );
};

const UserProfilCardContentWithRouteAndMenu = ({ profil, inView }: FrontContentProps) => {
  const [isFixed, setIsFixed] = React.useState(false);

  return (
    <>
      <TopBarMenuProfil
        title={profil.idPlayer}
        confs={menusProfilPlayer.main}
        isFixed={!inView}
        setIsFixed={setIsFixed}
      />

      <div className={classNames('-content', { '-isTopBarFixed': !inView })}>
        <Outlet context={{ profil: profil }} />
      </div>
    </>
  );
};

export const UserProfilFrontContent = ({ profil, isPreview }: FrontContentProps) => {
  const neonClassName = useMemo(() => getNeonBorderClassName(profil), [profil]);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const styleBackground = isPreview
    ? {
        backgroundImage: `url("${profil.backgroundImage?.publicUrl}")`,
        backgroundSize: 'cover',
      }
    : {};
  return (
    <div
      className={classNames('frontCardUserProfilContent', neonClassName)}
      style={styleBackground}
    >
      <Grid ref={ref} className={'frontCardUserProfilContent__header'} container>
        <UserProfilCardHeader profil={profil} />
      </Grid>
      <div className={'frontCardUserProfilContent__contentContainer'}>
        {!isPreview && (
          <UserProfilCardContentWithRouteAndMenu inView={inView} profil={profil} />
        )}
        {isPreview && <ProfilTabsDetailComp profil={profil} />}
      </div>
    </div>
  );
};

/*

      <MainRowLayout
        mainContent={<ProfilInfos onDisplayInfo={onReturnCard} profil={profil} />}
        leftContent={
          <ProfilGames
            selectedGames={selectedGames}
            profil={profil}
            handleSelection={handleSelection}
          />
        }
        rightContent={<ProfilStats selectedGames={selectedGames} profil={profil} />}
      />
 */
