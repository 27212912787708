import { AxiosInstance } from 'axios';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';

import { Country } from '../../domain/country/Country';
import { ICountryRepository } from '../../domain/country/Country.repository';
import { RestCountry, toCountry } from './RestCountry';

export class CountryRepository
  extends AxiosProviderRepository
  implements ICountryRepository
{
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  getAll(): Promise<Country[]> {
    return this.axios
      .get('/unpy/api/country/all')
      .then((resp) =>
        resp.data.map((restCountry: RestCountry) => toCountry(restCountry)),
      );
  }
}
