import { useState } from 'react';

type UseBooleanToggleReturn = [boolean, () => void, () => void, () => void];

export const useBooleanToggle = (defautlValue?: boolean): UseBooleanToggleReturn => {
  const [isTrue, setIsTrue] = useState<boolean>(defautlValue || false);

  const toggle = () => {
    setIsTrue((prevState) => !prevState);
  };

  const setTrue = () => {
    setIsTrue(true);
  };

  const setFalse = () => {
    setIsTrue(false);
  };

  return [isTrue, toggle, setTrue, setFalse];
};
