import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const useFetch = <T, U = T>(
  fetch: () => Promise<T>,
  defaultStateValue: U,
  condition = true,
): [T | U, Dispatch<SetStateAction<T | U>>, boolean, () => void] => {
  const [elementFetched, setElementFetched] = useState<T | U>(defaultStateValue);
  const [fetching, setFetching] = useState<boolean>(true);

  function callAPI() {
    fetch()
      .then((element) => {
        setElementFetched(element);
      })
      .finally(() => {
        setFetching(false);
      });
  }

  function retry() {
    if (condition) {
      callAPI();
    }
  }

  useEffect(() => {
    if (condition) {
      callAPI();
    }
  }, [condition]);

  return [elementFetched, setElementFetched, fetching, retry];
};
