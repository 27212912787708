import { PRIVACY_CODE, ProfilPrivacy } from 'domain/privacy/ProfilPrivacy';
import {
  RestStructureProfilView,
  toStructureProfilView,
} from 'secondary/structureProfil/RestStructureProfil';
import { RestUserProfilView, toUserProfilView } from 'secondary/user/RestUserProfilView';

import { toEnum } from '../../helper/enum.helper';

export interface RestPrivacy {
  id: number;
  profil: RestUserProfilView | RestStructureProfilView;
  code: string;
  checked: boolean;
}

export function toProfilPrivacy(restPrivacy: RestPrivacy): ProfilPrivacy {
  return new ProfilPrivacy(
    restPrivacy.id,
    Object.hasOwn(restPrivacy.profil, 'idPlayer')
      ? toUserProfilView(restPrivacy.profil as RestUserProfilView)
      : toStructureProfilView(restPrivacy.profil as RestStructureProfilView),
    toEnum(PRIVACY_CODE, restPrivacy.code),
    restPrivacy.checked,
  );
}
