import { TypeBracket } from '../../domain/event/TypeBracket';
import { TypeBracketCodeEnum } from '../../domain/event/TypeBracketCodeEnum';
import { toEnum } from '../../helper/enum.helper';

export interface RestTypeBracket {
  id: number;
  code: string;
}

export function toTypeBracket(restTypeBracket: RestTypeBracket) {
  return new TypeBracket(
    restTypeBracket.id,
    toEnum(TypeBracketCodeEnum, restTypeBracket.code),
  );
}
