import { MarketPlaceAnnouncementView } from '../../domain/marketPlaceAnnouncement/MarketPlaceAnnouncementView';
import { MarketPlaceAnnouncementRepository } from './MarketPlaceAnnouncement.repository';
import {
  RestMarketPlaceAnnouncementView,
  toMarketPlaceAnnouncementView,
} from './RestMarketPlaceAnnouncementView';

const arrayFutures: RestMarketPlaceAnnouncementView[] = [
  {
    id: 1,
    type: 'COACHING',
    image: 'https://images3.alphacoders.com/115/1153033.jpg',
    game: 'Apex Legends',
    pricing: 15,
  },
  {
    id: 2,
    type: 'COACHING',
    image:
      'https://media.gqmagazine.fr/photos/6219015a62fefef7e3640b57/16:9/w_2560%2Cc_limit/fifa%2520cover%25201.jpg',
    game: 'Fifa',
    pricing: 10,
  },
  {
    id: 3,
    type: 'LOGO DESIGN',
    image:
      'https://media.gqmagazine.fr/photos/6219015a62fefef7e3640b57/16:9/w_2560%2Cc_limit/fifa%2520cover%25201.jpg',
    game: 'Fifa',
    pricing: 10,
  },
  {
    id: 4,
    type: 'COACHING',
    image:
      'https://media.gqmagazine.fr/photos/6219015a62fefef7e3640b57/16:9/w_2560%2Cc_limit/fifa%2520cover%25201.jpg',
    game: 'Fifa',
    pricing: 20,
  },
  {
    id: 5,
    type: 'LOGO DESIGN',
    image: 'https://images3.alphacoders.com/115/1153033.jpg',
    game: 'Apex Legends',
    pricing: 20,
  },
];

export class InMemoryRestmarketPlaceAnnouncementView
  extends MarketPlaceAnnouncementRepository
  implements Partial<MarketPlaceAnnouncementRepository>
{
  async getRecent(): Promise<MarketPlaceAnnouncementView[]> {
    return arrayFutures.map((restMarketPlaceAnnouncementView) =>
      toMarketPlaceAnnouncementView(restMarketPlaceAnnouncementView),
    );
  }
}
