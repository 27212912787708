import K24 from '@assets/GamesImages/2K24.jpg';
import APX from '@assets/GamesImages/APX.jpg';
import CS2 from '@assets/GamesImages/CS2.jpg';
import CSG from '@assets/GamesImages/CSG.jpg';
import DOTA2 from '@assets/GamesImages/DOTA2.jpg';
import FFA from '@assets/GamesImages/FFA.jpg';
import FRT from '@assets/GamesImages/FRT.jpg';
import HST from '@assets/GamesImages/HST.jpg';
import LOL from '@assets/GamesImages/LOL.jpg';
import MARD8 from '@assets/GamesImages/MARD8.jpg';
import MW3 from '@assets/GamesImages/MW3.jpg';
import OVW from '@assets/GamesImages/OVW.jpg';
import PBG from '@assets/GamesImages/PBG.jpg';
import R6S from '@assets/GamesImages/R6S.jpg';
import RCK from '@assets/GamesImages/RCK.jpg';
import SCB6 from '@assets/GamesImages/SCB6.jpg';
import SMULT from '@assets/GamesImages/SMULT.jpg';
import STRFGT from '@assets/GamesImages/STRFGT.jpg';
import TEK8 from '@assets/GamesImages/TEK8.jpg';
import TFT from '@assets/GamesImages/TFT.jpg';
import VLR from '@assets/GamesImages/VLR.jpg';
import WRZ from '@assets/GamesImages/WRZ.jpg';
import XDF from '@assets/GamesImages/XDF.jpg';

export enum GAME_ACTIVE_LIST {
  'APX' = 'APX',
  'WRZ' = 'WRZ',
  'MW3' = 'MW3',
  'OVW' = 'OVW',
  'XDF' = 'XDF',
  'CS2' = 'CS2',
  'LOL' = 'LOL',
  'PBG' = 'PBG',
  'R6S' = 'R6S',
  'FFA' = 'FFA',
  'VLR' = 'VLR',
  'TFT' = 'TFT',
  'CSG' = 'CSG',
  'RCK' = 'RCK',
  'FRT' = 'FRT',
  'STRFGT' = 'STRFGT',
  'SCB6' = 'SCB6',
  'MARD8' = 'MARD8',
  'TEK8' = 'TEK8',
  'SMULT' = 'SMULT',
  'DOTA2' = 'DOTA2',
  'HST' = 'HST',
  'K24' = 'K24',
}

export const GAME_IMAGES: { [key in GAME_ACTIVE_LIST]: string } = {
  MW3: MW3,
  OVW: OVW,
  XDF: XDF,
  CS2: CS2,
  APX: APX,
  WRZ: WRZ,
  LOL: LOL,
  PBG: PBG,
  R6S: R6S,
  FFA: FFA,
  VLR: VLR,
  TFT: TFT,
  CSG: CSG,
  RCK: RCK,
  FRT: FRT,
  STRFGT: STRFGT,
  SCB6: SCB6,
  MARD8: MARD8,
  TEK8: TEK8,
  SMULT: SMULT,
  K24: K24,
  HST: HST,
  DOTA2: DOTA2,
};

export const GAME_ACTIVE_LIST_SELECT_OPTIONS = {
  [GAME_ACTIVE_LIST.APX]: {
    selected: false,
    code: GAME_ACTIVE_LIST.APX,
  },
  [GAME_ACTIVE_LIST.WRZ]: {
    selected: false,
    code: GAME_ACTIVE_LIST.WRZ,
  },
  [GAME_ACTIVE_LIST.LOL]: {
    selected: false,
    code: GAME_ACTIVE_LIST.LOL,
  },
  [GAME_ACTIVE_LIST.PBG]: {
    selected: false,
    code: GAME_ACTIVE_LIST.PBG,
  },
  [GAME_ACTIVE_LIST.R6S]: {
    selected: false,
    code: GAME_ACTIVE_LIST.R6S,
  },
  [GAME_ACTIVE_LIST.FFA]: {
    selected: false,
    code: GAME_ACTIVE_LIST.FFA,
  },
  [GAME_ACTIVE_LIST.VLR]: {
    selected: false,
    code: GAME_ACTIVE_LIST.VLR,
  },
  [GAME_ACTIVE_LIST.TFT]: {
    selected: false,
    code: GAME_ACTIVE_LIST.TFT,
  },
  [GAME_ACTIVE_LIST.CSG]: {
    selected: false,
    code: GAME_ACTIVE_LIST.CSG,
  },
  [GAME_ACTIVE_LIST.RCK]: {
    selected: false,
    code: GAME_ACTIVE_LIST.RCK,
  },
  [GAME_ACTIVE_LIST.FRT]: {
    selected: false,
    code: GAME_ACTIVE_LIST.FRT,
  },
};
