import React from 'react';
import ReactCountryFlag from 'react-country-flag';

import { LangEnum } from '../../../domain/enums/Lang.enum';

interface IconFlagProps {
  codeLang?: LangEnum;
  codeISO?: string;
  className?: string;
}

const IconFlagByCodeLang = {
  [LangEnum.ESP]: <ReactCountryFlag countryCode="ES" svg={true} alt={'espagne-lang'} />,
  [LangEnum.FR]: <ReactCountryFlag countryCode="FR" svg={true} alt={'france-lang'} />,
  [LangEnum.EN]: <ReactCountryFlag countryCode="US" svg={true} alt={'anglais-lang'} />,
};

export const IconFlag = ({ codeLang, codeISO }: IconFlagProps) => {
  if (codeISO) {
    return <ReactCountryFlag countryCode={codeISO} svg={true} alt={codeISO} />;
  } else if (!codeLang) {
    return null;
  }
  return IconFlagByCodeLang[codeLang];
};
