import './ModalEditRole.scss';

import { Box } from '@mui/material';
import { Structure } from 'domain/structure/Structure';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React, { FC } from 'react';

import { StructureMember } from '../../../domain/structureMember/StructureMember';
import { useTranslate } from '../../hooks/useTranslate';
import { MemberRoleForm } from './MemberRoleForm';

interface ModalEditRoleProps {
  isOpen: boolean;
  handleClose: () => void;
  userId?: number;
  structure?: Structure;
  roleId: number;
  onSubmitted?: (structureMember: StructureMember) => void;
  name: string;
}

export const ModalEditRole: FC<ModalEditRoleProps> = ({
  isOpen,
  handleClose,
  onSubmitted,
  structure,
  userId,
  roleId,
  name,
}) => {
  const t = useTranslate();
  const enhancedOnSubmitted = (structureMember: StructureMember) => {
    handleClose();
    onSubmitted && onSubmitted(structureMember);
  };
  return (
    <ModalContainer
      handleClose={handleClose}
      isOpen={isOpen}
      content={
        <Box className={'modal-edit-role'}>
          <h2>{t('structure.modalRole.title', { name: name })}</h2>
          <MemberRoleForm
            onSubmitted={enhancedOnSubmitted}
            initialValues={{ role: roleId.toString() }}
            structure={structure}
            userId={userId}
            handleClose={handleClose}
          />
        </Box>
      }
    />
  );
};
