import { EventConfirmationStateEnum } from 'domain/event/EventConfirmationStateEnum';
import { SystemPointsEnum } from 'domain/event/SystemPoints.enum';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { RestPlatform, toPlatform } from 'secondary/platform/RestPlatform';
import { RestUserProfilView, toUserProfilView } from 'secondary/user/RestUserProfilView';

import { EventStatusEnum } from '../../domain/event/EventStatusEnum';
import { getNewLocalDateFromBack } from '../../helper/date.helper';
import { toEnum } from '../../helper/enum.helper';
import { RestGame, toGame } from '../game/RestGame';
import { RestTypeEvent, toTypeEvent } from './RestTypeEvent';
import { RestTypeTeam, toTypeTeam } from './RestTypeTeam';

export interface RestEventView {
  id: number;
  startDateTime: string;
  endSubscribeDate: string;
  name: string;
  creator: RestUserProfilView;
  freeRegister: boolean;
  platforms?: RestPlatform[];
  confirmationState: string;
  delayed: string;
  game: RestGame;
  status: string;
  typeEvent: RestTypeEvent;
  typeTeam?: RestTypeTeam;
  image?: string;
  publicImageUrl?: string;
  defaultImage?: boolean;
  prizeText?: string;
  pointSystem?: string;
  maxTeam?: number;
  totalParticipants?: number;
}

export function toUnpyEventView(restEventView: RestEventView) {
  return new UnpyEventView(
    restEventView.id,
    getNewLocalDateFromBack(restEventView.startDateTime),
    getNewLocalDateFromBack(restEventView.endSubscribeDate),
    toEnum(EventStatusEnum, restEventView.status),
    restEventView.name,
    restEventView.freeRegister,
    toUserProfilView(restEventView.creator),
    toGame(restEventView.game),
    toTypeEvent(restEventView.typeEvent),
    toTypeTeam(restEventView.typeTeam),
    restEventView.platforms?.map((platform) => toPlatform(platform)),
    restEventView.confirmationState
      ? toEnum(EventConfirmationStateEnum, restEventView.confirmationState)
      : undefined,
    restEventView.delayed ? new Date(restEventView.delayed) : undefined,
    restEventView.image,
    restEventView.publicImageUrl,
    restEventView.defaultImage,
    restEventView.prizeText,
    restEventView.pointSystem
      ? toEnum(SystemPointsEnum, restEventView.pointSystem)
      : undefined,
    restEventView.maxTeam,
    restEventView.totalParticipants,
  );
}
