import { words } from 'lodash';

export function getTimeNeededToReadText(wordCount: number): number {
  return Math.min(Math.max(3000, (60 / 160) * wordCount * 1000), 20000);
}

export enum MessageLevel {
  SUCCESS,
  WARNING,
  ERROR,
}

export function isMessageReceived(
  maybeMessageReceived: MessageReceived | Error,
): maybeMessageReceived is MessageReceived {
  return (maybeMessageReceived as MessageReceived).response !== undefined;
}
export interface MessageReceived {
  response: {
    status?: number;
    data?: {
      message: string;
    };
  };
}

export class APIMessage {
  readonly duration: number;
  readonly message: string;

  constructor(message: string, public readonly level: MessageLevel = MessageLevel.ERROR) {
    this.duration = getTimeNeededToReadText(words(message).length);
    this.message = message;
    this.level = level;
  }

  static of(message: MessageReceived, defaultMessageText: string): APIMessage {
    if (!message.response.data?.message) return new APIMessage(defaultMessageText);
    switch (message.response.status) {
      case 400:
        return new APIMessage(message.response.data.message);
      default:
        return new APIMessage(defaultMessageText);
    }
  }
  public static success(message: string): APIMessage {
    return new APIMessage(message, MessageLevel.SUCCESS);
  }
}
