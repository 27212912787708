import './PodiumView.scss';

import { EmojiEvents } from '@mui/icons-material';
import { EventStatusEnum } from 'domain/event/EventStatusEnum';
import { PlayerTeam } from 'domain/event/PlayerTeam';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { TagPlayer } from 'primary/Components/TagPlayer/TagPlayer';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

interface PodiumViewProps {
  event: UnpyEventView | UnpyEvent;
}

export const IconPodiumByIndex = [
  <EmojiEvents className={'-podium--first'} key={'-podium--first'} />,
  <EmojiEvents className={'-podium--second'} key={'-podium--second'} />,
  <EmojiEvents className={'-podium--third'} key={'-podium--third'} />,
];

export const IconPodiumByIndexBR = [
  <EmojiEvents className={'-podium--first'} key={'-podium--first'} />,
  <EmojiEvents className={'-podium--second'} key={'-podium--second'} />,
  <EmojiEvents className={'-podium--second'} key={'-podium--second'} />,
  <EmojiEvents className={'-podium--third'} key={'-podium--third'} />,
];
export const PodiumView = ({ event }: PodiumViewProps) => {
  const t = useTranslate();
  const { classementRepository } = useContextDependency();
  const [classementParticipants, , fetching] = useRetrieveFromDomain(
    () => classementRepository.getPodium(event.id),
    undefined,
  );
  const sorted = classementParticipants?.sort(
    (p, p2) => (p2.score ?? 0) - (p.score ?? 0),
  );
  return (
    <div className={'podiumView'}>
      {fetching && <LoadingScreen loading />}
      {!fetching && (
        <>
          <div className={'-title'}>
            {event.status === EventStatusEnum.FINISH && (
              <h2>
                {t('event.podium.title')}
                <span>{event.name}</span>
              </h2>
            )}
            {event.status !== EventStatusEnum.FINISH && (
              <h2>
                {t('event.podium.noFinishTitle')} <span>{event.name}</span>
              </h2>
            )}
            <span>{event.prizeText ?? t('event.podium.noPrice')}</span>
          </div>
          <div className={'-podium'}>
            <>
              <div className={'-podium--item'} key={0}>
                {IconPodiumByIndex[2]}
                {sorted?.[2] && (
                  <>
                    <span className={'-nameTeam'}>
                      {sorted[2]?.participant?.team.name}
                    </span>
                    <div className={'-playersTeam'}>
                      {sorted[2]?.participant?.team.players.map((player: PlayerTeam) => (
                        <TagPlayer key={player.player.idPlayer} player={player.player} />
                      ))}
                    </div>
                  </>
                )}
                {!sorted?.[2] && (
                  <span className={'-nameTeam'}>
                    {t('event.podium.noContentParticipant')}
                  </span>
                )}
              </div>
              <div className={'-podium--item'} key={1}>
                {IconPodiumByIndex[0]}
                {sorted?.[0] && (
                  <>
                    <span className={'-nameTeam'}>
                      {sorted[0]?.participant?.team.name}
                    </span>
                    <div className={'-playersTeam'}>
                      {sorted[0]?.participant?.team.players.map((player: PlayerTeam) => (
                        <TagPlayer key={player.player.idPlayer} player={player.player} />
                      ))}
                    </div>
                  </>
                )}
                {!sorted?.[0] && (
                  <span className={'-nameTeam'}>
                    {t('event.podium.noContentParticipant')}
                  </span>
                )}
              </div>
              <div className={'-podium--item'} key={2}>
                {IconPodiumByIndex[1]}
                {sorted?.[1] && (
                  <>
                    <span className={'-nameTeam'}>
                      {sorted[1]?.participant?.team.name}
                    </span>
                    <div className={'-playersTeam'}>
                      {sorted[1]?.participant?.team.players.map((player: PlayerTeam) => (
                        <TagPlayer key={player.player.idPlayer} player={player.player} />
                      ))}
                    </div>
                  </>
                )}
                {!sorted?.[2] && (
                  <span className={'-nameTeam'}>
                    {t('event.podium.noContentParticipant')}
                  </span>
                )}
              </div>
            </>
          </div>
        </>
      )}
    </div>
  );
};
