import { AxiosInstance } from 'axios';
import { IClassementRepository } from 'domain/classement/Classement.repository';
import {
  ClassementFilters,
  ClassementParticipant,
} from 'domain/classement/ClassementParticipant';
import { Pageable } from 'domain/pageable/Pageable';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';
import { toClassementParticipant } from 'secondary/classement/RestClassementParticipant';
import { toPageable } from 'secondary/RestPageable';

export class ClassementRepository
  extends AxiosProviderRepository
  implements IClassementRepository
{
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  search(
    filters: ClassementFilters,
    page: number,
    nbPerPage: number,
  ): Promise<Pageable<ClassementParticipant>> {
    return this.axios
      .get('/unpy/api/classement/search', {
        params: {
          ...filters,
          page,
          nbPerPage,
        },
      })
      .then((response) => toPageable(response, toClassementParticipant));
  }

  searchPerf(
    filters: ClassementFilters,
    userProfilId: number,
    page: number,
    nbPerPage: number,
  ): Promise<Pageable<ClassementParticipant>> {
    return this.axios
      .get(`/unpy/api/classement/perf/${userProfilId}/search`, {
        params: {
          ...filters,
          page,
          nbPerPage,
        },
      })
      .then((response) => toPageable(response, toClassementParticipant));
  }

  getPodium(eventId: number): Promise<ClassementParticipant[]> {
    return this.axios
      .get(`/unpy/api/classement/podium/${eventId}`)
      .then((response) => response.data.map(toClassementParticipant));
  }
}
