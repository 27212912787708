import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Product } from 'domain/payment/Product';

type InitialProductSliceState = {
  products?: Product[];
};

export const ProductSlice = createSlice({
  name: 'user',
  initialState: {
    products: undefined,
  } as InitialProductSliceState,
  reducers: {
    setAllProducts: (state, action: PayloadAction<Product[]>) => {
      state.products = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAllProducts } = ProductSlice.actions;

export default ProductSlice.reducer;
