import { RoutesList } from './Routes.config';

type ParametersRoutesStructureKey =
  | 'replaceDefault'
  | 'profilAppearence'
  | 'interests'
  | 'profilNetwork'
  | 'adminMembers'
  | 'adminStructure'
  | 'adminRoster';
// @ts-ignore
export const parametersRoutesStructure: RoutesList<ParametersRoutesStructureKey> = {
  replaceDefault: {
    to: 'profil/appearence',
    path: 'profil/appearence',
  },
  profilAppearence: {
    to: (idStructure: string) => `/parameters/structure/${idStructure}/profil/appearence`,
    path: '/parameters/structure/:idStructure/profil/appearence',
  },
  interests: {
    to: (idStructure: string) => `/parameters/structure/${idStructure}/profil/interests`,
    path: `/parameters/structure/:idStructure/profil/interests`,
  },
  profilNetwork: {
    to: (idStructure: string) => `/parameters/structure/${idStructure}/profil/network`,
    path: '/parameters/structure/:idStructure/profil/network',
  },
  adminMembers: {
    to: (idStructure: string) => `/parameters/structure/${idStructure}/admin/members`,
    path: '/parameters/structure/:idStructure/admin/members',
  },
  adminStructure: {
    to: (idStructure: string) => `/parameters/structure/${idStructure}/admin/structure`,
    path: '/parameters/structure/:idStructure/admin/structure',
  },
  adminRoster: {
    to: (idStructure: string) => `/parameters/structure/${idStructure}/admin/roster`,
    path: '/parameters/structure/:idStructure/admin/roster',
  },
};
