import { RoleStructure } from '../../domain/roleStructure/RoleStructure';
import { RolesStructureEnum } from '../../domain/security/RolesStructureEnum';
import { toEnum } from '../../helper/enum.helper';
import {
  RestPermissionStructure,
  toPermission,
} from '../permissionStructure/RestPermissionStructure';

export interface RestRoleStructure {
  id: number;
  rang: number;
  code: string;
  permissions: RestPermissionStructure[];
}

export function toRoleStructure(restRoleStructure: RestRoleStructure) {
  return new RoleStructure(
    restRoleStructure.id,
    restRoleStructure.rang,
    toEnum(RolesStructureEnum, restRoleStructure.code),
    restRoleStructure.permissions
      ? restRoleStructure.permissions.map((value) => toPermission(value))
      : [],
  );
}
