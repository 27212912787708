import './AutocompleteStructureProfil.scss';

import { Autocomplete, TextField } from '@mui/material';
import classNames from 'classnames';
import { debounce, DebouncedFunc } from 'lodash';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

import {
  StructureProfil,
  StructureProfilFilters,
} from '../../../domain/profil/StructureProfil';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useFetchWithFilterPage } from '../../hooks/useFetchWithFilterPage';
import { InputProps } from '../Input/InputWrapper';

type AutocompleteStructureProfilWrapperFetchProps = {
  multiple?: boolean;
  className?: string;
  idAsValue?: boolean;
} & InputProps;

export const AutocompleteStructureProfilWrapperFetch = (
  props: AutocompleteStructureProfilWrapperFetchProps,
) => {
  const { structureProfilRepository } = useContextDependency();

  const defaultFilters: StructureProfilFilters = {
    games: undefined,
    experiences: undefined,
    platforms: undefined,
    langs: undefined,
    name: '',
  };
  const { fetchedResource, setFilters } = useFetchWithFilterPage<
    StructureProfil,
    StructureProfilFilters
  >({
    filters: defaultFilters,
    callApi: (filters, page) => structureProfilRepository.search(filters, page, 100),
  });

  const setFiltersDebonced = debounce((name: string) => {
    setFilters({ ...defaultFilters, name: name });
  }, 100);

  return (
    <AutocompleteStructureProfil
      {...props}
      onChangeText={setFiltersDebonced}
      options={
        fetchedResource?.content?.map((option) => ({
          label: option.structure?.name,
          value: props.idAsValue ? option.structure?.id : option.structure?.name,
        })) ?? []
      }
    />
  );
};

type AutocompleteStructureProfilProps = {
  options: any;
  onChangeText: DebouncedFunc<(idPlayer: string) => void>;
  multiple?: boolean;
  className?: string;
} & InputProps;

export const AutocompleteStructureProfil = ({
  options,
  onChangeText,
  className,
  onChangeSelected,
  multiple,
  disabled,
  value,
  ...props
}: AutocompleteStructureProfilProps) => {
  const t = useTranslate();
  return (
    <Autocomplete
      className={classNames('autocomplete-structure-profil', className)}
      freeSolo
      multiple={multiple}
      disabled={disabled}
      defaultValue={value}
      onChange={(e, option: any) => {
        onChangeSelected && !disabled && onChangeSelected(option.value);
      }}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          value={value}
          disabled={disabled}
          placeholder={t('autocomplete.structureProfil.placeholder')}
          onChange={(event) => !disabled && onChangeText(event.target.value)}
        />
      )}
    />
  );
};
