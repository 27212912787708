import { AxiosInstance } from 'axios';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';

import { IMercatoAnnouncementRepository } from '../../domain/mercatoAnnouncement/MercatoAnnouncement.repository';
import { MercatoAnnouncementView } from '../../domain/mercatoAnnouncement/MercatoAnnouncementView';

export class MercatoAnnouncementRepository
  extends AxiosProviderRepository
  implements IMercatoAnnouncementRepository
{
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  getRecent(): Promise<MercatoAnnouncementView[]> {
    return Promise.resolve([]);
  }
}
