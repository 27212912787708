import { UserProfilView } from 'domain/user/UserProfilView';

import { User } from '../user/User';
import { Message } from './Message';

export type ConversationFilters = {
  idPlayer: string;
};

export class Conversation {
  constructor(
    public readonly id: number,
    public readonly participants: UserProfilView[],
    public readonly messages?: Message[],
  ) {}

  getUserTo(user: User) {
    return this.participants.find((part) => part.id != user.id);
  }
}
