import './StructureMembersSwitchView.scss';

import { Collections, List } from '@mui/icons-material';
import { Switch } from '@mui/material';
import { Structure } from 'domain/structure/Structure';
import {
  StructureMember,
  StructureMemberFilters,
} from 'domain/structureMember/StructureMember';
import { ModalEditRole } from 'primary/Components/StructureProfil/ModalEditRole';
import { Title } from 'primary/Components/Title/Title';
import { sendEventToastMessage } from 'primary/Components/Toast/Toast.helper';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetch } from 'primary/hooks/useFetch';
import { useFetchWithFilterPage } from 'primary/hooks/useFetchWithFilterPage';
import { useTranslate } from 'primary/hooks/useTranslate';
import ModalConfirm from 'primary/ModalConfirm/ModalConfirm';
import { MembersStructureComp } from 'primary/Parameters/structure/Admin/MembersStructure';
import { ActionModalAddMember } from 'primary/Structure/ActionModalAddMember';
import { ActionModalInfos } from 'primary/Structure/ActionModalInfos';
import { StructureMembersDatatable } from 'primary/Structure/StructureMembersDatatable';
import React, { useState } from 'react';

type StructureMemberSwitchViewProps = {
  structure?: Structure;
  readonly?: boolean;
  onEditted?: () => void;
  withInfosRole?: boolean;
  inView?: boolean;
};

export const HelperRolePermission = () => {
  const { roleStructureRepository } = useContextDependency();
  const [roles] = useFetch(() => roleStructureRepository.getAll(), undefined);
  const t = useTranslate();
  return (
    <div className={'membersStructure__remindRolesPermission'}>
      <h2>{t(`helpers.modal.rolesInfos.title`)}</h2>
      {roles && (
        <div className={'-listRoles'}>
          {roles.map((role) => (
            <div key={role.id} className={'-role'}>
              <span className={'-name'}>{t(`structure.roles.${role.code}`)}</span>
              <span className={'-permissions'}>
                {role.permissions.map((p) => (
                  <span key={p.id}>{t(`enum.rolePermissionStructure.${p.code}`)}</span>
                ))}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const StructureMemberSwitchView = ({
  structure,
  readonly,
  onEditted,
  withInfosRole,
  inView,
}: StructureMemberSwitchViewProps) => {
  if (!structure) return null;
  const [view, setView] = React.useState<'list' | 'card'>('list');
  const { structureMemberRepository, roleStructureRepository } = useContextDependency();
  const [memberToEditRole, setMemberToEditRole] = React.useState<
    StructureMember | undefined
  >(undefined);

  const closeModal = () => setMemberToEditRole(undefined);
  const [memberToDelete, setMemberToDelete] = useState<number | undefined>(undefined);
  const t = useTranslate();
  const handleDeleteMember = () => {
    if (!memberToDelete) return;
    structureMemberRepository.deleteMember(memberToDelete, structure.id).then(() => {
      sendEventToastMessage('structure.member.delete.success', 'success');
      setMemberToDelete(undefined);
      onEditted && onEditted();
    });
  };

  const staticFilters = {
    idStructure: structure?.id?.toString(),
  };
  const useFetchReturn = useFetchWithFilterPage<StructureMember, StructureMemberFilters>({
    keyStoreFilters: 'structureMember',
    staticFilters: staticFilters,
    filters: {},
    callApi: (filters, page, nbPerPage) =>
      structureMemberRepository.search(filters, page, nbPerPage),
  });

  return (
    <div className={'structureMembersSwitchView'}>
      <div className={'structureMembersSwitchView__header'}>
        <Title
          title={t('structure.parameters.membersStructure.title')}
          level={2}
          compensatePadding
          threeQuarter={!inView}
          bordered={!inView}
        />
        <div className={'-actions'}>
          {withInfosRole && (
            <ActionModalInfos
              title={t('structure.members.helpTitleBtn')}
              content={<HelperRolePermission />}
            />
          )}
          <ActionModalAddMember structure={structure} />
          <Switch
            checked={view === 'list'}
            defaultChecked={false}
            onChange={() => (view === 'list' ? setView('card') : setView('list'))}
          />
          {view === 'list' && (
            <>
              <span>Liste</span>
              <List />
            </>
          )}
          {view === 'card' && (
            <>
              <span>Cartes</span>
              <Collections />
            </>
          )}
        </div>
      </div>
      <div className={'structureMembersSwitchView__content'}>
        {view === 'list' && (
          <StructureMembersDatatable
            useFetchReturn={useFetchReturn}
            members={
              readonly
                ? structure?.members?.filter((m) => m.accepted)
                : structure?.members
            }
            onClickEditMember={setMemberToEditRole}
            onClickDeleteMember={(member) => setMemberToDelete(member.user.id)}
            readonly={readonly}
            classNameDatatable={'-structureMemberViewDatatable'}
          />
        )}
        {view === 'card' && (
          <MembersStructureComp
            onlyAccepted={readonly}
            readonly={readonly}
            structure={structure}
            withoutTitle
            onClickEditMember={setMemberToEditRole}
          />
        )}
      </div>

      {!readonly && memberToEditRole && (
        <ModalEditRole
          isOpen={!!memberToEditRole}
          handleClose={closeModal}
          userId={memberToEditRole.user.id}
          structure={structure}
          roleId={memberToEditRole.role.id}
          onSubmitted={onEditted}
          name={memberToEditRole.user.userProfil.idPlayer}
        />
      )}
      {!readonly && (
        <ModalConfirm
          handleClose={() => setMemberToDelete(undefined)}
          isOpen={!!memberToDelete}
          title={t('structure.modalDelete.confirmTitleModal')}
          description={t('structure.modalDelete.confirmText')}
          action={handleDeleteMember}
          labelAccept={t('structure.modalDelete.confirmBtnText')}
          labelDecline={t('structure.modalDelete.cancelBtnText')}
        />
      )}
    </div>
  );
};

type EditRoleMemberStrctureModalProps = {
  member: StructureMember;
  onSubmitted?: () => void;
};
