import './PlatformsRow.scss';

import classNames from 'classnames';
import { Neon } from 'domain/neon/Neon';
import { PlatformCodeEnum } from 'domain/platform/Platform';
import { Icon } from 'primary/Components/Icon/Icon';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

interface PlatformsRowProps {
  platforms?: PlatformCodeEnum[];
  big?: boolean;
  neon?: Neon;
  variant?: 'outlined' | 'contained';
}

export const PlatformsRow = ({
  platforms,
  big,
  neon,
  variant = 'outlined',
}: PlatformsRowProps) => {
  const t = useTranslate();
  return (
    <div className={'platformsRow'}>
      {Object.values(PlatformCodeEnum).map((code: PlatformCodeEnum) => (
        <Icon
          variant={variant}
          title={t(`enum.platforms.${code}`)}
          key={code}
          className={classNames(
            '-icon',
            { '-active': platforms?.includes(code) },
            { '-big': big },
          )}
          svg={code}
        />
      ))}
    </div>
  );
};
