import './LoggedInUserAvatarMenu.scss';

import { Apartment, Games, Groups, Logout, Person, Settings } from '@mui/icons-material';
import {
  ClickAwayListener,
  Divider,
  Fade,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Popper,
} from '@mui/material';
import { MessagePreview } from 'primary/Components/User/MessagePreview';
import React, { FC, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { User } from '../../../domain/user/User';
import { routesConfig } from '../../Configs/Routes.config';
import { useBooleanToggle } from '../../hooks/useBooleanToggle';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useTranslate } from '../../hooks/useTranslate';
import { ModalAddContent, ModalCreateTypes } from './ModalAddContent';
import { NotificationPreview } from './NotificationPreview';
import { OwnProfilPhotoOverview } from './OwnProfilPhotoOverview';

interface CreateMenuProps {
  handleOpenModalCreate: (type: ModalCreateTypes) => void;
}

const CreateMenu: FC<CreateMenuProps> = ({ handleOpenModalCreate }) => {
  const t = useTranslate();
  const anchorElCreate = useRef<HTMLButtonElement>(null);
  const [modalCreateOpen, setModalCreateOpen] = useState(false);
  const handleClickCreate = () => {
    setModalCreateOpen(true);
  };
  const handleClose = () => {
    setModalCreateOpen(false);
  };

  const navigate = useNavigate();

  const onClickStructure = () => {
    return handleOpenModalCreate('STRUCTURE');
  };

  return (
    <>
      <IconButton
        className={'create-menu'}
        onClick={handleClickCreate}
        ref={anchorElCreate}
        aria-controls={modalCreateOpen ? 'create-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={modalCreateOpen ? 'true' : undefined}
      >
        +
      </IconButton>
      <Popper
        open={modalCreateOpen}
        anchorEl={anchorElCreate.current}
        placement={'bottom-start'}
        transition
      >
        {({ TransitionProps }) => {
          return (
            <ClickAwayListener onClickAway={handleClose}>
              <Fade {...TransitionProps} timeout={350}>
                <Paper className={'create-menu__paper'}>
                  <>
                    <MenuItem onClick={() => handleOpenModalCreate('EVENT')}>
                      {t('event.createEvent')}
                    </MenuItem>
                    <MenuItem onClick={onClickStructure}>
                      {t('structure.createStructure')}
                    </MenuItem>
                  </>
                </Paper>
              </Fade>
            </ClickAwayListener>
          );
        }}
      </Popper>
    </>
  );
};

interface LoggedInUserAvatarMenuProps {
  user: User;
  socketReady: boolean;
}

export const LoggedInUserAvatarMenu = ({
  user,
  socketReady,
}: LoggedInUserAvatarMenuProps) => {
  const anchorElAccountMenu = useRef<HTMLButtonElement>(null);
  const [isOpenAccountMenu, , openModalAccountMenu, closeModalAccountMenu] =
    useBooleanToggle();
  const [openModalType, setOpenModalType] = useState<ModalCreateTypes | undefined>();
  const [isOpen, , openModal, closeModal] = useBooleanToggle();
  const t = useTranslate();
  const handleClickOpenAccountMenu = () => {
    openModalAccountMenu();
  };

  const handleClickCloseAccountMenu = () => {
    closeModalAccountMenu();
  };

  const handleOpenModalCreate = (type: ModalCreateTypes) => {
    setOpenModalType(type);
    openModal();
  };

  const navigate = useNavigate();

  const { authRepository } = useContextDependency();

  return (
    <>
      <NotificationPreview userId={user.id} />
      <MessagePreview userId={user.id} />
      <CreateMenu handleOpenModalCreate={handleOpenModalCreate} />
      {openModalType && (
        <ModalAddContent
          isOpen={isOpen}
          handleClose={closeModal}
          modalCreateType={openModalType}
        />
      )}
      <IconButton
        onClick={handleClickOpenAccountMenu}
        aria-controls={isOpen ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        ref={anchorElAccountMenu}
      >
        <OwnProfilPhotoOverview className={'avatar-user'} />
      </IconButton>
      <Popper
        open={isOpenAccountMenu}
        anchorEl={anchorElAccountMenu.current}
        placement={'bottom-start'}
        transition
      >
        {({ TransitionProps }) => {
          return (
            <ClickAwayListener onClickAway={handleClickCloseAccountMenu}>
              <Fade {...TransitionProps} timeout={350}>
                <Paper className={'account-menu__paper'}>
                  <span className={'-idPlayer'}>{user.userProfil.idPlayer}</span>
                  <Divider />
                  <MenuItem onClick={() => navigate(routesConfig.parametersUser.to())}>
                    <ListItemIcon>
                      <Settings fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                      {t('topbar.menuConnected.label.parameters')}
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      navigate(routesConfig.mercatoUserProfil.to(user.userProfil.id))
                    }
                  >
                    <ListItemIcon>
                      <Person fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                      {t('topbar.menuConnected.label.myProfil')}
                    </ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => navigate(routesConfig.relationPage.to())}>
                    <ListItemIcon>
                      <Groups fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                      {t('topbar.menuConnected.label.relations')}
                    </ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => navigate(routesConfig.myStructures.to())}>
                    <ListItemIcon>
                      <Apartment fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                      {t('topbar.menuConnected.label.myStructures')}
                    </ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => navigate(routesConfig.myEvents.to())}>
                    <ListItemIcon>
                      <Apartment fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                      {t('topbar.menuConnected.label.myEvents')}
                    </ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => navigate(routesConfig.activities.to())}>
                    <ListItemIcon>
                      <Games fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                      {t('topbar.menuConnected.label.activities')}
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    className={'link'}
                    onClick={() => authRepository.logout(true)}
                  >
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('topbar.menuConnected.label.logout')}</ListItemText>
                  </MenuItem>
                </Paper>
              </Fade>
            </ClickAwayListener>
          );
        }}
      </Popper>
    </>
  );
};
