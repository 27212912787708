import { Translation } from 'domain/translation/Translation';
import {
  TranslationOption,
  TranslationRepository,
} from 'domain/translation/Translation.repository';

import { i18n } from './i18n.nt';

export class TranslationI18nRepository implements TranslationRepository {
  translate(key: string, options?: TranslationOption, fallback?: string): Translation {
    return new Translation(i18n.t(key, fallback, options));
  }
}
