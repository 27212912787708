import { Campaign, Info, People, Scoreboard, SportsEsports } from '@mui/icons-material';
import { ConfParametersLink } from 'primary/Components/MenuParameters/MenusParametersUser.config';
import { routesConfig } from 'primary/Configs/Routes.config';
import React from 'react';
import { Params } from 'react-router-dom';

import { confNavBar } from './MenuEventViewPage';

type ListConfNavBar = {
  [key: string]: confNavBar[];
};
export const getLinkMenu = (
  subMenuItem: ConfParametersLink,
  params: Readonly<Params<string>>,
): string => {
  if (typeof subMenuItem.link === 'function') {
    if (subMenuItem.params) {
      const paramsParsed: string[] = [];
      subMenuItem.params.forEach((param) => {
        paramsParsed.push(params[param] as string);
      });
      if (paramsParsed.length !== subMenuItem.params?.length) {
        throw new Error('Paramètre de route non récupérés.');
      }
      return subMenuItem.link(...paramsParsed);
    }
    return subMenuItem.link();
  }
  return subMenuItem.link;
};

export const menusEventViewPage: ListConfNavBar = {
  main: [
    {
      label: 'event.eventViewPage.menu.links.infos',
      link: routesConfig.eventViewDetails.eventViewInfos.to,
      path: routesConfig.eventViewDetails.eventViewInfos.path,
      params: ['id'],
      icon: <Info />,
    },
    {
      label: 'event.eventViewPage.menu.links.annonces',
      link: routesConfig.eventViewDetails.eventViewAnnonces.to,
      path: routesConfig.eventViewDetails.eventViewAnnonces.path,
      params: ['id'],
      icon: <Campaign />,
    },
    {
      label: 'event.eventViewPage.menu.links.players',

      link: routesConfig.eventViewDetails.eventViewPlayers.to,
      path: routesConfig.eventViewDetails.eventViewPlayers.path,
      params: ['id'],
      icon: <People />,
      secondaryLink: true,
    },
    {
      label: 'event.eventViewPage.menu.links.scoreboard',

      link: routesConfig.eventViewDetails.eventViewScoreboard.to,
      path: routesConfig.eventViewDetails.eventViewScoreboard.path,
      params: ['id'],
      icon: <Scoreboard />,
    },
    {
      label: 'event.eventViewPage.menu.links.matchs',
      link: routesConfig.eventViewDetails.eventViewMatchs.to,
      path: routesConfig.eventViewDetails.eventViewMatchs.path,
      params: ['id'],
      secondaryLink: false,
      icon: <SportsEsports />,
    },
  ],
};
