import './ConversationItem.scss';

import { Delete } from '@mui/icons-material';
import { Badge, IconButton } from '@mui/material';
import classNames from 'classnames';
import { UserProfilView } from 'domain/user/UserProfilView';
import React from 'react';

import { Message } from '../../../../domain/message/Message';
import { useContextDependency } from '../../../hooks/useContextDependency';
import { useTranslate } from '../../../hooks/useTranslate';
import { sendEventToastMessage } from '../../Toast/Toast.helper';
import { WrapperOtherProfilPhotoOverview } from '../../User/WrapperOtherProfilPhotoOverview';
import { MessageItem } from '../messageItem/MessageItem';

interface ConversationItemProps {
  lastMessage: Message | undefined;
  userTo: UserProfilView;
  selected: boolean;
  onClick: () => void;

  idConversation: number;
  numberUnviewed: number;
  retry: () => void;
}

export const ConversationItem = ({
  lastMessage,
  userTo,
  selected,
  onClick,
  idConversation,
  retry,
  numberUnviewed,
}: ConversationItemProps) => {
  const t = useTranslate();
  const { messageRepository } = useContextDependency();

  return (
    <div
      onClick={onClick}
      className={classNames(
        'conversationItem',
        { '-selected': selected },
        { '-withUnviewed': numberUnviewed > 0 },
      )}
    >
      <div className={'conversationItem__photo'}>
        <WrapperOtherProfilPhotoOverview
          size={'small'}
          displayName={userTo.idPlayer}
          photo={userTo.publicPhotoUrl}
          className={classNames('photo', userTo?.neon?.getNeonBorderClassName())}
          idUser={userTo.idProfil}
        />
      </div>
      <div className={'conversationItem__lastMessage'}>
        <span className={'conversationItem__lastMessage-idPlayer'}>
          {userTo.idPlayer}
        </span>
        {lastMessage && <MessageItem message={lastMessage} isPreview />}
        {!lastMessage && (
          <span className={'-noMessage'}>{t('message.conversation.item.noMessage')}</span>
        )}
      </div>
      <div className={'conversationItem__deleteBtnContainer'}>
        <IconButton
          onClick={() =>
            messageRepository.deleteConversationById(idConversation).then(() => {
              retry();
              sendEventToastMessage(
                t('message.conversation.item.deleteSuccess'),
                'success',
              );
            })
          }
          className={'-deleteBtn'}
        >
          <Delete />
        </IconButton>
      </div>
      <Badge
        className={'-badgeNbrUnviewed'}
        badgeContent={numberUnviewed}
        color="primary"
      />
    </div>
  );
};
