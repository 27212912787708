import { Invoice } from 'domain/payment/Invoice';
import { InvoiceStatusEnum } from 'domain/payment/InvoiceStatusEnum';
import { toEnum } from 'helper/enum.helper';
import { RestProduct, toProduct } from 'secondary/payment/RestProduct';
import { RestUserView, toUserView } from 'secondary/user/RestUser';

export interface RestInvoice {
  id: number;
  total: number;
  idStripe: string;
  currency: string;
  url: string;
  status: InvoiceStatusEnum;
  user: RestUserView;
  product: RestProduct;
  date: Date;
}

export function toInvoice(invoice: RestInvoice) {
  return new Invoice(
    invoice.id,
    invoice.total / 100,
    invoice.idStripe,
    invoice.currency,
    invoice.url,
    toEnum(InvoiceStatusEnum, invoice.status),
    toUserView(invoice.user),
    toProduct(invoice.product),
    invoice.date,
  );
}
