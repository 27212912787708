import { ValidationStringLengthEnum } from 'domain/enums/ValidationStringLengthEnum';
import { IStructureMemberRepository } from 'domain/structureMember/StructureMember.repository';
import { CoupleButtonValidCancel } from 'primary/Components/CoupleButtonValidCancel/CoupleButtonValidCancel';
import { InputTextArea } from 'primary/Components/Input/InputTextArea';
import { InputWrapper } from 'primary/Components/Input/InputWrapper';
import { sendEventToastMessage } from 'primary/Components/Toast/Toast.helper';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate, UseTranslateReturn } from 'primary/hooks/useTranslate';
import { useYupValidationResolver } from 'primary/hooks/useYupValidationResolver';
import React from 'react';
import { useController, useForm } from 'react-hook-form';
import * as yup from 'yup';

interface JoinStructureFormProps {
  structureId: string;
  onSubmitted: () => void;
  onCancel: () => void;
}

export type JoinStructureFormData = {
  structure: string;
  message?: string;
};

const validationSchema = (t: UseTranslateReturn) =>
  yup.object({
    structure: yup.string().required(t('general.forms.errors.required')),
    message: yup
      .string()
      .max(
        ValidationStringLengthEnum.LONG,
        t('general.forms.errors.tooLong', {
          maxChar: ValidationStringLengthEnum.LONG,
        }),
      )
      .nullable(),
  });

const onSubmitJoinStructure =
  (
    structureMemberRepository: IStructureMemberRepository,
    onSubmitted: () => void,
    t: UseTranslateReturn,
    setIsLoading: (isLoading: boolean) => void,
  ) =>
  (data: JoinStructureFormData) => {
    setIsLoading(true);
    return structureMemberRepository
      .joinStructure(data)
      .then(() => {
        sendEventToastMessage(t('modals.joinStructure.success'), 'success');
      })
      .finally(() => {
        onSubmitted();
        setIsLoading(false);
      });
  };

export const JoinStructureForm = ({
  structureId,
  onSubmitted,
  onCancel,
}: JoinStructureFormProps) => {
  const { structureMemberRepository } = useContextDependency();
  const resolver = useYupValidationResolver(validationSchema);
  const [isLoading, setIsLoading] = React.useState(false);
  const {
    handleSubmit,
    control,
    register,
    formState: { isSubmitting, errors },
  } = useForm<JoinStructureFormData>({
    resolver,
    defaultValues: {
      structure: structureId,
    },
  });
  const { field: messageField } = useController({ control: control, name: 'message' });

  if (!structureId) return null;

  const t = useTranslate();
  const onSubmit = onSubmitJoinStructure(
    structureMemberRepository,
    () => onSubmitted(),
    t,
    setIsLoading,
  );
  return (
    <form className={'-JoinStructureForm'} onSubmit={handleSubmit(onSubmit)}>
      <InputWrapper
        errors={errors}
        Input={InputTextArea}
        isSubmitting={isSubmitting}
        label={t('message.input.placeholder')}
        inputProps={{
          ...messageField,
          minRows: 3,
        }}
      />
      <CoupleButtonValidCancel
        loading={isLoading}
        isTypeSubmit={true}
        onClickCancel={onCancel}
      />
    </form>
  );
};
