import { AxiosInstance } from 'axios';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';

import { Neon } from '../../domain/neon/Neon';
import { INeonRepository } from '../../domain/neon/Neon.repository';
import { RestNeon, toNeon } from './RestNeon';

export class NeonRepository extends AxiosProviderRepository implements INeonRepository {
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  getAllNeons(): Promise<Neon[]> {
    return this.axios
      .get(`/unpy/api/neons/all`)
      .then((response: any) => response.data.map((neon: RestNeon) => toNeon(neon)));
  }

  getNeonById(id: number): Promise<Neon> {
    return this.axios
      .get(`/unpy/api/neon/${id}`)
      .then((response: any) => toNeon(response.data));
  }
}
