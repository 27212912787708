import './NotificationPreview.scss';

import { Notifications } from '@mui/icons-material';
import {
  Badge,
  Button,
  ClickAwayListener,
  Fade,
  IconButton,
  Paper,
  Popper,
} from '@mui/material';
import { StompSubscription } from '@stomp/stompjs/esm6/stomp-subscription';
import classNames from 'classnames';
import {
  NotificationFilters,
  UnpyNotification,
} from 'domain/notification/UnpyNotification';
import { useFetchWithFilterPage } from 'primary/hooks/useFetchWithFilterPage';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  RestNotification,
  toNotification,
} from 'secondary/notification/RestNotification';

import {
  incrementNumberUnviewed,
  setNumberUnviewed,
} from '../../../domain/notification/store/NotificationSlice';
import { RootState } from '../../../store';
import { routesConfig } from '../../Configs/Routes.config';
import { useContextDependency } from '../../hooks/useContextDependency';
import { NotificationListInPreview } from '../../MessageList/NotificationList';

interface NotificationPreviewProps {
  userId: number;
}

export function useSubscribeTopic<R>(topic: string, onMessage: (message: R) => void) {
  const isSocketReady = useSelector((state: RootState) => state.socket.ready);
  const { socketRepository } = useContextDependency();

  useEffect(() => {
    let subscriber: StompSubscription | undefined;
    if (isSocketReady) {
      subscriber = socketRepository.subscribeTo<R>(topic, (notification) => {
        onMessage(notification);
      });
    }
    return () => {
      subscriber?.unsubscribe();
    };
  }, [isSocketReady]);
}

export const NotificationPreview = ({ userId }: NotificationPreviewProps) => {
  const anchorEl = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const numberUnviewed = useSelector(
    (state: RootState) => state.notification.numberUnviewed,
  );
  const { notificationRepository, authRepository } = useContextDependency();

  const defaultFilters: NotificationFilters = {
    userFrom: undefined,
    userTo: authRepository.currentUser?.id,
    types: [],
  };
  const {
    setFilters,
    activeFilters,
    fetchedResource,
    nextPage,
    loading,
    retry,
    allContents,
    addToCurrentPage,
  } = useFetchWithFilterPage<UnpyNotification, NotificationFilters>({
    keyStoreFilters: 'notification-preview',
    filters: defaultFilters,
    callApi: (filters, page) => notificationRepository.search(filters, page, 5),
    keepPageResult: true,
  });

  useSubscribeTopic<RestNotification>('/notification/message', (notification) => {
    addToCurrentPage(toNotification(notification));
    dispatch(incrementNumberUnviewed());
  });

  useSubscribeTopic<RestNotification>(
    `/notification/message/${userId}`,
    (notification) => {
      addToCurrentPage(toNotification(notification));
      dispatch(incrementNumberUnviewed());
    },
  );

  useEffect(() => {
    notificationRepository
      .getNumberUnviewed()
      .then((numberUnviewed) => dispatch(setNumberUnviewed(numberUnviewed)));
  }, []);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  return (
    <>
      <IconButton
        className={classNames('notification-menu', {
          '-someUnviewed': numberUnviewed > 0,
        })}
        onClick={handleClick}
        ref={anchorEl}
        aria-controls={open ? 'notification-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <Badge badgeContent={numberUnviewed} color="primary">
          <Notifications />
        </Badge>
      </IconButton>
      <Popper
        disablePortal={true}
        open={open}
        anchorEl={anchorEl.current}
        placement={'bottom'}
        transition
      >
        {({ TransitionProps }) => {
          if (!authRepository?.currentUser) return null;
          return (
            <ClickAwayListener onClickAway={handleClickAway}>
              <Fade {...TransitionProps} timeout={350}>
                <Paper className={'notificationPreview'}>
                  <NotificationListInPreview
                    ressourcesProps={{
                      allContents: allContents,
                      nextPage: nextPage,
                      loading: loading,
                      retry: retry,
                    }}
                    currentUserId={authRepository.currentUser.id}
                    isPreview
                    className={classNames(
                      'notificationPreview__list',
                      '--scrollBarContent',
                    )}
                  />
                  <div className={'-rowSeeMore'}>
                    <Button
                      onClick={() => {
                        navigate(routesConfig.notifications.to());
                        handleClickAway();
                      }}
                      variant={'contained'}
                      color={'primary'}
                    >
                      Tout voir
                    </Button>
                  </div>
                </Paper>
              </Fade>
            </ClickAwayListener>
          );
        }}
      </Popper>
    </>
  );
};
