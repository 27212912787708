import { UnpyEventView } from 'domain/event/UnpyEventView';

import { PlayerTeam } from '../event/PlayerTeam';
import { Structure } from '../structure/Structure';
import { TeamStatusEnum } from './TeamStatusEnum';
export type TeamFilters = {
  profilId?: number;
  eventId?: number;
};
export class Team {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly players: PlayerTeam[],
    public readonly status: TeamStatusEnum,
    public readonly structure?: Structure,
    public readonly event?: UnpyEventView,
  ) {}
}
