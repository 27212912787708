import './BracketView.scss';

import { MatchGroup } from 'domain/event/match/MatchGroup';
import { toBracketTournamentModel, UnpyMatchFilters } from 'domain/event/match/UnpyMatch';
import { TypeEventCodeEnum } from 'domain/event/TypeEventCodeEnum';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { FilterBracketRound } from 'primary/classements/Bracket/FilterBracketRound';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { NoMatchInfosBloc } from 'primary/events/components/matchs/view/NoMatchInfosBloc';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetchWithFilterPage } from 'primary/hooks/useFetchWithFilterPage';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

const Bracket = React.lazy(() =>
  import('primary/events/components/matchs/view/Bracket').then((module) => ({
    default: module.Bracket,
  })),
);

type BracketViewProps = {
  event: UnpyEvent | UnpyEventView;
};
export const BracketView = ({ event }: BracketViewProps) => {
  const { matchsRepository } = useContextDependency();
  const {
    setFilters,
    activeFilters,
    fetchedResource,
    nextPage,
    loading,
    retry,
    addToCurrentPage,
  } = useFetchWithFilterPage<MatchGroup, UnpyMatchFilters>({
    keyStoreFilters: 'matchs-bracket-view-filters',
    staticFilters: {
      idEvent: event.id,
      withMatchs: true,
    },
    filters: {},
    callApi: (filters, page) => matchsRepository.searchGroup(filters, page, -1),
  });

  const formattedMatchs = fetchedResource?.content
    ?.map((group) => ({
      ...group,
      matchs: group.matchs.map(toBracketTournamentModel),
    }))
    .flatMap((group) => group.matchs);
  const t = useTranslate();
  return (
    <div className={'bracketView'}>
      {loading && <LoadingScreen loading />}
      {!loading && !fetchedResource?.content?.length && (
        <NoMatchInfosBloc event={event} />
      )}
      <div className={'-header'}>
        {event.typeEvent.code === TypeEventCodeEnum.TOURNAMENT && (
          <div className={'-header'}>
            <FilterBracketRound
              totalRound={
                event?.totalParticipants
                  ? Math.ceil(Math.log(event.totalParticipants) / Math.log(2))
                  : undefined
              }
              currentFilter={activeFilters?.filters ?? {}}
              // @ts-ignore
              onChange={setFilters}
            />
          </div>
        )}
      </div>
      <div className={'-bracket'}>
        {formattedMatchs && (
          <React.Suspense fallback={<LoadingScreen loading />}>
            <Bracket event={event} formattedMatchs={formattedMatchs} retry={retry} />
          </React.Suspense>
        )}
      </div>
    </div>
  );
};
