import './ActionButton.scss';

import { Send } from '@mui/icons-material';
import { Button } from '@mui/material';
import { PRIVACY_CODE } from 'domain/privacy/ProfilPrivacy';
import {
  isPrivacyRestricted,
  isStructureProfil,
  isUserProfil,
} from 'domain/profil/Profil.func';
import { StructureProfil } from 'domain/profil/StructureProfil';
import { UserProfil } from 'domain/profil/UserProfil';
import { selectConnectedUserBlocked } from 'domain/relation/store/RelationSlice';
import { stopPropagationBeforeAction } from 'primary/Components/Profils/User/ActionButton/ActionButtonInviteStructure';
import { ModalSendMessageToStructure } from 'primary/Components/Profils/User/ActionButton/Modals/ModalSendMessageToStructure';
import { useBooleanToggle } from 'primary/hooks/useBooleanToggle';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { useSelector } from 'react-redux';

import { ModalSendMessage } from './Modals/ModalSendMessage';

interface ActionButtonSendMessageProps {
  profil: UserProfil | StructureProfil;
  withIcon?: boolean;
}

export const ActionButtonSendMessage = ({
  profil,
  withIcon,
}: ActionButtonSendMessageProps) => {
  const [isOpen, toggleOpen, , closeModal] = useBooleanToggle();
  const { authRepository } = useContextDependency();

  const t = useTranslate();
  const blocked = useSelector(selectConnectedUserBlocked);
  const alreadyBlocked = blocked?.find((b) => b.invited.id === profil.id);
  const isRestricted =
    !!alreadyBlocked || isPrivacyRestricted(profil, PRIVACY_CODE.SEND_MESSAGE);
  return (
    <>
      {isOpen && isUserProfil(profil) && (
        <ModalSendMessage
          handleClose={closeModal}
          isOpen={isOpen}
          name={profil.idPlayer}
          userId={profil.id}
        />
      )}
      {isOpen && isStructureProfil(profil) && (
        <ModalSendMessageToStructure
          handleClose={closeModal}
          isOpen={isOpen}
          name={profil.name || profil.structure?.name || ''}
          structureId={profil.structure?.id}
        />
      )}
      <Button
        color={'inherit'}
        variant={'text'}
        className={'-actionButton'}
        onClick={stopPropagationBeforeAction(toggleOpen)}
        disabled={isRestricted || !authRepository.currentUser}
      >
        {withIcon && <Send />}
        {t('profil.userProfilAction.sendMessage')}
      </Button>
    </>
  );
};
