import './ListGameSelectable.scss';

import React from 'react';

import { GAME_ACTIVE_LIST, GAME_IMAGES } from '../../../domain/game/GameActiveList';
import { ImageSelectable } from '../ImageSelectable/ImageSelectable';

interface ListGameSelectableProps {
  games?: GAME_ACTIVE_LIST[];
  action: (gameCode: GAME_ACTIVE_LIST) => void;
  gamesSelected?: GAME_ACTIVE_LIST[];
}

export const ListGameSelectable = ({
  games,
  action,
  gamesSelected,
}: ListGameSelectableProps) => {
  return (
    <div className={'listGameSelectable --scrollBarContent'}>
      {games?.map((gameCode) => (
        <ImageSelectable
          key={gameCode}
          onClick={() => action(gameCode)}
          alt={gameCode}
          imageUrl={GAME_IMAGES[gameCode]}
          isSelected={
            !!gamesSelected?.find((value) => value.toString() === gameCode.toString())
          }
        />
      ))}
    </div>
  );
};
