import { AxiosInstance } from 'axios';
import { PlayerTeam } from 'domain/event/PlayerTeam';
import { Pageable } from 'domain/pageable/Pageable';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';
import { toPlayerTeam } from 'secondary/event/RestPlayerTeam';
import { toPageable } from 'secondary/RestPageable';

import { Team, TeamFilters } from '../../domain/team/Team';
import { ITeamRepository } from '../../domain/team/Team.repository';
import { toTeam } from './RestTeam';

export class TeamRepository extends AxiosProviderRepository implements ITeamRepository {
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  search(filters: TeamFilters, page: number, nbPerPage: number): Promise<Pageable<Team>> {
    return this.axios
      .get('/unpy/api/teams/search', {
        params: {
          ...filters,
          page,
          nbPerPage,
        },
      })
      .then((response) => toPageable(response, toTeam));
  }
  getTeamById(teamId: number): Promise<Team> {
    return this.axios
      .get(`/unpy/api/team/${teamId}`)
      .then((response) => toTeam(response.data));
  }

  declineTeamInvitationEvent(response: boolean, teamId: number): Promise<void> {
    return this.axios.put(`/unpy/api/team/${teamId}/invitation/decline}`);
  }

  acceptTeamInvitationEvent(
    response: boolean,
    teamId: number,
    eventId: number,
  ): Promise<void> {
    return this.axios.put(`/unpy/api/team/${teamId}/invitation/accept/${eventId}`);
  }

  responseParticipationEvent(
    response: boolean,
    teamId: number,
    eventId: number,
  ): Promise<void> {
    return this.axios.put(
      `/unpy/api/team/${teamId}/event/${eventId}/register/${
        response ? 'accept' : 'decline'
      }`,
    );
  }

  deleteTeamForEvent(teamId: number, eventId: number): Promise<void> {
    return this.axios.delete(`/unpy/api/team/${teamId}/event/${eventId}/delete`);
  }

  getTeamsOfProfil(
    filters: TeamFilters,
    page: number,
    nbPerPage: number,
  ): Promise<Pageable<Team>> {
    return this.axios
      .get(`/unpy/api/team/profil`, {
        params: {
          ...filters,
          page,
          nbPerPage,
        },
      })
      .then((response) => toPageable(response, toTeam));
  }

  deleteMember(teamId: number, playerId: number): Promise<Team> {
    return this.axios
      .delete(`unpy/api/team/${teamId}/member/${playerId}/delete`)
      .then((response) => toTeam(response.data));
  }

  editRole(teamId: number, userId: number, role: string): Promise<PlayerTeam> {
    return this.axios
      .put(`unpy/api/team/edit/role`, {
        teamId: teamId,
        playerId: userId,
        roleCode: role,
      })
      .then((response) => toPlayerTeam(response.data));
  }

  leave(teamId: number): Promise<void> {
    return this.axios.put(`unpy/api/team/${teamId}/leave`);
  }

  exitTeam(id: number): Promise<void> {
    return this.axios.put(`unpy/api/team/${id}/leave`);
  }
}
