import './SocialNetworkFormsWrapper.scss';

import { Edit } from '@mui/icons-material';
import { TextField } from '@mui/material';
import { Network, socialNetworkList } from 'primary/network/network';
import React, { FC, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { useTranslate } from '../../hooks/useTranslate';
import { InputWrapper } from '../Input/InputWrapper';

interface SocialNetworkFormFieldProps {
  form: UseFormReturn<any>;
  network: Network;
  disabled?: boolean;
}

const SocialNetworkFormField: FC<SocialNetworkFormFieldProps> = ({
  form,
  network,
  disabled,
}) => {
  const t = useTranslate();

  return (
    <InputWrapper
      errors={form.formState.errors}
      inputClassName={'input-user-details '}
      labelClassName={'label-user-details '}
      isSubmitting={form.formState.isSubmitting}
      Input={TextField}
      inputProps={{
        placeholder: t('parameters.menus.profil.user.form.socialLinkPlaceholder', {
          network: network.value,
        }),
        disabled: form.formState.isSubmitting || disabled,
        ...form.register(network.value),
        variant: 'filled',
      }}
    />
  );
};

type SocialNetworkFormsWrapperProps = {
  form: any;
  initialValues?: any;
  disabled?: boolean;
};

export const SocialNetworkFormsWrapper: FC<SocialNetworkFormsWrapperProps> = ({
  form,
  initialValues,
  disabled,
}) => {
  const [displayedNetwork, setDisplayedNetwork] = useState<Network>(socialNetworkList[0]);

  return (
    <div className={'socialNetworkFormsWrapper'}>
      <h3>Réseaux sociaux</h3>
      <div className={'socialNetworkFormsWrapper__buttons'}>
        {socialNetworkList.map((network) => {
          // @ts-ignore
          const currentVal = form.watch(network.value);
          return (
            <div className={'-iconNetwork'} key={network.name}>
              {!!currentVal !== !!initialValues?.[network.value] && (
                <Edit fontSize={'small'} className={'-networkEditedIcon'} />
              )}
              {network.icon({
                onClick: () => setDisplayedNetwork(network),
                active: network.value === displayedNetwork.value,
                disabled: disabled,
              })}
            </div>
          );
        })}
      </div>
      <div className={'socialNetworkFormsWrapper__form'}>
        <SocialNetworkFormField
          key={displayedNetwork.name}
          disabled={disabled}
          network={displayedNetwork}
          form={form}
        />
      </div>
    </div>
  );
};
