import '../../Utils/Forms/UnpyForm.scss';

import { SwapHorizontalCircle } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, TextField } from '@mui/material';
import { ValidationStringLengthEnum } from 'domain/enums/ValidationStringLengthEnum';
import { IEventRepository } from 'domain/event/Event.repository';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import { canEditField, getInitialValueAppearence } from 'domain/event/UnpyEvent.func';
import { SocialNetworkFormsWrapper } from 'primary/Components/NetworkForms/SocialNetworkFormsWrapper';
import { Title } from 'primary/Components/Title/Title';
import { EventPhotoForm } from 'primary/events/forms/EventPhotoForm';
import { PreviewEditEventAppearence } from 'primary/Parameters/event/PreviewEditEventAppearence';
import { resizePropsFormData } from 'primary/Parameters/user/Profil/UserProfilAppearence';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { InputWrapper } from '../../Components/Input/InputWrapper';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useTranslate, UseTranslateReturn } from '../../hooks/useTranslate';
import { useYupValidationResolver } from '../../hooks/useYupValidationResolver';

export type EventAppearenceFormData = {
  name: string;
  resizeProps?: resizePropsFormData;
  image: string;
  imageFile: File;
  twitter: string;
  facebook: string;
  youtube: string;
  instagram: string;
  discord: string;
  twitch: string;
};

const validationSchema = (t: UseTranslateReturn) =>
  yup.object({
    name: yup
      .string()
      .max(
        ValidationStringLengthEnum.DEFAULT,
        t('general.forms.errors.tooLong', {
          maxChar: ValidationStringLengthEnum.DEFAULT,
        }),
      )
      .required(t('general.forms.errors.required')),
    image: yup
      .string()
      .max(
        ValidationStringLengthEnum.LONG,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
      )
      .nullable(),
    twitter: yup
      .string()
      .max(
        ValidationStringLengthEnum.LONG,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
      )
      .nullable(),
    facebook: yup
      .string()
      .max(
        ValidationStringLengthEnum.LONG,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
      )
      .nullable(),
    youtube: yup
      .string()
      .max(
        ValidationStringLengthEnum.LONG,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
      )
      .nullable(),
    instagram: yup
      .string()
      .max(
        ValidationStringLengthEnum.LONG,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
      )
      .nullable(),
    discord: yup
      .string()
      .max(
        ValidationStringLengthEnum.LONG,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
      )
      .nullable(),
    twitch: yup
      .string()
      .max(
        ValidationStringLengthEnum.LONG,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
      )
      .nullable(),
  });

export const useOnSubmit =
  (
    eventRepostory: IEventRepository,
    onSubmitted: (id: number) => void,
    idEvent: number,
    file: File,
    setLoading: (loading: boolean) => void,
  ) =>
  (data: EventAppearenceFormData) => {
    setLoading(true);
    eventRepostory
      .updateEventAppearence(data, idEvent, file)
      .then((event) => onSubmitted(event.id))
      .finally(() => setLoading(false));
  };

interface EventCreateFormProps {
  onCreated: (id: number) => void;
  event: UnpyEvent;
}

export const EventAppearenceForm: FC<EventCreateFormProps> = ({ onCreated, event }) => {
  const resolver = useYupValidationResolver(validationSchema);
  const form = useForm<EventAppearenceFormData>({
    resolver,
    defaultValues: getInitialValueAppearence(event),
  });
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { isSubmitting, errors },
  } = form;
  const { eventRepository } = useContextDependency();
  const [file, setFile] = useState<File>();
  const [loading, setLoading] = useState(false);
  const t = useTranslate();
  const onSubmit = useOnSubmit(
    eventRepository,
    onCreated,
    event.id,
    file as File,
    setLoading,
  );

  const watchedValues = form.watch();

  return (
    <Box className={'unpy-form'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Title
                  title={t('event.form.previewAppearence.titleForm')}
                  level={2}
                  accent
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <EventPhotoForm
                  form={form}
                  onUploadFile={setFile}
                  fileUploaded={file}
                  isdefaultImage={event.defaultImage}
                  initialPath={event.image}
                  disabled={!canEditField('image', event)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputWrapper
                  className={'eventAppearenceForm__field'}
                  label={t('event.form.labels.name')}
                  errors={errors}
                  isSubmitting={isSubmitting}
                  Input={TextField}
                  inputProps={{
                    placeholder: t('event.form.placeholders.name'),
                    variant: 'filled',
                    disabled: !canEditField('name', event),
                    ...register('name', { required: true }),
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <SocialNetworkFormsWrapper
                  form={form}
                  initialValues={getInitialValueAppearence(event)}
                  disabled={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} className={'-iconCol'}>
            <SwapHorizontalCircle />
            <LoadingButton
              variant={'contained'}
              color={'primary'}
              type={'submit'}
              loading={loading}
              disabled={loading}
            >
              {t('event.form.editBtn')}
            </LoadingButton>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Title
              title={t('event.form.previewAppearence.titlePreview')}
              level={2}
              accent
            />
            <PreviewEditEventAppearence
              event={event}
              draftedValues={watchedValues}
              draftImage={file}
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
