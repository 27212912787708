import './MenusParameters.scss';

import { Box, Divider, Hidden } from '@mui/material';
import classNames from 'classnames';
import { useBooleanToggle } from 'primary/hooks/useBooleanToggle';
import React, { FC } from 'react';
import { Link, matchPath, Params, useLocation, useParams } from 'react-router-dom';

import { useTranslate } from '../../hooks/useTranslate';
import { BottomNavParameters } from './BottomNavParameters';
import { ConfParametersLink, ListConfParametersMenu } from './MenusParametersUser.config';

type ParametersMenuProps = {
  confs: ListConfParametersMenu;
  withTimeline?: boolean;
  header?: React.ReactNode;
};

export const getLink = (
  subMenuItem: ConfParametersLink,
  params: Readonly<Params<string>>,
): string => {
  if (typeof subMenuItem.link === 'function') {
    if (subMenuItem.params) {
      const paramsParsed: string[] = [];
      subMenuItem.params.forEach((param) => {
        paramsParsed.push(params[param] as string);
      });
      if (paramsParsed.length !== subMenuItem.params?.length) {
        throw new Error('Paramètre de route non récupérés.');
      }
      return subMenuItem.link(...paramsParsed);
    }
    return subMenuItem.link();
  }
  return subMenuItem.link;
};

export const MenusParameters: FC<ParametersMenuProps> = ({
  confs,
  withTimeline,
  header,
}) => {
  const { pathname } = useLocation();
  const params = useParams<{ idStructure: string }>();
  const t = useTranslate();
  const [isMenuOpen, toggleMenu] = useBooleanToggle(true);
  const [isTimelineOpen, toggleTimeline, openTimeline, closeTimeline] =
    useBooleanToggle(true);

  const location = useLocation();

  return (
    <>
      <Hidden smDown>
        <div className={classNames('menuParameters__desktop')}>
          {!!header && (
            <div className={'-header'}>
              {header}
              <Divider />
            </div>
          )}
          <div
            className={classNames(
              'menuParameters__desktop-menu',
              '--scrollBarContent',
              {
                '-withTimeline': withTimeline,
              },
              { isClose: !isMenuOpen },
            )}
          >
            {confs.parametersItems.map((menu, index) => (
              <>
                <div key={menu.title} className={'menuParameters__desktop-column'}>
                  <h4
                    className={classNames('title', {
                      '-active': pathname.includes(menu.path),
                    })}
                  >
                    {menu.title}
                  </h4>
                  <nav>
                    {menu.subMenu.map((subMenuItem) => {
                      return (
                        <Link
                          className={classNames('item', {
                            '-active': matchPath(
                              { path: subMenuItem.path as unknown as string },
                              pathname,
                            ),
                            '-icon': !!subMenuItem.icon,
                          })}
                          key={subMenuItem.label}
                          to={getLink(subMenuItem, params)}
                        >
                          {!!subMenuItem.icon && (
                            <img
                              alt={'logo-unpy-up'}
                              src={subMenuItem.icon as string}
                              className={'logo-up -logo'}
                            />
                          )}
                          {subMenuItem.label}
                        </Link>
                      );
                    })}
                  </nav>
                </div>
              </>
            ))}
          </div>
        </div>
      </Hidden>

      <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}>
        <div className={'menuParameters__mobile'}>
          <BottomNavParameters confs={confs} />
        </div>
      </Box>
    </>
  );
};
