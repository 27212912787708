import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Relation } from 'domain/relation/Relation';
import { RelationTypeEnum } from 'domain/relation/RelationTypeEnum';
import { RootState } from 'store';

type InitialRelationSliceState = {
  relations?: Relation[];
};

export const RelationSlice = createSlice({
  name: 'user',
  initialState: {
    relations: undefined,
  } as InitialRelationSliceState,
  reducers: {
    setRelation: (state, action: PayloadAction<Relation[]>) => {
      state.relations = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setRelation } = RelationSlice.actions;

export default RelationSlice.reducer;

const selectSelf = (state: RootState) => state;
export const selectConnectedUserFriend = createSelector(selectSelf, (state) =>
  state.relations.relations
    ? state.relations.relations.filter((r) => r.type === RelationTypeEnum.FRIEND)
    : undefined,
);

export const selectConnectedUserBlocked = createSelector(selectSelf, (state) =>
  state.relations.relations
    ? state.relations.relations.filter((r) => r.type === RelationTypeEnum.BLOCK)
    : undefined,
);
