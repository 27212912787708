import './ModalAddContent.scss';

import { Box } from '@mui/material';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React, { FC, ReactNode } from 'react';

import ModalCreateEvent from '../../ModalCreate/ModalCreateEvent';
import { ModalCreateStructure } from '../../ModalCreate/ModalCreateStructure';

interface ModalAddContentProps {
  isOpen: boolean;
  handleClose: () => void;
  modalCreateType: ModalCreateTypes;
}

export type ModalCreateTypes = keyof ModalCreateType;

interface ModalCreateType {
  EVENT: (handleClose: () => void) => ReactNode;
  STRUCTURE: (handleClose: () => void) => ReactNode;
}

const MODAL_CREATE: ModalCreateType = {
  EVENT: (handleClose: () => void) => <ModalCreateEvent handleClose={handleClose} />,
  STRUCTURE: (handleClose: () => void) => (
    <ModalCreateStructure handleClose={handleClose} />
  ),
};

export const ModalAddContent: FC<ModalAddContentProps> = ({
  isOpen,
  handleClose,
  modalCreateType,
}) => {
  return (
    <ModalContainer
      handleClose={handleClose}
      isOpen={isOpen}
      content={
        <Box className={'modal-add-content'}>
          {MODAL_CREATE[modalCreateType](handleClose)}
        </Box>
      }
    />
  );
};
