import './LeftPanel.scss';

import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import classNames from 'classnames';
import {
  KeyDomainFilters,
  selectIsPanelOpenByDomain,
  setPanelOpenByDomain,
} from 'domain/filters/FiltersSlice';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

interface LeftPanelProps {
  className?: string;
  IconOpen?: ReactNode;
  initOpen?: boolean;
  keyDomain: KeyDomainFilters;
}

export const useLocalStorageState = (key: string) => {
  const initValue = localStorage.getItem(key);
  const [storedValue, setStoredValue] = useState(initValue === 'true');

  const enhancedSetValue = (value: boolean) => {
    localStorage.setItem(key, value.toString());
    setStoredValue(value);
  };

  return [storedValue, enhancedSetValue];
};

export const ANIMATION_BUMP_LEFT_PANEL = 'add-filter-bump';

export const LeftPanel: FC<LeftPanelProps> = ({
  children,
  className,
  IconOpen,
  keyDomain,
}) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state: RootState) =>
    selectIsPanelOpenByDomain(state, keyDomain),
  );
  const [isBouncing, setIsBouncing] = useState(false);

  useEffect(() => {
    const eventListener = (() => {
      setIsBouncing(true);
      setTimeout(() => {
        setIsBouncing(false);
      }, 500);
      // eslint-disable-next-line no-undef
    }) as EventListener;

    document.addEventListener(ANIMATION_BUMP_LEFT_PANEL, eventListener);
    return () => document.removeEventListener(ANIMATION_BUMP_LEFT_PANEL, eventListener);
  }, []);

  const toggleIsOpen = () => {
    dispatch(setPanelOpenByDomain({ key: keyDomain, isOpen: !isOpen }));
  };
  const rootDiv = document.getElementById('root');

  const render = () =>
    createPortal(
      <div
        className={classNames(
          'leftPanel',
          { '-open': isOpen, '-bounce': isBouncing },
          className,
        )}
      >
        <div className={'leftPanel__control'} onClick={toggleIsOpen}>
          {!isOpen && <ArrowRight />}
          {isOpen && <ArrowLeft />}
          {IconOpen && (
            // @ts-ignore
            <IconOpen className={'-icon'} />
          )}
        </div>
        <div className={classNames('leftPanel__content', '--scrollBarContent')}>
          {children}
        </div>
      </div>,
      rootDiv as Element,
    );

  return render();
};
