import { Download } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Invoice } from 'domain/payment/Invoice';
import { Translate } from 'domain/translation/Translation.repository';
import { formatDate } from 'helper/date.helper';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { usePaginationFromResource } from 'primary/hooks/usePaginationFromResource';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

const formatInvoiceToRowData = (invoices: Invoice[], t: Translate) => {
  return invoices.map((invoice) => ({
    id: invoice.id,
    total: `${invoice.total} ${invoice.currency}`,
    date: formatDate(invoice.date, 'readable(with hour)'),
    status: t(`enum.invoiceStatus.${invoice.status}`),
    url: invoice.url,
  }));
};

export const InvoicesList = () => {
  const t = useTranslate();
  const { invoiceRepository } = useContextDependency();
  const [invoices] = useRetrieveFromDomain(() => invoiceRepository.getAll(), undefined);
  const {
    setCurrentPage,
    currentPage,
    pageCount,
    elementToDisplay,
    setNbPerPAge,
    nbPerPage,
  } = usePaginationFromResource(10, invoices || []);
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: t('invoice.datatable.id'),
      flex: 1,
      headerClassName: '-header',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'total',
      headerName: t('invoice.datatable.total'),
      flex: 1,
      headerClassName: '-header',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'datetime-local',
      headerName: t('invoice.datatable.date'),
      flex: 1,
      headerClassName: '-header',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'status',
      headerName: t('invoice.datatable.status'),
      flex: 1,
      headerClassName: '-header',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'url',
      headerName: t('invoice.datatable.download'),
      headerClassName: '-header',
      headerAlign: 'center',
      sortable: false,
      flex: 2,
      renderCell: (value: GridRenderCellParams) => {
        return (
          <div className={'-actions'}>
            <IconButton
              disabled={!value?.value}
              title={t('invoice.datatable.downloadTitle')}
              onClick={() => window.open(value?.value, '_blank')}
              color={'primary'}
            >
              <Download />
            </IconButton>
          </div>
        );
      },
    },
  ];

  return (
    <div className={'invoicesList'}>
      <div className={'invoicesList__list'}>
        <DataGrid
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector
          disableEval
          disableVirtualization
          pagination
          paginationModel={{
            page: currentPage,
            pageSize: nbPerPage,
          }}
          onPaginationModelChange={(params) => setCurrentPage(params.page)}
          className={'-parameters'}
          autoHeight
          hideFooter
          sx={{ color: 'white' }}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                {t('invoice.list.noContent')}
              </Stack>
            ),
          }}
          rows={invoices ? formatInvoiceToRowData(invoices, t) : []}
          columns={columns}
        />
      </div>
    </div>
  );
};
