import { MatchGroup } from 'domain/event/match/MatchGroup';
import { RestUnpyMatch, toUnpyMatch } from 'secondary/event/match/RestUnpyMatch';
import { RestEventView, toUnpyEventView } from 'secondary/event/RestEventView';

export interface RestMatchGroup {
  id: number;
  title: string;
  event: RestEventView;
  phaseOrder: number;
  matchs: RestUnpyMatch[];
  totalMatchsInGroup?: number;
}

export function toMatchGroup(restMatchGroup: RestMatchGroup): MatchGroup {
  return new MatchGroup(
    restMatchGroup.id,
    restMatchGroup.title,
    toUnpyEventView(restMatchGroup.event),
    restMatchGroup.phaseOrder,
    restMatchGroup?.matchs?.map(toUnpyMatch),
    restMatchGroup.totalMatchsInGroup,
  );
}
