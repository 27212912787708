import { InvitationLinkTypeEnum } from 'domain/invitationLink/InvitationLinkTypeEnum';
import { UserView } from 'domain/user/UserView';
import { RestUserView } from 'secondary/user/RestUser';

export type InvitationLinkFilters = {
  idRessource: number;
  typeInvitationLink: InvitationLinkTypeEnum;
  createDate: Date;
  creator: RestUserView;
};

export class InvitationLink {
  constructor(
    public readonly id: number,
    public readonly uuid: string,
    public readonly idRessource: number,
    public readonly typeInvitationLink: InvitationLinkTypeEnum,
    public readonly createDate: Date,
    public readonly isVisited: boolean,
    public readonly numberCompleted: number,
    public readonly maxToComplete: number,
    public readonly creator: UserView,
  ) {}
}
