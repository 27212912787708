import { Country } from '../../domain/country/Country';

export interface RestCountry {
  id: number;
  libelle: string;
  code: string;
}

export function toCountry(restCountry: RestCountry) {
  return new Country(restCountry.id, restCountry.libelle, restCountry.code);
}
