import './TeamCreated.scss';

import { CheckOutlined, Close } from '@mui/icons-material';
import { Button } from '@mui/material';
import { TypeTeamCodeEnum } from 'domain/event/TypeTeamCodeEnum';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { Team } from 'domain/team/Team';
import { EventRegisterFormData } from 'primary/Components/Event/RegisterEventFormStep/FormStepPlayers';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

type FormStepValidateProps = {
  previousStep: (data: any) => void;
  data: EventRegisterFormData;
  eventId: string;
  teamCreated: Team;
  handleClose: () => void;
  event: UnpyEvent & UnpyEventView;
};
export const FormStepValidate = ({
  previousStep,
  data,
  eventId,
  teamCreated,
  event,
  handleClose,
}: FormStepValidateProps) => {
  const { authRepository, eventRepository } = useContextDependency();
  const t = useTranslate();
  return (
    <div className={'teamCreated'}>
      <div className={'teamCreated__icon'}>
        <CheckOutlined />
      </div>
      <div className={'teamCreated__message'}>
        {event.typeTeam?.code === TypeTeamCodeEnum.SOL && (
          <>
            {event.freeRegister && <span>{t('formStepper.creatingSuccess')}</span>}
            {!event.freeRegister && (
              <span>{t('formStepper.creatingSuccessWaitValidate')}</span>
            )}
          </>
        )}
        {event.typeTeam?.code !== TypeTeamCodeEnum.SOL && (
          <>
            {event.freeRegister && (
              <span>{t('formStepper.creatingSuccessWaitPlayer')}</span>
            )}
            {!event.freeRegister && (
              <span>{t('formStepper.creatingSuccessWaitPlayerAndValidate')}</span>
            )}
          </>
        )}
      </div>
      <div className={'teamCreated__actions'}>
        <Button
          variant={'outlined'}
          color={'primary'}
          onClick={handleClose}
          className={'cancel'}
        >
          <Close />
          {t('formStepper.finishAndClose')}
        </Button>
      </div>
    </div>
  );
};
