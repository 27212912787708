import './Mercato.scss';

import { useSelectionGameListPage } from 'primary/Mercato/Mercato.helper';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import { GAME_ACTIVE_LIST } from '../../domain/game/GameActiveList';
import { GameSelectionCaroussel } from '../Components/GameSelectionCaroussel/GameSelectionCaroussel';
import { LayoutPageListFitlered } from '../Components/Layout/Pages/LayoutPageListFiltered';
import { Section } from '../Components/Section/Section';
import { useTranslate } from '../hooks/useTranslate';
import { UserProfilList } from '../ProfilList/UserProfilList';

type MercatoGameCarrousselProps = {
  selectedGames: GAME_ACTIVE_LIST[];
  handleSelection: (value: string, type: 'ADD' | 'REMOVE') => void;
};
export const MercatoGameCarroussel = ({
  selectedGames,
  handleSelection,
}: MercatoGameCarrousselProps) => {
  const t = useTranslate();

  return (
    <div className={'caroussel'}>
      <h1>{t('mercato.titleCaroussel')}</h1>
      <GameSelectionCaroussel
        infinite
        selectedValues={selectedGames}
        slidesToScroll={1}
        rows={2}
        slidePerRow={4}
        onClick={(value, type: 'ADD' | 'REMOVE') => {
          handleSelection(value, type);
        }}
        center
      />
    </div>
  );
};

export const MercatoPlayer: FC = () => {
  const t = useTranslate();
  const { selectedGamesCode, handleSelection } =
    useSelectionGameListPage('mercatoPlayer');
  const teamCreating = useSelector((state: RootState) => state.teamCreating.teamCreating);

  return (
    <div className={'mercatoPlayer'}>
      <Section className={'mercatoPlayer__section mercatoPlayer__section-1'}>
        <LayoutPageListFitlered
          mainFilterContent={
            !teamCreating && (
              <MercatoGameCarroussel
                selectedGames={selectedGamesCode}
                handleSelection={handleSelection}
              />
            )
          }
          listContent={
            <div className={'mercatoPlayer__listPlayers'}>
              <UserProfilList
                title={
                  <h1>
                    {teamCreating
                      ? t('mercato.titleComposeTeam')
                      : t('mercato.titleSearchPlayer')}
                  </h1>
                }
                gamesSelected={selectedGamesCode}
                filters={
                  teamCreating
                    ? {
                        games: selectedGamesCode,
                        idStructure: teamCreating?.data?.structure,
                      }
                    : undefined
                }
              />
            </div>
          }
        />
      </Section>
    </div>
  );
};
