import './MenusParameters.scss';

import BottomNavigation from '@mui/material/BottomNavigation/BottomNavigation';
import React, { FC } from 'react';

interface BottomNavActionsProps {
  confs: ListConfParametersMenu;
}

import { BottomNavItem } from './BottomNavItem';
import { ListConfParametersMenu } from './MenusParametersUser.config';

export const BottomNavParameters: FC<BottomNavActionsProps> = ({ confs }) => {
  const [value, setValue] = React.useState(0);

  return (
    <BottomNavigation
      showLabels
      value={value}
      className={'bottomNav'}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
    >
      {confs.parametersItems.map((conf, index) => (
        <BottomNavItem key={conf.title} conf={conf} />
      ))}
    </BottomNavigation>
  );
};
