import { PermissionStructure } from '../permissionStructure/PermissionStructure';
import { RolesStructureEnum } from '../security/RolesStructureEnum';

export class RoleStructure {
  constructor(
    public readonly id: number,
    public readonly rang: number,
    public readonly code: RolesStructureEnum,
    public readonly permissions: PermissionStructure[],
  ) {}
}
