import { InvoiceStatusEnum } from 'domain/payment/InvoiceStatusEnum';
import { Product } from 'domain/payment/Product';
import { UserView } from 'domain/user/UserView';

export class Invoice {
  constructor(
    public readonly id: number,
    public readonly total: number,
    public readonly idStripe: string,
    public readonly currency: string,
    public readonly url: string,
    public readonly status: InvoiceStatusEnum,
    public readonly user: UserView,
    public readonly product: Product,
    public readonly date: Date,
  ) {}
}
