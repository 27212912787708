import { NotificationTypeEnum } from 'domain/notification/NotificationTypeEnum';
import { UnpyNotification } from 'domain/notification/UnpyNotification';

export type CATEGORY_NOTIFICATION = 'event' | 'mercato' | 'social' | 'divers';
export const CATEGORY_NOTIFICATION_MAP: Record<
  CATEGORY_NOTIFICATION,
  NotificationTypeEnum[]
> = {
  mercato: [
    NotificationTypeEnum.INVITATION_STRUCTURE,
    NotificationTypeEnum.INVITATION_STRUCTURE_RESPONSE,
    NotificationTypeEnum.JOIN_STRUCTURE,
    NotificationTypeEnum.JOIN_STRUCTURE_FROM_INVITATION_LINK,
  ],
  social: [
    NotificationTypeEnum.INVITATION_FRIEND,
    NotificationTypeEnum.INVITATION_FRIEND_RESPONSE,
  ],
  event: [
    NotificationTypeEnum.INVITATION_TEAM_EVENT,
    NotificationTypeEnum.PARTICIPATION_EVENT,
    NotificationTypeEnum.REVERT_EVENT_PLACE,
    NotificationTypeEnum.MAJ_CONFIRM_EVENT,
    NotificationTypeEnum.EVENT_TO_CONFIRM,
    NotificationTypeEnum.EVENT_CANCELED,
    NotificationTypeEnum.EVENT_START,
    NotificationTypeEnum.EVENT_DELAYED,
    NotificationTypeEnum.EVENT_FINISHED,
    NotificationTypeEnum.EVENT_CANCEL_DELAYED,
    NotificationTypeEnum.EVENT_SUBSCRIPTION_CLOSE,
    NotificationTypeEnum.MAJ_DECLINE_EVENT,
  ],
  divers: [NotificationTypeEnum.NEW_MESSAGE],
};

export const sortNotificationByCategory = (
  notifications: UnpyNotification[],
): Record<CATEGORY_NOTIFICATION, UnpyNotification[]> => {
  return Object.keys(CATEGORY_NOTIFICATION_MAP).reduce((acc, category) => {
    acc[category as CATEGORY_NOTIFICATION] = notifications.filter((notification) =>
      CATEGORY_NOTIFICATION_MAP[category as CATEGORY_NOTIFICATION].includes(
        notification.type,
      ),
    );
    return acc;
  }, {} as Record<CATEGORY_NOTIFICATION, UnpyNotification[]>);
};
