import {
  Translate,
  TranslationOption,
} from '../../domain/translation/Translation.repository';
import { useContextDependency } from './useContextDependency';

export type UseTranslateReturn = Translate;
export type UseTranslate = () => UseTranslateReturn;
export const useTranslate: UseTranslate = () => {
  const { translationRepository } = useContextDependency();
  return (key: string, option?: TranslationOption, fallback?: string) =>
    translationRepository.translate(key, option, fallback).get();
};
