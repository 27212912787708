export const toEnum = <T>(
  enumeration: T,
  value?: string,
  errorString?: string,
): T[keyof T] => {
  if (!value) throw new Error(errorString ?? 'Element is not defined');
  const enumValue = enumeration[value as keyof typeof enumeration];
  if (!enumValue) throw new Error(`${value} is not valid`);
  return enumValue;
};

export const toSafeEnum = <T>(
  enumeration: T,
  value?: string,
  errorString?: string,
): T[keyof T] | undefined => {
  if (value !== undefined) return toEnum(enumeration, value, errorString);
};

export function enumKeys<O extends object>(obj: O): (keyof O)[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as (keyof O)[];
}
