import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

export const useRetrieveFromDomainWithTransformer = <T, U = T>(
  retrieveFromDomain: () => Promise<T>,
  defaultStateValue: U,
  transform: (value: T) => U,
  condition = true,
): [U, Dispatch<SetStateAction<U>>, boolean, () => void] => {
  const [elementFromDomain, setElementFromDomain] = useState<U>(defaultStateValue);
  const [fetching, setFetching] = useState<boolean>(condition);

  const fetch = () => {
    setFetching(true);
    return retrieveFromDomain()
      .then((element) => {
        setFetching(false);
        setElementFromDomain(transform(element));
      })
      .finally(() => {
        setFetching(false);
      });
  };

  useEffect(() => {
    if (condition) {
      fetch().finally(() => {
        setFetching(false);
      });
    }
  }, [condition]);

  return [elementFromDomain, setElementFromDomain, fetching, fetch];
};

export const useRetrieveFromDomain = <T>(
  retrieveFromDomain: () => Promise<T>,
  defaultStateValue: T,
  condition = true,
): [T, Dispatch<SetStateAction<T>>, boolean, () => void] => {
  return useRetrieveFromDomainWithTransformer(
    retrieveFromDomain,
    defaultStateValue,
    (value) => value,
    condition,
  );
};

export const useRetrieveFromDomainToContextWithTransformer = <T, U = T | undefined>(
  retrieveFromDomain: () => Promise<T>,
  defaultStateValue: U | undefined,
  transform: (value: T) => U,
  condition = true,
  action: any,
  selector: (state: RootState) => T | any,
): [boolean, () => void, U | undefined] => {
  const [fetching, setFetching] = useState<boolean>(condition);
  const value = useSelector(selector);
  const dispatch = useDispatch();
  const fetch = () =>
    retrieveFromDomain()
      .then((element) => {
        setFetching(false);
        dispatch(action(transform(element)));
      })
      .finally(() => {
        setFetching(false);
      });

  useEffect(() => {
    if (condition && value === undefined) {
      fetch().finally(() => {
        setFetching(false);
      });
    }
  }, [condition, value]);
  return [fetching, fetch, value];
};

export const useRetrieveFromDomainToContext = <T>(
  retrieveFromDomain: () => Promise<T>,
  defaultStateValue: T | undefined,
  condition = true,
  action: (value: T) => void,
  selector: (state: RootState) => T | any,
): [boolean, () => void, T | undefined] => {
  return useRetrieveFromDomainToContextWithTransformer(
    retrieveFromDomain,
    defaultStateValue,
    (value) => value,
    condition,
    action,
    selector,
  );
};
