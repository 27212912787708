import { BackgroundImageView } from 'domain/backgroundImage/BackgroundImageView';
import { Country } from 'domain/country/Country';
import { Platform } from 'domain/platform/Platform';

import { Neon } from '../neon/Neon';

export class UserProfilView {
  constructor(
    public readonly id: number,
    public readonly idProfil: number,
    public readonly idPlayer: string,
    public readonly publicPhotoUrl: string,
    public readonly defaultPhoto: boolean,
    public readonly backgroundImage?: BackgroundImageView,
    public readonly neon?: Neon,
    public readonly country?: Country,
    public readonly platforms?: Platform[],
  ) {}
}
