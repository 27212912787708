import {
  Country,
  MercatoAnnouncementView,
  Platform,
  TypeAnnouncement,
} from '../../domain/mercatoAnnouncement/MercatoAnnouncementView';
import { NeonColor } from '../../primary/Components/Neon/Neon';

export interface RestMercatoAnnouncementView {
  id: number;
  createdAt: string;
  platforms: string[];
  neonColor: string;
  username: string;
  profilePhoto: string;
  country: string;
  games: string[];
  backgroundImage: string;
  type: string;
}

export function toMercatoAnnouncementView(
  restMercatoAnnouncement: RestMercatoAnnouncementView,
): MercatoAnnouncementView {
  const {
    id,
    createdAt,
    backgroundImage,
    country,
    games,
    neonColor,
    username,
    profilePhoto,
    platforms,
    type,
  } = restMercatoAnnouncement;
  return new MercatoAnnouncementView(
    id,
    new Date(createdAt),
    platforms as Platform[],
    neonColor as NeonColor,
    username,
    profilePhoto,
    country as Country,
    games,
    backgroundImage,
    type as TypeAnnouncement,
  );
}
