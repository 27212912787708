import { Cancel, Check } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { InvitationLink } from 'domain/invitationLink/InvitationLink';
import { StructureProfil } from 'domain/profil/StructureProfil';
import { StructureMember } from 'domain/structureMember/StructureMember';
import { KeyValue } from 'primary/Components/KeyValue/KeyValue';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { PreviewCardProfil } from 'primary/Components/Profils/PreviewCardProfil';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
interface ConsumeStructureInvitationLinkProps {
  link: InvitationLink;
  handleClose: () => void;
  nextStep: (data?: any) => void;
  data: { member: StructureMember };
}
export const ConsumeStructureInvitationLink = ({
  link,
  handleClose,
  nextStep,
}: ConsumeStructureInvitationLinkProps) => {
  const [isJoiningStructure, setIsJoiningStructure] = useState<boolean>(false);
  const { structureRepository, authRepository, structureMemberRepository } =
    useContextDependency();
  const [structure, , fetching] = useRetrieveFromDomain(
    () => structureRepository.getStructureById(link.idRessource),
    undefined,
  );
  const t = useTranslate();
  const handleJoinStructure = () => {
    setIsJoiningStructure(true);
    structureMemberRepository
      .createMemberFromLink(link.uuid)
      .then((member: StructureMember) => {
        nextStep({ member: member });
      })
      .finally(() => {
        setIsJoiningStructure(false);
      });
  };

  const valueOrNoContent = (value: string | number | undefined) =>
    value?.toString() || t('general.keyValueNoValue');

  if (!structure?.structureProfil) return <LoadingScreen loading />;
  const alreadyMember = structure.members.find(
    (m) => m.user.id === authRepository.currentUser?.id,
  );

  if (alreadyMember) {
    return (
      <div className={'-alreadyMember'}>
        <Cancel className={'-icon'} color={'warning'} />
        <span>{t('invitationLink.consumedModal.structure.alreadyMember')}</span>
      </div>
    );
  }
  return (
    <div className={'consumeStructureInvitationLink'}>
      <h2>
        {t('invitationLink.consumeModal.structure.title', {
          structureName: structure.name,
        })}
      </h2>
      {fetching && !structure && <LoadingScreen loading />}
      {!fetching && structure && (
        <div className={'-infosStructure'}>
          <div className={'-cardProfil'}>
            <PreviewCardProfil
              className={'-itemLayoutList'}
              key={structure.id}
              profil={structure.structureProfil as StructureProfil}
              noAction
            />
          </div>
          <div className={'-infosProfil'}>
            <KeyValue
              alignStart
              accent
              keyValue={t('invitationLink.consumeModal.structure.nbTournamentOranized')}
              value={valueOrNoContent(
                structure.structureProfil.unpyStatistiques?.nbEventAsCreator,
              )}
            />
            <KeyValue
              alignStart
              accent
              keyValue={t(
                'invitationLink.consumeModal.structure.nbTournamentParticipate',
              )}
              value={valueOrNoContent(
                structure.structureProfil.unpyStatistiques?.nbEventAsParticipant,
              )}
            />
            <KeyValue
              alignStart
              accent
              keyValue={t('invitationLink.consumeModal.structure.nbVictories')}
              value={valueOrNoContent(
                structure.structureProfil.unpyStatistiques?.nbVictory,
              )}
            />
            <KeyValue
              alignStart
              accent
              keyValue={t('invitationLink.consumeModal.structure.nbMembers')}
              value={structure.members.filter((m) => !!m.accepted).length.toString()}
            />
          </div>
        </div>
      )}
      <div className={'-actions'}>
        <Button
          variant={'outlined'}
          color={'primary'}
          onClick={handleClose}
          className={'cancel'}
        >
          <Cancel />
          {t('invitationLink.consumeModal.structure.cancelBtn')}
        </Button>
        <LoadingButton
          loading={isJoiningStructure}
          variant={'contained'}
          color={'primary'}
          onClick={handleJoinStructure}
        >
          {t('invitationLink.consumeModal.structure.goToSubscribe')}
        </LoadingButton>
      </div>
    </div>
  );
};

export const ConsumedStructureInvitationLink = ({
  handleClose,
  data,
}: ConsumeStructureInvitationLinkProps) => {
  const navigate = useNavigate();
  const t = useTranslate();
  const handleGoStructure = () => {
    handleClose();
    if (!data.member.structure?.structureProfil?.id) return;
    navigate(
      routesConfig.otherStructureProfilDetails.to(
        data.member.structure.structureProfil.id,
      ),
    );
  };

  const handleGoParams = () => {
    handleClose();
    navigate(routesConfig.parametersDetails.profilAppearence.to());
  };

  return (
    <div className={'structureInvitationLinkConsumed'}>
      <div className={'-finish'}>
        <Check className={'-icon'} color={'success'} />
        <div className={'-notice'}>
          <span>{t('invitationLink.consumedModal.structure.success')}</span>
        </div>
        <div className={'-actions'}>
          <>
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={handleGoStructure}
              disabled={!data.member.structure?.structureProfil?.id}
            >
              {t('invitationLink.consumedModal.structure.goToStructure')}
            </Button>
            <Button variant={'contained'} color={'primary'} onClick={handleGoParams}>
              {t('invitationLink.consumedModal.structure.goToParams')}
            </Button>
          </>
        </div>
      </div>
    </div>
  );
};
