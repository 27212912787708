import { Button } from '@mui/material';
import { InvitationLink } from 'domain/invitationLink/InvitationLink';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { useEffect } from 'react';

type InvitationLinkStepAccountProps = {
  nextStep: () => void;
  previousStep: () => void;
  link: InvitationLink;
};

export const InvitationLinkStepAccount = ({
  nextStep,
  previousStep,
  link,
}: InvitationLinkStepAccountProps) => {
  const { authRepository } = useContextDependency();
  const t = useTranslate();

  useEffect(() => {
    if (authRepository.currentUser) {
      nextStep();
    }
  }, [authRepository.currentUser]);

  const handleSubscribe = () => {
    authRepository.register(
      `${window.location.origin}/parameters/user/profil/apparence?invitationLink=${link.uuid}`,
    );
  };

  const handleLogin = () => {
    authRepository.login(
      `${window.location.origin}/parameters/user/profil/apparence?invitationLink=${link.uuid}`,
    );
  };

  return (
    <div className={'invitationLinkStepAccount'}>
      <p className={'-loggedInRequiredNotice'}>
        {t(
          `invitationLink.consumeModal.steps.account.${link?.typeInvitationLink}.description`,
        )}
      </p>
      <div className={'-actions'}>
        <Button
          onClick={handleLogin}
          variant={'contained'}
          color={'primary'}
          type={'submit'}
        >
          {t('invitationLink.consumeModal.steps.account.loginBtn')}
        </Button>
        <Button
          onClick={handleSubscribe}
          variant={'contained'}
          color={'primary'}
          type={'submit'}
        >
          {t('invitationLink.consumeModal.steps.account.registerBtn')}
        </Button>
      </div>
    </div>
  );
};
