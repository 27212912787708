import { Price } from 'domain/payment/Price';
import { PriceType } from 'domain/payment/PriceType';
import { toEnum } from 'helper/enum.helper';

export interface RestPrice {
  id: number;
  amount: number;
  idStripe: string;
  currency: string;
  type: string;
}

export function toPrice(restPrice: RestPrice) {
  return new Price(
    restPrice.id,
    restPrice.amount ? restPrice.amount / 100 : 0,
    restPrice.idStripe,
    restPrice.currency,
    toEnum(PriceType, restPrice.type),
  );
}
