import './AutocompleteEvent.scss';

import { Autocomplete, TextField } from '@mui/material';
import classNames from 'classnames';
import { DebouncedFunc } from 'lodash';
import React from 'react';

import { InputProps } from '../Input/InputWrapper';

type AutocompleteEventProps = {
  options: any;
  onChangeText: DebouncedFunc<(idPlayer: string) => void>;
  multiple?: boolean;
  className?: string;
} & InputProps;

export const AutocompleteEvent = ({
  options,
  onChangeText,
  className,
  onChangeSelected,
  multiple,
  disabled,
  value,
  ...props
}: AutocompleteEventProps) => {
  return (
    <Autocomplete
      className={classNames('autocomplete-event', className)}
      freeSolo
      multiple={multiple}
      disabled={disabled}
      defaultValue={value}
      onChange={(e, option: any) => {
        onChangeSelected && !disabled && onChangeSelected(option.value);
      }}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          value={value}
          disabled={disabled}
          placeholder={"Rechercher nom d'event"}
          onChange={(event) => !disabled && onChangeText(event.target.value)}
        />
      )}
    />
  );
};
