import LogoUpCropped from '@assets/Logo/up/LogoUpCropped.png';
import { routesConfig } from 'primary/Configs/Routes.config';

import { confNavBar } from './TopBar';

type ListConfNavBar = {
  [key: string]: confNavBar[];
};

export const menus: ListConfNavBar = {
  main: [
    {
      label: 'topbar.links.home',
      link: '/home',
      path: routesConfig.home.path,
    },
    {
      label: 'topbar.links.mercato',
      link: '/mercato/player',
      path: routesConfig.mercato.path,
    },
    {
      label: 'topbar.links.event',
      link: '/events',
      path: routesConfig.events.path,
    },
    {
      label: 'topbar.links.marketplace',
      link: '/marketplace',
      path: routesConfig.marketplace.path,
    },
    {
      label: 'topbar.links.up',
      link: '/up',
      icon: LogoUpCropped,
      path: routesConfig.up.path,
    },
  ],
};
