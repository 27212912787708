import { InfoOutlined } from '@mui/icons-material';
import { EventStatusEnum } from 'domain/event/EventStatusEnum';
import { TypeEventCodeEnum } from 'domain/event/TypeEventCodeEnum';
import { Title } from 'primary/Components/Title/Title';
import { MatchsViewLigue } from 'primary/events/components/matchs/view/MatchsViewLigue';
import { MatchsViewTournament } from 'primary/events/components/matchs/view/MatchsViewTournament';
import { EventViewPageContext } from 'primary/events/item/EventViewPage';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { useOutletContext } from 'react-router-dom';

const ComponentByTypeEvent = {
  [TypeEventCodeEnum.LIGUE]: <MatchsViewLigue />,
  [TypeEventCodeEnum.LIG_BR]: <MatchsViewLigue />,
  [TypeEventCodeEnum.TOURNAMENT]: <MatchsViewTournament />,
  [TypeEventCodeEnum.TOURNAMENT_DOUBLE_ELEM]: <MatchsViewTournament />,
};

export const MatchsView = () => {
  const { event } = useOutletContext<EventViewPageContext>();
  const { matchsRepository } = useContextDependency();
  const t = useTranslate();
  const staticFilters = {
    idEvent: event?.id,
  };

  if (!event) return null;

  return (
    <div className={'eventViewPageBloc eventViewPageMatchs'}>
      <Title
        title={t('event.eventViewPage.matchs.title')}
        level={1}
        threeQuarter
        bordered
      />
      {[EventStatusEnum.DRAFT, EventStatusEnum.PUBLISH].includes(event.status) && (
        <div className={'-infosWithText'}>
          <InfoOutlined />
          <span>{t('eventNotBegun')}</span>
        </div>
      )}
      {![EventStatusEnum.DRAFT, EventStatusEnum.PUBLISH].includes(event.status) && (
        <>{event && <>{ComponentByTypeEvent[event.typeEvent.code]}</>}</>
      )}
    </div>
  );
};
