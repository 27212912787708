import { MercatoAnnouncementView } from '../../domain/mercatoAnnouncement/MercatoAnnouncementView';
import { MercatoAnnouncementRepository } from './MercatoAnnouncement.repository';
import {
  RestMercatoAnnouncementView,
  toMercatoAnnouncementView,
} from './RestMercatoAnnouncementView';

const arrayFutures: RestMercatoAnnouncementView[] = [
  {
    id: 1,
    createdAt: new Date().toString(),
    platforms: ['Playstation', 'Xbox'],
    neonColor: 'blue',
    username: 'Ayouu',
    profilePhoto: 'test',
    country: 'France',
    games: ['Apex legend', 'Fifa'],
    backgroundImage: '1',
    type: 'Player',
  },
  {
    id: 2,
    createdAt: new Date().toString(),
    platforms: ['PC'],
    neonColor: 'violet',
    username: 'Toons',
    profilePhoto: 'test',
    country: 'UnitedStates',
    games: ['Fortnite', 'Rocket league'],
    backgroundImage: '2',
    type: 'Manager',
  },
  {
    id: 3,
    createdAt: new Date().toString(),
    platforms: ['PC', 'Playstation'],
    neonColor: 'violet',
    username: 'Retuza',
    profilePhoto: 'test',
    country: 'UnitedStates',
    games: ['Warzone', 'League of Legend'],
    backgroundImage: '1',
    type: 'Player',
  },
  {
    id: 4,
    createdAt: new Date().toString(),
    platforms: ['Xbox'],
    neonColor: 'blue',
    username: 'Funkyy',
    profilePhoto: 'test',
    country: 'France',
    games: ['Clash royal'],
    backgroundImage: '2',
    type: 'Player',
  },
  {
    id: 5,
    createdAt: new Date().toString(),
    platforms: ['Playstation', 'Xbox', 'PC'],
    neonColor: 'violet',
    username: 'LilooA',
    profilePhoto: 'test',
    country: 'France',
    games: ['CS GO'],
    backgroundImage: '1',
    type: 'Player',
  },
];

export class InMemoryRestMercatoAnnouncementView
  extends MercatoAnnouncementRepository
  implements Partial<MercatoAnnouncementRepository>
{
  async getRecent(): Promise<MercatoAnnouncementView[]> {
    return arrayFutures.map((restMercatoAnnouncementView) =>
      toMercatoAnnouncementView(restMercatoAnnouncementView),
    );
  }
}
