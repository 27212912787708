import { NeonType } from 'domain/neon/NeonType';

import { Neon, NeonCodeEnum } from '../../domain/neon/Neon';
import { toEnum } from '../../helper/enum.helper';
export interface RestNeon {
  id: number;
  code: string;
  color: string;
  lightColor: string;
  up: boolean;
  type: string;
}

export function toNeon(restNeon: RestNeon): Neon {
  const { id, color, code, lightColor, up, type } = restNeon;
  try {
    toEnum(NeonCodeEnum, code);
  } catch (e) {
    console.log(e);
  }
  return new Neon(
    id,
    toEnum(NeonCodeEnum, code),
    color,
    lightColor,
    up,
    toEnum(NeonType, type),
  );
}
