import {
  MarketPlaceAnnouncementView,
  TYPE_MARKET_ANNOUNCEMENT,
} from '../../domain/marketPlaceAnnouncement/MarketPlaceAnnouncementView';

export interface RestMarketPlaceAnnouncementView {
  id: number;
  type: string;
  image: string;
  game: string;
  pricing: number;
}

export function toMarketPlaceAnnouncementView(
  restMercatoAnnouncement: RestMarketPlaceAnnouncementView,
): MarketPlaceAnnouncementView {
  const { id, pricing, type, image, game } = restMercatoAnnouncement;
  return new MarketPlaceAnnouncementView(
    id,
    type as TYPE_MARKET_ANNOUNCEMENT,
    image,
    game,
    pricing,
  );
}
