import { Box } from '@mui/material';
import { InviteStructureForm } from 'primary/Components/Profils/User/ActionButton/Forms/InviteStructureForm';
import { useTranslate } from 'primary/hooks/useTranslate';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React from 'react';
interface ModalInviteStructureProps {
  isOpen: boolean;
  handleClose: () => void;
  userId?: number;
  name: string;
}
export const ModalInviteStructure = ({
  isOpen,
  handleClose,
  userId,
  name,
}: ModalInviteStructureProps) => {
  const t = useTranslate();
  if (!userId) return null;
  return (
    <ModalContainer
      handleClose={handleClose}
      isOpen={isOpen}
      content={
        <Box className={'modal-edit-role'}>
          <h2>{t('modals.inviteStructure.title', { name: name })}</h2>
          <InviteStructureForm
            onSubmitted={() => handleClose()}
            userId={userId?.toString()}
            onCancel={() => handleClose()}
          />
        </Box>
      }
    />
  );
};
