import { AxiosInstance } from 'axios';
import { Annonce, AnnonceFilters } from 'domain/annonce/Annonce';
import { IAnnonceRepository } from 'domain/annonce/Annonce.repository';
import { Pageable } from 'domain/pageable/Pageable';
import { CreateEditAnnoncesForm } from 'primary/annonce/Form/CreateEditAnnonceForm';
import { toAnnonce } from 'secondary/annonce/RestAnnonce';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';
import { toPageable } from 'secondary/RestPageable';

export class AnnonceRepository
  extends AxiosProviderRepository
  implements IAnnonceRepository
{
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  create(annonceForm: CreateEditAnnoncesForm): Promise<Annonce> {
    return this.axios
      .post(`unpy/api/annonce/create`, annonceForm)
      .then((response) => toAnnonce(response.data));
  }

  deleteById(id: number): Promise<Annonce> {
    return this.axios
      .delete(`unpy/api/annonce/${id}`)
      .then((response) => toAnnonce(response.data));
  }

  edit(annonceForm: CreateEditAnnoncesForm, id: number): Promise<Annonce> {
    return this.axios
      .put(`unpy/api/annonce/${id}/update`, annonceForm)
      .then((response) => toAnnonce(response.data));
  }

  getById(id: number): Promise<Annonce> {
    return this.axios
      .get(`unpy/api/annonce/${id}`)
      .then((response) => toAnnonce(response.data));
  }

  searchAnnonceEvent(
    idEvent: number,
    filters: AnnonceFilters,
    page: number,
    nbPerPage: number,
  ): Promise<Pageable<Annonce>> {
    return this.axios
      .get(`/unpy/api/annonces/event/${idEvent}/search`, {
        params: {
          ...filters,
          page,
          nbPerPage,
        },
      })
      .then((response) => toPageable(response, toAnnonce));
  }

  searchAnnonceStructure(
    idStructure: number,
    filters: AnnonceFilters,
    page: number,
    nbPerPage: number,
  ): Promise<Pageable<Annonce>> {
    return this.axios
      .get(`/unpy/api/annonces/structure/${idStructure}/search`, {
        params: {
          ...filters,
          page,
          nbPerPage,
        },
      })
      .then((response) => toPageable(response, toAnnonce));
  }
}
