import './ListElements.scss';

import { Cancel } from '@mui/icons-material';
import classNames from 'classnames';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import React, { ReactNode } from 'react';

type ListElementsProps = {
  loading: boolean;
  empty: boolean;
  children: ReactNode;
};

export const WrapperWidthElements = ({
  children,
  padded,
}: {
  children: ReactNode;
  padded?: boolean;
}) => {
  return (
    <div className={classNames('-wrapperWidthElements', { '-padded': padded })}>
      {children}
    </div>
  );
};

export const ListElements = ({ loading, empty, children }: ListElementsProps) => {
  return (
    <>
      {!loading && empty && (
        <div className={'-noContentWithIcon'}>
          <Cancel />
          <span className={'-noContent'}>Aucun résultat</span>
        </div>
      )}
      {loading && (
        <LoadingScreen loading={loading} message={'Recherche en cours . . .'} />
      )}
      {!loading && !empty && children}
    </>
  );
};
