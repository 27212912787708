import './LoadingScreen.scss';

import { CircularProgress } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
interface LoadingScreenProps {
  message?: string;
  loading?: boolean;
  size?: number;
  inline?: boolean;
}
export const LoadingScreen = ({
  message,
  loading,
  size = 30,
  inline,
}: LoadingScreenProps) => {
  if (!loading) return null;
  return (
    <div className={classNames('loadingScreen', { ['-inline']: inline })}>
      <CircularProgress size={size} color={'info'} />
      <span>{message}</span>
    </div>
  );
};
