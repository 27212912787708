import './MyActivitiesPage.scss';

import { Business, PersonAdd, PlaylistAdd, SportsEsports } from '@mui/icons-material';
import { Button, Collapse, Divider } from '@mui/material';
import { UnpyMatch, UnpyMatchFilters } from 'domain/event/match/UnpyMatch';
import {
  EventParticipant,
  EventParticipantFilters,
} from 'domain/event/participants/EventParticipant';
import { UnpyEventFilters } from 'domain/event/UnpyEvent';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { MyEvents } from 'primary/activities/MyEvents';
import { MyMatchs } from 'primary/activities/MyMatchs';
import { MyScores } from 'primary/activities/MyScores';
import { EventCard, EventCardSkelleton } from 'primary/Components/Event/EventCard';
import { ActivitiesEventParticipantCard } from 'primary/Components/Event/EventParticipantCard';
import { ActivitiesMatchLastScore } from 'primary/Components/Event/MatchLastScoreCard';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { Title } from 'primary/Components/Title/Title';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetchWithFilterPage } from 'primary/hooks/useFetchWithFilterPage';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageLayout } from '../../Components/PageLayout/PageLayout';

const ComponentDetailsByType = {
  event: MyEvents,
  matchs: MyMatchs,
  score: MyScores,
};

export const MyActivities: FC = () => {
  const { authRepository } = useContextDependency();
  const [isModalCreateEventOpen, setIsModalCreateEventOpen] = useState(false);
  const t = useTranslate();
  const navigate = useNavigate();
  const [typeOfDetails, setTypeOfDetails] = useState<
    'event' | 'matchs' | 'score' | undefined
  >();
  const ComponentDetails = typeOfDetails
    ? ComponentDetailsByType[typeOfDetails]
    : undefined;

  const goBack = () => {
    setTypeOfDetails(undefined);
  };

  if (!authRepository.currentUser) return null;

  return (
    <div className={'myActivitiesPage'}>
      <PageLayout>
        <div className={'-title'}>
          <Title
            title={'Mes ativités'}
            level={1}
            compensatePadding
            threeQuarter
            bordered
          />
        </div>
        <Collapse in={!typeOfDetails}>
          <div className={'-content'}>
            <div className={'-feedsContainer'}>
              <div className={'-feed'}>
                <Title
                  title={'Prochains matchs à jouer'}
                  level={3}
                  compensatePadding
                  accent
                />
                <FeedNextMatchsToPlay setTypeOfDetails={setTypeOfDetails} />
              </div>
              <Divider className={'-divider'} />
              <div className={'-feed'}>
                <Title title={'Derniers résultats'} level={3} compensatePadding accent />
                <FeedLastScoreEdited setTypeOfDetails={setTypeOfDetails} />
              </div>
            </div>
            <div className={'-socialsContainer'}>
              <div className={'-socialItems'}>
                <div className={'-iconWithNumber'}>
                  <Business className={'-socialLogo'} />
                  <span>0</span>
                </div>
                <Title title={'Invitations structures'} level={3} compensatePadding />
              </div>
              <div className={'-socialItems'}>
                <div className={'-iconWithNumber'}>
                  <SportsEsports className={'-socialLogo'} />
                  <span>0</span>
                </div>
                <Title title={'Invitations équipe'} level={3} compensatePadding />
              </div>
              <div className={'-socialItems'}>
                <div className={'-iconWithNumber'}>
                  <PersonAdd className={'-socialLogo'} />
                  <span>0</span>
                </div>
                <Title title={'Invitations amis'} level={3} compensatePadding />
              </div>
              <div className={'-socialItems'}>
                <div className={'-iconWithNumber'}>
                  <PlaylistAdd className={'-socialLogo'} />
                  <span>0</span>
                </div>
                <Title title={'Participation en attente'} level={3} compensatePadding />
              </div>
            </div>
          </div>
        </Collapse>
        <Collapse in={!!typeOfDetails}>
          <div className={'-detailsContent'}>
            {ComponentDetails && <ComponentDetails goBack={goBack} />}
          </div>
        </Collapse>
      </PageLayout>
    </div>
  );
};

export type FeedsProps = {
  setTypeOfDetails: (type: 'event' | 'matchs' | 'score') => void;
};
export const FeetActivitiesHandlingEvent = ({ setTypeOfDetails }: FeedsProps) => {
  const { eventRepository } = useContextDependency();

  const {
    fetchedResource,
    goToPage,
    loading,
    activeFilters,
    changeNbPerPage,
    setFilters,
  } = useFetchWithFilterPage<UnpyEventView, UnpyEventFilters>({
    keyStoreFilters: 'event',
    filters: {},
    callApi: (filters) => eventRepository.searchOwn(filters, 0, 5),
  });
  const events = fetchedResource?.content;
  const fetching = loading;
  return (
    <>
      <div className={'-feedsRow -feedHandlingEvent --scrollBarContent'}>
        {!events &&
          fetching &&
          Array(5)
            .fill(0)
            .map((val, index) => (
              <EventCardSkelleton className={'-feedHandlingEvent__item'} key={index} />
            ))}
        {!events?.length && !fetching && (
          <span className={'-noContent'}>Aucun évennement géré en cours</span>
        )}
        {events &&
          events.length &&
          events.map((event) => (
            <EventCard
              key={'announcement' + event.id}
              // @ts-ignore
              event={event}
              className={'-feedsRow--item'}
            />
          ))}
      </div>
      <div className={'-seeMoreContainer'}>
        <Button
          color={'primary'}
          style={{ justifySelf: 'flex-end' }}
          variant={'text'}
          onClick={() => setTypeOfDetails('event')}
        >
          {'Voir plus >>>'}
        </Button>
      </div>
    </>
  );
};

export const FeedNextMatchsToPlay = ({ setTypeOfDetails }: FeedsProps) => {
  const { authRepository, eventParticipantRepository } = useContextDependency();
  const staticFilters: EventParticipantFilters = {
    orderByNextMatch: true,
    hasScoreNull: true,
    idProfil: authRepository.currentUser?.userProfil.id,
  };
  const useFetchReturn = useFetchWithFilterPage<
    EventParticipant,
    EventParticipantFilters
  >({
    keyStoreFilters: 'next-match-feed',
    staticFilters: staticFilters,
    filters: {},
    callApi: (filters, page) => eventParticipantRepository.search(filters, 0, 5),
  });

  return (
    <>
      <div className={'-feedsRow -feedHandlingEvent --scrollBarContent'}>
        {!useFetchReturn.fetchedResource?.content?.length && useFetchReturn.loading && (
          <LoadingScreen loading />
        )}
        {!useFetchReturn.fetchedResource?.content?.length && !useFetchReturn.loading && (
          <span className={'-noContent'}>Aucun matchs à venir</span>
        )}
        {useFetchReturn.fetchedResource?.content.map((eventParticipant) => (
          <ActivitiesEventParticipantCard
            key={eventParticipant.id}
            // @ts-ignore
            eventParticipant={eventParticipant}
            className={'-feedsRow--item'}
          />
        ))}
      </div>
      <div className={'-seeMoreContainer'}>
        <Button
          color={'primary'}
          style={{ justifySelf: 'flex-end' }}
          variant={'text'}
          disabled={!((useFetchReturn.fetchedResource?.content?.length ?? 0) > 5)}
          onClick={() => setTypeOfDetails('matchs')}
        >
          {'Voir plus >>>'}
        </Button>
      </div>
    </>
  );
};

export const FeedLastScoreEdited = ({ setTypeOfDetails }: FeedsProps) => {
  const { matchsRepository, authRepository } = useContextDependency();
  const staticFilters: UnpyMatchFilters = {
    lastModifiedBeforeTodayForParticipant: authRepository.currentUser?.userProfil.id,
  };
  const useFetchReturn = useFetchWithFilterPage<UnpyMatch, UnpyMatchFilters>({
    keyStoreFilters: 'last-result-feed',
    staticFilters: staticFilters,
    filters: {},
    callApi: (filters, page) => matchsRepository.search(filters, 0, 5),
  });

  return (
    <>
      <div className={'-feedsRow -feedHandlingEvent --scrollBarContent'}>
        {!useFetchReturn.fetchedResource?.content?.length &&
          useFetchReturn.loading &&
          Array(5)
            .fill(0)
            .map((val, index) => (
              <EventCardSkelleton className={'-feedHandlingEvent__item'} key={index} />
            ))}
        {!useFetchReturn.fetchedResource?.content?.length && !useFetchReturn.loading && (
          <span className={'-noContent'}>Aucun matchs à venir</span>
        )}
        {useFetchReturn.fetchedResource?.content.map((match) => (
          <ActivitiesMatchLastScore
            key={match.id}
            // @ts-ignore
            match={match}
            className={'-feedsRow--item'}
          />
        ))}
      </div>
      <div className={'-seeMoreContainer'}>
        <Button
          color={'primary'}
          style={{ justifySelf: 'flex-end' }}
          variant={'text'}
          disabled={!((useFetchReturn.fetchedResource?.content?.length ?? 0) > 5)}
          onClick={() => setTypeOfDetails('matchs')}
        >
          {'Voir plus >>>'}
        </Button>
      </div>
    </>
  );
};
