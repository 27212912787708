import './ModalContainer.scss';

import { Box, Modal } from '@mui/material';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

interface ModalContainerProps {
  handleClose: () => void;
  content: ReactNode;
  isOpen: boolean;
  fullScreen?: boolean;
}

const ModalContainer = ({
  handleClose,
  content,
  isOpen,
  fullScreen,
}: ModalContainerProps) => {
  return (
    <Modal
      className={classNames('-modal', { '-fullScreen': fullScreen })}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      aria-describedby="modal-modal-description"
    >
      <Box className={'modal-container'}>
        <div className={'modal-container__container'}>{content}</div>
      </Box>
    </Modal>
  );
};

export default ModalContainer;
