import { TypeTeam } from '../../domain/event/TypeTeam';
import { TypeTeamCodeEnum } from '../../domain/event/TypeTeamCodeEnum';
import { toEnum } from '../../helper/enum.helper';

export interface RestTypeTeam {
  id: number;
  code: string;
}

export function toTypeTeam(restTypeTeam?: RestTypeTeam) {
  if (!restTypeTeam) return undefined;
  return new TypeTeam(restTypeTeam.id, toEnum(TypeTeamCodeEnum, restTypeTeam.code));
}
