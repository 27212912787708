import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import React from 'react';

import { Section } from '../../Components/Section/Section';
import { useTranslate } from '../../hooks/useTranslate';
import { HomeSectionProps } from '../Home';

export const HomeMercatoSection = ({ className }: HomeSectionProps) => {
  const t = useTranslate();
  const { allProfilRepository } = useContextDependency();

  const [compareProfil] = useRetrieveFromDomain(
    () => allProfilRepository.compareProfil(),
    undefined,
  );

  return (
    <Section className={className}>
      <div className={'-home-marketplace'}>
        <div className={'-diagonal-effect'}></div>
        <h2 className={'-title'}>
          {t('home.marketplace.title')} <br />
          <span className={'-title'}>{t('home.marketplace.subtitle')}</span>
          <span className={'-title'}>{t('home.marketplace.lasttitle')}</span>
        </h2>
      </div>
    </Section>
  );
};
