import { createSlice } from '@reduxjs/toolkit';

type InitialSocketSliceState = {
  ready: boolean;
};

export const socketSlice = createSlice({
  name: 'socket',
  initialState: {
    ready: false,
  } as InitialSocketSliceState,
  reducers: {
    setReady: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.ready = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setReady } = socketSlice.actions;

export default socketSlice.reducer;
