import '../../Utils/Forms/UnpyForm.scss';

import { LoadingButton } from '@mui/lab';
import { Box, Grid, Switch, TextField } from '@mui/material';
import { ValidationStringLengthEnum } from 'domain/enums/ValidationStringLengthEnum';
import {
  BRACKET_TEAM_NUMBER_OPT,
  DOUBLE_BRACKET_TEAM_NUMBER_OPT,
} from 'domain/event/eventOptByGames';
import { SystemPointsEnum } from 'domain/event/SystemPoints.enum';
import { TypeEventCodeEnum } from 'domain/event/TypeEventCodeEnum';
import { getInitialValuesPublish } from 'domain/event/UnpyEvent.func';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { InputSelectMui } from 'primary/Components/Input/InputSelectMui';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetch } from 'primary/hooks/useFetch';
import React, { FC } from 'react';
import { useController, useForm, UseFormSetValue } from 'react-hook-form';
import * as yup from 'yup';

import { IEventRepository } from '../../../domain/event/Event.repository';
import { UnpyEvent } from '../../../domain/event/UnpyEvent';
import { Translate } from '../../../domain/translation/Translation.repository';
import { ChangeHandlerTypeMeta, InputWrapper } from '../../Components/Input/InputWrapper';
import { sendEventToastMessage } from '../../Components/Toast/Toast.helper';
import { useRetrieveFromDomain } from '../../hooks/useRetrieveFromDomain';
import { useTranslate, UseTranslateReturn } from '../../hooks/useTranslate';
import { useYupValidationResolver } from '../../hooks/useYupValidationResolver';

/*
  'pointSystem',
  'freeRegister',
  'singleStructureTeam',
  {
    key: 'maxTeam',
    condition: (event: UnpyEvent) => event.typeEvent.code === TypeEventCodeEnum.LIGUE,
  },
  {
    key: 'bracketTeamNumber',
    condition: (event: UnpyEvent) =>
      event.typeEvent.code === TypeEventCodeEnum.TOURNAMENT,
  },
  'typeTeam',
  'platformes',
 */
export type EventPublishFormData = {
  pointSystem?: string;
  freeRegister?: boolean;
  singleStructureTeam?: boolean;
  maxTeam?: number;
  bracketTeamNumber?: number;
  typeTeamCode?: string;
  platforms?: string[];
};

const getValidationSchema = (typeEvent: TypeEventCodeEnum) => (t: UseTranslateReturn) =>
  yup.object({
    pointSystem: yup
      .string()
      .max(
        ValidationStringLengthEnum.SMALL,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.SMALL }),
      )
      .nullable()
      .required(t('general.forms.errors.required')),
    freeRegister: yup.boolean().nullable(),
    singleStructureTeam: yup.boolean().nullable(),
    maxTeam:
      typeEvent === TypeEventCodeEnum.LIGUE
        ? yup.number().nullable().required(t('general.forms.errors.required'))
        : yup.mixed().nullable(),
    bracketTeamNumber:
      typeEvent === TypeEventCodeEnum.TOURNAMENT
        ? yup.number().nullable().required(t('general.forms.errors.required'))
        : yup.mixed().nullable(),
    typeTeamCode: yup
      .string()
      .max(
        ValidationStringLengthEnum.SMALL,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.SMALL }),
      )
      .nullable()
      .required(t('general.forms.errors.required')),
    platforms: yup.array().nullable().min(1).required(t('general.forms.errors.required')),
  });

export const useOnSubmit =
  (
    eventRepostory: IEventRepository,
    onSubmitted: (event: UnpyEvent) => void,
    idEvent: number,
    t: Translate,
    setIsLoading: (isLoading: boolean) => void,
  ) =>
  (data: EventPublishFormData) => {
    setIsLoading(true);
    eventRepostory
      .openEvent(data, idEvent)
      .then((event) => {
        sendEventToastMessage(t('event.parameters.rules.updateSuccess'), 'success');
        onSubmitted(event);
      })
      .finally(() => setIsLoading(false));
  };

interface EventPublishFormProps {
  onSubmitted: (event: UnpyEvent | UnpyEventView) => void;
  event: UnpyEvent | UnpyEventView;
}

const _changeHandlerPlatforms =
  (setValue: UseFormSetValue<EventPublishFormData>) => (event: ChangeHandlerTypeMeta) => {
    // @ts-ignore
    setValue('platforms', event.target.value);
  };

export const EventPublishForm: FC<EventPublishFormProps> = ({ onSubmitted, event }) => {
  const t = useTranslate();
  const resolver = useYupValidationResolver(getValidationSchema(event.typeEvent.code));
  const initValues = getInitialValuesPublish(
    t,
    event as UnpyEvent,
  ) as EventPublishFormData;
  const [isLoading, setIsLoading] = React.useState(false);
  const form = useForm<EventPublishFormData>({
    resolver,
    // @ts-ignore
    defaultValues: initValues,
  });
  const { eventRepository } = useContextDependency();

  const onSubmit = useOnSubmit(eventRepository, onSubmitted, event.id, t, setIsLoading);

  const [typeTeam] = useRetrieveFromDomain(
    () => eventRepository.getAllTypeTeam(),
    undefined,
  );

  const { platformRepository } = useContextDependency();

  const [platforms] = useFetch(() => platformRepository.getAll(), undefined);

  const { field: typeTeamField } = useController({
    control: form.control,
    name: 'typeTeamCode',
  });
  const { field: bracketTeamNumberField } = useController({
    control: form.control,
    name: 'bracketTeamNumber',
  });
  const { field: systemPointField } = useController({
    control: form.control,
    name: 'pointSystem',
    defaultValue: initValues.pointSystem,
  });

  const { field: platformsField } = useController({
    control: form.control,
    name: 'platforms',
  });

  return (
    <Box className={'eventRulesForm unpy-form'}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <InputWrapper
              className={'modal-create-event__field'}
              label={t('event.form.labels.typeTeam')}
              errors={form.formState.errors}
              Input={InputSelectMui}
              isSubmitting={false}
              required
              inputProps={{
                placeholder: t('event.form.placeholders.typeTeam'),
                ...typeTeamField,
                options: typeTeam
                  ? typeTeam.map((typeTeam) => ({
                      label: t(`enum.typeTeamCode.${typeTeam.code}`),
                      value: typeTeam.code,
                    }))
                  : [],
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWrapper
              className={'unpy-form__field'}
              label={t('event.form.labels.systemPoints')}
              errors={form.formState.errors}
              Input={InputSelectMui}
              isSubmitting={false}
              required
              inputProps={{
                placeholder: t('event.form.placeholders.systemPoints'),
                ...systemPointField,
                options: Object.values(SystemPointsEnum).map((systemPoint) => ({
                  label: t(`enum.systemPoints.${systemPoint}`),
                  value: systemPoint,
                })),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWrapper
              label={t('event.form.labels.freeRegister')}
              errors={form.formState.errors}
              className={'unpy-form__field'}
              isSubmitting={form.formState.isSubmitting}
              Input={Switch}
              noFullWidth
              inputProps={{
                ...form.register('freeRegister'),
                defaultChecked: event.freeRegister,
              }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWrapper
              label={t('event.form.labels.singleStructure')}
              errors={form.formState.errors}
              className={'unpy-form__field'}
              isSubmitting={form.formState.isSubmitting}
              Input={Switch}
              noFullWidth
              inputProps={{
                ...form.register('singleStructureTeam'),
                defaultChecked: (event as UnpyEvent).singleStructureTeam,
              }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {event.typeEvent.code === TypeEventCodeEnum.TOURNAMENT && (
              <InputWrapper
                className={'unpy-form__field'}
                label={t('event.form.labels.bracketTeamNumber')}
                errors={form.formState.errors}
                Input={InputSelectMui}
                isSubmitting={false}
                required
                inputProps={{
                  ...bracketTeamNumberField,
                  onChange: (event: ChangeHandlerTypeMeta) => {
                    form.setValue('bracketTeamNumber', event.target.value as number);
                    form.setValue('maxTeam', event.target.value as number);
                  },
                  options: BRACKET_TEAM_NUMBER_OPT.map((number) => ({
                    label: t(`event.form.labels.bracketTeamNumberOpt`, { count: number }),
                    value: number.toString(),
                  })),
                }}
              />
            )}
            {event.typeEvent.code === TypeEventCodeEnum.TOURNAMENT_DOUBLE_ELEM && (
              <InputWrapper
                className={'unpy-form__field'}
                label={t('event.form.labels.bracketTeamNumber')}
                errors={form.formState.errors}
                Input={InputSelectMui}
                isSubmitting={false}
                required
                inputProps={{
                  ...bracketTeamNumberField,
                  onChange: (event: ChangeHandlerTypeMeta) => {
                    form.setValue('bracketTeamNumber', event.target.value as number);
                    form.setValue('maxTeam', event.target.value as number);
                  },
                  options: DOUBLE_BRACKET_TEAM_NUMBER_OPT.map((number) => ({
                    label: t(`event.form.labels.bracketTeamNumberOpt`, { count: number }),
                    value: number.toString(),
                  })),
                }}
              />
            )}
            {(event.typeEvent.code === TypeEventCodeEnum.LIGUE ||
              event.typeEvent.code === TypeEventCodeEnum.LIG_BR) && (
              <InputWrapper
                errors={form.formState.errors}
                Input={TextField}
                label={t('event.form.labels.maxTeam')}
                className={'-input'}
                inputProps={{
                  type: 'number',
                  ...form.register('maxTeam'),
                  variant: 'filled',
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWrapper
              label={t('parameters.menus.profil.user.form.platformLabel')}
              errors={form.formState.errors}
              inputClassName={'input-user-details '}
              labelClassName={'label-user-details '}
              Input={InputSelectMui}
              isSubmitting={form.formState.isSubmitting}
              required
              // @ts-ignore
              inputProps={{
                placeholder: t('parameters.menus.profil.user.form.platformPlaceholder'),
                ...platformsField,
                options: platforms?.map((platform) => ({
                  label: platform.libelle,
                  value: platform.code.toString(),
                })),
                // @ts-ignore
                onChange: _changeHandlerPlatforms(form.setValue),
                multiple: true,
              }}
            />
          </Grid>
        </Grid>
        <div className={'unpy-form__buttons -one -center'}>
          <LoadingButton
            variant={'contained'}
            color={'primary'}
            type={'submit'}
            loading={form.formState.isSubmitting}
          >
            {t('event.form.publishBtn')}
          </LoadingButton>
        </div>
      </form>
    </Box>
  );
};
