import { AxiosInstance } from 'axios';
import { User } from 'domain/user/User';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';

import { Auth } from '../../domain/auth/Auth.repository';
import { UserRegistration } from '../../domain/auth/UserRegistration';
import { RestUser, toAccount } from '../user/RestUser';

export interface IAuthManager {
  init(onUpdateToken: (token?: string) => void): Promise<boolean>;
  login(redirectUri?: string): Promise<void>;
  register(redirectUrl?: string): Promise<void>;
  logout(keepUrl: boolean): Promise<void>;
  isConnected(): boolean;
  getToken(): string | undefined;
  isTokenExpired(minValidity: number): boolean;
  updateToken(minValidity: number): Promise<boolean>;
}

export class AuthRepository extends AxiosProviderRepository implements Auth {
  private _currentUser?: User;

  private readonly authManager: IAuthManager;

  constructor(authManager: IAuthManager, readonly axios: AxiosInstance) {
    super(axios);
    this.authManager = authManager;
  }

  async init(onUpdateToken: () => void): Promise<boolean> {
    let isConnected = false;
    try {
      isConnected = await this.authManager.init(onUpdateToken);
    } catch (e) {
      console.log(e);
    }
    try {
      if (isConnected) this._currentUser = await this.fetchCurrentAccount();
    } catch (err) {
      console.log(err);
      //this.authManager.logout(true);
    }
    return isConnected;
  }

  refreshCurrentUser(): Promise<User> {
    if (!this._currentUser) return Promise.reject('Not logged in');
    return this.fetchCurrentAccount().then((user) => {
      this._currentUser = user;
      return user;
    });
  }

  private fetchCurrentAccount(): Promise<User> {
    return this.axios
      .get<RestUser>(`/unpy/api/current-user`, {
        headers: {
          Authorization: `Bearer ${this.authManager.getToken()}`,
        },
      })
      .then((response: any) => {
        return toAccount(response.data);
      });
  }

  // public updateActiveFcmtoken(token: string): Promise<User> {
  //   return this.axios
  //     .post<RestUser>(
  //       `/unpy/api/fcm/register`,
  //       {
  //         token: token,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${this.authManager.getToken()}`,
  //         },
  //       },
  //     )
  //     .then((response: any) => {
  //       return toAccount(response.data);
  //     });
  // }

  private removeCurrentAccount(): void {
    this._currentUser = undefined;
  }
  get currentUser(): User | undefined {
    return this._currentUser;
  }
  login(redirectUri?: string): Promise<void> {
    return this.authManager.login(redirectUri);
  }
  register(redirectUrl?: string): Promise<void> {
    return this.authManager.register(redirectUrl);
  }

  logout(keepUrl: boolean): Promise<void> {
    return this.authManager.logout(keepUrl).then(() => {
      this.removeCurrentAccount();
    });
  }
  isConnected(): boolean {
    return this.authManager.isConnected();
  }
  getToken(): Promise<string | undefined> {
    const token = this.authManager.getToken();
    if (!token) {
      throw new Error('Not logged in');
    }
    return this.authManager
      .updateToken(5)
      .then((refreshed) => {
        if (refreshed) {
          const token = this.authManager.getToken();
          return Promise.resolve(token);
        } else {
          const tokenUnsecured = this.getTokenUnsecured();
          return Promise.resolve(tokenUnsecured);
        }
      })
      .catch((err) => {
        console.log(err);
        return Promise.resolve(undefined);
      });
  }

  getTokenUnsecured(): string | undefined {
    return this.authManager.getToken();
  }

  registerAccount(data: UserRegistration): Promise<void> {
    return this.axios.post(`/unpy/api/create-account`, data);
  }
}
