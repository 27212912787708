import { AxiosInstance } from 'axios';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';

import { IDocumentRepository } from '../../domain/documents/Document.repository';
import { DocumentView } from '../../domain/documents/DocumentView';
import { toDocumentView } from './RestDocumentView';

export class DocumentRepository
  extends AxiosProviderRepository
  implements IDocumentRepository
{
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  getUserProfilPhoto(): Promise<DocumentView> {
    return this.axios
      .get(`/unpy/api/user/profil/photo`)
      .then((response: any) => toDocumentView(response.data));
  }

  getUserProfilPhotoOther(idUser: number, photo?: string): Promise<DocumentView> {
    return this.axios
      .get(`/unpy/api/user/${idUser}/profil/photo?photo=${photo}`)
      .then((response: any) => toDocumentView(response.data));
  }

  getUserProfilBanner(): Promise<DocumentView> {
    return this.axios
      .get(`/unpy/api/user/profil/banner`)
      .then((response: any) => toDocumentView(response.data));
  }

  getUserProfilBannerOther(banner: string, idUser: string): Promise<DocumentView> {
    return this.axios
      .get(`/unpy/api/user/${idUser}/profil/banner/${banner}`)
      .then((response: any) => toDocumentView(response.data));
  }

  getStructureProfilPhoto(): Promise<DocumentView> {
    return this.axios
      .get(`/unpy/api/structure/profil/photo`)
      .then((response: any) => toDocumentView(response.data));
  }

  getStructureProfilPhotoOther(
    structureProfilId: number,
    photo?: string,
  ): Promise<DocumentView> {
    return this.axios
      .get(`/unpy/api/structure/${structureProfilId}/profil/photo?photo=${photo}`)
      .then((response: any) => toDocumentView(response.data));
  }

  getStructureProfilBanner(): Promise<DocumentView> {
    return this.axios
      .get(`/unpy/api/structure/profil/banner`)
      .then((response: any) => toDocumentView(response.data));
  }

  getStructureProfilBannerOther(
    structureProfilId: number | string,
    banner?: string,
  ): Promise<DocumentView> {
    return this.axios
      .get(`/unpy/api/structure/${structureProfilId}/profil/banner?banner=${banner}`)
      .then((response: any) => toDocumentView(response.data));
  }

  getImageEvent(eventId: number, fileName: string): Promise<DocumentView> {
    return this.axios
      .get(`/unpy/api/event/${eventId}/image`)
      .then((response: any) => toDocumentView(response.data));
  }
}
