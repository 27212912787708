import { Collections, List } from '@mui/icons-material';
import { Checkbox, InputLabel, Switch, TextField } from '@mui/material';
import { UnpyMatchParticipantsFilters } from 'domain/event/match/MatchParticipant';
import { UnpyMatchFilters } from 'domain/event/match/UnpyMatch';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { debounce } from 'lodash';
import { FilterBracketRound } from 'primary/classements/Bracket/FilterBracketRound';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

type HeaderMatchsEditProps = {
  onChangeMode?: (mode: 'session' | 'bracket') => void;
  mode?: 'session' | 'bracket';
  event: UnpyEvent | UnpyEventView;
  withRoundFilter?: boolean;
  withOnlyMyMatchs?: boolean;
  withOnlyScoreUnset?: boolean;
  withTextField?: boolean;
  retry: () => void;
  filters: UnpyMatchFilters & UnpyMatchParticipantsFilters;
  setFilters: (
    keyFilter: keyof UnpyMatchFilters & UnpyMatchParticipantsFilters,
    name?: string | number | boolean,
  ) => void;
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface GenericObjectFilter {}

export const HeaderMatchsEdit = ({
  onChangeMode,
  mode,
  event,
  retry,
  withOnlyScoreUnset,
  withOnlyMyMatchs,
  withTextField,
  setFilters,
  filters,
  withRoundFilter,
}: HeaderMatchsEditProps) => {
  const t = useTranslate();

  const setFiltersDebonced = debounce(
    (keyFilter: keyof UnpyMatchFilters & UnpyMatchParticipantsFilters, val?: string) => {
      if (!val || val.length < 2) return;
      setFilters(keyFilter, val);
    },
    1500,
  );

  return (
    <div className={'headerMatchEdit'}>
      <div className={'-filters'}>
        {withRoundFilter && (
          <FilterBracketRound
            withUpperLowerFilter={false}
            totalRound={
              event?.totalParticipants
                ? Math.ceil(Math.log(event.totalParticipants) / Math.log(2))
                : undefined
            }
            currentFilter={filters}
            onChange={setFilters}
          />
        )}
        {withTextField && (
          <TextField
            className={'-textField -filterText'}
            label={t('event.matchs.edit.filters.textValue')}
            placeholder={t('event.matchs.edit.filters.textValuePlaceholder')}
            onChange={(event) => {
              setFiltersDebonced('textValue', event.target.value);
            }}
          />
        )}
        {withOnlyScoreUnset && (
          <div className={'-inputOnlyScoreUnset'}>
            <InputLabel>{t('event.matchs.edit.filters.onlyScoreUnset')}</InputLabel>
            <Checkbox
              onChange={(event) => {
                setFilters('onlyScoreUnset', event.target.checked);
              }}
              checked={filters.onlyScoreUnset}
              defaultChecked={false}
            />
          </div>
        )}
        {withOnlyMyMatchs && (
          <div className={'-inputOnlyScoreUnset'}>
            <InputLabel>{t('event.matchs.edit.filters.onlyMyMatchs')}</InputLabel>
            <Checkbox
              onChange={(event) => {
                setFilters('onlyScoreUnset', event.target.checked);
              }}
              checked={filters.onlyScoreUnset}
              defaultChecked={false}
            />
          </div>
        )}
        {onChangeMode && mode && (
          <div className={'-switch'}>
            <Switch
              checked={mode === 'session'}
              defaultChecked={false}
              onChange={() =>
                mode === 'session' ? onChangeMode('bracket') : onChangeMode('session')
              }
            />
            {mode === 'session' && (
              <>
                <span>{t('classement.parameters.switch.bracketLabel')}</span>
                <List />
              </>
            )}
            {mode === 'bracket' && (
              <>
                <span>{t('classement.parameters.switch.sessionLabel')}</span>
                <Collections />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
