import '../MercatoFilters.scss';

import { Button, Hidden } from '@mui/material';
import { selectFiltersByDomain } from 'domain/filters/FiltersSlice';
import { UserProfilFilters } from 'domain/profil/UserProfil';
import { ModalFitlers } from 'primary/Components/Filters/ModalFilters';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import { StructureProfilFilters } from '../../../../domain/profil/StructureProfil';
import { useTranslate } from '../../../hooks/useTranslate';
import { FilterExperience } from '../FilterExperiences';
import { FilterPlatform } from '../FilterPlatform';
import { FilterTalkLang } from '../FilterTalkLang';

interface MercatoStructureFiltersProps {
  setFilters: (filters: StructureProfilFilters) => void;
}

export const MercatoStructureFiltersMobile = ({
  setFilters,
}: MercatoStructureFiltersProps) => {
  const t = useTranslate();
  return (
    <ModalFitlers
      title={t('mercato.filters.titlePlayer')}
      resetFilters={() => setFilters?.({})}
      filtersComp={<MercatoStructureFiltersComp setFilters={setFilters} />}
    />
  );
};

export const MercatoStructureFilters = ({ setFilters }: MercatoStructureFiltersProps) => {
  return (
    <>
      <Hidden smUp>
        <MercatoStructureFiltersMobile setFilters={setFilters} />
      </Hidden>

      <Hidden smDown>
        <MercatoStructureFiltersComp setFilters={setFilters} />
      </Hidden>
    </>
  );
};

export const MercatoStructureFiltersComp = ({
  setFilters,
}: MercatoStructureFiltersProps) => {
  const t = useTranslate();

  // @ts-ignore
  const activeFiltersStore: UserProfilFilters = useSelector((state: RootState) =>
    selectFiltersByDomain(state, 'mercatoStructure'),
  );

  const onChangeLangs = (values: string[]) => {
    setFilters({
      ...activeFiltersStore,
      langs: values,
    });
  };

  const onChangeExperience = (value?: string) => {
    setFilters({
      ...activeFiltersStore,
      experiences: value ? [value] : undefined,
    });
  };

  const onChangePlatform = (value?: string) => {
    setFilters({
      ...activeFiltersStore,
      platforms: value ? [value] : undefined,
    });
  };

  return (
    <div className={'mercatoFilters'}>
      <div className={'-filtersInputs'}>
        <div className={'mercatoFilters__filterText'}>
          <FilterTalkLang
            selectedLangs={activeFiltersStore.langs}
            onSelectLang={onChangeLangs}
          />
        </div>
        <div className={'mercatoFilters__filterSvg'}>
          <FilterPlatform
            selectedPlatform={
              activeFiltersStore.platforms ? activeFiltersStore.platforms[0] : undefined
            }
            onSelectPlatform={onChangePlatform}
          />
        </div>
        <div className={'mercatoFilters__filterText'}>
          <FilterExperience
            selectedExperience={
              activeFiltersStore.experiences
                ? activeFiltersStore.experiences[0]
                : undefined
            }
            onSelectExperience={onChangeExperience}
          />
        </div>
        <Hidden smDown>
          <Button onClick={() => setFilters?.({})} color={'primary'} variant={'outlined'}>
            {t(`layoutPageCommonList.filtersResetBtn`)}
          </Button>
        </Hidden>
      </div>
    </div>
  );
};
