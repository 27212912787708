export enum PermissionStructureEnum {
  INVITE_MEMBERS = 'INVITE_MEMBERS',
  ACCEPT_MEMBERS = 'ACCEPT_MEMBERS',
  REMOVE_MEMBERS = 'REMOVE_MEMBERS',
  UPGRADE_MEMBER = 'UPGRADE_MEMBER',
  RETROGRADE_MEMBER = 'RETROGRADE_MEMBER',
  UPDATE_BIO = 'UPDATE_BIO',
  SUBSCRIBE_EVENT = 'SUBSCRIBE_EVENT',
  UPDATE_PP = 'UPDATE_PP',
  SEND_INFOS = 'SEND_INFOS',
  UPDATE_NAME = 'UPDATE_NAME',
}
