import { GamingProfilEnum } from 'domain/enums/GamingProfil.enum';
import { ProfilTypeEnum } from 'domain/profil/ProfilTypeEnum';
import { UnpyStatistiquesProfil } from 'domain/profil/UnpyStatistiquesProfil';
import { toEnum } from 'helper/enum.helper';
import { toProfilPrivacy } from 'secondary/privacy/RestPrivacy';

import { Profil } from '../../domain/profil/Profil';
import { StructureProfil } from '../../domain/profil/StructureProfil';
import { UserProfil } from '../../domain/profil/UserProfil';
import { toBackgroundImageView } from '../backgroundImage/RestBackgroundImageView';
import { RestCountry, toCountry } from '../country/RestCountry';
import { toGameProfil } from '../gameProfil/RestGameProfil';
import { toLang } from '../lang/RestLang';
import { toNeon } from '../neon/RestNeon';
import { toPlatform } from '../platform/RestPlatform';
import { RestStructure, toStructure } from '../structure/RestStructure';
import { RestStructureProfil } from '../structureProfil/RestStructureProfil';
import { RestUserProfil } from '../userProfil/RestUserProfil';

export type RestProfil = RestStructureProfil | RestUserProfil;

export interface RestUnpyStatistiquesProfil {
  id: number;
  idProfil?: number;
  typeProfil?: string;
  nbEventAsParticipant?: number;
  nbEventAsCreator?: number;
  nbPodium?: number;
  nbVictory?: number;
}

export const toUnpyStatistiquesProfil = (
  restUnpuStatProfil: RestUnpyStatistiquesProfil,
) => {
  return new UnpyStatistiquesProfil(
    restUnpuStatProfil.id,
    restUnpuStatProfil.idProfil,
    toEnum(ProfilTypeEnum, restUnpuStatProfil.typeProfil),
    restUnpuStatProfil.nbEventAsParticipant,
    restUnpuStatProfil.nbEventAsCreator,
    restUnpuStatProfil.nbPodium,
    restUnpuStatProfil.nbVictory,
  );
};

export function toProfilFromAllProfil(restProfil: RestProfil[]): Profil[] {
  return restProfil.map((restProfilMap) => {
    if (Object.hasOwn(restProfilMap, 'structure')) {
      return new StructureProfil(
        restProfilMap.id,
        restProfilMap.games ? restProfilMap.games.map((game) => toGameProfil(game)) : [],
        toNeon(restProfilMap.neon),
        restProfilMap.backgroundImage
          ? toBackgroundImageView(restProfilMap.backgroundImage)
          : undefined,
        restProfilMap.description,
        restProfilMap.gamingProfil,
        restProfilMap.lang,
        restProfilMap.platforms
          ? restProfilMap.platforms.map((platform) => toPlatform(platform))
          : undefined,
        restProfilMap.video,
        restProfilMap.pingActive,
        restProfilMap.twitter,
        restProfilMap.facebook,
        restProfilMap.youtube,
        restProfilMap.instagram,
        restProfilMap.discord,
        restProfilMap.twitch,
        restProfilMap.talkLangs
          ? restProfilMap.talkLangs.map((lang) => toLang(lang))
          : undefined,
        restProfilMap.privacies
          ? restProfilMap.privacies.map((privacy) => toProfilPrivacy(privacy))
          : [],
        restProfilMap.unpyStatistiques
          ? toUnpyStatistiquesProfil(restProfilMap.unpyStatistiques)
          : undefined,
        (restProfilMap as RestStructureProfil).name,
        (restProfilMap as RestStructureProfil).structure
          ? toStructure((restProfilMap as RestStructureProfil).structure as RestStructure)
          : undefined,
        (restProfilMap as RestStructureProfil).bannerImage,
        (restProfilMap as RestStructureProfil).bannerImagePublicUrl,
        (restProfilMap as RestStructureProfil).defaultBanner,
      );
    } else {
      return new UserProfil(
        restProfilMap.id,
        restProfilMap.games ? restProfilMap.games.map((game) => toGameProfil(game)) : [],
        (restProfilMap as RestUserProfil).idPlayer,
        toNeon(restProfilMap.neon),
        (restProfilMap as RestUserProfil).userId,
        restProfilMap.backgroundImage
          ? toBackgroundImageView(restProfilMap.backgroundImage)
          : undefined,
        restProfilMap.description,
        restProfilMap.gamingProfil
          ? toEnum(GamingProfilEnum, restProfilMap.gamingProfil)
          : undefined,
        restProfilMap.lang,
        restProfilMap.platforms
          ? restProfilMap.platforms.map((platform) => toPlatform(platform))
          : undefined,
        restProfilMap.video,
        restProfilMap.pingActive,
        restProfilMap.twitter,
        restProfilMap.facebook,
        restProfilMap.youtube,
        restProfilMap.instagram,
        restProfilMap.discord,
        restProfilMap.twitch,
        restProfilMap.talkLangs
          ? restProfilMap.talkLangs.map((lang) => toLang(lang))
          : undefined,
        restProfilMap.privacies
          ? restProfilMap.privacies.map((privacy) => toProfilPrivacy(privacy))
          : [],
        restProfilMap.unpyStatistiques
          ? toUnpyStatistiquesProfil(restProfilMap.unpyStatistiques)
          : undefined,
        (restProfilMap as RestUserProfil).birthdate
          ? new Date((restProfilMap as RestUserProfil).birthdate as string)
          : undefined,
        (restProfilMap as RestUserProfil).country
          ? toCountry((restProfilMap as RestUserProfil).country as RestCountry)
          : undefined,
        (restProfilMap as RestUserProfil).photo,
        (restProfilMap as RestUserProfil).publicPhotoUrl,
        (restProfilMap as RestUserProfil).defaultPhoto,
      );
    }
  });
}
