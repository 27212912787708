import { createTheme } from '@mui/material/styles';
// eslint-disable-next-line unused-imports/no-unused-imports
import type ThemeAugmentation from '@mui/x-data-grid/themeAugmentation';

export const themeParameters = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#ff7800',
    },
    secondary: {
      main: '#202020',
    },
    warning: {
      main: '#ffd036',
    },
    info: {
      main: '#c2c2c2',
    },
  },
});
