import './StaticOffer.scss';

import { Button, Grid } from '@mui/material';
import classNames from 'classnames';
import { Product } from 'domain/payment/Product';
import { TranslationType } from 'domain/translation/Translation.repository';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

type CardOfferProductProps = {
  product: Product;
  productType: 'boost' | 'pass';
  embedded?: boolean;
  onClickCheckout: () => void;
};

export const CardStaticOffer = ({
  product,
  productType,
  embedded,
}: CardOfferProductProps) => {
  const t = useTranslate();

  return (
    <Grid container className={classNames('cardStaticOffer', { '-embedded': embedded })}>
      <h3 className={'-productTitleAccent'}>{t(`products.${productType}.label`)}</h3>
      <div className={'-productPrice'}>
        <span className={'-price'}>
          {!product && <LoadingScreen loading={true} />}
          {product?.price?.amount} {t(product?.price?.currency as TranslationType)}
        </span>
        {productType === 'boost' && (
          <div className={'-notice'}>{t('products.boost.subLabel')}</div>
        )}
      </div>
      <Button
        variant={'contained'}
        onClick={undefined}
        color={'primary'}
        className={'-comingSoon'}
        disabled
      >
        {t(`products.comingSoon`)}
      </Button>
      <div className={'-seeMoreContainer'}>
        {/*<Button*/}
        {/*  variant={'contained'}*/}
        {/*  onClick={() => console.log('CHOOSE PRODUCT')}*/}
        {/*  color={'primary'}*/}
        {/*  className={'-btnSeeMore'}*/}
        {/*>*/}
        {/*  {t(`products.seeMoreBtn`)}*/}
        {/*</Button>*/}
      </div>
    </Grid>
  );
};
