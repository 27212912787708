import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { ClassementParticipant } from 'domain/classement/ClassementParticipant';
import { UseTranslateReturn } from 'primary/hooks/useTranslate';
import React from 'react';

export const COLUMNS_CLASSEMENT_LIGUE = (t: UseTranslateReturn): GridColDef[] => [
  {
    field: 'position',
    headerName: '#',
    headerClassName: '-header',
    headerAlign: 'center',
    cellClassName: '-cell -accent -little',
    sortable: false,
  },
  {
    field: 'name',
    headerName: t('event.teams.datatable.labelName'),
    headerClassName: '-header',
    headerAlign: 'center',
    cellClassName: '-cell -padded -little',
    sortable: false,
  },
  {
    field: 'vnd',
    headerName: t('event.teams.datatable.labelVictories'),
    headerClassName: '-header',
    headerAlign: 'center',
    align: 'center',
    cellClassName: '-cell -padded -little',
    sortable: false,
    renderCell: (value: GridRenderCellParams<ClassementParticipant>) => {
      if (!value.value) return;
      return (
        <div>
          {`${value.value.victories ?? '-'} / ${value.value.nulls ?? '-'} / ${
            value.value.defeats ?? '-'
          }`}
        </div>
      );
    },
  },
  {
    field: 'score',
    headerName: t('event.teams.datatable.labelScore'),
    headerAlign: 'center',
    cellClassName: '-cell',
    headerClassName: '-header  -position',
    sortable: false,
  },
];

export const COLUMNS_CLASSEMENT_LIGUE_BR = (t: UseTranslateReturn): GridColDef[] => [
  {
    field: 'position',
    headerName: '#',
    headerClassName: '-header',
    headerAlign: 'center',
    cellClassName: '-cell -accent -little',
    sortable: false,
  },
  {
    field: 'name',
    headerName: t('event.teams.datatable.labelName'),
    headerClassName: '-header',
    headerAlign: 'center',
    cellClassName: '-cell -padded',
    sortable: false,
  },
  {
    field: 'results',
    headerName: t('event.teams.datatable.labelTop1'),
    headerClassName: '-header',
    headerAlign: 'center',
    align: 'center',
    cellClassName: '-cell -padded',
    sortable: false,
    renderCell: (value: GridRenderCellParams<ClassementParticipant>) => {
      if (!value.value) return;
      return (
        <div>
          {`${value.value.top1 ?? '-'} / ${value.value.top3 ?? '-'} / ${
            value.value.topHalf ?? '-'
          }`}
        </div>
      );
    },
  },
  {
    field: 'score',
    headerName: t('event.teams.datatable.labelScore'),
    headerAlign: 'center',
    cellClassName: '-cell',
    headerClassName: '-header  -position',
    sortable: false,
  },
];
