import './MatchView.scss';

import { UnpyEvent } from 'domain/event/UnpyEvent';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { MatchComponentProps } from 'primary/Components/bracket/CustomBracket/types';
import { useBooleanToggle } from 'primary/hooks/useBooleanToggle';
import React from 'react';

import { CustomMatch } from './CustomMatch';

export type OnClickResultMatchFunc = (
  result: 'LOOSE' | 'WIN' | 'FORFAIT',
  teamId: number,
) => void;
export type OnClickCancelMatchFunc = (teamId: number) => void;

type MatchViewProps = {
  className?: string;
  isEditable?: boolean;
  fullWidth?: boolean;
  guetteredTop?: boolean;
  guetteredBottom?: boolean;
  noBorder?: boolean;
  event: UnpyEventView | UnpyEvent;
  onEdited?: () => void;
  onEdit?: () => void;
} & MatchComponentProps;

export const MatchView = (props: MatchViewProps) => {
  const [isOpenEditScore, , openEditScore, closeEditScore] = useBooleanToggle();

  const handleValidateEditScoreTournament = () => {
    props.onEdited?.();
    closeEditScore();
  };

  return (
    <div className={'matchView'}>
      <div className={'match-actions'}></div>
      <CustomMatch
        {...props}
        typeEvent={props.event.typeEvent.code}
        isEditable={props.isEditable}
        openEditScore={props.onEdit}
      />
    </div>
  );
};

/*
      <Popper
        open={Boolean(anchorElCreate)}
        anchorEl={anchorElCreate}
        placement={'bottom-start'}
        transition
      >
        {({ TransitionProps }) => {
          return (
            <ClickAwayListener onClickAway={handleClose}>
              <Fade {...TransitionProps} timeout={350}>
                <Paper className={'create-menu__paper'}>
                  <span>edit result</span>
                </Paper>
              </Fade>
            </ClickAwayListener>
          );
        }}
      </Popper>
 */
