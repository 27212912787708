import { UnpySubscription } from 'domain/payment/UnpySubscription';
import { UserProfilView } from 'domain/user/UserProfilView';

import { RolesEnum } from '../security/RolesEnum';

export class UserView {
  constructor(
    public readonly id: number,
    public readonly roles: RolesEnum[],
    public readonly username: string,
    public readonly email: string,
    public readonly userProfil: UserProfilView,
    public readonly subscription?: UnpySubscription,
  ) {}
}
