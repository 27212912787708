import { AnnonceListEventWithActions } from 'primary/annonce/AnnonceListEventWithActions';
import { Title } from 'primary/Components/Title/Title';
import { EventViewPageContext } from 'primary/events/item/EventViewPage';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { useOutletContext } from 'react-router-dom';

export const Annonces = () => {
  const t = useTranslate();
  const { event } = useOutletContext<EventViewPageContext>();
  if (!event) return null;
  return (
    <div className={'eventViewPageBloc eventViewPageInfos'}>
      <Title
        title={t('event.eventViewPage.annonces.title')}
        level={1}
        threeQuarter
        bordered
      />
      <AnnonceListEventWithActions idEvent={event?.id as number} />
    </div>
  );
};
