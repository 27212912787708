import { AxiosInstance } from 'axios';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';

import { Platform } from '../../domain/platform/Platform';
import { IPlatformRepository } from '../../domain/platform/Platform.repository';
import { RestPlatform, toPlatform } from './RestPlatform';

export class PlatformRepository
  extends AxiosProviderRepository
  implements IPlatformRepository
{
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  getAll(): Promise<Platform[]> {
    return this.axios
      .get('/unpy/api/platform/all')
      .then((resp) =>
        resp.data.map((restPlatform: RestPlatform) => toPlatform(restPlatform)),
      );
  }
}
