import { TimelineEventWrapperContextParam } from 'primary/Components/Event/TimelineEvent';
import React from 'react';

export const EventTimelineInfos = () => {
  return (
    <div className={'eventTimelineInfos'}>
      <TimelineEventWrapperContextParam orientation={'vertical'} withInfos />
    </div>
  );
};
