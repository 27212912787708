import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

export const RedirectCheckout = () => {
  const location = useLocation();
  const params = useParams();
  useEffect(() => {
    console.log(params);
  }, [params, location]);
  return null;
};
