import './ModalCreate.scss';

import { Lock } from '@mui/icons-material';
import { Button } from '@mui/material';
import { RolesEnum } from 'domain/security/RolesEnum';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { UnpyLogoUp } from 'primary/Components/UnpyLogo/UnpyLogoUp';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Title } from '../Components/Title/Title';
import { routesConfig } from '../Configs/Routes.config';
import { EventCreateForm } from '../events/forms/EventCreateForm';
import { useTranslate } from '../hooks/useTranslate';

interface ModalCreateEventProps {
  handleClose: () => void;
}

const ModalCreateEvent = ({ handleClose }: ModalCreateEventProps) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const { eventRepository, authRepository } = useContextDependency();

  const [count, , loading] = useRetrieveFromDomain(
    () => eventRepository.countEventsCreationConsume(),
    undefined,
  );
  const isUserUp = authRepository.currentUser?.roles.includes(RolesEnum.USER_UP);
  return (
    <div className={'modal-create modal-create__event'}>
      <Title threeQuarter bordered title={t('topbar.create.event.label')} level={2} />
      {loading && <LoadingScreen loading />}
      {!loading && !isUserUp && (count?.count ?? 0) >= (count?.max ?? 0) && (
        <LimiteCreateEventSubscription handleClose={handleClose} />
      )}
      {!loading && (isUserUp || (count?.count ?? 0) < (count?.max ?? 0)) && (
        <EventCreateForm
          handleClose={handleClose}
          onCreated={(id: number) => navigate(routesConfig.parametersEvent.to(id))}
        />
      )}
    </div>
  );
};

export default ModalCreateEvent;

export const LimiteCreateEventSubscription = ({
  handleClose,
}: {
  handleClose: () => void;
}) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const handleGotOSubscribe = () => {
    handleClose();
    navigate(routesConfig.up.to());
  };
  return (
    <div className={'limitCreatedEventSubscription'}>
      <Lock className={'lock-icon'} />
      <span>{t('event.limit.create')}</span>
      <div className={'notice'}>
        <span>{t('event.limit.createSubscribeNotice')}</span>
        <UnpyLogoUp />
        <span>{t('event.limit.createSubscribeNotice2')}</span>
      </div>
      <Button
        variant={'contained'}
        onClick={handleGotOSubscribe}
        color={'primary'}
        className={'-subscribeBtn'}
      >
        {t('home.up.subscribe')}
      </Button>
    </div>
  );
};
