import './SimpleEventListElement.scss';

import { InfoRounded } from '@mui/icons-material';
import { Chip } from '@mui/material';
import classNames from 'classnames';
import { EventSubStatusEnum } from 'domain/event/EventStatusEnum';
import { getSubStatusOfEvent } from 'domain/event/UnpyEvent.func';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { formatDate } from 'helper/date.helper';
import { EventImage } from 'primary/Components/Event/EventImage';
import { PopperButtonsConf } from 'primary/Components/PopperButton/PopperButton';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from '../../store';
import { useContextDependency } from '../hooks/useContextDependency';

type SimpleEventListElementProps = {
  event: UnpyEventView;
  onClick?: (event: UnpyEventView) => void;
  isSelected?: boolean;
  className?: string;
};

export const SimpleEventListElement = ({
  event,
  onClick,
  isSelected,
}: SimpleEventListElementProps) => {
  const { relationRepository, authRepository } = useContextDependency();
  const user = useSelector((state: RootState) => state.user.connectedUser);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const t = useTranslate();

  const handleClick = () => {
    navigate(routesConfig.eventViewDetails.eventViewInfos.to(event.id));
  };

  const conf: PopperButtonsConf[] = useMemo(() => {
    return [
      {
        label: t('profil.userProfilAction.infosMore'),
        action: handleClick,
        icon: <InfoRounded />,
      },
    ];
  }, [event]);

  return (
    <div
      className={classNames('simpleEventListItem', { isSelected: isSelected })}
      onClick={() => onClick?.(event)}
    >
      {isSelected && <div className={'-selected'}></div>}
      <div className={'-header'}>
        <EventImage image={event.publicImageUrl} />
        <div className={'-tagContainer'}>
          <Chip
            size={'small'}
            color={'primary'}
            className={classNames('-tag')}
            label={t(`enum.typeEventCode.${event.typeEvent.code}`)}
          />
          <Chip
            size={'small'}
            color={'primary'}
            className={classNames('-tag')}
            label={
              getSubStatusOfEvent(event)
                ? t(
                    `enum.eventSubStatus.${
                      getSubStatusOfEvent(event) as EventSubStatusEnum
                    }.title`,
                  )
                : t(`enum.eventStatus.${event.status}`)
            }
          />
        </div>
        <div className={'-name'}>
          <span>{event.name}</span>
        </div>
        <div className={'-datesInfos'}>
          <span>
            {t('event.eventViewPage.header.textInfos.startDate', {
              date: formatDate(event.startDateTime, 'readable(with hour)'),
            })}
          </span>
          <span>
            {t('event.eventViewPage.header.textInfos.endSubscribe', {
              date: formatDate(event.endSubscribeDate, 'readable(with hour)'),
            })}
          </span>
        </div>
      </div>
    </div>
  );
};
