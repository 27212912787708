import { AnnonceTypeEnum } from 'domain/annonce/AnnonceTypeEnum';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { StructureView } from 'domain/structure/StructureView';
import { UserView } from 'domain/user/UserView';

export type AnnonceFilters = {
  type?: AnnonceTypeEnum;
  create?: Date;
  isPublic?: boolean;
  createDateBefore?: Date;
  createDateAfter?: Date;
  userCreator?: number;
  eventId?: number;
  structureId?: number;
};

export class Annonce {
  constructor(
    public readonly id: number,
    public readonly typeAnnonce: AnnonceTypeEnum,
    public readonly create: Date,
    public readonly isPublic: boolean,
    public readonly text: string,
    public readonly creator: UserView,
    public readonly eventView?: UnpyEventView,
    public readonly structureView?: StructureView,
    public readonly image?: string,
  ) {}
}
