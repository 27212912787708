import { NeonColor } from '../../primary/Components/Neon/Neon';

export type Country = 'France' | 'UnitedStates';

export type TypeAnnouncement = 'Player' | 'Manager';

export type Platform = 'Playstation' | 'Xbox' | 'PC';

export class MercatoAnnouncementView {
  constructor(
    public readonly id: number,
    public readonly createdAt: Date,
    public readonly platforms: Platform[],
    public readonly neonColor: NeonColor,
    public readonly username: string,
    public readonly profilePhoto: string,
    public readonly country: Country,
    public readonly games: string[],
    public readonly backgroundImage: string,
    public readonly type: TypeAnnouncement,
  ) {}
}
