export const ellipsis = (length: number, str?: string) => {
  if (!str) {
    return '';
  }
  if (str.length <= length) {
    return str;
  }

  return `${str.substring(0, length)}...`;
};
