import './LinkSocialNetwork.scss';

import classNames from 'classnames';
import { useTranslate } from 'primary/hooks/useTranslate';
import { Network } from 'primary/network/network';
import React from 'react';
interface LinkSocialNetworkProps {
  variant?: 'outlined' | 'contained';
  network: Network;
  value?: string;
  big?: boolean;
  className?: string;
}

export const LinkSocialNetwork = ({
  variant = 'contained',
  network,
  value,
  big,
  className,
}: LinkSocialNetworkProps) => {
  const t = useTranslate();
  if (variant === 'contained') {
    return (
      <a
        key={network.value}
        target="_blank"
        href={value}
        className={classNames(
          'linkNetwork',
          className,
          { '-disabled': !value },
          { '-big': big },
          { '-present': !!value },
        )}
        rel="noreferrer"
        title={value || t('myProfil.noNetworkValue', { network: network.name })}
      >
        {network.icon({})}
      </a>
    );
  } else {
    return (
      <div className={classNames('outlinedContainer', { '-big': big })}>
        <a
          key={network.value}
          target="_blank"
          href={value}
          className={classNames(
            'linkNetwork',
            className,
            { '-disabled': !value },
            { '-big': big },
            { '-present': !!value },
          )}
          rel="noreferrer"
          title={value || t('myProfil.noNetworkValue', { network: network.name })}
        >
          {network.icon({})}
        </a>
      </div>
    );
  }
};
