import { AxiosInstance } from 'axios';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';

import { INotificationRepository } from '../../domain/notification/Notification.repository';
import {
  NotificationFilters,
  UnpyNotification,
} from '../../domain/notification/UnpyNotification';
import { Pageable } from '../../domain/pageable/Pageable';
import { toPageable } from '../RestPageable';
import { toNotification } from './RestNotification';

export class NotificationRepository
  extends AxiosProviderRepository
  implements INotificationRepository
{
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  search(
    filters: NotificationFilters,
    page: number,
    nbPerPage: number,
  ): Promise<Pageable<UnpyNotification>> {
    return this.axios
      .get('/unpy/api/notification/search', {
        params: {
          ...filters,
          page,
          nbPerPage,
        },
      })
      .then((response) => {
        return toPageable(response, toNotification);
      });
  }

  toViewed(idNotification: number): Promise<UnpyNotification> {
    return this.axios
      .put(`/unpy/api/notification/viewed/${idNotification}`)
      .then((response) => toNotification(response.data));
  }

  getNumberUnviewed(): Promise<number> {
    return this.axios
      .get(`/unpy/api/notification/unviewed`)
      .then((response) => response.data);
  }
}
