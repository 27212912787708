import './EventGamePage.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { ChevronLeft } from '@mui/icons-material';
import { Button } from '@mui/material';
import classNames from 'classnames';
import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { GAME_ACTIVE_LIST, GAME_IMAGES } from '../../domain/game/GameActiveList';
import { toEnum } from '../../helper/enum.helper';
import { Section } from '../Components/Section/Section';
import { useTranslate } from '../hooks/useTranslate';
import { EventGamePopular } from './list/EventGamePopular';

export const EventsGamePage: FC = () => {
  const t = useTranslate();
  const navigate = useNavigate();
  const { gameCode } = useParams<{ gameCode: string }>();
  return (
    <Section className={classNames('eventGamePage')}>
      <div className={'eventGamePage__header'}>
        <div className={'-titleContainer'}>
          <div className={'-backContainer'}>
            <Button
              variant={'outlined'}
              onClick={() => navigate(-1)}
              color={'info'}
              className={'-goBack'}
              type={'submit'}
            >
              <ChevronLeft />
              {t('event.eventsPage.goBack')}
            </Button>
            <h1>Events {t(`games.libelle.${toEnum(GAME_ACTIVE_LIST, gameCode)}`)}</h1>
          </div>
          <img
            className={'-imgGame'}
            src={GAME_IMAGES[gameCode as GAME_ACTIVE_LIST]}
            alt={gameCode}
          />
        </div>
        <div>
          <EventGamePopular gameSelected={toEnum(GAME_ACTIVE_LIST, gameCode)} />
        </div>
      </div>
      {/*<div className={'eventGamePage__content'}>*/}
      {/*  <h1>{t('event.eventsPage.tournamentFuture')}</h1>*/}
      {/*  {gameCode && <EventList gamesSelected={toEnum(GAME_ACTIVE_LIST, gameCode)} />}*/}
      {/*</div>*/}
    </Section>
  );
};
