import './NotificationPage.scss';

import {
  NotificationFilters,
  UnpyNotification,
} from 'domain/notification/UnpyNotification';
import { CATEGORY_NOTIFICATION_MAP } from 'domain/notification/UnpyNotification..helper';
import { UnpyTabs } from 'primary/Components/Tabs/UnpyTabs';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetchWithFilterPage } from 'primary/hooks/useFetchWithFilterPage';
import React, { FC } from 'react';

import { Title } from '../../Components/Title/Title';
import { useTranslate } from '../../hooks/useTranslate';
import { NotificationListInPage } from '../../MessageList/NotificationList';

export const NotificationPage: FC = () => {
  const { authRepository } = useContextDependency();
  if (!authRepository.currentUser) return null;
  return <NotificationPageComponent userId={authRepository.currentUser.id} />;
};

type NotificationPageComponentProps = {
  userId: number;
};
export const NotificationPageComponent = ({ userId }: NotificationPageComponentProps) => {
  const { authRepository } = useContextDependency();
  const t = useTranslate();
  const { notificationRepository, socketRepository } = useContextDependency();
  const staticFilters: NotificationFilters = {
    userFrom: undefined,
    userTo: userId,
  };

  const fetchedRessources = useFetchWithFilterPage<UnpyNotification, NotificationFilters>(
    {
      keyStoreFilters: 'all-notification',
      filters: {},
      staticFilters: staticFilters,
      callApi: (filters, page) => notificationRepository.search(filters, page, 10),
    },
  );
  if (!authRepository.currentUser) return null;

  const tabsConf = [
    {
      label: t(`notification.tabs.all`),
      action: () => {
        fetchedRessources.setFilters({
          types: [],
        });
      },
    },
    {
      label: t(`notification.tabs.mercato`),
      action: () => {
        fetchedRessources.setFilters({
          types: CATEGORY_NOTIFICATION_MAP.mercato,
        });
      },
    },
    {
      label: t(`notification.tabs.event`),
      action: () => {
        fetchedRessources.setFilters({
          types: CATEGORY_NOTIFICATION_MAP.event,
        });
      },
    },
    {
      label: t(`notification.tabs.social`),
      action: () => {
        fetchedRessources.setFilters({
          types: CATEGORY_NOTIFICATION_MAP.social,
        });
      },
    },
    {
      label: t(`notification.tabs.divers`),
      action: () => {
        fetchedRessources.setFilters({
          types: CATEGORY_NOTIFICATION_MAP.divers,
        });
      },
    },
  ];

  return (
    <div className={'notificationPage'}>
      <Title
        title={t('notification.pageTitle')}
        level={1}
        compensatePadding
        threeQuarter
        bordered
      />
      <div className={'notificationPage__content'}>
        <UnpyTabs tabsConf={tabsConf} fonced marged withDivider asFlex />
        <NotificationListInPage
          key={`all-notification`}
          keyDomainFilter={`all-notification`}
          ressourceFetched={fetchedRessources}
          currentUserId={authRepository.currentUser.id}
        />
      </div>
    </div>
  );
};
