import './MessageForm.scss';

import { Send } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { ValidationStringLengthEnum } from 'domain/enums/ValidationStringLengthEnum';
import { InputTextArea } from 'primary/Components/Input/InputTextArea';
import React, { createRef, FC } from 'react';
import { useForm, UseFormSetValue } from 'react-hook-form';
import * as yup from 'yup';

import { Message } from '../../../../domain/message/Message';
import { IMessageRepository } from '../../../../domain/message/Message.repository';
import { useContextDependency } from '../../../hooks/useContextDependency';
import { useTranslate, UseTranslateReturn } from '../../../hooks/useTranslate';
import { useYupValidationResolver } from '../../../hooks/useYupValidationResolver';
import { InputWrapper } from '../../Input/InputWrapper';

export type MessageFormFormData = {
  content: string;
};

const validationSchema = (t: UseTranslateReturn) =>
  yup.object({
    content: yup
      .string()
      .max(
        ValidationStringLengthEnum.LONG,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
      )
      .required('Veuillez tapez un message'),
  });

export const useOnSubmit =
  (
    onSubmitted: (message: Message) => void,
    repository: IMessageRepository,
    idConversation: number,
    setValue: UseFormSetValue<MessageFormFormData>,
  ) =>
  async (data: MessageFormFormData) => {
    return repository
      .sendMessageToConversation(data.content, idConversation)
      .then((value) => {
        setValue('content', '');
        onSubmitted(value);
      });
  };

interface MessageFormProps {
  onSubmitted: (message: Message) => void;
  idConversation: number;
}

export const MessageForm: FC<MessageFormProps> = ({ onSubmitted, idConversation }) => {
  const { messageRepository } = useContextDependency();
  const t = useTranslate();
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    formState: { isSubmitting, errors },
    handleSubmit,
    setValue,
  } = useForm<MessageFormFormData>({
    resolver,
  });

  const ref = createRef<HTMLButtonElement>();
  const onSubmit = useOnSubmit(onSubmitted, messageRepository, idConversation, setValue);

  return (
    <div className={'messageForm'}>
      <form onSubmit={handleSubmit(onSubmit)} name={'message-form'}>
        <div className={'messageForm__input'}>
          <InputWrapper
            errors={errors}
            inputClassName={''}
            isSubmitting={isSubmitting}
            Input={InputTextArea}
            inputProps={{
              placeholder: t('message.input.placeholder'),
              disabled: isSubmitting,
              ...register('content', { required: true }),
              variant: 'filled',
              maxLength: 255,
              // @ts-ignore
              multiline: true,
              onKeyDown: (evt) => {
                if (evt.keyCode == 13) {
                  ref?.current?.click();
                }
              },
            }}
          />
        </div>
        <IconButton
          ref={ref}
          color={'primary'}
          type={'submit'}
          className={'messageForm__btn'}
        >
          <Send />
        </IconButton>
      </form>
    </div>
  );
};
