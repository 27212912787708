import { DocumentView } from '../../domain/documents/DocumentView';
export interface RestDocumentView {
  fileName: string;
  publicUrl: string;
}

export function toDocumentView(restDocumentView: RestDocumentView): DocumentView {
  const { fileName, publicUrl } = restDocumentView;
  return new DocumentView(fileName, publicUrl);
}
