import Keycloak from 'keycloak-js';
import { ModalDisconnectedOpenType } from 'primary/Components/User/ModalDisconnected';

import { IAuthManager } from './Auth.repository';

export class AuthManager implements IAuthManager {
  private readonly keycloak: Keycloak;

  constructor(keycloak: Keycloak) {
    this.keycloak = keycloak;
  }
  init(onUpdateToken: (token?: string) => void): Promise<boolean> {
    return this.keycloak
      .init({
        checkLoginIframe: true,
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      })
      .then((authenticated) => {
        this.keycloak.onTokenExpired = () => {
          this.keycloak
            .updateToken(0)
            .then(() => {
              const token = this.getToken();
              if (!token) throw new Error('No Token');
              onUpdateToken(token);
              return token;
            })
            .catch((err) => {
              console.error(err);
              const event = new CustomEvent(ModalDisconnectedOpenType);
              window.document.dispatchEvent(event);
              return Promise.reject(err);
            });
        };
        return authenticated;
      });
  }
  login(redirectUri?: string): Promise<void> {
    const url = this.keycloak.createLoginUrl({ redirectUri: redirectUri });
    location.assign(url);
    return Promise.resolve();
  }
  register(redirectUrl?: string): Promise<void> {
    return this.keycloak.register({
      redirectUri: redirectUrl,
    });
  }
  logout(keepUrl: boolean): Promise<void> {
    const keepPageQuery = keepUrl ? `?redirect=${window.location.pathname}` : '';
    return this.keycloak.logout({
      redirectUri: `${window.location.origin}/${keepPageQuery}`,
    });
  }
  isConnected(): boolean {
    return !!this.keycloak.authenticated;
  }
  getToken(): string | undefined {
    return this.keycloak.token;
  }
  isTokenExpired(minValidity: number): boolean {
    return this.keycloak.isTokenExpired(minValidity);
  }
  updateToken(minValidity: number): Promise<boolean> {
    return this.keycloak.updateToken(minValidity);
  }
}
