import './FilterTalkLang.scss';

import { Lang } from 'domain/lang/Lang';
import { insertEmptyOpt } from 'helper/options.helper';
import { InputSelectMui } from 'primary/Components/Input/InputSelectMui';
import {
  ChangeHandlerTypeMeta,
  InputWrapper,
} from 'primary/Components/Input/InputWrapper';
import React from 'react';

import { useContextDependency } from '../../hooks/useContextDependency';
import { useFetch } from '../../hooks/useFetch';
import { useTranslate } from '../../hooks/useTranslate';

type FilterTalkLangProps = {
  onSelectLang: (values: string[]) => void;
  selectedLangs?: string[];
};

export const FilterTalkLang = ({ onSelectLang, selectedLangs }: FilterTalkLangProps) => {
  const t = useTranslate();
  const { langRepository } = useContextDependency();
  const [talkLangs] = useFetch(() => langRepository.getAll(), undefined);
  if (!talkLangs) return null;

  return (
    <div>
      <InputWrapper
        inline
        errors={{}}
        Input={InputSelectMui}
        isSubmitting={false}
        label={t('mercato.filters.player.langs')}
        inputProps={{
          options: insertEmptyOpt(
            talkLangs?.map((lang: Lang) => ({
              label: lang.libelle,
              value: lang.code,
            })) || [],
          ),
          onChange: (event: ChangeHandlerTypeMeta) => {
            //ts-ignore
            onSelectLang(event.target.value as string[]);
          },
          menuPortalTarget: document.body,
          value: selectedLangs,
        }}
      />
    </div>
  );
};
