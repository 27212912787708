import './NeonForm.scss';

import { Check, Lock } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import classNames from 'classnames';
import { Neon } from 'domain/neon/Neon';
import { NeonType } from 'domain/neon/NeonType';
import { selectAllNeons, setNeons } from 'domain/neon/store/NeonSlice';
import { getNeonBorderClassNameByNeon } from 'domain/profil/Profil.func';
import { isUserUp } from 'domain/user/User.helper';
import { useRetrieveFromDomainToContext } from 'primary/hooks/useRetrieveFromDomain';
import { ProfilAppearenceFormData } from 'primary/Parameters/user/Profil/UserProfilAppearence';
import React, { FC, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { useContextDependency } from '../../hooks/useContextDependency';
import { useTranslate } from '../../hooks/useTranslate';
interface NeonFormProps {
  form: UseFormReturn<ProfilAppearenceFormData>;
  type: NeonType;
}

interface BoxNeonSelectableProps {
  onClick?: () => void;
  isSelected: boolean;
  isLockUp?: boolean;
  readonly?: boolean;
  helperText?: string;
  neon: Neon;
}

export const BoxNeonSelectable = ({
  neon,
  onClick,
  isLockUp,
  helperText,
  isSelected,
  readonly,
}: BoxNeonSelectableProps) => {
  const neonBorderClassName = getNeonBorderClassNameByNeon(neon);
  return (
    <button
      className={classNames('boxNeonSelectableContainer', { '-isSelectable': !readonly })}
      onClick={
        readonly
          ? undefined
          : (e) => {
              e.preventDefault();
              e.stopPropagation();
              onClick?.();
            }
      }
    >
      <div className={classNames('boxNeonSelectable', neonBorderClassName)}></div>
      {isSelected && (
        <div className={'boxNeonSelectableContainer__overSelected'}>
          <Check color={'success'} />
        </div>
      )}
      {isLockUp && (
        <div className={'boxNeonSelectableContainer__overLocked'}>
          <Lock />
        </div>
      )}
    </button>
  );
};

export const NeonForm: FC<NeonFormProps> = ({ form, type }) => {
  const t = useTranslate();

  const { neonRepository, authRepository } = useContextDependency();

  const [, , neons] = useRetrieveFromDomainToContext(
    () => neonRepository.getAllNeons(),
    undefined,
    true,
    setNeons,
    selectAllNeons,
  );

  const [selectedId, setSelectedId] = useState<number | undefined>(
    form.getValues('neon'),
  );
  const enhancedSetSelectedId = (id: number) => {
    setSelectedId(id);
    form.setValue('neon', id);
  };
  return (
    <div className={'neon-form'}>
      <div className={'neon-form__noUp'}>
        <h3>{t('parameters.menus.profil.user.form.neonLabel')} *</h3>
        {!neons && <CircularProgress size={100} />}
        {neons
          ?.filter((neon) => neon.type === type)
          ?.sort((a) => (a.isUp ? 1 : -1))
          .map((neon) => (
            <BoxNeonSelectable
              key={neon.code}
              onClick={() => enhancedSetSelectedId(neon.id)}
              neon={neon}
              isSelected={!!selectedId && selectedId === neon.id}
              isLockUp={neon.isUp && !isUserUp(authRepository?.currentUser)}
            />
          ))}
      </div>
    </div>
  );
};
