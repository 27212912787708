import './EventListPage.scss';

import { useSelectionGameListPage } from 'primary/Mercato/Mercato.helper';
import React, { FC, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { GameSelectionCaroussel } from '../Components/GameSelectionCaroussel/GameSelectionCaroussel';
import { LayoutPageListFitlered } from '../Components/Layout/Pages/LayoutPageListFiltered';
import { Section } from '../Components/Section/Section';
import { useTranslate } from '../hooks/useTranslate';
import { EventList } from './list/EventList';

export const EventListPage: FC = () => {
  const t = useTranslate();
  const { selectedGamesCode, handleSelection } = useSelectionGameListPage('event');
  const [searchParams, setUrlSearchParams] = useSearchParams();
  const gameToFilter = searchParams?.get('game');
  const refGameSetted = useRef(false);

  useEffect(() => {
    if (gameToFilter && !selectedGamesCode.find((game) => game === gameToFilter)) {
      handleSelection(gameToFilter, 'ADD');
      refGameSetted.current = true;
      setUrlSearchParams({});
    }
  }, [gameToFilter, selectedGamesCode]);

  return (
    <div className={'event-list-page'}>
      <div className={'-diagonal-effect'}></div>
      <h1 className={'-absoluteTitle'}>{t('event.eventsListPage.titleEvents')}</h1>
      <Section className={'event-list-page__section'}>
        <LayoutPageListFitlered
          mainFilterContent={
            <div className={'caroussel'}>
              <h1>{t('event.eventsListPage.titleCaroussel')}</h1>
              <GameSelectionCaroussel
                infinite
                selectedValues={selectedGamesCode}
                slidesToScroll={1}
                rows={2}
                slidePerRow={4}
                onClick={(value, type: 'ADD' | 'REMOVE') => {
                  handleSelection(value, type);
                }}
                center
              />
            </div>
          }
          listContent={
            <div className={'event-list-page__listEvents'}>
              <EventList
                title={t('event.eventsListPage.titleEventList')}
                gamesSelected={selectedGamesCode}
                typeSearch={'PUBLIC'}
              />
            </div>
          }
        />
      </Section>
    </div>
  );
};
