import {
  Cancel,
  DomainVerification,
  Grading,
  PersonAdd,
  PlayArrow,
  Public,
  SportsScore,
} from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import { addDays, isBefore } from 'date-fns';
import { EventConfirmationStateEnum } from 'domain/event/EventConfirmationStateEnum';
import { EventStatusEnum } from 'domain/event/EventStatusEnum';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import { getSubStatusOfEvent } from 'domain/event/UnpyEvent.func';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { formatDate } from 'helper/date.helper';
import { useTranslate } from 'primary/hooks/useTranslate';
import * as React from 'react';

export type ACTION_TIMELINE_EVENT_STATE =
  | 'DRAFT'
  | 'PUBLISH_SUBSCRIBE_OPEN'
  | 'PUBLISH_SUBSCRIBE_CLOSE_WAITING_ASK_CONFIRM'
  | 'PUBLISH_SUBSCRIBE_CLOSE_WAITING_CONFIRM'
  | 'PUBLISH_SUBSCRIBE_CLOSE_WAITING_BEGIN'
  | 'BEGUN'
  | 'FINISHED';

export const getActionTimelineEventState: (
  event: UnpyEvent | UnpyEventView,
) => ACTION_TIMELINE_EVENT_STATE = (event: UnpyEvent | UnpyEventView) => {
  const state = event.status;
  const subState = getSubStatusOfEvent(event);
  switch (state) {
    case EventStatusEnum.DRAFT:
      return 'DRAFT';
    case EventStatusEnum.PUBLISH:
      {
        if (subState === 'SUBSCRIBE_OPEN') {
          return 'PUBLISH_SUBSCRIBE_OPEN';
        } else if (subState === 'SUBSCRIBE_CLOSE') {
          if (
            !event.confirmationState &&
            isBefore(addDays(event.startDateTime, -2), new Date())
          ) {
            return 'PUBLISH_SUBSCRIBE_CLOSE_WAITING_CONFIRM';
          }
          if (!event.confirmationState) {
            return 'PUBLISH_SUBSCRIBE_CLOSE_WAITING_ASK_CONFIRM';
          } else if (event.confirmationState === EventConfirmationStateEnum.WAITING) {
            return 'PUBLISH_SUBSCRIBE_CLOSE_WAITING_CONFIRM';
          } else {
            return 'PUBLISH_SUBSCRIBE_CLOSE_WAITING_BEGIN';
          }
        }
      }
      break;
    case EventStatusEnum.ON_GOING:
      return 'BEGUN';
    case (EventStatusEnum.FINISH, EventStatusEnum.CANCELED):
      return 'FINISHED';
    default:
      return 'DRAFT';
  }
  return 'DRAFT';
};

type DraftActionTimelineEventProps = {
  handlePublish: () => void;
  handleCancel: () => void;
};

export const DraftActionTimelineEvent = ({
  handlePublish,
  handleCancel,
}: DraftActionTimelineEventProps) => {
  const t = useTranslate();
  return (
    <>
      <MenuItem onClick={handlePublish} disableRipple>
        <Public />
        {t('event.timeline.actions.publish.label')}
      </MenuItem>
      <MenuItem onClick={handleCancel} disableRipple>
        <Cancel />
        {t('event.timeline.actions.cancel.label')}
      </MenuItem>
    </>
  );
};

type PublishSubscribeOpenActionTimelineEventProps = {
  handleCloseSubscribe: () => void;
  handleCancel: () => void;
};

export const PublishSubscribeOpenActionTimelineEvent = ({
  handleCloseSubscribe,
  handleCancel,
}: PublishSubscribeOpenActionTimelineEventProps) => {
  const t = useTranslate();

  return (
    <>
      <MenuItem key={'close'} onClick={handleCloseSubscribe} disableRipple>
        <Grading />
        {t('event.timeline.actions.closeSubscribe.label')}
      </MenuItem>
      <MenuItem onClick={handleCancel} disableRipple>
        <Cancel />
        {t('event.timeline.actions.cancel.label')}
      </MenuItem>
    </>
  );
};

type PublishSubscribeCloseWaitAskForConfirmProps = {
  handleReopenSubscribe: () => void;
  handleCancel: () => void;
  event: UnpyEvent;
};
export const PublishSubscribeCloseWaitAskForConfirm = ({
  handleReopenSubscribe,
  handleCancel,
  event,
}: PublishSubscribeCloseWaitAskForConfirmProps) => {
  const t = useTranslate();
  return (
    <>
      <MenuItem onClick={handleReopenSubscribe} disableRipple>
        <PersonAdd />
        {t('event.timeline.actions.reopenSubscribe.label')}
      </MenuItem>
      <MenuItem disabled onClick={handleReopenSubscribe} disableRipple>
        <DomainVerification />
        {t('event.timeline.actions.validateStart.wainting', {
          date: formatDate(addDays(event.startDateTime, -2), 'readable(with hour)'),
        })}
      </MenuItem>
      <MenuItem onClick={handleCancel} disableRipple>
        <Cancel />
        {t('event.timeline.actions.cancel.label')}
      </MenuItem>
    </>
  );
};

type PublishSubscribeCloseWaitConfirmProps = {
  handleValidateStart: () => void;
  handleCancel: () => void;
};

export const PublishSubscribeCloseWaitConfirm = ({
  handleValidateStart,
  handleCancel,
}: PublishSubscribeCloseWaitConfirmProps) => {
  const t = useTranslate();
  return (
    <>
      <MenuItem onClick={handleValidateStart} disableRipple>
        <DomainVerification />
        {t('event.timeline.actions.validateStart.label')}
      </MenuItem>
      <MenuItem onClick={handleCancel} disableRipple>
        <Cancel />
        {t('event.timeline.actions.cancel.label')}
      </MenuItem>
    </>
  );
};

type BegunProps = {
  handleFinish: () => void;
  handleCancel: () => void;
};

export const BegunActions = ({ handleCancel, handleFinish }: BegunProps) => {
  const t = useTranslate();
  return (
    <>
      <MenuItem onClick={handleFinish} disableRipple>
        <SportsScore />
        {t('event.timeline.actions.finish.label')}
      </MenuItem>
      <MenuItem onClick={handleCancel} disableRipple>
        <Cancel />
        {t('event.timeline.actions.cancel.label')}
      </MenuItem>
    </>
  );
};

type WaitBeginProps = {
  handleCancel: () => void;
  handleStart: () => void;
  event: UnpyEvent | UnpyEventView;
};

export const WaitBegin = ({ handleCancel, event, handleStart }: WaitBeginProps) => {
  const t = useTranslate();
  const canStart = isBefore(event.startDateTime, new Date());
  return (
    <>
      <MenuItem
        disabled={!canStart}
        onClick={!canStart ? undefined : handleStart}
        disableRipple
      >
        <PlayArrow />
        {!canStart
          ? t('event.timeline.actions.start.wainting', {
              date: formatDate(event.startDateTime, 'readable(with hour)'),
            })
          : t('event.timeline.actions.start.label')}
      </MenuItem>
      <MenuItem onClick={handleCancel} disableRipple>
        <Cancel />
        {t('event.timeline.actions.cancel.label')}
      </MenuItem>
    </>
  );
};
