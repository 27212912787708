import { Add, Remove } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { UserProfil } from 'domain/profil/UserProfil';
import { useTranslate } from 'primary/hooks/useTranslate';
import { addPlayer, removePlayer } from 'primary/teams/store/TeamCreatingSlice';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

type TeamCreatingActionProps = {
  profil: UserProfil;
  expanded?: boolean;
};
export const TeamCreatingAction = ({ profil, expanded }: TeamCreatingActionProps) => {
  const dispatch = useDispatch();
  const t = useTranslate();
  const teamCreating = useSelector((state: RootState) => state.teamCreating.teamCreating);
  const alreadyAdded = useMemo(
    () =>
      teamCreating?.data?.players
        ? Object.keys(teamCreating.data.players).find(
            (key) => teamCreating?.data?.players[key]?.idPlayer === profil.idPlayer,
          )
        : undefined,
    [teamCreating],
  );

  const isComplete = useMemo(
    () =>
      teamCreating?.data?.players
        ? !Object.values(teamCreating.data.players).some((v) => !v.idPlayer)
        : undefined,
    [teamCreating],
  );
  const handleClickAddTeam = () => {
    if (!currentNameFieldPlayer) return;
    dispatch(
      addPlayer({
        idPlayer: profil.idPlayer,
        role: undefined,
        nameField: currentNameFieldPlayer,
      }),
    );
  };

  const handleClickRemoveTeam = useCallback(() => {
    alreadyAdded && dispatch(removePlayer({ nameField: alreadyAdded }));
  }, [alreadyAdded, dispatch]);
  const currentNameFieldPlayer = useMemo(
    () =>
      teamCreating?.data?.players
        ? Object.keys(teamCreating.data.players)
            .map((key) => {
              if (teamCreating?.data?.players[key]?.idPlayer === undefined) {
                return key;
              }
            })
            .filter((val) => val !== undefined)[0]
        : undefined,
    [teamCreating],
  );

  return (
    <>
      {!expanded && teamCreating && !alreadyAdded && (
        <IconButton
          title={
            isComplete ? t('user.myProfil.fullToAddTeam') : t('user.myProfil.addToTeam')
          }
          onClick={handleClickAddTeam}
          disabled={isComplete}
        >
          <Add />
        </IconButton>
      )}
      {!expanded && teamCreating && alreadyAdded && (
        <IconButton
          title={t('user.myProfil.removeFromTeam')}
          onClick={handleClickRemoveTeam}
        >
          <Remove />
        </IconButton>
      )}
      {expanded && teamCreating && !alreadyAdded && (
        <Button onClick={handleClickAddTeam} disabled={isComplete}>
          {isComplete ? t('user.myProfil.fullToAddTeam') : t('user.myProfil.addToTeam')}
          <Add />
        </Button>
      )}
      {expanded && teamCreating && alreadyAdded && (
        <Button title={t('user.myProfil.removeFromTeam')} onClick={handleClickRemoveTeam}>
          {t('user.myProfil.removeFromTeam')}
          <Remove />
        </Button>
      )}
    </>
  );
};
