import './StructureProfilCard.scss';

import { UserProfilContext } from 'primary/Components/Profils/User/UserProfilCard';
import { ReactNode } from 'react';
import React from 'react';

import { StructureProfil } from '../../../../domain/profil/StructureProfil';
import { useBooleanToggle } from '../../../hooks/useBooleanToggle';
import { StructureProfilFrontContent } from './front/StructureProfilFrontContent';

type MainRowLayoutProps = {
  mainContent: ReactNode;
  leftContent: ReactNode;
  rightContent: ReactNode;
};

export const MainRowLayout = ({
  mainContent,
  leftContent,
  rightContent,
}: MainRowLayoutProps) => {
  return (
    <div className={'-mainRow'}>
      <div className={'-mainRow__container'}>{leftContent}</div>
      <div className={'-mainRow__mainContainer'}>{mainContent}</div>
      <div className={'-mainRow__container'}>{rightContent}</div>
    </div>
  );
};

type StructureProfilCardProps = {
  profil: StructureProfil;
  isPreview?: boolean;
};

export type StructureProfilContext = StructureProfilCardProps;
export type ProfilContext = StructureProfilContext | UserProfilContext;

export const StructureProfilCard = ({ profil, isPreview }: StructureProfilCardProps) => {
  const [isOpen, toggleOpen, open, close] = useBooleanToggle();
  const styleBackground = !isPreview
    ? {
        backgroundImage: `url("${profil.backgroundImage?.publicUrl}")`,
        backgroundSize: 'cover',
      }
    : {};
  return (
    <div className={'structureProfilCard'}>
      <div style={styleBackground} className={'-backgroundStructureProfil'}></div>
      <StructureProfilFrontContent
        onReturnCard={open}
        profil={profil}
        isPreview={isPreview}
      />
    </div>
  );
};
