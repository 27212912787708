import './coupleButtonValidCancel.scss';

import { Cancel } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

type CoupleButtonValidCancelProps = {
  onClickValid?: () => void;
  onClickCancel?: () => void;
  isTypeSubmit?: boolean;
  disabledValid?: boolean;
  loading?: boolean;
};

export const CoupleButtonValidCancel = ({
  onClickValid,
  onClickCancel,
  isTypeSubmit,
  disabledValid,
  loading,
}: CoupleButtonValidCancelProps) => {
  const t = useTranslate();
  return (
    <div className="coupleButtonValidCancel">
      {onClickCancel && (
        <Button
          className={'-button'}
          variant={'outlined'}
          color={'primary'}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClickCancel();
          }}
        >
          <Cancel />
          {t('event.form.cancelBtn')}
        </Button>
      )}
      <LoadingButton
        variant={'contained'}
        color={'primary'}
        type={isTypeSubmit ? 'submit' : 'button'}
        onClick={isTypeSubmit ? undefined : onClickValid}
        disabled={disabledValid}
      >
        {t('general.btnLabel.validate')}
      </LoadingButton>
    </div>
  );
};
