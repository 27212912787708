import { StructureProfilView } from 'domain/profil/StructureProfilView';
import { UserProfilView } from 'domain/user/UserProfilView';

export enum PRIVACY_CODE {
  ADD_FRIEND = 'ADD_FRIEND',
  JOIN_STRUCTURE = 'JOIN_STRUCTURE',
  INVITE_STRUCTURE = 'INVITE_STRUCTURE',
  SEND_MESSAGE = 'SEND_MESSAGE',
}

export class ProfilPrivacy {
  constructor(
    public readonly id: number,
    public readonly profil: UserProfilView | StructureProfilView,
    public readonly code: PRIVACY_CODE,
    public readonly isChecked: boolean,
  ) {}
}
