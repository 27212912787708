export type TYPE_MARKET_ANNOUNCEMENT = 'COACHING' | 'LOGO DESIGN';

export class MarketPlaceAnnouncementView {
  constructor(
    public readonly id: number,
    public readonly type: TYPE_MARKET_ANNOUNCEMENT,
    public readonly image: string,
    public readonly game: string,
    public readonly pricing: number,
  ) {}
}
