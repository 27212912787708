import './ProfilPage.scss';

import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { RootState } from '../../../store';
import { StructureProfilCard } from '../../Components/Profils/structure/StructureProfilCard';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useFetch } from '../../hooks/useFetch';

export const OtherStructureProfilPage = () => {
  const { structureProfilRepository, authRepository } = useContextDependency();
  const { id } = useParams<{ id: string }>();
  const [structureProfil] = useFetch(
    () => structureProfilRepository.getStructureProfilById(id as string),
    undefined,
  );
  const user = useSelector((state: RootState) => state.user.connectedUser);

  if (!structureProfil) return null;
  return (
    <div className={'myProfilPage'}>
      <StructureProfilCard profil={structureProfil} />
    </div>
  );
};
