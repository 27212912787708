import { Block } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { UserProfil } from 'domain/profil/UserProfil';
import {
  selectConnectedUserBlocked,
  setRelation,
} from 'domain/relation/store/RelationSlice';
import { stopPropagationBeforeAction } from 'primary/Components/Profils/User/ActionButton/ActionButtonInviteStructure';
import { sendEventToastMessage } from 'primary/Components/Toast/Toast.helper';
import { useBooleanToggle } from 'primary/hooks/useBooleanToggle';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import ModalConfirm from 'primary/ModalConfirm/ModalConfirm';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface ActionButtonBlockProps {
  profil: UserProfil;
  withIcon?: boolean;
}

export const ActionButtonBlock = ({ profil, withIcon }: ActionButtonBlockProps) => {
  const [isOpen, , open, closeModal] = useBooleanToggle();
  const t = useTranslate();
  const { relationRepository, authRepository } = useContextDependency();
  const [isLoading, setIsLoading] = React.useState(false);
  const blocked = useSelector(selectConnectedUserBlocked);
  const alreadyBlocked = blocked?.find((b) => b.invited.idProfil === profil.id);
  const prefixTranslate = alreadyBlocked ? 'unBlock' : 'block';
  const dispatch = useDispatch();
  const handleBlock = () => {
    if (!profil.userId) return;
    setIsLoading(true);
    relationRepository
      .blockUser(profil.userId)
      .then(() => {
        sendEventToastMessage(
          t(`relations.${prefixTranslate}.success`, { name: profil.idPlayer }),
          'success',
        );
        relationRepository.getAllRelationsOfConnectedUser().then((relations) => {
          dispatch(setRelation(relations));
        });
      })
      .finally(() => {
        setIsLoading(false);
        closeModal();
      });
  };

  const handleUnBlock = () => {
    if (!profil.userId) return;
    setIsLoading(true);
    relationRepository
      .unBlockUser(profil.userId)
      .then(() => {
        sendEventToastMessage(
          t(`relations.${prefixTranslate}.success`, { name: profil.idPlayer }),
          'success',
        );
        relationRepository.getAllRelationsOfConnectedUser().then((relations) => {
          dispatch(setRelation(relations));
        });
      })
      .finally(() => {
        setIsLoading(false);
        closeModal();
      });
  };
  return (
    <>
      {isOpen && (
        <ModalConfirm
          handleClose={() => closeModal()}
          isOpen={isOpen}
          title={t(`relations.${prefixTranslate}.title`, { name: profil.idPlayer })}
          description={t(`relations.${prefixTranslate}.notice`)}
          action={!alreadyBlocked ? handleBlock : handleUnBlock}
          labelAccept={t(`relations.${prefixTranslate}.confirmBtnText`)}
          labelDecline={t(`relations.${prefixTranslate}.cancelBtnText`)}
        />
      )}
      <LoadingButton
        loading={isLoading}
        color={'inherit'}
        variant={'text'}
        className={'linkMenu'}
        disabled={!authRepository.currentUser}
        onClick={stopPropagationBeforeAction(open)}
      >
        {withIcon && <Block />}
        {!alreadyBlocked
          ? t(`profil.userProfilAction.${prefixTranslate}`)
          : t(`profil.userProfilAction.${prefixTranslate}`)}
      </LoadingButton>
    </>
  );
};
