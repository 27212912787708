import './RightPanel.scss';

import { ArrowLeft, ArrowRight, People } from '@mui/icons-material';
import classNames from 'classnames';
import { PlayersNumberByTypeTeams } from 'domain/event/TypeTeam';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

interface RightPanelProps {
  className?: string;
  IconOpen?: ReactNode;
  showButton?: boolean;
  defaultOpen?: boolean;
}

export const RightPanel: FC<RightPanelProps> = ({
  children,
  className,
  IconOpen,
  showButton,
  defaultOpen,
}) => {
  const teamCreating = useSelector((state: RootState) => state.teamCreating.teamCreating);
  const [isOpen, setIsOpen] = useState(defaultOpen);

  useEffect(() => {
    if (!isOpen && teamCreating) {
      setIsOpen(true);
    } else if (isOpen && !teamCreating) {
      setIsOpen(false);
    }
  }, [teamCreating]);
  const toggleIsOpen = () => {
    // @ts-ignore
    setIsOpen((prevState) => !prevState);
  };
  const rootDiv = document.getElementById('root');

  const render = () =>
    createPortal(
      <div className={classNames('rightPanel', { '-open': isOpen }, className)}>
        {showButton && (
          <div className={'rightPanel__control'} onClick={toggleIsOpen}>
            {isOpen && <ArrowRight />}
            {!isOpen && <ArrowLeft />}
            {IconOpen && (
              // @ts-ignore
              <IconOpen className={'-icon'} variant={'blue'} />
            )}
            {teamCreating && (
              <div className={'-teamCreatingCount'}>
                <People />
                {Object.values(teamCreating?.data?.players).filter(
                  (val) => !!val.idPlayer,
                )?.length ?? 0}
                /{PlayersNumberByTypeTeams[teamCreating.typeTeam.code].length}
              </div>
            )}
          </div>
        )}
        <div className={'rightPanel__content'}>{children}</div>
      </div>,
      rootDiv as Element,
    );

  return render();
};
