import './LeftRight.scss';

import { ArrowLeft } from '@mui/icons-material';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

type LeftRightProps = {
  left: React.ReactNode;
  right: React.ReactNode;
  isOpen?: boolean;
  close: () => void;
  scrollInside?: boolean;
  transparency: 'opaq' | 'low' | 'default';
  sizeLeft: 'big' | 'medium' | 'default';
};
export const LeftRight = ({
  left,
  right,
  close,
  scrollInside,
  transparency = 'default',
  sizeLeft = 'default',
  isOpen,
}: LeftRightProps) => {
  const t = useTranslate();
  return (
    <div
      className={classNames('leftRight', {
        '-scrollInside': scrollInside,
        '-lowTransparency': transparency === 'low',
        '-opaq': transparency === 'opaq',
        '-medium': sizeLeft === 'medium',
        '-big': sizeLeft === 'big',
      })}
    >
      <div
        className={classNames('leftRight__left', {
          '-scrollInside': scrollInside,
          '-lowTransparency': transparency === 'low',
          '-opaq': transparency === 'opaq',
          '-medium': sizeLeft === 'medium',
          '-big': sizeLeft === 'big',
          '--open': !isOpen,
        })}
      >
        {left}
      </div>
      <div
        className={classNames('leftRight__right', {
          '-scrollInside': scrollInside,
          '-lowTransparency': transparency === 'low',
          '-opaq': transparency === 'opaq',
          '-medium': sizeLeft === 'medium',
          '-big': sizeLeft === 'big',
          '--open': isOpen,
        })}
      >
        <div className={'-header'}>
          {!!close && (
            <Button
              className="leftRight__returnBtn"
              onClick={close}
              color={'primary'}
              variant={'outlined'}
            >
              <ArrowLeft /> {t('general.action.retour')}
            </Button>
          )}
        </div>
        {right}
      </div>
    </div>
  );
};
