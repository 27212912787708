import './EventGamePopular.scss';

import { CircularProgress } from '@mui/material';
import { addDays, format } from 'date-fns';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import React from 'react';

import { UnpyEventFilters } from '../../../domain/event/UnpyEvent';
import { GAME_ACTIVE_LIST } from '../../../domain/game/GameActiveList';
import { EventCard } from '../../Components/Event/EventCard';
import { RowCard } from '../../Components/RowCard/RowCard';
import { Title } from '../../Components/Title/Title';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useFetchWithFilterPage } from '../../hooks/useFetchWithFilterPage';
import { useTranslate } from '../../hooks/useTranslate';

interface EventGamePopularProps {
  gameSelected: GAME_ACTIVE_LIST;
}

export const EventGamePopular = ({ gameSelected }: EventGamePopularProps) => {
  const { eventRepository } = useContextDependency();

  const defaultFilters: UnpyEventFilters = {
    game: gameSelected,
    startDateTime: format(addDays(new Date(), 1), 'dd/MM/yyyy:hh:mm'),
  };
  const { fetchedResource, goToPage, loading, activeFilters, setFilters } =
    useFetchWithFilterPage<UnpyEventView, UnpyEventFilters>({
      filters: defaultFilters,
      callApi: (filters, page) => eventRepository.searchPublic(filters, page, 5),
    });

  const t = useTranslate();

  return (
    <div className={'eventGamePopular'}>
      <Title
        title={t('event.eventsPage.tournamentFamous')}
        level={1}
        compensatePadding
        threeQuarter
        bordered
      />
      {fetchedResource?.content === undefined && <CircularProgress />}
      {fetchedResource?.content && fetchedResource.content.length === 0 && (
        <span>Aucune annonce event récente</span>
      )}
      {fetchedResource && fetchedResource.content.length > 0 && (
        <RowCard className={'-scrollable'}>
          {fetchedResource.content.map((event) => (
            <EventCard
              key={'announcement' + event.id}
              event={event}
              className={'container-announcement__announcement -item'}
            />
          ))}
        </RowCard>
      )}
    </div>
  );
};
