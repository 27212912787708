import { InputSelectMui } from 'primary/Components/Input/InputSelectMui';
import React from 'react';
import { UseControllerReturn } from 'react-hook-form/dist/types';

import { useContextDependency } from '../../hooks/useContextDependency';
import { useFetch } from '../../hooks/useFetch';
import { useTranslate } from '../../hooks/useTranslate';
import { InputWrapper } from '../Input/InputWrapper';

interface StructureSelectorProps {
  userId: number;
  userToExclude?: number;
  errors: any;
  control: UseControllerReturn<any, any>;
  participationAsStructureName?: string;
}

export const StructureSelector = ({
  userId,
  errors,
  control,
  userToExclude,
}: StructureSelectorProps) => {
  const { structureRepository, authRepository } = useContextDependency();
  const [structures] = useFetch(
    () => structureRepository.getStructuresOfUser(userId, userToExclude),
    undefined,
  );

  const t = useTranslate();

  return (
    <InputWrapper
      label={t('ping.form.structureChoiceLabel')}
      errors={errors}
      Input={InputSelectMui}
      isSubmitting={false}
      required
      inputProps={{
        ...control.field,
        isLoading: structures === undefined,
        onLoadingMessage: t('ping.form.structureLoadingPlaceholder'),
        noContentMessage: t('ping.form.noStructures'),
        options: structures?.map((structure) => {
          const canInvite = true;
          return {
            label: `${structure.name} ${
              canInvite ? '' : `(${t('ping.form.noPermission')})`
            }`,
            value: structure.id.toString(),
            disabled: !canInvite,
          };
        }),
        //onChangeText: _changeHandlerType(setValue),
      }}
    />
  );
};
