import { createContext, Dispatch } from 'react';

import { UnpyEvent } from '../../../domain/event/UnpyEvent';
import { UnpyEventAction } from './Event.reducer';

export type EVENT_CONTEXT_TYPE = {
  event?: UnpyEvent;
  dispatch: Dispatch<UnpyEventAction>;
};

export const EVENT_CONTEXT = createContext({} as EVENT_CONTEXT_TYPE);
