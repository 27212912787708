import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClassementFilters } from 'domain/classement/ClassementParticipant';
import { UnpyMatchParticipantsFilters } from 'domain/event/match/MatchParticipant';
import { UnpyMatchFilters } from 'domain/event/match/UnpyMatch';
import { EventParticipantFilters } from 'domain/event/participants/EventParticipant';
import { UnpyEventFilters } from 'domain/event/UnpyEvent';
import { MessageFilters } from 'domain/message/Message';
import { NotificationFilters } from 'domain/notification/UnpyNotification';
import { StructureProfilFilters } from 'domain/profil/StructureProfil';
import { UserProfilFilters } from 'domain/profil/UserProfil';
import { RelationFilters } from 'domain/relation/Relation';
import { StructureFilters } from 'domain/structure/Structure';
import { StructureMemberFilters } from 'domain/structureMember/StructureMember';
import { TeamFilters } from 'domain/team/Team';
import { RootState } from 'store';

export type KeyDomainFilters =
  | string
  | 'structures-list-profil'
  | 'myStructuresPage-created'
  | 'myStructuresPage-joined'
  | 'relationsList'
  | 'myActivitiesPage'
  | 'mercatoPlayer'
  | 'mercatoStructure'
  | 'structure'
  | 'structureMember'
  | 'event'
  | 'teamEventParam'
  | 'event-classement-param-list'
  | 'teamEventView'
  | 'teamActivities'
  | 'relation'
  | 'all-notification'
  | 'mercato-notification'
  | 'event-notification'
  | 'social-notification'
  | 'divers-notification'
  | 'notification-preview'
  | 'conversation-list'
  | 'matchs-ligue-filters'
  | 'matchs-ligue-br-filters'
  | 'event-participants-param-list'
  | 'groups-ligue-filters'
  | 'divers';

export type FiltersStoreItems =
  | UserProfilFilters
  | UnpyEventFilters
  | StructureProfilFilters
  | StructureFilters
  | StructureMemberFilters
  | NotificationFilters
  | TeamFilters
  | ClassementFilters
  | MessageFilters
  | EventParticipantFilters
  | UnpyMatchParticipantsFilters
  | UnpyMatchFilters
  | {
      page?: number;
      nbPerPage?: number;
    }
  | RelationFilters;

export type FiltersSliceItem<F extends FiltersStoreItems> = {
  statePanel: 'OPEN' | 'CLOSED';
  filters: F;
  page?: number;
  nbPerPage?: number;
};

type InitialFiltersSliceState = {
  [key in KeyDomainFilters]: FiltersSliceItem<FiltersStoreItems>;
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState: {
    mercatoPlayer: {
      statePanel: 'CLOSED',
      filters: {},
    },
    mercatoStructure: {
      statePanel: 'CLOSED',
      filters: {},
    },
    event: {
      statePanel: 'CLOSED',
      filters: {},
    },
    divers: {
      statePanel: 'CLOSED',
      filters: {},
    },
    structure: {
      statePanel: 'CLOSED',
      filters: {},
    },
    structureMember: {
      statePanel: 'CLOSED',
      filters: {},
    },
    relation: {
      statePanel: 'CLOSED',
      filters: {},
    },
  } as InitialFiltersSliceState,
  reducers: {
    setFiltersByKey: (
      state,
      action: PayloadAction<{
        key: KeyDomainFilters;
        filters: FiltersStoreItems;
      }>,
    ) => {
      if (!state[action.payload.key]) {
        state[action.payload.key] = {
          statePanel: 'CLOSED',
          filters: action.payload.filters,
        };
      } else {
        state[action.payload.key] = {
          ...state[action.payload.key],
          page: 0,
          filters: action.payload.filters,
        };
      }
      return state;
    },
    resetDomainFilters: (state, action: PayloadAction<KeyDomainFilters>) => {
      state[action.payload] = {
        statePanel: 'CLOSED',
        filters: {},
        page: 0,
        nbPerPage: 0,
      };
      return state;
    },
    changePageByKey: (
      state,
      action: PayloadAction<{
        key: KeyDomainFilters;
        page: number;
      }>,
    ) => {
      state = {
        ...state,
        [action.payload.key]: {
          ...(state[action.payload.key] || {}),
          page: action.payload.page,
        },
      };
      return state;
    },
    changeNbPerPageByKey: (
      state,
      action: PayloadAction<{
        key: KeyDomainFilters;
        nbPerPage: number;
      }>,
    ) => {
      state = {
        ...state,
        [action.payload.key]: {
          ...(state[action.payload.key] || {}),
          nbPerPage: action.payload.nbPerPage,
        },
      };
      return state;
    },
    setPanelOpenByDomain: (
      state,
      action: PayloadAction<{
        key: KeyDomainFilters;
        isOpen: boolean;
      }>,
    ) => {
      state[action.payload.key].statePanel = action.payload.isOpen ? 'OPEN' : 'CLOSED';
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setFiltersByKey,
  setPanelOpenByDomain,
  changeNbPerPageByKey,
  changePageByKey,
  resetDomainFilters,
} = filtersSlice.actions;

export default filtersSlice.reducer;

export const selectDomainFilters = (_: RootState, key: KeyDomainFilters) =>
  _.filters?.[key];

export const selectFiltersByDomain = (_: RootState, key: KeyDomainFilters) =>
  _.filters?.[key]?.filters;

export const selectIsPanelOpenByDomain = (_: RootState, key: KeyDomainFilters) =>
  _.filters?.[key]?.statePanel === 'OPEN';
