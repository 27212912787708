import './UnpyLogo.scss';

import LogoUpCropped from '@assets/Logo/up/LogoUpCropped.png';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { routesConfig } from '../../Configs/Routes.config';

type UnpyLogoProps = {
  className?: string;
  size?: 'small' | 'medium' | 'big' | 'bigger' | 'filled';
  withoutLink?: boolean;
};
export const UnpyLogoUp = ({
  className,
  withoutLink,
  size = 'medium',
}: UnpyLogoProps) => {
  return (
    <div className={classNames('unpyLogo', className, size)}>
      {!withoutLink && (
        <Link to={routesConfig.up.to()}>
          <img
            loading={'lazy'}
            alt={'logo-unpy-up'}
            src={LogoUpCropped}
            className={'logo-up -logo -big'}
          />
        </Link>
      )}
      {withoutLink && <img alt={'logo-unpy-up'} src={LogoUpCropped} />}
    </div>
  );
};
