import { AxiosInstance } from 'axios';
import { Pageable } from 'domain/pageable/Pageable';
import { UserProfil, UserProfilFilters } from 'domain/profil/UserProfil';
import { User } from 'domain/user/User';
import { IUserProfilRepository } from 'domain/user/UserProfil.repository';
import { UserProfilPersonnalDataFormData } from 'primary/Parameters/user/Account/UserProfilPersonnalData';
import { UserProfilDetailsFormData } from 'primary/Parameters/user/Profil/UserDetails';
import { ProfilAppearenceFormData } from 'primary/Parameters/user/Profil/UserProfilAppearence';
import { UserProfilNetworkFormFormData } from 'primary/Parameters/user/Profil/UserProfilNetworkForm';
import { AccountInfosFormData } from 'primary/Parameters/user/Security/AccountInfosForm';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';
import { toUser } from 'secondary/user/RestUser';

import { toPageable } from '../RestPageable';
import { toUserProfil } from './RestUserProfil';

export class UserProfilRepository
  extends AxiosProviderRepository
  implements IUserProfilRepository
{
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  getConnectedUserProfil(): Promise<UserProfil> {
    return this.axios
      .get('/unpy/api/user/profil')
      .then((resp) => toUserProfil(resp.data));
  }

  search(
    filters: UserProfilFilters,
    page: number,
    nbPerPage: number,
  ): Promise<Pageable<UserProfil>> {
    return this.axios
      .get('/unpy/api/user/profil/search', {
        params: {
          ...filters,
          structure: filters.idStructure,
          page,
          nbPerPage,
        },
      })
      .then((response) => toPageable(response, toUserProfil));
  }

  getProfilById(idProfil: string): Promise<UserProfil> {
    return this.axios
      .get(`/unpy/api/user/profil/${idProfil}`)
      .then((resp) => toUserProfil(resp.data));
  }

  updateUserProfilAppearence(data: ProfilAppearenceFormData, file?: File) {
    const bodyFormData = new FormData();
    bodyFormData.set(
      'form',
      new Blob([JSON.stringify(data)], {
        type: 'application/json',
      }),
    );

    bodyFormData.set('file', file || '');

    return this.axios
      .put(`/unpy/api/user/profil/update/appearence`, bodyFormData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((resp) => toUserProfil(resp.data));
  }

  updateProfilUserDetails(data: UserProfilDetailsFormData): Promise<UserProfil> {
    return this.axios
      .put(`/unpy/api/user/profil/update/details`, data)
      .then((resp) => toUserProfil(resp.data));
  }

  updateProfilNetwork(data: UserProfilNetworkFormFormData): Promise<UserProfil> {
    const mappedData = Object.values(data.privacies).map((d) => ({
      code: d.code,
      profilId: d.profilId,
      isChecked: d.isChecked,
    }));
    return this.axios
      .put(`/unpy/api/user/profil/update/network`, {
        ...data,
        privacies: mappedData,
      })
      .then((resp) => toUserProfil(resp.data));
  }

  updateProfilUserPersonnalData(
    data: UserProfilPersonnalDataFormData,
  ): Promise<UserProfil> {
    return this.axios
      .put(`/unpy/api/user/profil/update/personnal`, data)
      .then((resp) => toUserProfil(resp.data));
  }

  askNewPassword(): Promise<void> {
    return this.axios.put(`/unpy/api/user/password/reset`);
  }

  updateUserProfilAccountInfos(data: AccountInfosFormData): Promise<User> {
    return this.axios
      .put(`/unpy/api/user/update/details`, data)
      .then((resp) => toUser(resp.data));
  }
}
