import './NotificationList.scss';

import { Grid } from '@mui/material';
import classNames from 'classnames';
import { KeyDomainFilters } from 'domain/filters/FiltersSlice';
import { NotificationTypeEnum } from 'domain/notification/NotificationTypeEnum';
import { CATEGORY_NOTIFICATION_MAP } from 'domain/notification/UnpyNotification..helper';
import { InfiniteScroll } from 'primary/Components/InfiniteScroll/InfiniteScroll';
import { InputRadios } from 'primary/Components/Input/InputRadios';
import { LayoutList } from 'primary/Components/Layout/Lists/LayoutList';
import { ListElements } from 'primary/Components/ListElements/ListElements';
import { UseFetchWithFilterPageReturn } from 'primary/hooks/useFetchWithFilterPage';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { ChangeHandler } from 'react-hook-form';

import {
  NotificationFilters,
  UnpyNotification,
} from '../../domain/notification/UnpyNotification';
import { NotificationListItem } from './NotificationListItem';

interface NotificationListProps {
  className?: string;
  currentUserId: number;
  typesToFetch?: NotificationTypeEnum[];
  isPreview?: boolean;
  ressourcesProps: {
    allContents?: UnpyNotification[];
    nextPage: () => void;
    loading: boolean;
    retry: () => void;
  };
}

export const NotificationListInPreview = ({
  className,
  isPreview,
  ressourcesProps,
}: NotificationListProps) => {
  const t = useTranslate();
  const { allContents, nextPage, loading, retry } = ressourcesProps;

  return (
    <div className={classNames('notificationList', className, { isPreview: isPreview })}>
      <InfiniteScroll
        loadingOnBottom
        onScrollBottom={() => nextPage()}
        isLoading={loading}
      >
        {() => (
          <>
            {!loading &&
              allContents &&
              allContents?.length !== 0 &&
              allContents?.map((message) => (
                <>
                  <NotificationListItem
                    key={message.id}
                    className={'-item'}
                    notification={message}
                    reFetchNotification={retry}
                  />
                </>
              ))}
            {!loading && allContents?.length === 0 && (
              <p>{t('notificationList.noContent')}</p>
            )}
          </>
        )}
      </InfiniteScroll>
    </div>
  );
};

type FiltersBarNotificationProps = {
  setFilters: (filters: NotificationFilters) => void;
  filters: NotificationFilters;
};

export const FiltersBarNotification = ({
  setFilters,
  filters,
}: FiltersBarNotificationProps) => {
  const t = useTranslate();
  return (
    <div className={'filtersBarNotification'}>
      <InputRadios
        asTag
        orientation={'horizontal'}
        value={filters.types}
        options={Object.keys(CATEGORY_NOTIFICATION_MAP).map((type) => ({
          // @ts-ignore
          label: t(`enum.notificationCategory.${type}`),
          value: type,
        }))}
        // @ts-ignore
        onChange={(e: ChangeHandler) => {
          // @ts-ignore
          onChange(e.target.value);
        }}
      />
    </div>
  );
};

interface NotificationListInPageProps {
  className?: string;
  currentUserId: number;
  typesToFetch?: NotificationTypeEnum[];
  keyDomainFilter: KeyDomainFilters;
  ressourceFetched: UseFetchWithFilterPageReturn<UnpyNotification, NotificationFilters>;
}
export const NotificationListInPage = ({
  currentUserId,
  className,
  keyDomainFilter,
  ressourceFetched,
}: NotificationListInPageProps) => {
  const {
    setFilters,
    changeNbPerPage,
    activeFilters,
    fetchedResource,
    goToPage,
    loading,
    retry,
  } = ressourceFetched;

  return (
    <div className={classNames('notificationList')}>
      <LayoutList
        opaq
        className={'-layoutListNotification'}
        tablePagination
        resetFilters={() => setFilters({})}
        keyDomainFilter={keyDomainFilter}
        paginationProps={{
          goToPage: goToPage,
          currentPage: fetchedResource?.currentPage,
          numberOfItem: fetchedResource?.totalElementsCount,
          setRowPerPage: changeNbPerPage,
          numberOfItemPerPage: fetchedResource?.pageSize,
          pagesCount: fetchedResource?.pagesCount || 0,
        }}
        pagination
        listComponent={
          <ListElements loading={loading} empty={fetchedResource?.content.length === 0}>
            <Grid container>
              {!loading &&
                fetchedResource?.totalElementsCount !== 0 &&
                fetchedResource?.content.map((message) => (
                  <NotificationListItem
                    key={message.id}
                    className={'-item'}
                    notification={message}
                    reFetchNotification={retry}
                  />
                ))}
            </Grid>
          </ListElements>
        }
        // @ts-ignore
        filtersTag={activeFilters?.filters}
        noTags
        onDeleteFilter={(filter) => {
          setFilters({ ...activeFilters?.filters, [filter.key]: undefined });
        }}
      />
    </div>
  );
};
