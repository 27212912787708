import './ProfilList.scss';

import { KeyDomainFilters } from 'domain/filters/FiltersSlice';
import { GAME_ACTIVE_LIST } from 'domain/game/GameActiveList';
import { StructureProfil, StructureProfilFilters } from 'domain/profil/StructureProfil';
import { debounce } from 'lodash';
import { AutocompleteStructureProfil } from 'primary/Components/AutoCompleteUserProfil/AutocompleteStructureProfil';
import { ListElements } from 'primary/Components/ListElements/ListElements';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { useEffect } from 'react';

import { MercatoStructureFilters } from '../Components/Filters/MercatoStructure/MercatoStructureFilters';
import { LayoutList } from '../Components/Layout/Lists/LayoutList';
import { PreviewCardProfil } from '../Components/Profils/PreviewCardProfil';
import { useContextDependency } from '../hooks/useContextDependency';
import { useFetchWithFilterPage } from '../hooks/useFetchWithFilterPage';

interface StructureProfilListProps {
  gamesSelected: string[];
  asCreator?: boolean;
  asMember?: boolean;
  keyDomainFilter: KeyDomainFilters;
  titleList?: string;
}

export const StructureProfilList = ({
  gamesSelected,
  asCreator,
  asMember,
  keyDomainFilter,
  titleList,
}: StructureProfilListProps) => {
  const { structureProfilRepository } = useContextDependency();
  const defaultFilters: StructureProfilFilters = {
    games: gamesSelected,
    experiences: undefined,
    platforms: undefined,
    langs: undefined,
  };
  const staticFilters =
    asCreator !== undefined || asMember !== undefined
      ? {
          asCreator: asCreator || false,
          asMember: asMember || false,
        }
      : undefined;
  const {
    fetchedResource,
    changeNbPerPage,
    goToPage,
    loading,
    activeFilters,
    setFilters,
  } = useFetchWithFilterPage<StructureProfil, StructureProfilFilters>({
    keyStoreFilters: keyDomainFilter,
    staticFilters: staticFilters,
    filters: defaultFilters,
    callApi: (filters, page, nbPerPage) =>
      structureProfilRepository.search(filters, page, nbPerPage),
  });

  const t = useTranslate();

  useEffect(() => {
    setFilters({
      games: gamesSelected,
      experiences: undefined,
      platforms: undefined,
      langs: undefined,
      ...staticFilters,
    });
  }, [gamesSelected]);

  const setFiltersDebonced = debounce((name: string) => {
    if (name?.length < 3) return;
    setFilters({ ...activeFilters, name: name });
  }, 100);

  return (
    <LayoutList
      filtersBarComponent={<MercatoStructureFilters setFilters={setFilters} />}
      filtersTag={activeFilters?.filters.games}
      onDeleteFilter={(filter) => {
        // @ts-ignore
        const keyGame = Object.keys(GAME_ACTIVE_LIST).find(
          (g) =>
            //@ts-ignore
            t(`filtersValues.${GAME_ACTIVE_LIST[g] as GAME_ACTIVE_LIST}`) ===
            filter.value,
        );
        setFilters({
          ['games']: activeFilters.filters.games?.filter((g) => g !== keyGame),
        });
      }}
      title={titleList}
      keyDomainFilter={keyDomainFilter}
      paginationProps={{
        goToPage: goToPage,
        currentPage: fetchedResource?.currentPage,
        numberOfItem: fetchedResource?.totalElementsCount,
        setRowPerPage: changeNbPerPage,
        numberOfItemPerPage: fetchedResource?.pageSize,
        pagesCount: fetchedResource?.pagesCount || 0,
      }}
      pagination
      tablePagination
      listComponent={
        <ListElements loading={loading} empty={fetchedResource?.content.length === 0}>
          <div className={'-containerList'}>
            {fetchedResource?.content.map((profil) => (
              <div key={profil.id} className={'list__elements'}>
                <PreviewCardProfil
                  className={'-itemLayoutList'}
                  key={profil.id}
                  profil={profil}
                  inPage
                />
              </div>
            ))}
          </div>
        </ListElements>
      }
      searchTextComponent={
        <AutocompleteStructureProfil
          onChangeText={setFiltersDebonced}
          options={
            fetchedResource?.content?.map((option) => option.structure?.name) ?? []
          }
        />
      }
      // @ts-ignore
      keyFiltersWithoutTag={['asCreator', 'asMember']}
      resetFilters={() => setFilters({})}
    />
  );
};
