import './Mercato.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Grid } from '@mui/material';
import classNames from 'classnames';
import { LayoutPageListFitlered } from 'primary/Components/Layout/Pages/LayoutPageListFiltered';
import { UnpyTabs } from 'primary/Components/Tabs/UnpyTabs';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useTranslate } from 'primary/hooks/useTranslate';
import { useSelectionGameListPage } from 'primary/Mercato/Mercato.helper';
import { MercatoGameCarroussel } from 'primary/Mercato/MercatoPlayer';
import { StructureProfilList } from 'primary/ProfilList/StructureProfilList';
import { UserProfilList } from 'primary/ProfilList/UserProfilList';
import React, { FC, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { RootState } from 'store';

import { Section } from '../Components/Section/Section';

export const MercatoPage: FC = () => {
  return (
    <Grid className={classNames('mercato')}>
      <Section className={'mercato__content'}>
        <div className={'-backgroundList'}></div>
        <Outlet />
      </Section>
    </Grid>
  );
};

export const MercatoPageRefonte: FC = () => {
  const t = useTranslate();
  const { selectedGamesCode, handleSelection } =
    useSelectionGameListPage('mercatoPlayer');
  const teamCreating = useSelector((state: RootState) => state.teamCreating.teamCreating);

  const refPortal = useRef<HTMLDivElement>();
  const location = useLocation();

  const tabsConf = [
    {
      label: t(`mercatoTabs.players`),
      component: (
        <UserProfilList
          title={
            teamCreating ? t('mercato.titleComposeTeam') : t('mercato.titleSearchPlayer')
          }
          gamesSelected={selectedGamesCode}
          filters={
            teamCreating
              ? {
                  games: selectedGamesCode,
                  idStructure: teamCreating?.data?.structure,
                }
              : undefined
          }
        />
      ),
      to: routesConfig.mercatoSubPath.mercatoPlayer.to,
    },
    {
      label: t(`mercatoTabs.structures`),
      component: (
        <StructureProfilList
          titleList={t('mercato.titleSearchStructure')}
          keyDomainFilter={'mercatoStructure'}
          gamesSelected={selectedGamesCode}
        />
      ),
      to: routesConfig.mercatoSubPath.mercatoStructure.to,
    },
  ];

  return (
    <Grid className={classNames('mercato')}>
      <div className={'-diagonal-effect'}></div>
      <h1 className={'-absoluteTitle'}>{t('mercato.absoluteTitle')}</h1>
      <Section className={'mercato__content'}>
        <LayoutPageListFitlered
          mainFilterContent={
            <div className={'-headerMercatoPlayer'}>
              <MercatoGameCarroussel
                selectedGames={selectedGamesCode}
                handleSelection={handleSelection}
              />
              <UnpyTabs
                black
                tabsConf={tabsConf}
                center
                asFlex
                asTitle
                portalElement={refPortal.current}
              />
            </div>
          }
          listContent={
            <div
              className={'-content'}
              // @ts-ignore
              ref={refPortal}
            ></div>
          }
        />
      </Section>
    </Grid>
  );
};
