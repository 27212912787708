import { InfoOutlined } from '@mui/icons-material';
import { EventStatusEnum } from 'domain/event/EventStatusEnum';
import { TypeEventCodeEnum } from 'domain/event/TypeEventCodeEnum';
import { Title } from 'primary/Components/Title/Title';
import { PodiumView } from 'primary/events/components/PodiumView';
import { ClassementParticipantDatataleParameters } from 'primary/events/datatable/ClassementParticipantDatatableParameters';
import { EventViewPageContext } from 'primary/events/item/EventViewPage';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { useOutletContext } from 'react-router-dom';

export const ClassementView = () => {
  const { event } = useOutletContext<EventViewPageContext>();
  const t = useTranslate();
  if (!event) return null;
  return (
    <div className={'classement'}>
      <Title
        title={t('event.parameters.classement.title')}
        level={1}
        compensatePadding
        threeQuarter
        bordered
      />
      {[EventStatusEnum.DRAFT, EventStatusEnum.PUBLISH].includes(event.status) && (
        <div className={'-infosWithText'}>
          <InfoOutlined />
          <span>{t('eventNotBegun')}</span>
        </div>
      )}
      {![EventStatusEnum.DRAFT, EventStatusEnum.PUBLISH].includes(event.status) && (
        <>
          {(event.typeEvent.code === TypeEventCodeEnum.LIGUE ||
            event.typeEvent.code === TypeEventCodeEnum.LIG_BR) && (
            <ClassementParticipantDatataleParameters event={event} />
          )}
          {(event.typeEvent.code === TypeEventCodeEnum.TOURNAMENT ||
            event.typeEvent.code === TypeEventCodeEnum.TOURNAMENT_DOUBLE_ELEM) && (
            <div className={'-eventBracketPodiumView'}>
              <PodiumView event={event} />
            </div>
          )}
        </>
      )}
    </div>
  );
};
