import { UnpyMatch } from 'domain/event/match/UnpyMatch';
import { RestMatchGroup, toMatchGroup } from 'secondary/event/match/RestMatchGroup';
import {
  RestMatchParticipant,
  toMatchParticipant,
} from 'secondary/event/match/RestMatchParticipant';

export interface RestUnpyMatch {
  id: number;
  group: RestMatchGroup;
  participants: RestMatchParticipant[];
  looseMatch: boolean;
  looseMatchId?: number;
  nextMatchId?: number;
  numberBO?: number;
  startDate?: string;
}

export function toUnpyMatch(restUnpyMatch: RestUnpyMatch) {
  return new UnpyMatch(
    restUnpyMatch.id,
    toMatchGroup(restUnpyMatch.group),
    restUnpyMatch.participants ? restUnpyMatch.participants.map(toMatchParticipant) : [],
    restUnpyMatch.looseMatch,
    restUnpyMatch.looseMatchId,
    restUnpyMatch.nextMatchId,
    restUnpyMatch.numberBO,
    restUnpyMatch.startDate ? new Date(restUnpyMatch.startDate) : undefined,
  );
}
