import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Neon } from 'domain/neon/Neon';
import { PlatformsRow } from 'primary/Components/PlatformsRow/PlatformsRow';
import { TalkLangsRow } from 'primary/TalkLangsRow/TalkLangsRow';
import React, { createRef } from 'react';
import Slider from 'react-slick';

import { UserProfil } from '../../../../../domain/profil/UserProfil';
import { Structure } from '../../../../../domain/structure/Structure';
import { useContextDependency } from '../../../../hooks/useContextDependency';
import { useRetrieveFromDomain } from '../../../../hooks/useRetrieveFromDomain';
import { useTranslate } from '../../../../hooks/useTranslate';

type UserProfilPlatformTeamProps = {
  profil: UserProfil;
  neon?: Neon;
};

type StructureOverviewProps = {
  structure: Structure;
};

export const ProfilHeaderLeft = ({ profil, neon }: UserProfilPlatformTeamProps) => {
  const { structureRepository } = useContextDependency();
  const [structures] = useRetrieveFromDomain(
    () => structureRepository.getStructuresOfUser(profil?.userId as number),
    undefined,
    !!profil?.userId,
  );
  const refSlider = createRef<Slider>();
  const t = useTranslate();
  return (
    <div className={'profilHeaderLeft'}>
      <div className={'profilHeaderLeft__item'}>
        <TalkLangsRow
          country={profil?.country}
          langs={profil?.talkLangs?.map((lang) => lang.code)}
        />
      </div>
      <div className={'profilHeaderLeft__item'}>
        <PlatformsRow
          big
          neon={neon}
          platforms={profil?.platforms?.map((platform) => platform.code)}
        />
      </div>
    </div>
  );
};
