import './GameSelectionCaroussel.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { ArrowLeft, ArrowRight, Check } from '@mui/icons-material';
import { Hidden, IconButton, useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import { ListGameSelectable } from 'primary/Components/UserStatsGameForms/ListGameSelectable';
import React, { createRef, useCallback, useMemo } from 'react';
import Slider from 'react-slick';

import { GAME_ACTIVE_LIST, GAME_IMAGES } from '../../../domain/game/GameActiveList';

interface GameSelectionCarousselProps {
  onClick?: (value: GAME_ACTIVE_LIST, type: 'ADD' | 'REMOVE') => void;
  selectedValues: GAME_ACTIVE_LIST[];
  gamesToShow?: GAME_ACTIVE_LIST[];
  withoutControl?: boolean;
  withoutDots?: boolean;
  nativeArrow?: boolean;
  slidesToShow?: number;
  slidesToScroll?: number;
  className?: string;
  rows?: number;
  slidePerRow?: number;
  labelNoGames?: string;
  embeded?: boolean;
  center?: boolean;
  infinite?: boolean;
  noCaroussel?: boolean;
}

const _getFilteredGames = (gameCodeToFilter?: GAME_ACTIVE_LIST[]): GAME_ACTIVE_LIST[] => {
  if (gameCodeToFilter) {
    return Object.keys(GAME_ACTIVE_LIST).filter((key) =>
      gameCodeToFilter.includes(key as GAME_ACTIVE_LIST),
    ) as GAME_ACTIVE_LIST[];
  }
  return Object.keys(GAME_ACTIVE_LIST) as GAME_ACTIVE_LIST[];
};

export const GameSelectionCaroussel = ({
  onClick,
  selectedValues,
  withoutControl,
  slidesToShow,
  slidesToScroll,
  className,
  gamesToShow,
  rows,
  slidePerRow,
  labelNoGames,
  embeded,
  center,
  infinite,
  withoutDots,
  nativeArrow,
  noCaroussel,
}: GameSelectionCarousselProps) => {
  const refSlider = createRef<Slider>();
  const filteredGames = useMemo(() => _getFilteredGames(gamesToShow), [gamesToShow]);
  const noFilteredGames = useMemo(
    () => !filteredGames || filteredGames?.length === 0,
    [filteredGames],
  );
  // @ts-ignore
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const isEmbedOrMobile = embeded || isMobile;

  const selectGame = useCallback(
    (game: GAME_ACTIVE_LIST) => {
      onClick?.(game, selectedValues.find((value) => value === game) ? 'REMOVE' : 'ADD');
    },
    [onClick, selectedValues],
  );

  const responsiveConf = [
    {
      breakpoint: 1240,
      settings: {
        slidesPerRow: slidePerRow || 1,
        slidesToShow: slidesToShow || 1,
        rows: rows || 1,
        slidesToScroll: slidesToScroll || 1,
        infinite: infinite,
        dots: !withoutDots,
        arrows: nativeArrow,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesPerRow: 2,
        slidesToShow: 1,
        rows: rows || 1,
        slidePerRow: 2,
        slidesToScroll: slidesToScroll || 1,
        infinite: infinite,
        dots: !withoutDots,
        arrows: nativeArrow,
      },
    },
    {
      breakpoint: 824,
      settings: {
        slidesPerRow: 1,
        slidesToShow: 1,
        rows: rows || 1,
        slidePerRow: 2,
        slidesToScroll: slidesToScroll || 1,
        infinite: infinite,
        dots: !withoutDots,
        arrows: nativeArrow,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesPerRow: 1,
        slidesToShow: 1,
        rows: 1,
        slidesToScroll: 1,
        infinite: infinite,
        dots: !withoutDots,
        arrows: nativeArrow,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesPerRow: 1,
        slidesToShow: 1,
        rows: 1,
        slidesToScroll: 1,
        infinite: infinite,
        dots: !withoutDots,
        arrows: nativeArrow,
      },
    },
  ];

  if (noCaroussel) {
    return (
      <ListGameSelectable
        games={filteredGames}
        gamesSelected={selectedValues}
        action={selectGame}
      />
    );
  }

  return (
    <>
      <Hidden smDown={!embeded}>
        <div
          className={classNames('gameSelectionCaroussel', className, {
            '-multiplerows': rows && rows > 1,
            '-noContent': noFilteredGames,
            '-embeded': isEmbedOrMobile,
            '-withFooterArrow': !withoutControl && isEmbedOrMobile,
          })}
        >
          {!withoutControl && !isEmbedOrMobile && (
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                refSlider?.current?.slickPrev();
              }}
              disabled={noFilteredGames}
              className={'gameSelectionCaroussel__arrow -left'}
            >
              <ArrowLeft
                width={isEmbedOrMobile ? '25px' : '50px'}
                height={isEmbedOrMobile ? '25px' : '50px'}
              />
            </IconButton>
          )}
          {!noFilteredGames && (
            <Slider
              ref={refSlider}
              speed={500}
              slidesToScroll={slidesToScroll || 1}
              rows={rows || 1}
              slidesPerRow={slidePerRow || 1}
              slidesToShow={slidesToShow || 1}
              swipeToSlide
              arrows={nativeArrow}
              dots={!withoutDots || !isEmbedOrMobile}
              infinite={infinite}
              centerMode={true}
              className={'center'}
              responsive={responsiveConf}
            >
              {!noFilteredGames &&
                filteredGames.map((key: GAME_ACTIVE_LIST) => (
                  <div
                    className={classNames('gameSelectionCaroussel__item', {
                      '-notClickable': !embeded && !onClick,
                      '-embeded': embeded,
                    })}
                    key={key}
                  >
                    <div className={'-selectedWrapper'}>
                      <img
                        src={GAME_IMAGES[key as GAME_ACTIVE_LIST] as string}
                        alt={key}
                        onClick={(e) => {
                          if (onClick) {
                            onClick(
                              key as GAME_ACTIVE_LIST,
                              selectedValues.find((value) => value === key)
                                ? 'REMOVE'
                                : 'ADD',
                            );
                          } else {
                            e.preventDefault();
                          }
                        }}
                      />
                      {selectedValues.find((value) => value === key) && (
                        <div className={'gameSelectionCaroussel__item-overSelected'}>
                          <Check color={'success'} />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </Slider>
          )}
          {noFilteredGames && (
            <div className={'gameSelectionCaroussel__noContent'}>
              <span className={'-noGames'}>{labelNoGames}</span>
            </div>
          )}
          {!withoutControl && !isEmbedOrMobile && (
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                e.bubbles = false;
                refSlider?.current?.slickNext();
              }}
              disabled={noFilteredGames}
              className={'gameSelectionCaroussel__arrow -right'}
            >
              <ArrowRight
                width={isEmbedOrMobile ? '25px' : '50px'}
                height={isEmbedOrMobile ? '25px' : '50px'}
              />
            </IconButton>
          )}
          {!withoutControl && isEmbedOrMobile && (
            <div className={'gameSelectionCaroussel__arrowFooter'}>
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  e.bubbles = false;
                  refSlider?.current?.slickPrev();
                  return e;
                }}
                disabled={noFilteredGames}
                className={'--arrow-right'}
              >
                <ArrowLeft
                  width={isEmbedOrMobile ? '25px' : '50px'}
                  height={isEmbedOrMobile ? '25px' : '50px'}
                />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  refSlider?.current?.slickNext();
                }}
                disabled={noFilteredGames}
                className={'--arrow-right'}
              >
                <ArrowRight
                  width={isEmbedOrMobile ? '25px' : '50px'}
                  height={isEmbedOrMobile ? '25px' : '50px'}
                />
              </IconButton>
            </div>
          )}
        </div>
      </Hidden>
      {!embeded && (
        <Hidden smUp>
          <ListGameSelectable
            games={filteredGames}
            gamesSelected={selectedValues}
            action={selectGame}
          />
        </Hidden>
      )}
    </>
  );
};
