import { Pageable } from '../domain/pageable/Pageable';
import { RestWrapper } from './RestWrapper';

export interface RestPageable<T> {
  results?: T[] | undefined;
  page: number;
  nbPerPage: number;
  totalPage: number;
  totalElementInResult: number;
}

export function toPageable<T, U>(
  response: RestWrapper<RestPageable<T>>,
  transform: (item: T) => U,
): Pageable<U> {
  return new Pageable(
    response.data.results ? response.data.results.map(transform) : [],
    response.data.page,
    response.data.nbPerPage,
    response.data.totalPage,
    response.data.totalElementInResult,
  );
}
