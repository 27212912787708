import './OtherProfilPhotoOverview.scss';

import React, { FC } from 'react';

import { OtherUserProfilPhotoOverview } from './OtherUserProfilPhotoOverview';

export type PhotoSize = 'big' | 'medium' | 'small';

export const ATTR_BY_SIZE: Record<PhotoSize, { width: number; height: number }> = {
  big: {
    width: 300,
    height: 300,
  },
  medium: {
    width: 150,
    height: 150,
  },
  small: {
    width: 80,
    height: 80,
  },
};
interface ProfilPhotoOverviewProps {
  className?: string;
  photo?: string;
  displayName?: string;
  idUser: number;
  size: PhotoSize;
}

export const WrapperOtherProfilPhotoOverview: FC<ProfilPhotoOverviewProps> = ({
  className,
  photo,
  displayName,
  idUser,
  size,
}) => {
  return (
    <OtherUserProfilPhotoOverview
      idUserProfl={idUser}
      photo={photo}
      displayName={displayName}
      className={className}
      size={size}
    />
  );
};
