import './UserPreviewCardProfil.scss';

import { InfoRounded, Settings } from '@mui/icons-material';
import classNames from 'classnames';
import { GAME_ACTIVE_LIST } from 'domain/game/GameActiveList';
import { GameProfil } from 'domain/gameProfil/GameProfil';
import {
  getName,
  getNeonBorderClassName,
  getNeonVariant,
} from 'domain/profil/Profil.func';
import { UserProfil } from 'domain/profil/UserProfil';
import {
  selectConnectedUserBlocked,
  selectConnectedUserFriend,
} from 'domain/relation/store/RelationSlice';
import { toEnum } from 'helper/enum.helper';
import { GameSelectionCaroussel } from 'primary/Components/GameSelectionCaroussel/GameSelectionCaroussel';
import { IconFlag } from 'primary/Components/Icon/IconFlag';
import { Neon } from 'primary/Components/Neon/Neon';
import { NoGamesIllustration } from 'primary/Components/NoGames/NoGamesIllustration';
import { PlatformsRow } from 'primary/Components/PlatformsRow/PlatformsRow';
import {
  PopperButton,
  PopperButtonsConf,
} from 'primary/Components/PopperButton/PopperButton';
import { ActionButtonAddFriend } from 'primary/Components/Profils/User/ActionButton/ActionButtonAddFriend';
import { ActionButtonBlock } from 'primary/Components/Profils/User/ActionButton/ActionButtonBlock';
import { ActionButtonInviteStructure } from 'primary/Components/Profils/User/ActionButton/ActionButtonInviteStructure';
import { ActionButtonSendMessage } from 'primary/Components/Profils/User/ActionButton/ActionButtonSendMessage';
import { TeamCreatingAction } from 'primary/Components/Profils/User/TeamCreatingActions';
import { WrapperOtherProfilPhotoOverview } from 'primary/Components/User/WrapperOtherProfilPhotoOverview';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useBooleanToggle } from 'primary/hooks/useBooleanToggle';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

interface UserPreviewCardProfilProps {
  profil: UserProfil;
  userName: string;
  className?: string;
  inPage?: boolean;
  draftPhoto?: File;
  noAction?: boolean;
}

interface ToggleGameStatsProps {
  profil: UserProfil;
  inPage?: boolean;
}
const ToggleGameStats = ({ profil, inPage }: ToggleGameStatsProps) => {
  const [isOpenGames, toggleGames, openGames, closeGames] = useBooleanToggle(true);
  const [selectedGames, setSelectedGame] = useState<GAME_ACTIVE_LIST>();
  const handleSelection = (value: string, type: 'ADD' | 'REMOVE') => {
    if (type === 'ADD') {
      setSelectedGame(toEnum(GAME_ACTIVE_LIST, value));
    }
    if (type === 'REMOVE') {
      setSelectedGame(undefined);
    }
  };

  const t = useTranslate();
  return (
    <div className={'unpyStatsWithGames'}>
      {/*<Button*/}
      {/*  variant={'outlined'}*/}
      {/*  color={'primary'}*/}
      {/*  onClick={toggleGames}*/}
      {/*  className={'-toggleBtnGameStat'}*/}
      {/*>*/}
      {/*  <ChangeCircle />*/}
      {/*  {!isOpenGames*/}
      {/*    ? t('profil.previewCard.displayGames')*/}
      {/*    : t('profil.previewCard.displayStats')}*/}
      {/*</Button>*/}
      {/*<Collapse in={isOpenGames}>*/}
      <div className={'-games'}>
        <GameSelectionCaroussel
          gamesToShow={profil.games.map((value: GameProfil) => value.game.code)}
          selectedValues={[]}
          rows={1}
          slidePerRow={1}
          slidesToScroll={1}
          embeded
          infinite
          withoutDots
          center
        />
      </div>
      {/*</Collapse>*/}
      {/*<Collapse in={!isOpenGames}>*/}
      {/*  <div className={'-stats'}>*/}
      {/*    <StatsUnpyProfilView profil={profil} />*/}
      {/*  </div>*/}
      {/*</Collapse>*/}
    </div>
  );
};

export const UserPreviewCardProfil = ({
  profil,
  className,
  inPage,
  draftPhoto,
  noAction,
}: UserPreviewCardProfilProps) => {
  const neonBorderClassName = getNeonBorderClassName(profil);
  const { authRepository } = useContextDependency();
  const friends = useSelector(selectConnectedUserFriend);
  const block = useSelector(selectConnectedUserBlocked);

  const navigation = useNavigate();
  const handleClick = useCallback(
    (e) => {
      e.cancelBubble = true;
      e.preventDefault();
      e.stopPropagation();
      navigation(routesConfig.mercatoUserProfil.to(profil.id));
      return e;
    },
    [profil],
  );

  const handleClickParam = useCallback(() => {
    navigation(routesConfig.parametersUser.to());
  }, []);

  const t = useTranslate();
  // @ts-ignore
  const conf: PopperButtonsConf[] = useMemo(() => {
    if (profil.id === authRepository?.currentUser?.userProfil.id) {
      return [
        {
          label: t('profil.userProfilAction.infosMore'),
          action: handleClick,
          icon: <InfoRounded />,
        },
        {
          label: t('profil.userProfilAction.parameters'),
          action: handleClickParam,
          icon: <Settings />,
        },
      ];
    }
    return [
      {
        label: t('profil.userProfilAction.infosMore'),
        action: handleClick,
        icon: <InfoRounded />,
      },
      {
        button: <ActionButtonAddFriend profil={profil} withIcon />,
      },
      {
        button: <ActionButtonSendMessage profil={profil} withIcon />,
      },
      {
        button: <ActionButtonInviteStructure profil={profil} withIcon />,
      },
      {
        button: <ActionButtonBlock profil={profil} withIcon />,
      },
    ];
  }, [profil, friends, block]);

  return (
    <div className={'-paddingWrapper'}>
      <div className={'rightBarMenu'}>
        {!noAction && (
          <PopperButton
            conf={conf}
            popperName={'user-profil-preview'}
            placement={'right-start'}
          />
        )}
        <TeamCreatingAction profil={profil} />
      </div>

      <div
        key={profil.id}
        className={classNames(
          'userPreviewCardProfil',
          className,
          neonBorderClassName,
          { '-hoverable': !noAction },
          { '-pointerable': !noAction },
        )}
        style={{
          backgroundImage: `url("${profil.backgroundImage?.publicUrlSm}")`,
          backgroundSize: 'cover',
        }}
        onTouchStart={undefined}
        onTouchEnd={undefined}
      >
        {noAction && (
          <div className={classNames('background__transp')}>
            <div className={'userPreviewCardProfil__header'}>
              <div
                className={classNames('userPreviewCardProfil__header-photo', {
                  '-inPage': !!inPage,
                })}
              >
                <WrapperOtherProfilPhotoOverview
                  displayName={getName(profil)}
                  photo={
                    draftPhoto ? URL.createObjectURL(draftPhoto) : profil.publicPhotoUrl
                  }
                  className={classNames('photo', neonBorderClassName)}
                  idUser={profil.userId as number}
                  size={inPage ? 'small' : 'big'}
                />
                {profil.country && (
                  <span className={'-lang'} title={profil.country.libelle}>
                    <IconFlag codeLang={undefined} codeISO={profil.country.code} />
                  </span>
                )}
              </div>
              <div className={classNames('userPreviewCardProfil__header-infos')}>
                <div className={'-username'} title={profil.idPlayer}>
                  <h3>{profil.idPlayer}</h3>
                </div>
                <Neon
                  className={'neon -marged -threeQuarter'}
                  variant={getNeonVariant(profil?.neon)}
                />
                <div className={'-badges'}>
                  <PlatformsRow
                    variant={'contained'}
                    platforms={profil.platforms?.map((platform) => platform.code)}
                  />
                </div>
              </div>
            </div>
            <div className={'userPreviewCardProfil__content'}>
              <Neon
                className={'neon -threeQuarter -marginVertical'}
                variant={getNeonVariant(profil?.neon)}
              />
              <div className={'userPreviewCardProfil__content-row -third'}>
                {profil?.games?.length > 0 && (
                  <ToggleGameStats profil={profil} inPage={inPage} />
                )}
                {profil?.games?.length === 0 && <NoGamesIllustration />}
              </div>
            </div>
          </div>
        )}
        {!noAction && (
          <Link
            to={routesConfig.mercatoUserProfil.to(profil.id)}
            className={'-linkWrapper'}
          >
            <div className={classNames('background__transp')}>
              <div className={'userPreviewCardProfil__header'}>
                <div
                  className={classNames('userPreviewCardProfil__header-photo', {
                    '-inPage': !!inPage,
                  })}
                >
                  <WrapperOtherProfilPhotoOverview
                    displayName={getName(profil)}
                    photo={
                      draftPhoto ? URL.createObjectURL(draftPhoto) : profil.publicPhotoUrl
                    }
                    className={classNames('photo', neonBorderClassName)}
                    idUser={profil.userId as number}
                    size={inPage ? 'small' : 'big'}
                  />
                  {profil.country && (
                    <span className={'-lang'} title={profil.country.libelle}>
                      <IconFlag codeLang={undefined} codeISO={profil.country.code} />
                    </span>
                  )}
                </div>
                <div className={classNames('userPreviewCardProfil__header-infos')}>
                  <div className={'-username'} title={profil.idPlayer}>
                    <h3>{profil.idPlayer}</h3>
                  </div>
                  <Neon
                    className={'neon -marged -threeQuarter'}
                    variant={getNeonVariant(profil?.neon)}
                  />
                  <div className={'-badges'}>
                    <PlatformsRow
                      variant={'contained'}
                      platforms={profil.platforms?.map((platform) => platform.code)}
                    />
                  </div>
                </div>
              </div>
              <div className={'userPreviewCardProfil__content'}>
                <Neon
                  className={'neon -threeQuarter -marginVertical'}
                  variant={getNeonVariant(profil?.neon)}
                />
                <div className={'userPreviewCardProfil__content-row -third'}>
                  {profil?.games?.length > 0 && (
                    <ToggleGameStats profil={profil} inPage={inPage} />
                  )}
                  {profil?.games?.length === 0 && <NoGamesIllustration />}
                </div>
              </div>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};
