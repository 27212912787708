import './ImageSelectable.scss';

import { Check, Lock } from '@mui/icons-material';
import React from 'react';

export const ImageSelectable = (props: {
  onClick?: () => void;
  alt: string;
  imageUrl: string;
  isSelected: boolean;
  isLockUp?: boolean;
  helperText?: string;
}) => {
  return (
    <button
      className={'imageSelectable'}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        props.onClick?.();
      }}
    >
      <img
        alt={'background-' + props.alt}
        src={props.imageUrl}
        width={'100px'}
        height={'100px'}
      />
      {props.isSelected && (
        <div className={'imageSelectable__overSelected'}>
          <Check color={'success'} />
        </div>
      )}
      {props.isLockUp && (
        <div className={'imageSelectable__overLocked'}>
          <Lock />
        </div>
      )}
    </button>
  );
};
