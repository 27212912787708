import { AxiosInstance } from 'axios';
import { IInvoiceRepository } from 'domain/payment/IInvoice.repository';
import { Invoice } from 'domain/payment/Invoice';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';
import { toInvoice } from 'secondary/payment/RestInvoice';

export class InvoiceRepository
  extends AxiosProviderRepository
  implements IInvoiceRepository
{
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  getAll(): Promise<Invoice[]> {
    return this.axios.get(`unpy/api/invoices/all`).then((resp) => {
      return resp.data.map(toInvoice);
    });
  }

  getOneById(id: string): Promise<Invoice> {
    return this.axios.get(`unpy/api/invoice/${id}`).then((resp) => toInvoice(resp.data));
  }
}
