import { Product } from 'domain/payment/Product';
import { toEnum } from 'helper/enum.helper';
import { ProductCodeEnum } from 'secondary/payment/ProductCodeEnum';
import { RestPrice, toPrice } from 'secondary/payment/RestPrice';

export interface RestProduct {
  id: number;
  libelle: string;
  description: string;
  idStripe: string;
  price: RestPrice;
  code: string;
  active: boolean;
}

export function toProduct(restProduct: RestProduct) {
  return new Product(
    restProduct.id,
    restProduct.libelle,
    restProduct.description,
    restProduct.idStripe,
    toPrice(restProduct.price),
    toEnum(ProductCodeEnum, restProduct.code),
    restProduct.active,
  );
}
