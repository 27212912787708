import { PermissionStructureEnum } from 'domain/permissionStructure/PermissionStructureEnum';
import { toEnum } from 'helper/enum.helper';

import { PermissionStructure } from '../../domain/permissionStructure/PermissionStructure';

export interface RestPermissionStructure {
  id: number;
  code: string;
}

export function toPermission(restPermission: RestPermissionStructure) {
  return new PermissionStructure(
    restPermission.id,
    toEnum(PermissionStructureEnum, restPermission.code),
  );
}
