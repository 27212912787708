import { UnviewedMessageByConversation } from 'domain/message/UnviewedMessageByConversation';

export interface RestUnviewedMessageByConversation {
  idConversation: number;
  countUnviewed: number;
}

export function toUnviewedMessageByConversation(
  restUnviewedMessageByConversation: RestUnviewedMessageByConversation,
) {
  return new UnviewedMessageByConversation(
    restUnviewedMessageByConversation.idConversation,
    restUnviewedMessageByConversation.countUnviewed,
  );
}
