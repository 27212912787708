import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BackgroundImageView } from 'domain/backgroundImage/BackgroundImageView';
import { RootState } from 'store';

type BackgroundImageSliceStateById = { [key: number]: BackgroundImageView };

type InitialBackgroundImageSliceState = {
  byId?: BackgroundImageSliceStateById;
};

export const backgroundImageSlice = createSlice({
  name: 'backgroundImage',
  initialState: {
    byId: undefined,
  } as InitialBackgroundImageSliceState,
  reducers: {
    setBackgroundImages: (state, action: PayloadAction<BackgroundImageView[]>) => {
      action.payload.map((backgroundImage) => {
        if (!state.byId) state.byId = {};
        state.byId[backgroundImage.id] = backgroundImage;
      });
    },
    setBackgroundImageById: (state, action: PayloadAction<BackgroundImageView>) => {
      if (!state.byId) state.byId = {};
      state.byId[action.payload.id] = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBackgroundImages, setBackgroundImageById } =
  backgroundImageSlice.actions;

export default backgroundImageSlice.reducer;

const selectSelf = (state: RootState) => state;
const selectSelfWithId = (_: RootState, id: number) => id;
export const selectBackgroundImageById = createSelector(
  selectSelf,
  selectSelfWithId,
  (state, id) => state.backgroundImages.byId?.[id],
);

export const selectAllBackgroundImages = createSelector(selectSelf, (state) =>
  state.backgroundImages.byId ? Object.values(state.backgroundImages.byId) : undefined,
);
