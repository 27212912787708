import { GamingProfilEnum } from 'domain/enums/GamingProfil.enum';
import { toEnum } from 'helper/enum.helper';
import { RestPrivacy, toProfilPrivacy } from 'secondary/privacy/RestPrivacy';
import {
  RestUnpyStatistiquesProfil,
  toUnpyStatistiquesProfil,
} from 'secondary/profils/RestProfil';

import { UserProfil } from '../../domain/profil/UserProfil';
import {
  RestBackgroundImageView,
  toBackgroundImageView,
} from '../backgroundImage/RestBackgroundImageView';
import { RestCountry, toCountry } from '../country/RestCountry';
import { RestGameProfil, toGameProfil } from '../gameProfil/RestGameProfil';
import { RestLang, toLang } from '../lang/RestLang';
import { RestNeon, toNeon } from '../neon/RestNeon';
import { RestPlatform, toPlatform } from '../platform/RestPlatform';

export interface RestUserProfil {
  id: number;
  backgroundImage?: RestBackgroundImageView;
  country?: RestCountry;
  description?: string;
  gamingProfil?: string;
  idPlayer: string;
  lang?: string;
  neon: RestNeon;
  photo?: string;
  platforms?: RestPlatform[];
  video?: string;
  pingActive?: boolean;
  twitter?: string;
  facebook?: string;
  youtube?: string;
  instagram?: string;
  discord?: string;
  twitch?: string;
  games?: RestGameProfil[];
  talkLangs?: RestLang[];
  birthdate?: string;
  userId: number;
  publicPhotoUrl?: string;
  defaultPhoto?: boolean;
  privacies?: RestPrivacy[];
  unpyStatistiques?: RestUnpyStatistiquesProfil;
}

export function toUserProfil(restUserProfil: RestUserProfil): UserProfil {
  return new UserProfil(
    restUserProfil.id,
    restUserProfil.games ? restUserProfil.games.map((game) => toGameProfil(game)) : [],
    restUserProfil.idPlayer,
    toNeon(restUserProfil.neon),
    restUserProfil.userId,
    restUserProfil.backgroundImage
      ? toBackgroundImageView(restUserProfil.backgroundImage)
      : undefined,
    restUserProfil.description,
    restUserProfil.gamingProfil
      ? toEnum(GamingProfilEnum, restUserProfil.gamingProfil)
      : undefined,
    restUserProfil.lang,
    restUserProfil.platforms
      ? restUserProfil.platforms.map((platform) => toPlatform(platform))
      : undefined,
    restUserProfil.video,
    restUserProfil.pingActive,
    restUserProfil.twitter,
    restUserProfil.facebook,
    restUserProfil.youtube,
    restUserProfil.instagram,
    restUserProfil.discord,
    restUserProfil.twitch,
    restUserProfil.talkLangs
      ? restUserProfil.talkLangs.map((lang) => toLang(lang))
      : undefined,
    restUserProfil.privacies
      ? restUserProfil.privacies.map((privacy) => toProfilPrivacy(privacy))
      : undefined,
    restUserProfil.unpyStatistiques
      ? toUnpyStatistiquesProfil(restUserProfil.unpyStatistiques)
      : undefined,
    restUserProfil.birthdate ? new Date(restUserProfil.birthdate) : undefined,
    restUserProfil.country ? toCountry(restUserProfil.country) : undefined,
    restUserProfil.photo,
    restUserProfil.publicPhotoUrl,
    restUserProfil.defaultPhoto,
  );
}
