export class Environment {
  constructor(
    public readonly apiWebserviceUrl: string,
    public readonly keycloakUrl: string,
    public readonly keycloakRealm: string,
    public readonly keycloakClientId: string,
    public readonly socketUrl: string,
    public readonly stripeKey: string,
    public readonly forntUrl: string,
    public readonly sentryUrl: string,
  ) {}
}
