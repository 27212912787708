import './MatchLastScore.scss';

import { Add, ReadMore } from '@mui/icons-material';
import { Skeleton } from '@mui/lab';
import { Button, Chip } from '@mui/material';
import classNames from 'classnames';
import { toMatchLigueModel, UnpyMatch } from 'domain/event/match/UnpyMatch';
import { TypeEventCodeEnum } from 'domain/event/TypeEventCodeEnum';
import { MatchView } from 'primary/Components/bracket/MatchView';
import { KeyValue } from 'primary/Components/KeyValue/KeyValue';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import React from 'react';

import { useTranslate } from '../../hooks/useTranslate';

interface MatchLastScoreProps {
  match: UnpyMatch;
  className?: string;
}

interface MatchLastScoreSkeletonProps {
  className?: string;
}

export const MatchLastScoreSkelleton = ({ className }: MatchLastScoreSkeletonProps) => {
  const t = useTranslate();
  return (
    <div className={classNames('eventCard', className)}>
      <div className={'eventCard__img'}>
        <Skeleton animation="wave" height={'100%'} width="100%" />
      </div>
      <div className={'eventCard__content'}>
        <div className={'eventCard__content-creator'}>
          <Skeleton animation="wave" height={'100%'} width="100%" />
          <span>XXXXX</span>
        </div>
        <div className={'eventCard__content-infos'}>
          <div>
            <span className={'-nameEvent'}>XXXXX</span>
          </div>
          <div>
            <span className={'-startDateEvent'}>XXXXX</span>
          </div>
          <div className={'-overlayAdd'}>
            <Button
              color={'primary'}
              style={{ justifySelf: 'flex-end' }}
              variant={'contained'}
            >
              <Add />
              {t('event.card.skeleton.addOwn')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ActivitiesMatchLastScore = ({ match }: MatchLastScoreProps) => {
  const { authRepository } = useContextDependency();
  const t = useTranslate();
  if (!match) return null;
  const currentParticipant = match.participants.find((p) =>
    p.eventParticipant.team.players.find(
      (player) => player.player.id === authRepository.currentUser?.userProfil?.id,
    ),
  );
  const currentPlace =
    match.participants
      .sort((a, b) => (b.score ?? 0) - (a.score ?? 0))
      .findIndex((p) => p.id === currentParticipant?.id) +
    1 -
    match.participants.length;
  const matchFormatted = toMatchLigueModel(match);
  const group = match.group;

  return (
    <div className={classNames('matchLastScoreCard')}>
      <div className={'-scores'}>
        {group.event.typeEvent.code !== TypeEventCodeEnum.LIG_BR && (
          <MatchView
            className={'-lastScoreMatchView'}
            fullWidth
            guetteredBottom
            noBorder
            onEdited={() => console.log('EDITED')}
            key={matchFormatted.id}
            event={group.event}
            match={matchFormatted}
            topParty={matchFormatted.participants[0]}
            bottomParty={matchFormatted.participants[1]}
            bottomWon={matchFormatted.participants[1].isWinner ?? false}
            topWon={matchFormatted.participants[0].isWinner ?? false}
            isEditable={false}
            onMatchClick={() => console.log('CLICKED')}
            onPartyClick={() => console.log('PARTY CLICKED')}
            onMouseEnter={() => console.log('MOUSE ENTER')}
            onMouseLeave={() => console.log('MOUSE LEAVE')}
            topHovered={false}
            bottomHovered={false}
            bottomText={''}
            topText={''}
            // @ts-ignore
            resultFallback={(p: any) => 'ok'}
            teamNameFallback={'ok'}
          />
        )}
        {group.event.typeEvent.code === TypeEventCodeEnum.LIG_BR && (
          <Chip
            key={match.id}
            className={'-match'}
            label={
              <div className={'-brPosition'}>
                {t('event.lastScoreCard.positionBrLabel')}
                {currentPlace} / {match.participants.length}
              </div>
            }
          />
        )}
      </div>
      <div className={'-keyValues'}>
        <KeyValue keyValue={'évennement:'} value={group.event.name} accent />
        <KeyValue
          keyValue={'statut :'}
          value={t(`enum.eventStatus.${group.event.status}`)}
          accent
        />

        <Button
          color={'primary'}
          style={{ justifySelf: 'flex-end' }}
          variant={'text'}
          onClick={() => alert('ON CLICK')}
        >
          <ReadMore />
        </Button>
      </div>
    </div>
  );
};
