import './EventViewPage.scss';
import '../modals/ModalRegisterEvent.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Box, Button, Chip } from '@mui/material';
import classNames from 'classnames';
import { EventSubStatusEnum } from 'domain/event/EventStatusEnum';
import { TypeTeam } from 'domain/event/TypeTeam';
import { TypeTeamCodeEnum } from 'domain/event/TypeTeamCodeEnum';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import {
  getNumberOfTeamRegistered,
  getSubStatusOfEvent,
} from 'domain/event/UnpyEvent.func';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { GAME_ACTIVE_LIST, GAME_IMAGES } from 'domain/game/GameActiveList';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useCountdown } from 'primary/countDown/CountDown';
import { Infos } from 'primary/events/EventViewPageContents/Infos';
import { MenuEventViewPage } from 'primary/events/MenuEventViewPage';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { EventImage } from '../../Components/Event/EventImage';
import { KeyValue } from '../../Components/KeyValue/KeyValue';
import { SkeletonNoContent } from '../../Components/SkeletonNoContent/SkeletonNoContent';
import { ModalAddContent } from '../../Components/User/ModalAddContent';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useRetrieveFromDomain } from '../../hooks/useRetrieveFromDomain';
import { useTranslate } from '../../hooks/useTranslate';
import { menusEventViewPage } from '../MenusEventViewPage.config';
import ModalRegisterEvent from '../modals/ModalRegisterEvent';
import { EventActions } from './EventActions';

interface EventTextInfosProps {
  event?: UnpyEvent | UnpyEventView;
}

const EventTextInfos = ({ event }: EventTextInfosProps) => {
  const t = useTranslate();
  const [daysStartDate, hoursStartDate, minutesStartDate, secondsStartDate, isInPast] =
    useCountdown(event?.startDateTime as Date, true);
  const [days, hours, minutes, seconds] = useCountdown(event?.endSubscribeDate as Date);
  if (!event) return null;
  return (
    <div className={'-textInfos'}>
      <div className={'-column'}>
        {!getSubStatusOfEvent(event) && (
          <KeyValue
            alignStart
            keyValue={t('event.eventViewPage.header.textInfos.status')}
            value={t(`enum.eventStatus.${event.status}`)}
          />
        )}
        {getSubStatusOfEvent(event) &&
          getSubStatusOfEvent(event) === EventSubStatusEnum.SUBSCRIBE_OPEN && (
            <>
              <KeyValue
                alignStart
                keyValue={t('event.eventViewPage.header.textInfos.status')}
                // @ts-ignore
                value={t(`enum.eventSubStatus.${getSubStatusOfEvent(event)}.title`)}
              />
              <KeyValue
                alignStart
                keyValue={t('event.eventViewPage.header.textInfos.remainingPlace')}
                value={(
                  (event.maxTeam ?? event.maxTeam ?? 0) -
                  (getNumberOfTeamRegistered(event) ?? 0)
                ).toString()}
              />
              <KeyValue
                alignStart
                keyValue={t('event.eventViewPage.header.textInfos.endSubscribeDate')}
                value={t(`event.countDownLine`, {
                  day: days,
                  hour: hours,
                  minute: minutes,
                  second: seconds,
                })}
              />
            </>
          )}
        {getSubStatusOfEvent(event) &&
          getSubStatusOfEvent(event) === EventSubStatusEnum.SUBSCRIBE_CLOSE && (
            <>
              <KeyValue
                alignStart
                keyValue={t('event.eventViewPage.header.textInfos.status')}
                // @ts-ignore
                value={t(`enum.eventSubStatus.${getSubStatusOfEvent(event)}.title`)}
              />
              <KeyValue
                alignStart
                keyValue={
                  isInPast
                    ? t('event.eventViewPage.header.textInfos.startDatePassed')
                    : t('event.eventViewPage.header.textInfos.startDate')
                }
                value={t(`event.countDownLine`, {
                  day: daysStartDate,
                  hour: hoursStartDate,
                  minute: minutesStartDate,
                  second: secondsStartDate,
                })}
              />
            </>
          )}
      </div>
    </div>
  );
};

export type EventViewPageContext = { event: UnpyEvent };

export type EventViewPageProps = {
  eventForPreview?: UnpyEventView;
};

export const EventViewPage = ({ eventForPreview }: EventViewPageProps) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { eventRepository, authRepository } = useContextDependency();
  const [isModalRegisterOpen, setIsModalRegisterOpen] = useState(false);
  const [isModalCreateStructureOpen, setIsModalCreateStructureOpen] = useState(false);

  const [event, , , retry] = useRetrieveFromDomain(
    () => eventRepository.getEventById(id as string),
    undefined,
    !eventForPreview,
  );

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const [key, setKey] = useState(Math.random());

  if (!event && !eventForPreview) return <LoadingScreen loading />;
  const eventToUse = eventForPreview ?? event;
  if (!eventToUse) return null;

  return (
    <section className={classNames('eventViewPage')}>
      <div ref={ref} className={'eventViewPage__header'}>
        <div className={'-overlay'}></div>
        <div className={'-imgContainer'}>
          <SkeletonNoContent displayContent={!!eventToUse}>
            {eventToUse && <EventImage image={eventToUse.publicImageUrl} />}
          </SkeletonNoContent>
        </div>
        <SkeletonNoContent displayContent={!!eventToUse}>
          <div className={'-infoContainer'}>
            <div className={'-headerTitleWithAction'}>
              <h1>{eventToUse?.name}</h1>
              {!eventForPreview && eventToUse && (
                <EventActions
                  key={key}
                  className={'-actions'}
                  event={eventToUse}
                  currentUser={authRepository.currentUser}
                  openModalRegister={() => setIsModalRegisterOpen(true)}
                  retry={() => {
                    retry();
                    setKey(Math.random());
                  }}
                />
              )}
              {eventForPreview && eventToUse && (
                <Button
                  variant={'contained'}
                  onClick={() =>
                    navigate(
                      routesConfig.eventViewDetails.eventViewInfos.to(eventToUse.id),
                    )
                  }
                  color={'primary'}
                  className={'-registerBtn'}
                >
                  {t('goToPageEvent')}
                </Button>
              )}
            </div>
            <div className={'-infosWithImage'}>
              {eventToUse?.game.code && (
                <img
                  className={'-imgGame'}
                  src={GAME_IMAGES[eventToUse.game.code as GAME_ACTIVE_LIST]}
                  alt={eventToUse.game.code}
                />
              )}
              {eventToUse && <EventTextInfos event={eventToUse} />}
            </div>
            <div className={'-chipContainer'}>
              <Chip
                className={'-tag'}
                label={t(`enum.typeEventCode.${eventToUse?.typeEvent?.code}`)}
                variant="outlined"
              />
              {event?.typeTeam && (
                <Chip
                  className={'-tag'}
                  label={t(
                    `enum.typeTeamCode.${eventToUse?.typeTeam?.code as TypeTeamCodeEnum}`,
                  )}
                  variant="outlined"
                />
              )}
              <Chip
                className={'-tag'}
                label={
                  eventToUse?.prizeText ||
                  t('event.eventViewPage.header.textInfos.noPrize')
                }
                variant="outlined"
              />
            </div>
          </div>
        </SkeletonNoContent>
      </div>
      {eventForPreview && (
        <div className={classNames('eventViewPage__contentContainer')}>
          <Infos event={eventToUse} readOnly />
        </div>
      )}
      {!eventForPreview && (
        <div className={classNames('eventViewPage__contentContainer')}>
          <div className={classNames('-menu', { '-fixed': !inView })}>
            <MenuEventViewPage confs={menusEventViewPage.main} />
          </div>
          <div className={classNames('-content', { '-menufixed': !inView })}>
            <Outlet context={{ event: event }} />
          </div>
        </div>
      )}

      <ModalContainer
        handleClose={() => setIsModalRegisterOpen(false)}
        isOpen={isModalRegisterOpen}
        content={
          <Box className={'modal-register'}>
            {eventToUse && eventToUse?.typeTeam && (
              <ModalRegisterEvent
                idEvent={eventToUse.id}
                handleClose={() => setIsModalRegisterOpen(false)}
                typeTeam={eventToUse.typeTeam as TypeTeam}
                onSubmitted={() => {
                  retry();
                  setIsModalRegisterOpen(false);
                  setKey(Math.random());
                }}
              />
            )}
          </Box>
        }
      />
      <ModalAddContent
        isOpen={isModalCreateStructureOpen}
        handleClose={() => setIsModalCreateStructureOpen(false)}
        modalCreateType={'EVENT'}
      />
    </section>
  );
};
