import './AnnoncesList.scss';

import { Divider } from '@mui/material';
import classNames from 'classnames';
import { Annonce } from 'domain/annonce/Annonce';
import { getNeonBorderClassName } from 'domain/profil/Profil.func';
import { formatDate } from 'helper/date.helper';
import { WrapperOtherProfilPhotoOverview } from 'primary/Components/User/WrapperOtherProfilPhotoOverview';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { useEffect } from 'react';

interface AnnoncesListProps {
  annonces?: Annonce[];
  scrollToTop?: () => void;
}

interface AnnonceItemProps {
  annonce: Annonce;
}

const AnnonceItem = ({ annonce }: AnnonceItemProps) => {
  const t = useTranslate();
  return (
    <div className={'annonceItem'}>
      <div className={'annonceItem__header'}>
        <WrapperOtherProfilPhotoOverview
          size={'small'}
          displayName={annonce.creator.userProfil.idPlayer}
          photo={annonce.creator.userProfil.publicPhotoUrl}
          className={classNames(
            '-photo',
            '-photo',
            getNeonBorderClassName(annonce.creator.userProfil),
          )}
          idUser={annonce.creator.id as number}
        />
        <div className={'-nameWithDateContainer'}>
          <span className={'-name'}>{annonce.creator.userProfil.idPlayer}</span>
          <span className={'-date'}>
            {formatDate(annonce.create, 'readable(with hour)')}
          </span>
        </div>
        <Divider />
      </div>
      <div className={'annonceItem__content'}>
        <div
          dangerouslySetInnerHTML={{
            __html: annonce.text || t('profil.details.description.noContent'),
          }}
        ></div>
      </div>
    </div>
  );
};

export const AnnoncesList = ({ annonces, scrollToTop }: AnnoncesListProps) => {
  const t = useTranslate();
  useEffect(() => {
    scrollToTop?.();
  }, []);
  return (
    <div className={'annoncesList'}>
      {annonces?.map((annonce) => (
        <AnnonceItem key={annonce.id} annonce={annonce} />
      ))}
      {!annonces && <span className={'-noContent'}>{t('annonce.noContentList')}</span>}
    </div>
  );
};
