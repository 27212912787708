import { Grid, Switch } from '@mui/material';
import { Annonce } from 'domain/annonce/Annonce';
import { IAnnonceRepository } from 'domain/annonce/Annonce.repository';
import { AnnonceTypeEnum } from 'domain/annonce/AnnonceTypeEnum';
import { ValidationStringLengthEnum } from 'domain/enums/ValidationStringLengthEnum';
import { CoupleButtonValidCancel } from 'primary/Components/CoupleButtonValidCancel/CoupleButtonValidCancel';
import { InputTextArea } from 'primary/Components/Input/InputTextArea';
import { InputWrapper } from 'primary/Components/Input/InputWrapper';
import { sendEventToastMessage } from 'primary/Components/Toast/Toast.helper';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate, UseTranslateReturn } from 'primary/hooks/useTranslate';
import { useYupValidationResolver } from 'primary/hooks/useYupValidationResolver';
import React from 'react';
import { useController, useForm } from 'react-hook-form';
import * as yup from 'yup';

interface CreateEditAnnonceFormProps {
  eventId?: string;
  structureId?: string;
  onSubmitted: (annonce: Annonce) => void;
  onCancel: () => void;
  annonceToEdit?: Annonce;
  type: AnnonceTypeEnum;
}

export type CreateEditAnnoncesForm = {
  id?: number;
  typeAnnonce: string;
  isPublic: boolean;
  sendNotificaton: boolean;
  eventId: string;
  structureId?: string;
  text: string;
  sendNotification: boolean;
};

const validationSchema = (t: UseTranslateReturn) =>
  yup.object({
    typeAnnonce: yup.string().required(t('general.forms.errors.required')),
    isPublic: yup.boolean().required(t('general.forms.errors.required')),
    sendNotification: yup.boolean().required(t('general.forms.errors.required')),
    eventId: yup.string().nullable(),
    structureId: yup.string().nullable(),
    text: yup
      .string()
      .max(
        ValidationStringLengthEnum.XL,
        t('general.forms.errors.tooLong', {
          maxChar: ValidationStringLengthEnum.XL,
        }),
      )
      .required(t('general.forms.errors.required')),
  });

const onSubmitCreateEditAnnonce =
  (
    annonceRepository: IAnnonceRepository,
    onSubmitted: (annonce: Annonce) => void,
    t: UseTranslateReturn,
    idAnnonce?: number,
  ) =>
  (data: CreateEditAnnoncesForm) => {
    if (idAnnonce) {
      return annonceRepository.edit(data, idAnnonce).then((annonce: Annonce) => {
        sendEventToastMessage(t('annonce.edit.succeed'), 'success');
        onSubmitted(annonce);
      });
    } else {
      return annonceRepository.create(data).then((annonce: Annonce) => {
        sendEventToastMessage(t('annonce.create.succeed'), 'success');
        onSubmitted(annonce);
      });
    }
  };

export const CreateEditAnnonceForm = ({
  eventId,
  structureId,
  onSubmitted,
  onCancel,
  annonceToEdit,
  type,
}: CreateEditAnnonceFormProps) => {
  const t = useTranslate();
  const { annonceRepository, authRepository } = useContextDependency();
  const onSubmit = onSubmitCreateEditAnnonce(annonceRepository, onSubmitted, t);
  const resolver = useYupValidationResolver(validationSchema);
  const {
    handleSubmit,
    control,
    register,
    formState: { isSubmitting, errors },
  } = useForm<CreateEditAnnoncesForm>({
    resolver,
    defaultValues: {
      eventId,
      structureId: structureId,
      typeAnnonce: annonceToEdit?.typeAnnonce || type,
      isPublic: annonceToEdit?.isPublic || true,
      sendNotification: false,
      text: annonceToEdit?.text || '',
    },
  });

  const { field: textControl } = useController({
    control: control,
    name: 'text',
  });

  if (!eventId && !structureId) return null;
  return (
    <form className={'-addAnnonceForm'} onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid xs={12}>
          <Grid container sx={{ justifyContent: 'center' }}>
            <Grid xs={12} sm={6}>
              <InputWrapper
                noFullWidth
                className={'unpy-form__field'}
                label={t('annonce.form.isPublic.label')}
                errors={errors}
                Input={Switch}
                isSubmitting={false}
                required
                inputProps={{
                  ...register('isPublic'),
                  defaultChecked: annonceToEdit?.isPublic || true,
                }}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <InputWrapper
                noFullWidth
                className={'unpy-form__field'}
                label={t(`annonce.form.sendNotification.${type}`)}
                errors={errors}
                Input={Switch}
                isSubmitting={false}
                required
                inputProps={{
                  ...register('sendNotification'),
                  defaultChecked: annonceToEdit?.isPublic || true,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <InputWrapper
            inputClassName={'input-user-details '}
            labelClassName={'label-user-details '}
            label={t('annonce.form.text.label')}
            errors={errors}
            Input={InputTextArea}
            isSubmitting={isSubmitting}
            inputProps={{
              ...textControl,
              minRows: 3,
            }}
          />
        </Grid>
        <Grid xs={12}>
          <CoupleButtonValidCancel isTypeSubmit={true} onClickCancel={onCancel} />
        </Grid>
      </Grid>
    </form>
  );
};
