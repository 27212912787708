import { configureStore } from '@reduxjs/toolkit';
import BackgroundImageSlice from 'domain/backgroundImage/store/BackgroundImageSlice';
import FiltersSlice from 'domain/filters/FiltersSlice';
import NeonSlice from 'domain/neon/store/NeonSlice';
import NotificationReducer from 'domain/notification/store/NotificationSlice';
import ProductSlice from 'domain/payment/store/ProductSlice';
import RelationSlice from 'domain/relation/store/RelationSlice';
import SocketReducer from 'domain/socket/store/SocketSlice';
import TranslationSlice from 'domain/translation/store/TranslationSlice';
import UserSlice from 'domain/user/store/UserSlice';
import TeamCreatingSlice from 'primary/teams/store/TeamCreatingSlice';
import { useDispatch } from 'react-redux';

const store = configureStore({
  reducer: {
    user: UserSlice,
    notification: NotificationReducer,
    socket: SocketReducer,
    backgroundImages: BackgroundImageSlice,
    neons: NeonSlice,
    translation: TranslationSlice,
    teamCreating: TeamCreatingSlice,
    relations: RelationSlice,
    products: ProductSlice,
    filters: FiltersSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
