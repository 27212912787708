import { AxiosInstance } from 'axios';
import { Pageable } from 'domain/pageable/Pageable';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';
import { toPageable } from 'secondary/RestPageable';

import { IStructureRepository } from '../../domain/structure/IStructure.repository';
import { Structure, StructureFilters } from '../../domain/structure/Structure';
import { StructureCreateFormData } from '../../primary/Structure/StructureCreateForm';
import { RestStructure, toStructure } from './RestStructure';

export class StructureRepository
  extends AxiosProviderRepository
  implements IStructureRepository
{
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  search(
    filters: StructureFilters,
    page: number,
    nbPerPage: number,
  ): Promise<Pageable<Structure>> {
    return this.axios
      .get('/unpy/api/structure/search', {
        params: {
          ...filters,
          page,
          nbPerPage,
        },
      })
      .then((response) => toPageable(response, toStructure));
  }

  create(formStructure: StructureCreateFormData): Promise<Structure> {
    return this.axios
      .post('/unpy/api/structure/create', formStructure)
      .then((response) => toStructure(response.data));
  }

  getStructureById(idStructure: number): Promise<Structure> {
    return this.axios
      .get(`/unpy/api/structure/${idStructure}`)
      .then((response) => toStructure(response.data));
  }

  getStructuresOfUser(idUser: number, userToExclude?: number): Promise<Structure[]> {
    return this.axios
      .get(
        `/unpy/api/structures/user/${idUser}${userToExclude ? `/${userToExclude}` : ''}`,
      )
      .then((response) =>
        response.data.map((value: RestStructure) => toStructure(value)),
      );
  }
}
