import { Alert, ThemeProvider } from '@mui/material';
import { EventStatusEnum } from 'domain/event/EventStatusEnum';
import { TypeEventCodeEnum } from 'domain/event/TypeEventCodeEnum';
import { canGoToOnGoing } from 'domain/event/UnpyEvent.func';
import { formatDate } from 'helper/date.helper';
import { PageLayout } from 'primary/Components/PageLayout/PageLayout';
import { Title } from 'primary/Components/Title/Title';
import { EVENT_CONTEXT } from 'primary/context/event/EventContext';
import { PodiumView } from 'primary/events/components/PodiumView';
import { ClassementParticipantDatataleParameters } from 'primary/events/datatable/ClassementParticipantDatatableParameters';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { useContext } from 'react';

import { themeParameters } from '../parameters.helper';

export const Classement = () => {
  const { event } = useContext(EVENT_CONTEXT);
  const t = useTranslate();
  if (!event) return null;
  const classementCanDisplay = [
    EventStatusEnum.ON_GOING,
    EventStatusEnum.FINISH,
    EventStatusEnum.CANCELED,
  ].includes(event.status);
  return (
    <div className={'classement'}>
      <PageLayout>
        <>
          <Title
            title={t('event.parameters.classement.title')}
            level={1}
            compensatePadding
            threeQuarter
            bordered
          />
          <ThemeProvider theme={themeParameters}>
            {!classementCanDisplay && (
              <div className={'eventMatchs__noClassement'}>
                {event.status === EventStatusEnum.CANCELED && (
                  <Alert severity="warning">
                    {t('event.parameters.classements.canceledWithoutBoard')}
                  </Alert>
                )}
                {canGoToOnGoing(event) && (
                  <Alert severity="error">
                    {t('event.parameters.classements.noMatchGenerated')}
                  </Alert>
                )}
                {!canGoToOnGoing(event) && (
                  <Alert severity="info">
                    {t('event.parameters.classements.infoStatus', {
                      date: formatDate(event.startDateTime, 'readable(with hour)'),
                    })}
                  </Alert>
                )}
              </div>
            )}
            {classementCanDisplay && (
              <>
                {(event.typeEvent.code === TypeEventCodeEnum.LIGUE ||
                  event.typeEvent.code === TypeEventCodeEnum.LIG_BR) && (
                  <ClassementParticipantDatataleParameters event={event} />
                )}
                {(event.typeEvent.code === TypeEventCodeEnum.TOURNAMENT ||
                  event.typeEvent.code === TypeEventCodeEnum.TOURNAMENT_DOUBLE_ELEM) && (
                  <div className={'-eventBracketPodiumView'}>
                    <PodiumView event={event} />
                  </div>
                )}
              </>
            )}
          </ThemeProvider>
        </>
      </PageLayout>
    </div>
  );
};
