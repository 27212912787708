import './ActionButton.scss';

import { PersonAdd, PersonRemove } from '@mui/icons-material';
import { Button } from '@mui/material';
import { PRIVACY_CODE } from 'domain/privacy/ProfilPrivacy';
import { isPrivacyRestricted } from 'domain/profil/Profil.func';
import { UserProfil } from 'domain/profil/UserProfil';
import {
  selectConnectedUserBlocked,
  selectConnectedUserFriend,
  setRelation,
} from 'domain/relation/store/RelationSlice';
import { stopPropagationBeforeAction } from 'primary/Components/Profils/User/ActionButton/ActionButtonInviteStructure';
import { ModalAddFriend } from 'primary/Components/Profils/User/ActionButton/Modals/ModalAddFriend';
import { sendEventToastMessage } from 'primary/Components/Toast/Toast.helper';
import { useBooleanToggle } from 'primary/hooks/useBooleanToggle';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import ModalConfirm from 'primary/ModalConfirm/ModalConfirm';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface ActionButtonAddFriendProps {
  profil: UserProfil;
  withIcon?: boolean;
}

export const ActionButtonAddFriend = ({
  profil,
  withIcon,
}: ActionButtonAddFriendProps) => {
  const { relationRepository, authRepository } = useContextDependency();
  const [isOpen, toggleOpen, , closeModal] = useBooleanToggle();
  const t = useTranslate();
  const friends = useSelector(selectConnectedUserFriend);
  const dispatch = useDispatch();
  const alreadyFriend = friends?.find(
    (f) => f.invited.idProfil === profil.id || f.owner.idProfil === profil.id,
  );
  const onConfirmDeleteFriend = () => {
    if (!alreadyFriend) return;
    relationRepository.deleteById(alreadyFriend.id).then(() => {
      relationRepository.getAllRelationsOfConnectedUser().then((relations) => {
        sendEventToastMessage(t('modals.deleteFriend.success'), 'success');
        dispatch(setRelation(relations));
      });
    });
  };
  const blocked = useSelector(selectConnectedUserBlocked);
  const alreadyBlocked = blocked?.find((b) => b.invited.idProfil === profil.id);
  const isRestricted =
    !!alreadyBlocked || isPrivacyRestricted(profil, PRIVACY_CODE.ADD_FRIEND);
  return (
    <>
      {isOpen && !alreadyFriend && (
        <ModalAddFriend
          handleClose={closeModal}
          isOpen={isOpen}
          name={profil.idPlayer}
          userId={profil.id}
        />
      )}
      {isOpen && alreadyFriend && (
        <ModalConfirm
          handleClose={closeModal}
          isOpen={isOpen}
          title={t('profil.userProfilAction.removeFriend', { name: profil.idPlayer })}
          description={t('profil.userProfilAction.removeFriendTextConfirm')}
          action={onConfirmDeleteFriend}
          labelAccept={t('profil.userProfilAction.removeFriendBtnAccept')}
          labelDecline={t('profil.userProfilAction.removeFriendBtnCancel')}
        />
      )}
      <Button
        color={'inherit'}
        variant={'text'}
        className={'-actionButton'}
        onClick={stopPropagationBeforeAction(toggleOpen)}
        disabled={isRestricted || !authRepository.currentUser}
      >
        {withIcon && !alreadyFriend ? <PersonAdd /> : <PersonRemove />}
        {!alreadyFriend
          ? t('profil.userProfilAction.addFriend', { name: profil.idPlayer })
          : t('profil.userProfilAction.removeFriend', { name: profil.idPlayer })}
      </Button>
    </>
  );
};
