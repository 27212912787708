import './UserDetailsForms.scss';

import { InputSelectMui } from 'primary/Components/Input/InputSelectMui';
import { UserProfilDetailsFormData } from 'primary/Parameters/user/Profil/UserDetails';
import React, { FC } from 'react';
import { useController, UseFormReturn, UseFormSetValue } from 'react-hook-form';

import { useContextDependency } from '../../hooks/useContextDependency';
import { useFetch } from '../../hooks/useFetch';
import { useTranslate } from '../../hooks/useTranslate';
import { ChangeHandlerTypeMeta, InputWrapper } from '../Input/InputWrapper';

interface PlatformsFormProps {
  form: UseFormReturn<UserProfilDetailsFormData>;
}

export const _changeHandlerPlatforms =
  (setValue: UseFormSetValue<UserProfilDetailsFormData>) =>
  (event: ChangeHandlerTypeMeta) => {
    setValue('platforms', event.target.value as string[]);
  };

export const PlatformsFields: FC<PlatformsFormProps> = ({ form }) => {
  const t = useTranslate();

  const { platformRepository } = useContextDependency();

  const [platforms] = useFetch(() => platformRepository.getAll(), undefined);

  const { field: platformsField } = useController({
    control: form.control,
    name: 'platforms',
  });

  return (
    <InputWrapper
      label={t('parameters.menus.profil.user.form.platformLabel')}
      errors={form.formState.errors}
      inputClassName={'input-user-details '}
      labelClassName={'label-user-details '}
      Input={InputSelectMui}
      isSubmitting={form.formState.isSubmitting}
      required
      inputProps={{
        placeholder: t('parameters.menus.profil.user.form.platformPlaceholder'),
        ...platformsField,
        options: platforms?.map((country) => ({
          label: country.libelle,
          value: country.id.toString(),
        })),
        onChange: _changeHandlerPlatforms(form.setValue),
        multiple: true,
        value: (form.getValues('platforms') as string[]) || [],
      }}
    />
  );
};
