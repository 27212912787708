import './SkeletonNoContent.scss';

import { Skeleton } from '@mui/lab';
import React, { FC } from 'react';
interface SkeletonNoContentProps {
  displayContent?: boolean;
}
export const SkeletonNoContent: FC<SkeletonNoContentProps> = ({
  displayContent,
  children,
}) => {
  if (displayContent) return <>{children}</>;
  return (
    <div className={'skeletonNoContent'}>
      {!displayContent && <Skeleton animation="wave" height={'100%'} width="100%" />}
      {displayContent && <>{children}</>}
    </div>
  );
};
