import './NeonText.scss';

import classNames from 'classnames';
import React, { FC } from 'react';

import { NeonClassName } from '../../../domain/neon/Neon';

export type NeonColor = 'blue' | 'violet';

interface NeonTextProps {
  className?: string;
  variant: NeonClassName;
  text: string;
  onClick?: () => void;
}

export const NeonText: FC<NeonTextProps> = ({ onClick, className, variant, text }) => {
  return (
    <span
      onClick={onClick}
      className={classNames('textNeon', `textNeon__${variant}`, className)}
    >
      {text}
    </span>
  );
};
