import './FilterBracketRound.scss';

import { Cancel } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { UnpyMatchFilters } from 'domain/event/match/UnpyMatch';
import { InputSelectMui } from 'primary/Components/Input/InputSelectMui';
import { InputWrapper } from 'primary/Components/Input/InputWrapper';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { useRef } from 'react';
import { ChangeHandler } from 'react-hook-form';

type FilterBracketRoundProps = {
  totalRound?: number;
  withUpperLowerFilter?: boolean;
  onChange: (key: keyof UnpyMatchFilters, val?: string | number | boolean) => void;
  currentFilter: UnpyMatchFilters;
};

export type RoundBracketFilter = {
  borneMin?: number;
  borneMax?: number;
};
export const FilterBracketRound = ({
  totalRound,
  onChange,
  currentFilter,
  withUpperLowerFilter,
}: FilterBracketRoundProps) => {
  const t = useTranslate();
  const initialValue = useRef(currentFilter);
  if (!totalRound || isNaN(totalRound)) return null;
  return (
    <div className={'filterBracketRound'}>
      <span>{t('classement.general.bracket.filtersRound.labelMin')}</span>
      <div className={'-borneMin'}>
        <InputWrapper
          errors={{}}
          Input={InputSelectMui}
          isSubmitting={false}
          value={
            currentFilter.roundFrom != undefined ? currentFilter.roundFrom + 1 : undefined
          }
          inputProps={{
            options: new Array(totalRound || 1).fill(1).map((_, index) => ({
              label: (index + 1).toString(),
              value: (index + 1).toString(),
            })),
            // @ts-ignore
            onChange: (e: ChangeHandler) => {
              // @ts-ignore
              return onChange(
                'roundFrom',
                // @ts-ignore
                e?.target?.value ? e?.target?.value - 1 : undefined,
              );
            },
            value:
              currentFilter.roundFrom != undefined
                ? currentFilter.roundFrom + 1
                : undefined,
          }}
        />
      </div>
      <span>{t('classement.general.bracket.filtersRound.labelConnector')}</span>
      <div className={'-borneMax'}>
        <InputWrapper
          errors={{}}
          Input={InputSelectMui}
          isSubmitting={false}
          value={
            currentFilter.roundTo != undefined ? currentFilter.roundTo + 1 : undefined
          }
          variant={'outlined'}
          inputProps={{
            disabled: currentFilter.roundFrom === undefined,
            // @ts-ignore
            options: new Array(totalRound || 1)
              .fill(1)
              .map((_, index) => {
                if (
                  currentFilter.roundFrom !== undefined &&
                  index > currentFilter.roundFrom - 1
                ) {
                  return {
                    label: (index + 1).toString(),
                    value: (index + 1).toString(),
                  };
                } else {
                  return undefined;
                }
              })
              .filter((value) => value !== undefined),
            // @ts-ignore
            onChange: (e: ChangeHandler) => {
              // @ts-ignore
              onChange('roundTo', e?.target?.value ? e?.target?.value - 1 : undefined);
            },
            value:
              currentFilter.roundTo != undefined ? currentFilter.roundTo + 1 : undefined,
          }}
        />
        <IconButton
          onClick={() => {
            onChange('roundFrom', initialValue.current.roundFrom);
            onChange('roundTo', initialValue.current.roundTo);
          }}
          className={'-cancelFilters'}
        >
          <Cancel />
        </IconButton>
      </div>
    </div>
  );
};
