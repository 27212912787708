import { UserProfilView } from 'domain/user/UserProfilView';

import { NotificationResourceTypeEnum } from './NotificationResourceTypeEnum';
import { NotificationTypeEnum } from './NotificationTypeEnum';

export interface NotificationFilters {
  userFrom?: number;
  userTo?: number;
  types?: NotificationTypeEnum[];
}

export class UnpyNotification {
  constructor(
    public readonly id: number,
    public readonly userTo: UserProfilView,
    public readonly userFrom: UserProfilView,
    public readonly creationDate: Date,
    public readonly type: NotificationTypeEnum,
    public readonly resources: Record<NotificationResourceTypeEnum, string>,
    public readonly viewed: boolean,
    public readonly content?: string,
  ) {}
}
