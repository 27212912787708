import './LayoutPageListFiltered.scss';

import classNames from 'classnames';
import React, { ReactNode } from 'react';

type LayoutPageListFilteredProps = {
  mainFilterContent?: ReactNode;
  listContent: ReactNode;
  withShadow?: boolean;
};
export const LayoutPageListFitlered = ({
  mainFilterContent,
  listContent,
  withShadow,
}: LayoutPageListFilteredProps) => {
  return (
    <div className={classNames('layoutPageListFiltered', { '-withShadow': withShadow })}>
      {mainFilterContent && (
        <div className={'-mainFilterContent'}>{mainFilterContent}</div>
      )}
      <div className={'-listContent'}>{listContent}</div>
    </div>
  );
};
