import { useTranslate } from 'primary/hooks/useTranslate';
import { useEffect, useState } from 'react';
import React from 'react';

export const useCountdown = (targetDate: Date, forcePositive = true) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown, forcePositive);
};

const getReturnValues = (countDown: number, forcePositive: boolean) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [
    Math.abs(days),
    Math.abs(hours),
    Math.abs(minutes),
    Math.abs(seconds),
    Math.sign(countDown) === -1,
  ];
};

interface CountDownProps {
  targetDate: Date;
  isShort?: boolean;
  inPastMessage?: string;
}
export const CountDown = ({ targetDate, isShort, inPastMessage }: CountDownProps) => {
  const [days, hours, minutes, seconds, isInPast] = useCountdown(targetDate);
  const labelPrefix = isShort ? 'countDownShort' : 'countDown';
  const t = useTranslate();
  if (isInPast && inPastMessage) return <span>{inPastMessage}</span>;
  return (
    <div className={'countDown'}>
      {isInPast && (
        <span className={'-item -days'}>{inPastMessage ?? t('countDown.inPast')}</span>
      )}
      <span className={'-item -days'}>{t(`${labelPrefix}.day`, { count: days })} </span>
      <span className={'-item -hours'}>
        {t(`${labelPrefix}.hour`, { count: hours })}{' '}
      </span>
      <span className={'-item -minutes'}>
        {t(`${labelPrefix}.minute`, { count: minutes })}{' '}
      </span>
      <span className={'-item -seconds'}>
        {t(`${labelPrefix}.second`, { count: seconds })}{' '}
      </span>
    </div>
  );
};
