import './ProfilPage.scss';

import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { RootState } from '../../../store';
import { UserProfilCard } from '../../Components/Profils/User/UserProfilCard';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useFetch } from '../../hooks/useFetch';

export const OtherUserProfilPage = () => {
  const { userProfilRepository, authRepository } = useContextDependency();
  const { id } = useParams<{ id: string }>();
  const [userProfil] = useFetch(
    () => userProfilRepository.getProfilById(id as string),
    undefined,
  );
  const user = useSelector((state: RootState) => state.user.connectedUser);
  if (!userProfil) return null;
  return (
    <div className={'myProfilPage'}>
      <UserProfilCard profil={userProfil} />
    </div>
  );
};

type WrapperFetchUserProfilCardProps = {
  id: number;
};
export const WrapperFetchUserProfilCard = ({ id }: WrapperFetchUserProfilCardProps) => {
  const { userProfilRepository } = useContextDependency();
  const [userProfil] = useFetch(
    () => userProfilRepository.getProfilById(id.toString()),
    undefined,
  );
  if (!userProfil) return null;
  return <UserProfilCard profil={userProfil} isPreview />;
};
