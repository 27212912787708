import { Price } from 'domain/payment/Price';
import { ProductCodeEnum } from 'secondary/payment/ProductCodeEnum';

export class Product {
  constructor(
    public readonly id: number,
    public readonly libelle: string,
    public readonly description: string,
    public readonly idStripe: string,
    public readonly price: Price,
    public readonly code: ProductCodeEnum,
    public readonly active: boolean,
  ) {}
}
