import React from 'react';

import { UnpyNotification } from '../../../domain/notification/UnpyNotification';

interface NotificationGeneralContentProps {
  notification: UnpyNotification;
  reFetchNotification: () => void;
  isResponse?: boolean;
}

export const NotificationGeneralContent = ({
  notification,
}: NotificationGeneralContentProps) => {
  return (
    <div>
      <span>{notification.content}</span>
    </div>
  );
};
