import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import { ToastContainer } from 'react-toastify';
export type TypeToast = 'success' | 'info' | 'error' | 'warning';

export type EventMessageToast = {
  duration: number;
  message: string;
  type: TypeToast;
};

export const MessageEventType = 'notification-toast-event';

type EventContent = {
  message?: string;
  type: TypeToast;
  duration: number;
};

const TIMEOUT = 2000;

export const Toast = () => {
  return (
    <ToastContainer
      position={'top-center'}
      theme={'dark'}
      bodyStyle={{ fontFamily: 'KanitReglar' }}
    />
    //   open={isOpen}
    //   autoHideDuration={event.duration || TIMEOUT}
    //   onClose={handleClose}
    //   anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    //   message={event.message}
    // >
    //   <Alert onClose={handleClose} severity={event.type} sx={{ width: '100%' }}>
    //     {event.message}
    //   </Alert>
    // </ToastContainer>
  );
};
