import { EventConfirmationStateEnum } from 'domain/event/EventConfirmationStateEnum';
import { SystemPointsEnum } from 'domain/event/SystemPoints.enum';
import { Platform } from 'domain/platform/Platform';
import { UserProfilView } from 'domain/user/UserProfilView';

import { Game } from '../game/Game';
import { EventStatusEnum } from './EventStatusEnum';
import { TypeEvent } from './TypeEvent';
import { TypeTeam } from './TypeTeam';

export class UnpyEventView {
  constructor(
    public readonly id: number,
    public readonly startDateTime: Date,
    public readonly endSubscribeDate: Date,
    public readonly status: EventStatusEnum,
    public readonly name: string,
    public readonly freeRegister: boolean,
    public readonly creator: UserProfilView,
    public readonly game: Game,
    public readonly typeEvent: TypeEvent,
    public readonly typeTeam?: TypeTeam,
    public readonly platforms?: Platform[],
    public readonly confirmationState?: EventConfirmationStateEnum,
    public readonly delayed?: Date,
    public readonly image?: string,
    public readonly publicImageUrl?: string,
    public readonly defaultImage?: boolean,
    public readonly prizeText?: string,
    public readonly pointSystem?: SystemPointsEnum,
    public readonly maxTeam?: number,
    public readonly totalParticipants?: number,
  ) {}
}
