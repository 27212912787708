import React, { FC } from 'react';

import { EVENT_CONTEXT, EVENT_CONTEXT_TYPE } from './EventContext';

export const EventProvider: FC<EVENT_CONTEXT_TYPE> = ({ children, event, dispatch }) => {
  return (
    <EVENT_CONTEXT.Provider
      value={{
        event,
        dispatch,
      }}
    >
      {children}
    </EVENT_CONTEXT.Provider>
  );
};
