import { routesConfig } from '../../Configs/Routes.config';
import { ConfParametersLink, ListConfParametersMenu } from './MenusParametersUser.config';

export const subMenuParametersProfilStructure: ConfParametersLink[] = [
  {
    label: 'Apparence profil',
    link: routesConfig.parametersDetailsStructure.profilAppearence.to,
    path: routesConfig.parametersDetailsStructure.profilAppearence.path,
    params: ['idStructure'],
  },
  {
    label: 'Jeux joués',
    link: routesConfig.parametersDetailsStructure.interests.to,
    path: routesConfig.parametersDetailsStructure.interests.path,
    params: ['idStructure'],
  },
  {
    label: 'Réseaux et ping',
    link: routesConfig.parametersDetailsStructure.profilNetwork.to,
    path: routesConfig.parametersDetailsStructure.profilNetwork.path,
    params: ['idStructure'],
  },
];

export const subMenuParametersStructureAdmin: ConfParametersLink[] = [
  {
    label: 'Membres / Rôles',
    link: routesConfig.parametersDetailsStructure.adminMembers.to,
    path: routesConfig.parametersDetailsStructure.adminMembers.path,
    params: ['idStructure'],
  },
  {
    label: 'Paramètre structure',
    link: routesConfig.parametersDetailsStructure.adminStructure.to,
    path: routesConfig.parametersDetailsStructure.adminStructure.path,
    params: ['idStructure'],
  },
];

export const menusParametersStructure: ListConfParametersMenu = {
  defaultTabUrl: routesConfig.parametersStructure.path,
  parametersItems: [
    {
      title: 'Profil',
      path: '/structure/profil',
      subMenu: subMenuParametersProfilStructure,
    },
    {
      title: 'Administration',
      path: '/structure/admin',
      subMenu: subMenuParametersStructureAdmin,
    },
  ],
};
