import { AxiosInstance } from 'axios';
import { IRoleStructureRepository } from 'domain/roleStructure/RoleStructure.repository';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';

import { RoleStructure } from '../../domain/roleStructure/RoleStructure';
import { RestRoleStructure, toRoleStructure } from './RestRoleStructure';

export class RoleStructureRepository
  extends AxiosProviderRepository
  implements IRoleStructureRepository
{
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  getAll(): Promise<RoleStructure[]> {
    return this.axios
      .get('/unpy/api/structure/roles/all')
      .then((resp) =>
        resp.data.map((restRoleStructure: RestRoleStructure) =>
          toRoleStructure(restRoleStructure),
        ),
      );
  }
}
