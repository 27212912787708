import { AxiosInstance } from 'axios';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';

import { IMarketPlaceAnnouncementRepository } from '../../domain/marketPlaceAnnouncement/MarketPlaceAnnouncement.repository';
import { MarketPlaceAnnouncementView } from '../../domain/marketPlaceAnnouncement/MarketPlaceAnnouncementView';

export class MarketPlaceAnnouncementRepository
  extends AxiosProviderRepository
  implements IMarketPlaceAnnouncementRepository
{
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  getRecent(): Promise<MarketPlaceAnnouncementView[]> {
    return Promise.resolve([]);
  }
}
