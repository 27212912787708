import './PreviewRelationUser.scss';

import { Chip } from '@mui/material';
import classNames from 'classnames';
import { RelationTypeEnum } from 'domain/relation/RelationTypeEnum';
import { sendEventToastMessage } from 'primary/Components/Toast/Toast.helper';
import { useTranslate } from 'primary/hooks/useTranslate';
import ModalConfirm from 'primary/ModalConfirm/ModalConfirm';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Relation } from '../../domain/relation/Relation';
import { RootState } from '../../store';
import { WrapperOtherProfilPhotoOverview } from '../Components/User/WrapperOtherProfilPhotoOverview';
import { useContextDependency } from '../hooks/useContextDependency';

type PreviewRelationUserProps = {
  relation: Relation;
  onClick?: (relation: Relation | null) => void;
  isSelected: boolean;
};

export const PreviewRelationUser = ({
  relation,
  onClick,
  isSelected,
}: PreviewRelationUserProps) => {
  const user = useSelector((state: RootState) => state.user.connectedUser);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { relationRepository } = useContextDependency();
  const playerToDisplay =
    relation.invited.idPlayer === user?.userProfil.idPlayer
      ? relation.owner
      : relation.invited;
  const strTranslate =
    relation.type === RelationTypeEnum.BLOCK
      ? 'confirmModalDeleteBlock'
      : 'confirmModalDeleteFriend';
  const t = useTranslate();
  const handleDeleteAction = () => {
    setIsLoading(true);
    relationRepository.deleteById(relation.id).then(() => {
      sendEventToastMessage(t('general.edit.success'), 'success');
      setOpen(false);
      setIsLoading(false);
    });
  };

  return (
    <div
      className={classNames('previewRelationUser', { isSelected: isSelected })}
      onClick={() => (isSelected ? onClick?.(null) : onClick?.(relation))}
    >
      <div className={classNames('previewRelationUser__infos')}>
        <div className={'previewRelationUser__infos-photo'}>
          <WrapperOtherProfilPhotoOverview
            size={'small'}
            displayName={playerToDisplay.idPlayer}
            photo={playerToDisplay.publicPhotoUrl}
            className={classNames(
              'photo',
              '-photo',
              playerToDisplay.neon?.getNeonBorderClassName(),
            )}
            idUser={playerToDisplay.id as number}
          />
        </div>
        <span className={'previewRelationUser__infos-name'}>
          {playerToDisplay.idPlayer}
        </span>
        <Chip
          className={classNames('-tagRelationStatus')}
          label={
            relation.type === RelationTypeEnum.FRIEND
              ? t(`enum.relationStatus.${relation.status}`)
              : t(`relations.tagBlock`)
          }
        />
      </div>
      <ModalConfirm
        handleClose={() => setOpen(false)}
        isOpen={open}
        title={t(`relations.${strTranslate}.title`, {
          name: playerToDisplay.idPlayer,
        })}
        //@ts-ignore
        description={t(`relations.${strTranslate}.confirmText`, {
          name: playerToDisplay.idPlayer,
        })}
        action={handleDeleteAction}
        labelAccept={t(`relations.${strTranslate}.confirmBtnText`)}
        labelDecline={t(`relations.${strTranslate}.cancelBtnText`)}
      />
    </div>
  );
};
