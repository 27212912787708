export type PageableCurrentPage = number;
export type PageablePageSize = number;
export type PageablePageCount = number;
export type PageableTotalElementsCount = number;

export class Pageable<T> {
  constructor(
    public readonly content: T[],
    public readonly currentPage: PageableCurrentPage,
    public readonly pageSize: PageablePageSize,
    public readonly pagesCount: PageablePageCount,
    public readonly totalElementsCount: PageableTotalElementsCount,
  ) {}
}
