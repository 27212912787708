import { ClassementParticipant } from 'domain/classement/ClassementParticipant';
import {
  RestEventParticipant,
  toEventParticipant,
} from 'secondary/event/participants/RestEventParticipant';

export interface RestClassementParticipant {
  id: number;
  participant?: RestEventParticipant;
  score?: number;
  victories: number;
  defeats: number;
  nulls: number;
  top1: number;
  top3: number;
  topHalf: number;
  finalPlace?: number;
}

export function toClassementParticipant(
  restClassementParticipant: RestClassementParticipant,
): ClassementParticipant {
  const { id, participant, score, finalPlace } = restClassementParticipant;
  return new ClassementParticipant(
    id,
    participant != null ? toEventParticipant(participant) : undefined,
    restClassementParticipant.victories,
    restClassementParticipant.defeats,
    restClassementParticipant.nulls,
    restClassementParticipant.top1,
    restClassementParticipant.top3,
    restClassementParticipant.topHalf,
    score,
    finalPlace,
  );
}
