import { Game } from '../../domain/game/Game';
import { GAME_ACTIVE_LIST } from '../../domain/game/GameActiveList';

export interface RestGame {
  id: number;
  libelle: string;
  code: string;
  active: boolean;
}

export function toGame(restGame: RestGame) {
  return new Game(
    restGame.id,
    restGame.libelle,
    GAME_ACTIVE_LIST[restGame.code as keyof typeof GAME_ACTIVE_LIST],
    restGame.active,
  );
}
