import { EventSubStatusEnum } from 'domain/event/EventStatusEnum';
import {
  EventParticipant,
  EventParticipantFilters,
} from 'domain/event/participants/EventParticipant';
import { getSubStatusOfEvent, useGetPlayerOfEvent } from 'domain/event/UnpyEvent.func';
import { EventParticipantCardListItem } from 'primary/Components/Event/EventParticipantCardListItem';
import { SkeletonNoContent } from 'primary/Components/SkeletonNoContent/SkeletonNoContent';
import { Title } from 'primary/Components/Title/Title';
import { EventParticipantDatatable } from 'primary/events/datatable/EventParticipantDatatableParameters';
import { EventViewPageContext } from 'primary/events/item/EventViewPage';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetchWithFilterPage } from 'primary/hooks/useFetchWithFilterPage';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { useOutletContext } from 'react-router-dom';

export const ParticipantsView = () => {
  const { event } = useOutletContext<EventViewPageContext>();
  const { eventRepository, eventParticipantRepository, authRepository } =
    useContextDependency();
  const t = useTranslate();
  const staticFilters = {
    idEvent: event?.id,
    onlyAccepted: true,
  };
  const useFetchReturn = useFetchWithFilterPage<
    EventParticipant,
    EventParticipantFilters
  >({
    keyStoreFilters: 'event-participants-param-list',
    staticFilters: staticFilters,
    filters: {},
    callApi: (filters, page) => eventParticipantRepository.search(filters, page, 10),
  });
  if (!event) return null;

  return (
    <div className={'eventViewPageBloc eventViewPageRules'}>
      {event?.id && authRepository?.currentUser?.userProfil?.id && (
        <MyEventParticipation
          eventId={event?.id}
          profilId={authRepository?.currentUser?.userProfil?.id}
        />
      )}
      <Title
        title={t('event.eventViewPage.players.title')}
        level={1}
        threeQuarter
        bordered
      />
      <SkeletonNoContent displayContent={!!event}>
        {event && (
          <EventParticipantDatatable
            asView
            readOnly
            useFetchReturn={useFetchReturn}
            reFetch={useFetchReturn.retry}
            eventId={event.id}
            disabled={getSubStatusOfEvent(event) !== EventSubStatusEnum.SUBSCRIBE_OPEN}
          />
        )}
      </SkeletonNoContent>
    </div>
  );
};
type MyEventParticipationProps = {
  eventId: number;
  profilId: number;
};
const MyEventParticipation = ({ eventId, profilId }: MyEventParticipationProps) => {
  const t = useTranslate();
  const participant = useGetPlayerOfEvent(eventId, profilId);
  if (!participant) return null;
  return (
    <div className={'myParticipation'}>
      <Title
        title={t('event.parameters.myParticipation.title')}
        level={1}
        compensatePadding
        threeQuarter
        bordered
      />
      <div className={'-participant'}>
        {participant && (
          <EventParticipantCardListItem participant={participant} displayState />
        )}
      </div>
    </div>
  );
};
