import './ActionButton.scss';

import { GroupAdd } from '@mui/icons-material';
import { Button } from '@mui/material';
import { PRIVACY_CODE } from 'domain/privacy/ProfilPrivacy';
import { isPrivacyRestricted } from 'domain/profil/Profil.func';
import { UserProfil } from 'domain/profil/UserProfil';
import { selectConnectedUserBlocked } from 'domain/relation/store/RelationSlice';
import { ModalInviteStructure } from 'primary/Components/Profils/User/ActionButton/Modals/ModalInviteStructure';
import { useBooleanToggle } from 'primary/hooks/useBooleanToggle';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { useSelector } from 'react-redux';

interface ActionButtonInviteStructureProps {
  profil: UserProfil;
  withIcon?: boolean;
}

export const stopPropagationBeforeAction = (action?: () => void) => (e: any) => {
  e.stopPropagation();
  action?.();
};

export const ActionButtonInviteStructure = ({
  profil,
  withIcon,
}: ActionButtonInviteStructureProps) => {
  const { authRepository } = useContextDependency();
  const [isOpen, toggleOpen, , closeModal] = useBooleanToggle();
  const t = useTranslate();
  const blocked = useSelector(selectConnectedUserBlocked);
  const alreadyBlocked = blocked?.find((b) => b.invited.id === profil.id);
  const isRestricted =
    !!alreadyBlocked || isPrivacyRestricted(profil, PRIVACY_CODE.INVITE_STRUCTURE);

  return (
    <>
      {isOpen && (
        <ModalInviteStructure
          handleClose={closeModal}
          isOpen={isOpen}
          name={profil.idPlayer}
          userId={profil.userId}
        />
      )}
      <Button
        color={'inherit'}
        variant={'text'}
        className={'-actionButton'}
        onClick={stopPropagationBeforeAction(toggleOpen)}
        disabled={isRestricted || !authRepository.currentUser}
      >
        {withIcon && <GroupAdd />}
        {t('profil.userProfilAction.inviteStructure')}
      </Button>
    </>
  );
};
