import { sendEventToastMessage } from 'primary/Components/Toast/Toast.helper';
import { useTranslate } from 'primary/hooks/useTranslate';
import { useDispatch, useSelector } from 'react-redux';

import { setConnectedUserProfil } from '../../../domain/user/store/UserSlice';
import { RootState } from '../../../store';
import { useContextDependency } from '../../hooks/useContextDependency';

export const useUserParameters = () => {
  const user = useSelector((state: RootState) => state.user.connectedUser);
  const dispatch = useDispatch();
  const { userProfilRepository } = useContextDependency();
  const t = useTranslate();
  const onSubmitted = () => {
    sendEventToastMessage(t('general.edit.success'), 'success');
    userProfilRepository
      .getConnectedUserProfil()
      .then((value) => dispatch(setConnectedUserProfil(value)));
  };

  return {
    onSubmitted,
    user,
    userProfilRepository,
  };
};
