import './UnpyUp.scss';

import { Grid } from '@mui/material';
import { toUpper } from 'lodash';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { useFetchAllProducts } from 'primary/hooks/useFetchAllProducts';
import { UnpyUpOffers } from 'primary/Up/UnpyUpOffers';
import React from 'react';

import { useTranslate } from '../../hooks/useTranslate';

export const UnpyUpPage = () => {
  const t = useTranslate();
  const products = useFetchAllProducts();
  if (!products) return <LoadingScreen loading={true} />;
  return (
    <div className={'upPage'}>
      <Grid container className={'-containerTitle'}>
        <h1>
          <span>{t('up.pageTitleAccent')}</span>
          {toUpper(t('up.pageTitle'))}
        </h1>
      </Grid>
      <UnpyUpOffers products={products} />
    </div>
  );
};
