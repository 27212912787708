import './UnpyUpOffers.scss';

import { Button, Grid, Hidden } from '@mui/material';
import classNames from 'classnames';
import { ISubscriptionRepository } from 'domain/payment/ISubscription.repository';
import { Product } from 'domain/payment/Product';
import { UnpySession } from 'domain/payment/UnpySession';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import { CardDurationOffer } from 'primary/Up/DurationOffer';
import { ModalWaitingForActions } from 'primary/Up/ModalWaitingForActions';
import React, { useMemo, useState } from 'react';
import { ProductCodeEnum } from 'secondary/payment/ProductCodeEnum';

import { CardStaticOffer } from './StaticOffer';

const DURATION_SUBSCRIBE = {
  1: {
    normal: ProductCodeEnum.ABO_UP_1,
    premium: ProductCodeEnum.ABO_UP_PREMIUM_1,
  },
  6: {
    normal: ProductCodeEnum.ABO_UP_6,
    premium: ProductCodeEnum.ABO_UP_PREMIUM_6,
  },
  12: {
    normal: ProductCodeEnum.ABO_UP_12,
    premium: ProductCodeEnum.ABO_UP_PREMIUM_12,
  },
};

type UnpyUpOffersProps = {
  embedded?: boolean;
  products: Product[];
};

export const useOnClickCheckout =
  (
    setIsWaitingOpen: (value: boolean) => void,
    subscriptionRepository: ISubscriptionRepository,
    onSubmitted: (session: UnpySession) => void,
  ) =>
  (productId: number, type: 'abo' | 'prod') =>
  () => {
    setIsWaitingOpen(true);
    subscriptionRepository.initSession(productId).then(onSubmitted);
  };
export const UnpyUpOffers = ({ embedded, products }: UnpyUpOffersProps) => {
  const { authRepository, productRepository } = useContextDependency();
  const [durationDisplayed, setDurationDisplayed] = React.useState<1 | 6 | 12>(1);
  const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
  const [isModalWaitingOpen, setIsModalWaitingOpen] = useState(false);

  const prodBoost = useMemo(
    () => products.find((prod) => prod.code === ProductCodeEnum.PROD_BOOST),
    [products],
  );
  const prodPass = useMemo(
    () => products.find((prod) => prod.code === ProductCodeEnum.PASS),
    [products],
  );
  const prodSubbscribeUp = useMemo(
    () =>
      products.find((prod) => prod.code === DURATION_SUBSCRIBE[durationDisplayed].normal),
    [products, durationDisplayed],
  );
  const prodSubbscribeUpPremium = useMemo(
    () =>
      products.find(
        (prod) => prod.code === DURATION_SUBSCRIBE[durationDisplayed].premium,
      ),
    [products, durationDisplayed],
  );
  const onSubmitted = (session: UnpySession) => {
    window.location.replace(session.url);
  };
  const t = useTranslate();
  const { subscribtionRepository } = useContextDependency();
  const onClickCheckout = useOnClickCheckout(
    setIsModalWaitingOpen,
    subscribtionRepository,
    onSubmitted,
  );

  return (
    <Grid
      container
      className={classNames('-containerOffers', {
        '-embedded': embedded,
        'borderNeons -size__medium borderNeons__orange': embedded,
      })}
    >
      <Hidden smUp>
        {!embedded && (
          <div className={'-durationOffers__headerMenu'}>
            <Button
              variant={'text'}
              onClick={() => setDurationDisplayed(1)}
              color={'primary'}
              className={classNames({ '-active': durationDisplayed === 1 })}
            >
              {t('products.durationLabel.1')}
            </Button>
            <Button
              variant={'text'}
              onClick={() => setDurationDisplayed(6)}
              color={'primary'}
              className={classNames({ '-active': durationDisplayed === 6 })}
            >
              {t('products.durationLabel.6')}
            </Button>
            <Button
              variant={'text'}
              onClick={() => setDurationDisplayed(12)}
              color={'primary'}
              className={classNames({ '-active': durationDisplayed === 12 })}
            >
              {t('products.durationLabel.12')}
            </Button>
          </div>
        )}
      </Hidden>
      <Grid container className={'-contentOffers'}>
        <Grid xs={12} sm={12} md={12} lg={4} className={'-fixedOffers'}>
          {prodBoost && (
            <CardStaticOffer
              key={'boost'}
              productType={'boost'}
              embedded={embedded}
              product={prodBoost}
              onClickCheckout={onClickCheckout(prodBoost.id, 'prod')}
            />
          )}
          {prodPass && (
            <CardStaticOffer
              key={'pass'}
              productType={'pass'}
              embedded={embedded}
              product={prodPass}
              onClickCheckout={onClickCheckout(prodPass.id, 'prod')}
            />
          )}
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={8} className={'-durationOffersContainer'}>
          <Hidden smDown>
            {!embedded && (
              <div className={'-durationOffers__headerMenu'}>
                <Button
                  variant={'text'}
                  onClick={() => setDurationDisplayed(1)}
                  color={'primary'}
                  className={classNames({ '-active': durationDisplayed === 1 })}
                >
                  {t('products.durationLabel.1')}
                </Button>
                <Button
                  variant={'text'}
                  onClick={() => setDurationDisplayed(6)}
                  color={'primary'}
                  className={classNames({ '-active': durationDisplayed === 6 })}
                >
                  {t('products.durationLabel.6')}
                </Button>
                <Button
                  variant={'text'}
                  onClick={() => setDurationDisplayed(12)}
                  color={'primary'}
                  className={classNames({ '-active': durationDisplayed === 12 })}
                >
                  {t('products.durationLabel.12')}
                </Button>
              </div>
            )}
          </Hidden>

          <Grid container className={'-durationOffers'}>
            <Grid item xs={12} md={6}>
              {prodSubbscribeUp && (
                <CardDurationOffer
                  duration={durationDisplayed}
                  subscribeType={'normal'}
                  product={prodSubbscribeUp}
                  embedded={embedded}
                  key={prodSubbscribeUp.code}
                  onClickCheckout={onClickCheckout(prodSubbscribeUp.id, 'abo')}
                  isAlreadySubscribed={
                    authRepository?.currentUser?.subscription?.product?.code ===
                    prodSubbscribeUp.code.toString()
                  }
                  canChange={
                    authRepository?.currentUser?.subscription?.product?.code &&
                    authRepository?.currentUser?.subscription?.product?.code !==
                      prodSubbscribeUp?.code?.toString()
                  }
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {prodSubbscribeUpPremium && (
                <CardDurationOffer
                  duration={durationDisplayed}
                  subscribeType={'premium'}
                  product={prodSubbscribeUpPremium}
                  onClickCheckout={onClickCheckout(prodSubbscribeUpPremium.id, 'abo')}
                  embedded={embedded}
                  key={prodSubbscribeUpPremium.code}
                  isAlreadySubscribed={
                    authRepository?.currentUser?.subscription?.product?.code ===
                    prodSubbscribeUpPremium.code.toString()
                  }
                  canChange={
                    authRepository?.currentUser?.subscription?.product?.code &&
                    authRepository?.currentUser?.subscription?.product?.code !==
                      prodSubbscribeUpPremium?.code?.toString()
                  }
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ModalWaitingForActions
        handleClose={() => setIsModalWaitingOpen(false)}
        isOpen={isModalWaitingOpen}
      />
    </Grid>
  );
};
