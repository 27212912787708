import './BannerPhotoOverview.scss';

import classNames from 'classnames';
import React, { FC } from 'react';

import { useContextDependency } from '../../hooks/useContextDependency';
import { useRetrieveFromDomain } from '../../hooks/useRetrieveFromDomain';
import { LoadingScreen } from '../LoadingScreen/LoadingScreen';

interface OtherStructureProfilBannerOverviewProps {
  className?: string;
  banner?: string;
  idStructureProfil: number;
}

export const OtherStructureProfilBannerOverview: FC<
  OtherStructureProfilBannerOverviewProps
> = ({ className, banner, idStructureProfil }) => {
  const { documentRepostory } = useContextDependency();
  const isDraft = banner?.includes('blob');
  const [profilBanner, , fetching] = useRetrieveFromDomain(
    () => documentRepostory.getStructureProfilBannerOther(idStructureProfil, banner),
    undefined,
    !!banner && !banner.startsWith('http'),
  );
  if (fetching) return <LoadingScreen loading={fetching} />;

  return (
    <img
      className={classNames('bannerPhotoOverview', className)}
      src={!!banner || isDraft ? banner : profilBanner?.publicUrl}
      alt={profilBanner?.fileName ?? 'default-banner'}
    />
  );
};
