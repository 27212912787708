import { setAllProducts } from 'domain/payment/store/ProductSlice';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useRetrieveFromDomainToContext } from 'primary/hooks/useRetrieveFromDomain';
import { RootState } from 'store';

export const useFetchAllProducts = () => {
  const { productRepository } = useContextDependency();
  const [, , products] = useRetrieveFromDomainToContext(
    () => productRepository.getAll(),
    undefined,
    true,
    setAllProducts,
    (state: RootState) => state.products.products,
  );
  return products;
};
