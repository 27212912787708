import { Team } from 'domain/team/Team';
import { TeamStatusEnum } from 'domain/team/TeamStatusEnum';
import { toEnum } from 'helper/enum.helper';
import { RestEventView, toUnpyEventView } from 'secondary/event/RestEventView';

import { RestPlayerTeam, toPlayerTeam } from '../event/RestPlayerTeam';
import { RestStructure, toStructure } from '../structure/RestStructure';

export interface RestTeam {
  id: number;
  name: string;
  players: RestPlayerTeam[];
  structure: RestStructure;
  status?: string;
  event?: RestEventView;
}

export function toTeam(restTeam: RestTeam) {
  return new Team(
    restTeam.id,
    restTeam.name,
    restTeam.players?.map((player) => toPlayerTeam(player)) ?? [],
    toEnum(TeamStatusEnum, restTeam.status),
    restTeam.structure ? toStructure(restTeam.structure) : undefined,
    restTeam.event ? toUnpyEventView(restTeam.event) : undefined,
  );
}
