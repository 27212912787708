import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UnpyNotification } from 'domain/notification/UnpyNotification';
import { Pageable } from 'domain/pageable/Pageable';
import _ from 'lodash';
import { RootState } from 'store';

type InitialNotificationSliceState = {
  numberUnviewed: number;
  numberUnviewedMessagesByConversation: {
    [key: number]: number;
  };
  listById: {
    [key: number]: UnpyNotification;
  };
  search: {
    byPages: {
      [key: number]: UnpyNotification[];
    };
  };
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    content: [],
    numberUnviewed: 0,
    numberUnviewedMessagesByConversation: {},
    listById: {},
    search: {
      byPages: {},
    },
  } as InitialNotificationSliceState,
  reducers: {
    setAllNotificationsPage: (
      state,
      action: PayloadAction<Pageable<UnpyNotification>>,
    ) => {
      state.search.byPages = {
        ...state.search.byPages,
        [action.payload.currentPage]: action.payload.content,
      };
    },
    setAllNotifications: (state, action: PayloadAction<UnpyNotification[]>) => {
      state.listById = { ...state.listById, ..._.keyBy(action.payload, 'id') };
    },
    setOneNotification: (state, action: PayloadAction<UnpyNotification>) => {
      state.listById[action.payload.id] = action.payload;
    },
    setNumberUnviewed: (state, action: PayloadAction<number>) => {
      state.numberUnviewed = action.payload > 0 ? action.payload : 0;
    },
    setNumberUnviewedMessages: (
      state,
      action: PayloadAction<{ [key: number]: number }>,
    ) => {
      state.numberUnviewedMessagesByConversation = {
        ...state.numberUnviewedMessagesByConversation,
        ...action.payload,
      };
    },
    incrementNumberUnviewed: (state) => {
      state.numberUnviewed += 1;
    },
    decrementNumberUnviewed: (state) => {
      state.numberUnviewed = state.numberUnviewed - 1 > 0 ? state.numberUnviewed - 1 : 0;
    },
    incrementNumberUnviewedMessages: (state, action: PayloadAction<number>) => {
      state.numberUnviewedMessagesByConversation = {
        ...state.numberUnviewedMessagesByConversation,
        [action.payload]:
          (state.numberUnviewedMessagesByConversation[action.payload] || 0) + 1,
      };
    },
    decrementNumberUnviewedMessages: (state, action: PayloadAction<number>) => {
      state.numberUnviewedMessagesByConversation = {
        ...state.numberUnviewedMessagesByConversation,
        [action.payload]: state.numberUnviewedMessagesByConversation[action.payload]
          ? state.numberUnviewedMessagesByConversation[action.payload] - 1
          : 0,
      };
    },
    moinsNumberUnviewedMessages: (
      state,
      action: PayloadAction<{ idConversation: number; unviewToDecrease: number }>,
    ) => {
      state.numberUnviewedMessagesByConversation = {
        ...state.numberUnviewedMessagesByConversation,
        [action.payload.idConversation]:
          state.numberUnviewedMessagesByConversation[action.payload.idConversation] &&
          state.numberUnviewedMessagesByConversation[action.payload.idConversation] -
            action.payload.unviewToDecrease >
            0
            ? state.numberUnviewedMessagesByConversation[action.payload.idConversation] -
              action.payload.unviewToDecrease
            : 0,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setNumberUnviewed,
  setNumberUnviewedMessages,
  incrementNumberUnviewed,
  decrementNumberUnviewed,
  incrementNumberUnviewedMessages,
  decrementNumberUnviewedMessages,
  moinsNumberUnviewedMessages,
  setAllNotifications,
  setAllNotificationsPage,
  setOneNotification,
} = notificationSlice.actions;

export default notificationSlice.reducer;

const selectSelf = (state: RootState) => state;
const selectSelfWithId = (_: RootState, id: number) => id;
export const selectNotificationById = createSelector(
  selectSelf,
  selectSelfWithId,
  (state, id) => state.notification.listById?.[id],
);

export const selectAllNotification = createSelector(selectSelf, (state) =>
  state.notification.listById ? Object.values(state.notification.listById) : undefined,
);

export const selectNotificationByPage = createSelector(
  selectSelf,
  selectSelfWithId,
  (state, pageNumber) => state.notification.search.byPages?.[pageNumber],
);
