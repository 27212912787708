import './ModalDisconnected.scss';

import { Cancel } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useBooleanToggle } from 'primary/hooks/useBooleanToggle';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslate } from '../../hooks/useTranslate';

export const ModalDisconnectedOpenType = 'OPEN_MODAL_DISCONNECTED';

export const ModalDisconnected = () => {
  const [isOpen, , open, handleClose] = useBooleanToggle();
  const navigate = useNavigate();
  const [location, setLocation] = React.useState('');
  useEffect(() => {
    const eventListener = (() => {
      if (!isOpen) {
        open();
        navigate(routesConfig.home.to());
      }
      // eslint-disable-next-line no-undef
    }) as EventListener;

    document.addEventListener(ModalDisconnectedOpenType, eventListener);
    return () => document.removeEventListener(ModalDisconnectedOpenType, eventListener);
  }, []);

  const t = useTranslate();
  const { authRepository } = useContextDependency();

  return (
    <ModalContainer
      handleClose={handleClose}
      isOpen={isOpen}
      content={
        <Box className={'modalDisconnected'}>
          <div className={'modalDisconnected__container'}>
            <div className={'modalDisconnected__container-content'}>
              <h2>{t('general.disconnected.noticeSessionFinish')}</h2>
              <div className={'-buttons'}>
                <Button
                  className={'-button'}
                  variant={'outlined'}
                  color={'primary'}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <Cancel />
                  {t('general.disconnected.stayDisconnectLabel')}
                </Button>
                <LoadingButton
                  variant={'contained'}
                  color={'primary'}
                  type={'button'}
                  onClick={() => authRepository.login(location)}
                  disabled={false}
                >
                  {t('general.disconnected.reconnectLabel')}
                </LoadingButton>
              </div>
            </div>
          </div>
        </Box>
      }
    />
  );
};
