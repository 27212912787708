import './EventFilters.scss';

import { Button, Hidden } from '@mui/material';
import { EventStatusEnum } from 'domain/event/EventStatusEnum';
import { UnpyEventFilters } from 'domain/event/UnpyEvent';
import { selectFiltersByDomain } from 'domain/filters/FiltersSlice';
import { toEnum } from 'helper/enum.helper';
import { FilterStatus } from 'primary/Components/Filters/Events/FilterStatus';
import { FilterTypeEvent } from 'primary/Components/Filters/Events/FilterTypeEvent';
import { FilterTypeTeam } from 'primary/Components/Filters/Events/FilterTypeTeam';
import { ModalFitlers } from 'primary/Components/Filters/ModalFilters';
import { TypeSearchEvent } from 'primary/events/list/EventList';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import { FilterPlatform } from '../FilterPlatform';

interface EventsFiltersProps {
  setFilters: (filters: UnpyEventFilters) => void;
  typeSearch: TypeSearchEvent;
  resetFilters?: () => void;
}

export const EventsFilters = ({
  setFilters,
  typeSearch,
  resetFilters,
}: EventsFiltersProps) => {
  return (
    <>
      <Hidden smUp>
        <EventsFiltersMobile
          setFilters={setFilters}
          typeSearch={typeSearch}
          resetFilters={resetFilters}
        />
      </Hidden>

      <Hidden smDown>
        <EventsFiltersComp
          setFilters={setFilters}
          typeSearch={typeSearch}
          resetFilters={resetFilters}
        />
      </Hidden>
    </>
  );
};

const EventsFiltersMobile = ({
  setFilters,
  typeSearch,
  resetFilters,
}: EventsFiltersProps) => {
  const t = useTranslate();
  return (
    <ModalFitlers
      // @ts-ignore
      resetFilters={resetFilters}
      filtersComp={
        <EventsFiltersComp
          setFilters={setFilters}
          typeSearch={typeSearch}
          resetFilters={resetFilters}
        />
      }
    />
  );
};

const EventsFiltersComp = ({
  setFilters,
  typeSearch,
  resetFilters,
}: EventsFiltersProps) => {
  const t = useTranslate();
  const [key, setKey] = useState(Math.random());
  // @ts-ignore
  const activeFiltersStore: UnpyEventFilters = useSelector((state: RootState) =>
    selectFiltersByDomain(state, 'event'),
  );
  const onChangePlatform = (value?: string) => {
    setFilters({
      ...activeFiltersStore,
      platforms: value ? [value] : undefined,
    });
  };

  const onChangeStatus = (value?: string) => {
    setFilters({
      ...activeFiltersStore,
      status: value ? toEnum(EventStatusEnum, value) : undefined,
    });
  };

  const onChangeTypeEvent = (value?: string) => {
    setFilters({
      ...activeFiltersStore,
      typeEvent: value,
    });
  };

  const onChangeTypeTeam = (value?: string) => {
    setFilters({
      ...activeFiltersStore,
      typeTeam: value,
    });
  };

  const onChangeDate = (value?: string) => {
    setFilters({
      ...activeFiltersStore,
      startDateTime: value,
    });
  };

  useEffect(() => {
    setKey(Math.random());
  }, [activeFiltersStore]);

  return (
    <div className={'eventFilters'}>
      <div className={'-filtersInputs'}>
        <FilterPlatform
          selectedPlatform={
            activeFiltersStore.platforms ? activeFiltersStore.platforms[0] : undefined
          }
          onSelectPlatform={onChangePlatform}
        />
        <FilterStatus
          typeSearch={typeSearch}
          onSelectStatus={onChangeStatus}
          selectedStatus={activeFiltersStore.status}
        />
        <FilterTypeEvent
          onSelectTypeEvent={onChangeTypeEvent}
          selectedTypeEvent={activeFiltersStore.typeEvent}
        />
        <FilterTypeTeam
          onSelectTypeTeam={onChangeTypeTeam}
          selectedTypeTeam={activeFiltersStore.typeTeam}
        />
        <Hidden smDown>
          <div className={'--actions'}>
            <Button
              onClick={() =>
                setFilters?.({
                  platforms: undefined,
                  status: undefined,
                  typeEvent: undefined,
                  typeTeam: undefined,
                  startDateTime: undefined,
                })
              }
              color={'primary'}
              variant={'outlined'}
            >
              {t(`layoutPageCommonList.filtersResetBtn`)}
            </Button>
          </div>
        </Hidden>
      </div>
    </div>
  );
};
