import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StructureProfil } from '../../profil/StructureProfil';
import { UserProfil } from '../../profil/UserProfil';
import { Structure } from '../../structure/Structure';
import { User } from '../User';

type InitialUserSliceState = {
  connectedUser?: User;
  currentToken?: string;
  structuresOfUser?: Structure[];
  isAccountLoaded?: boolean;
};

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    connectedUser: undefined,
    structuresOfUser: undefined,
    currentToken: undefined,
    isAccountLoaded: undefined,
  } as InitialUserSliceState,
  reducers: {
    setIsAccountLoaded: (state, action: PayloadAction<boolean>) => {
      state.isAccountLoaded = action.payload;
    },
    setConnectedUser: (state, action: PayloadAction<User>) => {
      state.connectedUser = action.payload;
      state.isAccountLoaded = true;
    },
    clearConnectedUser: (state) => {
      state.connectedUser = undefined;
      state.isAccountLoaded = true;
    },
    setCurrentToken: (state, action: PayloadAction<string | undefined>) => {
      state.currentToken = action.payload;
      state.isAccountLoaded = true;
    },
    setConnectedUserProfil: (state, action: PayloadAction<UserProfil>) => {
      if (!state.connectedUser) return;
      state.connectedUser = {
        ...state.connectedUser,
        userProfil: action.payload,
      };
    },
    setOneConnectedUserStructure: (state, action: PayloadAction<Structure>) => {
      if (!state.connectedUser) return;
      state.structuresOfUser = [
        ...(state.structuresOfUser?.filter((s) => s.id != action.payload.id) ?? []),
        action.payload, // eslint-disable-next-line no-unsafe-optional-chaining
      ];
    },
    setOneConnectedUserStructureProfil: (
      state,
      action: PayloadAction<StructureProfil>,
    ) => {
      if (
        !state.connectedUser ||
        !state.structuresOfUser ||
        !state.structuresOfUser.find((state) => state.structureProfil?.id)
      )
        state.structuresOfUser = [
          ...(state.structuresOfUser?.map((structure) => {
            if (structure.structureProfil?.id === action.payload.id) {
              return {
                ...structure,
                structureProfil: action.payload,
              };
            }
            return structure;
          }) ?? []),
        ];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsAccountLoaded,
  setConnectedUser,
  clearConnectedUser,
  setConnectedUserProfil,
  setOneConnectedUserStructureProfil,
  setOneConnectedUserStructure,
  setCurrentToken,
} = userSlice.actions;

export default userSlice.reducer;
