import './MatchsViewLigue.scss';

import { Grid, Pagination } from '@mui/material';
import { UnpyMatch, UnpyMatchFilters } from 'domain/event/match/UnpyMatch';
import { ListElements } from 'primary/Components/ListElements/ListElements';
import { HeaderMatchsEdit } from 'primary/events/components/matchs/HeaderMatchsEdit';
import { MatchViewItem } from 'primary/events/components/matchs/view/MatchViewItem';
import { EventViewPageContext } from 'primary/events/item/EventViewPage';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetchWithFilterPage } from 'primary/hooks/useFetchWithFilterPage';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { useOutletContext } from 'react-router-dom';

export const MatchsViewLigue = () => {
  const { event } = useOutletContext<EventViewPageContext>();
  const { matchsRepository } = useContextDependency();
  const t = useTranslate();
  const {
    setFilters,
    activeFilters,
    fetchedResource,
    loading,
    goToPage,
    changeNbPerPage,
    retry,
  } = useFetchWithFilterPage<UnpyMatch, UnpyMatchFilters>({
    keyStoreFilters: 'matchs-ligue-filters',
    staticFilters: {
      idEvent: event.id,
    },
    filters: {},
    callApi: (filters, page) => matchsRepository.search(filters, page, 10),
  });

  const setFilterByKey = (
    key: keyof UnpyMatchFilters,
    val?: string | number | boolean,
  ) => {
    setFilters({ ...(activeFilters?.filters ?? {}), [key]: val });
  };

  return (
    <div className={'matchsViewLigue'}>
      <div className={'-header'}>
        <div className={'-filters'}>
          <HeaderMatchsEdit
            retry={retry}
            event={event}
            withTextField
            withOnlyScoreUnset
            withOnlyMyMatchs
            filters={activeFilters?.filters ?? {}}
            setFilters={setFilterByKey}
          />
        </div>
      </div>
      <ListElements loading={loading} empty={fetchedResource?.content.length === 0}>
        <Grid container>
          {!loading &&
            fetchedResource?.totalElementsCount !== 0 &&
            fetchedResource?.content.map((match) => (
              <Grid
                item
                key={match.id}
                xs={12}
                sm={12}
                md={4}
                className={'list__elements'}
              >
                <MatchViewItem match={match} event={event} />
              </Grid>
            ))}
        </Grid>
      </ListElements>
      <div className={'-pagination'}>
        <Pagination
          key={fetchedResource?.currentPage}
          count={fetchedResource?.pagesCount || 1}
          page={fetchedResource?.currentPage ? fetchedResource?.currentPage + 1 : 1}
          size={'medium'}
          onChange={(e, value) => goToPage(value - 1)}
        />
      </div>
    </div>
  );
};
