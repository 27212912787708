import { Button, Grid } from '@mui/material';
import { UnpySubscriptionStatus } from 'domain/payment/UnpySubscriptionStatus';
import { PageLayout } from 'primary/Components/PageLayout/PageLayout';
import { Title } from 'primary/Components/Title/Title';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import { InvoicesList } from 'primary/Parameters/user/Account/InvoicesList';
import { SubscriptionView } from 'primary/Parameters/user/Account/SubscriptionView';
import { useUserParameters } from 'primary/Parameters/user/useUserParameters.hook';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Payments = () => {
  const t = useTranslate();
  const { authRepository } = useContextDependency();
  const { user, onSubmitted } = useUserParameters();
  const navigate = useNavigate();
  const handleGoUpPage = () => {
    navigate(routesConfig.up.to());
  };
  return (
    <PageLayout>
      <Title
        title={t('user.parameters.paymentsAbonnements.title')}
        level={1}
        compensatePadding
        threeQuarter
        bordered
      />
      <Grid container>
        <Grid item xs={12} md={6}>
          <Title
            title={t('user.parameters.paymentsAbonnements.abonnements.title')}
            level={3}
            compensatePadding
          />
          {user?.subscription?.status === UnpySubscriptionStatus.ACTIVE && (
            <SubscriptionView
              onUpdate={() => authRepository.refreshCurrentUser()}
              subscription={user?.subscription}
            />
          )}
          {user?.subscription?.status !== UnpySubscriptionStatus.ACTIVE && (
            <div className={'-noSubscription'}>
              <span>{t('subscription.noSubscriptionText')}</span>
              <Button
                variant={'contained'}
                color={'primary'}
                onClick={handleGoUpPage}
                className={'-btnSubscribe'}
              >
                {t('subscription.parametersGoSubscribe')}
              </Button>
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Title title={t('invoice.list.title')} level={3} compensatePadding />
          <InvoicesList />
        </Grid>
      </Grid>
    </PageLayout>
  );
};
