import './ModalConsumeLinkInvitation.scss';

import { InvitationLinkTypeEnum } from 'domain/invitationLink/InvitationLinkTypeEnum';
import { FormStepper, FormStepperConf } from 'primary/Components/FormStepper/FormStepper';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import { useTranslate, UseTranslateReturn } from 'primary/hooks/useTranslate';
import { InvitationLinkStepAccount } from 'primary/InvitationLink/Modal/Steps/InvitationLinkStepAccount';
import {
  ConsumedStructureInvitationLink,
  ConsumeStructureInvitationLink,
} from 'primary/InvitationLink/Modal/Steps/StructureInvitationLinkContent';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { RootState } from 'store';

export const confStepInvitationLink: (
  t: UseTranslateReturn,
  typeInvitationLink: InvitationLinkTypeEnum,
) => FormStepperConf = (t: UseTranslateReturn, typeInvitationLink) => ({
  steps: [
    {
      id: 'step-account',
      title: t(`invitationLink.consumeModal.stepsByType.${typeInvitationLink}.account`),
      component: InvitationLinkStepAccount,
    },
    {
      id: 'step-structure',
      title: t(`invitationLink.consumeModal.stepsByType.${typeInvitationLink}.consume`),
      component: ConsumeStructureInvitationLink,
    },
    {
      id: 'step-finish',
      title: t(`invitationLink.consumeModal.stepsByType.${typeInvitationLink}.finish`),
      component: ConsumedStructureInvitationLink,
    },
  ],
});
export const ModalConsumeLinkInvitation = () => {
  const [searchParams] = useSearchParams();
  const [link, setLink] = useState<string | undefined>();
  const { invitationLinkRepository } = useContextDependency();

  const [forceClose, setForceClose] = useState(false);
  const t = useTranslate();
  useEffect(() => {
    const uuid = searchParams?.get('invitationLink');
    if (uuid && !link) {
      setLink(uuid);
    }
  }, [searchParams]);
  const isAccountLoaded = useSelector((state: RootState) => state.user.isAccountLoaded);

  const [linkFetched, , fetching] = useRetrieveFromDomain(
    () => invitationLinkRepository.getByUuid(link as string),
    undefined,
    !!link,
  );

  return (
    <ModalContainer
      handleClose={() => setForceClose(true)}
      isOpen={!!link && !forceClose}
      content={
        <div className={'modalConsumeLinkInvitation'}>
          <div className={'modalConsumeLinkInvitation__header'}>
            <h2>
              {linkFetched &&
                t(
                  `invitationLink.consumeModal.${linkFetched?.typeInvitationLink}.titleModal`,
                )}
            </h2>
          </div>
          <div className={'modalConsumeLinkInvitation__content'}></div>
          {linkFetched && (
            <FormStepper
              conf={confStepInvitationLink(t, linkFetched.typeInvitationLink)}
              generalProps={{
                handleClose: close,
                link: linkFetched,
              }}
            />
          )}
          {!fetching && !linkFetched && (
            <span>{t('invitationLink.consumeModal.linkNotFound')}</span>
          )}
        </div>
      }
    />
  );
};
