import '../../structure/Profil/Appearence.scss';

import { SwapHoriz } from '@mui/icons-material';
import { Button, Grid, ThemeProvider } from '@mui/material';
import { BackgroundImageType } from 'domain/backgroundImage/BackgroundImageType';
import { ValidationStringLengthEnum } from 'domain/enums/ValidationStringLengthEnum';
import { NeonType } from 'domain/neon/NeonType';
import { IUserProfilRepository } from 'domain/user/UserProfil.repository';
import { PageLayout } from 'primary/Components/PageLayout/PageLayout';
import { Title } from 'primary/Components/Title/Title';
import { BackgroundImageForm } from 'primary/Components/UserAppaearenceForms/BackgroundImageForm';
import { NeonForm } from 'primary/Components/UserAppaearenceForms/NeonForm';
import { ProfilPhotoForm } from 'primary/Components/UserAppaearenceForms/ProfilPhotoForm';
import { useTranslate, UseTranslateReturn } from 'primary/hooks/useTranslate';
import { useYupValidationResolver } from 'primary/hooks/useYupValidationResolver';
import { PreviewEditUserProfil } from 'primary/Parameters/user/Profil/PreviewEditUserProfil';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { themeParameters } from '../../parameters.helper';
import { useUserParameters } from '../useUserParameters.hook';

export type resizePropsFormData = {
  startX: number;
  startY: number;
  endX: number;
  endY: number;
};

export type ProfilAppearenceFormData = {
  fileNameUploaded?: string;
  resizeProps?: resizePropsFormData;
  backgroundImageId?: number;
  neon?: number;
};

const validationSchema = (t: UseTranslateReturn) =>
  yup.object({
    fileNameUploaded: yup
      .string()
      .max(
        ValidationStringLengthEnum.LONG,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
      )
      .nullable(),
    backgroundImageId: yup.number(),
    neon: yup.number(),
  });

export const useOnSubmit =
  (userProfilRepository: IUserProfilRepository, onSubmitted: () => void, file?: File) =>
  (data: ProfilAppearenceFormData) => {
    userProfilRepository.updateUserProfilAppearence(data, file).then(onSubmitted);
  };

export const UserProfilAppearence = () => {
  const { user, onSubmitted, userProfilRepository } = useUserParameters();
  const t = useTranslate();
  const resolver = useYupValidationResolver(validationSchema);

  const initialValues = useMemo(() => {
    return {
      fileNameUploaded: user?.userProfil.photo as string,
      backgroundImageId: user?.userProfil.backgroundImage?.id,
      neon: user?.userProfil.neon?.id,
    };
  }, [user?.userProfil]);

  const form = useForm<ProfilAppearenceFormData>({
    resolver,
    defaultValues: initialValues,
    mode: 'onSubmit',
  });
  const [file, setFile] = useState<File | undefined>();

  const onSubmit = useMemo(
    () => useOnSubmit(userProfilRepository, onSubmitted, file),
    [file],
  );
  const watchedValues = form.watch();
  return (
    <div className={'appearence'}>
      <form
        className={'appearenceForm'}
        name={'appearenceForm'}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <PageLayout>
          <>
            <Title
              title={t('user.parameters.appearence.title')}
              level={1}
              compensatePadding
              threeQuarter
              bordered
            />
            <ThemeProvider theme={themeParameters}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={5}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Title
                        title={t('user.parameters.appearence.titleForm')}
                        level={2}
                        accent
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className={'-boxField'}>
                      <ProfilPhotoForm
                        form={form}
                        onUploadFile={setFile}
                        fileUploaded={file}
                        isDefaultPhoto={user?.userProfil.defaultPhoto}
                        initialPath={initialValues.fileNameUploaded}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className={'-boxField'}>
                      <NeonForm form={form} type={NeonType.PLAYER} />
                    </Grid>
                    <Grid item xs={12} className={'-boxField'}>
                      <BackgroundImageForm
                        form={form}
                        type={BackgroundImageType.PLAYER}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2} className={'-iconCol'}>
                  <SwapHoriz />
                  <Button
                    color={'primary'}
                    style={{ justifySelf: 'flex-end' }}
                    variant={'contained'}
                    type={'submit'}
                  >
                    {t('parameters.menus.profil.user.form.submit')}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Title
                    title={t('user.parameters.appearence.titlePreview')}
                    level={2}
                    accent
                  />
                  <PreviewEditUserProfil
                    profil={user?.userProfil}
                    draftedValues={watchedValues}
                    draftPhoto={file}
                  />
                </Grid>
              </Grid>
            </ThemeProvider>
          </>
        </PageLayout>
      </form>
    </div>
  );
};
