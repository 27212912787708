import { AxiosInstance } from 'axios';
import {
  InvitationLink,
  InvitationLinkFilters,
} from 'domain/invitationLink/InvitationLink';
import { IInvitationLinkRepository } from 'domain/invitationLink/InvitationLink.repository';
import { Pageable } from 'domain/pageable/Pageable';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';
import { toInvitationLink } from 'secondary/invitationLink/RestInvitationLink';
import { toPageable } from 'secondary/RestPageable';

export class InvitationLinkRepository
  extends AxiosProviderRepository
  implements IInvitationLinkRepository
{
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  // create(invitationLinkForm: CreateEditInvitationLinksForm): Promise<InvitationLink> {
  //   return this.axios
  //     .post(`unpy/api/link/invitation`, invitationLinkForm)
  //     .then((response) => toInvitationLink(response.data));
  // }

  deleteById(id: number): Promise<InvitationLink> {
    return this.axios
      .delete(`unpy/api/invitationLink/${id}`)
      .then((response) => toInvitationLink(response.data));
  }

  // edit(
  //   invitationLinkForm: CreateEditInvitationLinksForm,
  //   id: number,
  // ): Promise<InvitationLink> {
  //   return this.axios
  //     .put(`unpy/api/invitationLink/${id}`, invitationLinkForm)
  //     .then((response) => toInvitationLink(response.data));
  // }

  // getById(id: number): Promise<InvitationLink> {
  //   return this.axios
  //     .get(`unpy/api/invitationLink/${id}`)
  //     .then((response) => toInvitationLink(response.data));
  // }

  search(
    filters: InvitationLinkFilters,
    page: number,
    nbPerPage: number,
  ): Promise<Pageable<InvitationLink>> {
    return this.axios
      .get(`/unpy/api/link/invitations/search`, {
        params: {
          ...filters,
          page,
          nbPerPage,
        },
      })
      .then((response) => toPageable(response, toInvitationLink));
  }

  getByUuid(uuidLink: string): Promise<InvitationLink> {
    return this.axios
      .get(`/unpy/api/link/invitation/${uuidLink}`)
      .then((response) => toInvitationLink(response.data));
  }
}
