import { BottomNavigationAction } from '@mui/material';
import classNames from 'classnames';
import React, { FC } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { BottomNavActions } from './BottomNavActions';
import { ConfParameterItem } from './MenusParametersUser.config';

interface BottomNavItemProps {
  conf: ConfParameterItem;
}

export const BottomNavItem: FC<BottomNavItemProps> = ({ conf }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const { pathname } = useLocation();
  const currentSubConfActive = conf.subMenu.find((confF) =>
    matchPath(
      {
        path: confF.path as unknown as string,
        end: false,
      },
      pathname,
    ),
  );
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      <BottomNavActions
        conf={conf.subMenu}
        open={open}
        setOpen={setOpen}
        anchorRef={anchorRef}
        arias={conf.title}
        currentSubConfActive={currentSubConfActive}
      />
      <BottomNavigationAction
        showLabel
        className={classNames('bottomNav__button', {
          '-selected': !!currentSubConfActive,
        })}
        ref={anchorRef}
        id={conf.title + '-button'}
        aria-controls={open ? conf.title : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        label={
          <div className={'-bottomNavLabel'}>
            <span className={'-title'}>{conf.title}</span>
            <span className={'-subPath'}>{currentSubConfActive?.label}</span>
          </div>
        }
      />
    </>
  );
};
