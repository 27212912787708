import './UnpyLogo.scss';

import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import unpyLogoV1 from '../../../assets/Logo/unpyLogoV1.png';
import { routesConfig } from '../../Configs/Routes.config';

type UnpyLogoProps = {
  className?: string;
  size?: 'small' | 'medium' | 'big' | 'filled' | 'bigger';
};
export const UnpyLogo = ({ className, size = 'medium' }: UnpyLogoProps) => {
  return (
    <div className={classNames('unpyLogo', className, size)}>
      <Link to={routesConfig.home.to()}>
        <img alt={'logo-unpy'} src={unpyLogoV1} />
      </Link>
    </div>
  );
};
