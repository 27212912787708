import { BackgroundImageType } from 'domain/backgroundImage/BackgroundImageType';

export class BackgroundImageView {
  constructor(
    public readonly id: number,
    public readonly code: string,
    public readonly isUp: boolean,
    public readonly path: string,
    public readonly fileName: string,
    public readonly publicUrl: string,
    public readonly publicUrlSm: string,
    public readonly isDefault: boolean,
    public readonly type: BackgroundImageType,
  ) {}
}
