import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Neon } from 'domain/neon/Neon';
import _ from 'lodash';
import { RootState } from 'store';

type InitialNeonSliceState = {
  byId?: { [key: number]: Neon };
};

export const neonSlice = createSlice({
  name: 'neon',
  initialState: {
    byId: undefined,
  } as InitialNeonSliceState,
  reducers: {
    setNeons: (state, action: PayloadAction<Neon[]>) => {
      state.byId = { ...state.byId, ..._.keyBy(action.payload, 'id') };
    },
    setNeonById: (state, action: PayloadAction<Neon>) => {
      if (!state.byId) state.byId = {};
      state.byId[action.payload.id] = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setNeons, setNeonById } = neonSlice.actions;

export default neonSlice.reducer;

const selectSelf = (state: RootState) => state;
const selectSelfWithId = (_: RootState, id: number) => id;
export const selectNeonById = createSelector(
  selectSelf,
  selectSelfWithId,
  (state, id) => state.neons.byId?.[id],
);

export const selectAllNeons = createSelector(selectSelf, (state) =>
  state.neons.byId ? Object.values(state.neons.byId) : undefined,
);
