import './AutocompleteUserProfil.scss';

import { Autocomplete, TextField } from '@mui/material';
import classNames from 'classnames';
import { debounce, DebouncedFunc } from 'lodash';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { useEffect, useMemo } from 'react';

import { UserProfil, UserProfilFilters } from '../../../domain/profil/UserProfil';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useFetchWithFilterPage } from '../../hooks/useFetchWithFilterPage';
import { InputProps, Option } from '../Input/InputWrapper';

type AutocompleteUserProfilWrapperFetchProps = {
  multiple?: boolean;
  className?: string;
  idStructure?: string;
  playersToExclude?: string[];
  notInEventId?: string;
  idAsValue?: boolean;
  staticFilters?: UserProfilFilters;
} & InputProps;

export const AutocompleteUserProfilWrapperFetch = (
  props: AutocompleteUserProfilWrapperFetchProps,
) => {
  const { userProfilRepository } = useContextDependency();

  const defaultFilters: UserProfilFilters = {
    games: undefined,
    experiences: undefined,
    platforms: undefined,
    maxAge: undefined,
    minAge: undefined,
    langs: undefined,
    idPlayer: '',
    idStructure: undefined,
    notInEventId: undefined,
  };
  const { fetchedResource, setFilters, loading } = useFetchWithFilterPage<
    UserProfil,
    UserProfilFilters
  >({
    keyStoreFilters: 'divers',
    filters: defaultFilters,
    staticFilters: props.staticFilters,
    conditionFetch: (f: UserProfilFilters) => (f.idPlayer?.length ?? 0) >= 3,
    callApi: (filters, page) => userProfilRepository.search(filters, page, 100),
  });

  const setFiltersDebonced = debounce((idPlayer: string) => {
    setFilters({ ...defaultFilters, idPlayer: idPlayer });
  }, 100);

  const options = useMemo(() => {
    return [
      ...(fetchedResource?.content?.map((option) => ({
        label: option.idPlayer,
        value: props.idAsValue ? option.id : option.idPlayer,
        meta: option,
      })) ?? []),
    ];
  }, [fetchedResource]);
  return (
    <AutocompleteUserProfil
      {...props}
      onChangeSelected={props.onChangeSelected}
      onChangeText={setFiltersDebonced}
      options={options}
      loading={loading}
    />
  );
};

type AutocompleteUserProfilProps = {
  options: any;
  onChangeText: DebouncedFunc<(idPlayer: string) => void>;
  multiple?: boolean;
  className?: string;
  loading?: boolean;
  playersToExclude?: string[];
} & InputProps;

export const AutocompleteUserProfil = ({
  options,
  onChangeText,
  className,
  onChangeSelected,
  multiple,
  disabled,
  value,
  loading,
  playersToExclude,
  ...props
}: AutocompleteUserProfilProps) => {
  useEffect(() => {
    onChangeText(value as string);
  }, [value]);
  const t = useTranslate();
  return (
    // @ts-ignore
    <Autocomplete
      {...props}
      loading={loading}
      key={value as string}
      className={classNames('autocomplete-user-profil', className)}
      multiple={multiple}
      disabled={disabled}
      noOptionsText={t('autocomplete.noOptions')}
      onChange={(e, option: any) => {
        if (!!onChangeSelected && !disabled) {
          onChangeSelected(option?.value);
        }
      }}
      options={options}
      getOptionDisabled={(option: Option) => {
        // @ts-ignore
        return playersToExclude?.includes(option.meta.idPlayer) ?? false;
      }}
      // @ts-ignore
      value={value}
      loadingText={
        <LoadingScreen
          inline
          size={15}
          loading={true}
          message={t('autocomplete.loading')}
        />
      }
      disablePortal
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t('autocomplete.userProfil.placeholder')}
          onChange={(event) => {
            !disabled && onChangeText(event.target.value);
          }}
        />
      )}
    />
  );
};
