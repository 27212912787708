import './index.css';

import * as Sentry from '@sentry/react';
//import { initializeApp } from 'firebase/app';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import store from './store';

Sentry.init({
  dsn: 'https://61bcbfdb0e78e5c6f758095417d3f881@o4507442338332672.ingest.de.sentry.io/4507442341347408',
  integrations: [Sentry.replayIntegration()],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// const firebaseConfig = {
//   apiKey: 'AIzaSyCJeL7UjZCTIyFjFf9HbdXR0Zi9u4q_X08',
//   authDomain: 'unpy-rec.firebaseapp.com',
//   projectId: 'unpy-rec',
//   storageBucket: 'unpy-rec.appspot.com',
//   messagingSenderId: '722694410642',
//   appId: '1:722694410642:web:1e84f0d1798c0d980bf14b',
//   measurementId: 'G-WBMHJW9N8M',
// };
// initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
