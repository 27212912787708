import './MyMessagePage.scss';

import { Add, Remove } from '@mui/icons-material';
import { Button, Collapse } from '@mui/material';
import classNames from 'classnames';
import { Conversation, ConversationFilters } from 'domain/message/Conversation';
import { User } from 'domain/user/User';
import { UserProfilView } from 'domain/user/UserProfilView';
import { InfiniteScroll } from 'primary/Components/InfiniteScroll/InfiniteScroll';
import { LeftRight } from 'primary/Components/Layout/LeftRight/LeftRight';
import { ConversationItem } from 'primary/Components/messages/conversationItem/ConversationItem';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

import { AutocompleteUserProfilWrapperFetch } from '../../Components/AutoCompleteUserProfil/AutocompleteUserProfil';
import {
  ListMessagesConversation,
  WrapperFetchListMessagesConversation,
} from '../../Components/messages/listMessages/ListMessagesConversation';
import { PageLayout } from '../../Components/PageLayout/PageLayout';
import { Title } from '../../Components/Title/Title';
import { useFetchWithFilterPage } from '../../hooks/useFetchWithFilterPage';

export const MESSAGES_UPDATE_EVENT = 'MESSAGES_UPDATE_EVENT';

export const PageMyMessages: FC = () => {
  const { authRepository, messageRepository } = useContextDependency();
  if (!authRepository.currentUser) return null;
  const t = useTranslate();
  const dispatch = useDispatch();
  const [newConversation, setNewConversation] = useState<boolean>(false);
  const [conversationChoosen, setConversationChoosen] = useState<number | undefined>();
  const {
    fetchedResource,
    goToPage,
    loading,
    activeFilters,
    setFilters,
    fetch,
    nextPage,
    retry,
    allContents,
  } = useFetchWithFilterPage<Conversation, ConversationFilters>({
    filters: { idPlayer: '' },
    callApi: (filters, page) => messageRepository.getConversations(filters, page, 10),
    keepPageResult: true,
    keyStoreFilters: 'conversation-list',
  });
  const conversationId = new URLSearchParams(window.location.search).get(
    'conversationId',
  );

  const numberUnviewedByConversation = useSelector(
    (state: RootState) => state.notification.numberUnviewedMessagesByConversation,
  );

  useEffect(() => {
    const eventListener = (() => {
      retry();
      // eslint-disable-next-line no-undef
    }) as EventListener;

    document.addEventListener(MESSAGES_UPDATE_EVENT, eventListener);
    return () => document.removeEventListener(MESSAGES_UPDATE_EVENT, eventListener);
  }, []);

  useEffect(() => {
    if (conversationId && !conversationChoosen) {
      setConversationChoosen(parseInt(conversationId));
    }
  }, [conversationId, conversationChoosen, fetchedResource]);

  const createConversation = (userId: number) => {
    messageRepository.createConversation(userId).then((conversation) => {
      setConversationChoosen(conversation.id);
    });
  };

  const onClickConversation = (conversation: Conversation) => () =>
    setConversationChoosen(
      conversation.id === conversationChoosen ? undefined : conversation.id,
    );

  const fetchedConversationChoosen = useMemo(() => {
    return fetchedResource?.content?.find(
      (conversation) => conversation.id === conversationChoosen,
    );
  }, [conversationChoosen, fetchedResource]);

  // @ts-ignore
  return (
    <div className={'myMessagePage'}>
      <PageLayout>
        <>
          <Title
            className={'myMessagePage__titlePage'}
            title={t('message.page.title')}
            level={1}
            compensatePadding
            threeQuarter
            bordered
          />
          <div className={'myMessagePage__container'}>
            <LeftRight
              sizeLeft={'big'}
              close={() => setConversationChoosen(undefined)}
              transparency={'opaq'}
              isOpen={!!conversationChoosen}
              scrollInside
              left={
                <div
                  className={classNames(
                    'myMessagePage__conversations',
                    '-mobileDisplay',
                    { '--false': !!conversationChoosen },
                    { '--true': !conversationChoosen },
                  )}
                >
                  <div className={'myMessagePage__conversations-header'}>
                    <div className={'-titleBtn'}>
                      <h3>{t('message.conversation.title')}</h3>
                      <Button onClick={() => setNewConversation(!newConversation)}>
                        {!newConversation && <Add />}
                        {newConversation && <Remove />}
                        <span>{t('message.conversation.addLabel')}</span>
                      </Button>
                    </div>
                    <Collapse className={'-collapse'} in={newConversation}>
                      <AutocompleteUserProfilWrapperFetch
                        idAsValue
                        onChangeSelected={(value) => createConversation(value as number)}
                        staticFilters={{
                          pingMsg: true,
                          toExclude: [authRepository.currentUser?.userProfil?.id],
                        }}
                      />
                    </Collapse>
                  </div>
                  <div className={classNames('myMessagePage__conversations__list')}>
                    <InfiniteScroll onScrollBottom={() => nextPage()} isLoading={loading}>
                      {(scrollToTop) =>
                        allContents?.map((conversation) => (
                          <ConversationItem
                            key={conversation.id}
                            numberUnviewed={
                              numberUnviewedByConversation[conversation.id] || 0
                            }
                            retry={fetch}
                            idConversation={conversation.id}
                            onClick={onClickConversation(conversation)}
                            selected={conversationChoosen === conversation.id}
                            userTo={
                              conversation.getUserTo(
                                authRepository.currentUser as User,
                              ) as UserProfilView
                            }
                            lastMessage={
                              conversation.messages?.sort((a, b) => b.id - a.id)[0]
                            }
                          />
                        ))
                      }
                    </InfiniteScroll>
                  </div>
                </div>
              }
              right={
                <div
                  className={classNames(
                    'myMessagePage__messages',
                    '-mobileDisplay',
                    { '--false': !conversationChoosen },
                    { '--true': !!conversationChoosen },
                  )}
                >
                  {fetchedConversationChoosen && (
                    <ListMessagesConversation
                      conversation={fetchedConversationChoosen}
                      key={fetchedConversationChoosen.id}
                    />
                  )}
                  {conversationChoosen && !fetchedConversationChoosen && (
                    <WrapperFetchListMessagesConversation
                      retryConvListe={fetch}
                      conversationId={conversationChoosen}
                    />
                  )}
                  {!conversationChoosen && (
                    <span>{t('message.page.chooseConversationToSeeMessage')}</span>
                  )}
                </div>
              }
            />
          </div>
        </>
      </PageLayout>
    </div>
  );
};
