export type RestCountConsume = {
  count: number;
  max: number;
};

export const toCountConsume = (restCountConsume: RestCountConsume): CountConsume => {
  return new CountConsume(restCountConsume.count, restCountConsume.max);
};

export class CountConsume {
  constructor(public readonly count: number, public readonly max: number) {}
}
