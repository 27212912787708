import { GamingProfilEnum } from 'domain/enums/GamingProfil.enum';
import { ProfilPrivacy } from 'domain/privacy/ProfilPrivacy';
import { UnpyStatistiquesProfil } from 'domain/profil/UnpyStatistiquesProfil';

import { BackgroundImageView } from '../backgroundImage/BackgroundImageView';
import { Country } from '../country/Country';
import { GameProfil } from '../gameProfil/GameProfil';
import { Lang } from '../lang/Lang';
import { Neon } from '../neon/Neon';
import { Platform } from '../platform/Platform';
import { Profil } from './Profil';

export interface UserProfilFilters {
  games?: string[];
  platforms?: string[];
  experiences?: string[];
  maxAge?: number;
  minAge?: number;
  langs?: string[];
  idPlayer?: string;
  idStructure?: string;
  notInEventId?: string;
  pingMsg?: boolean;
  toExclude?: number[];
  country?: string;
}

export type UserProfilFiltersKeys = keyof UserProfilFilters;

export class UserProfil extends Profil {
  constructor(
    id: number,
    games: GameProfil[],
    public readonly idPlayer: string,
    neon: Neon,
    public readonly userId?: number,
    backgroundImage?: BackgroundImageView,
    description?: string,
    gamingProfil?: GamingProfilEnum,
    lang?: string,
    platforms?: Platform[],
    video?: string,
    ping?: boolean,
    twitter?: string,
    facebook?: string,
    youtube?: string,
    instagram?: string,
    discord?: string,
    twitch?: string,
    talkLangs?: Lang[],
    privacies?: ProfilPrivacy[],
    unpyStatistiques?: UnpyStatistiquesProfil,
    public readonly birthdate?: Date,
    public readonly country?: Country,
    public readonly photo?: string,
    public readonly publicPhotoUrl?: string,
    public readonly defaultPhoto?: boolean,
    public readonly idProfil?: number,
  ) {
    super(
      id,
      games,
      neon,
      backgroundImage,
      description,
      gamingProfil,
      lang,
      platforms,
      video,
      ping,
      twitter,
      facebook,
      youtube,
      instagram,
      discord,
      twitch,
      talkLangs,
      privacies,
      unpyStatistiques,
    );
  }
}
