import './FilterTalkLang.scss';

import { insertEmptyOpt } from 'helper/options.helper';
import { InputSelectMui } from 'primary/Components/Input/InputSelectMui';
import { InputWrapper } from 'primary/Components/Input/InputWrapper';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { ChangeHandler } from 'react-hook-form';

import { useContextDependency } from '../../hooks/useContextDependency';
import { useFetch } from '../../hooks/useFetch';

type FilterPlatformProps = {
  onSelectPlatform: (value?: string) => void;
  selectedPlatform?: string;
};

export const FilterPlatform = ({
  onSelectPlatform,
  selectedPlatform,
}: FilterPlatformProps) => {
  const t = useTranslate();
  const { platformRepository } = useContextDependency();
  const [platforms] = useFetch(() => platformRepository.getAll(), undefined);
  if (!platforms) return null;

  return (
    <div>
      <InputWrapper
        inline
        className={'unpy-form__field'}
        label={t('event.filters.platform')}
        errors={{}}
        key={selectedPlatform}
        Input={InputSelectMui}
        isSubmitting={false}
        inputProps={{
          placeholder: t('event.filters.platform'),
          // @ts-ignore
          onChange: (e: ChangeHandler) => {
            // @ts-ignore
            onSelectPlatform(e.target.value as string);
            // @ts-ignore
            if (e.target.value === selectedPlatform) {
              // @ts-ignore
              onSelectPlatform(undefined);
            }
          },
          value: selectedPlatform,
          options: insertEmptyOpt(
            platforms.map((type) => ({
              // @ts-ignore
              label: t(`enum.platforms.${type.code}`),
              value: type.code,
            })),
          ),
        }}
      />
    </div>
  );
};
