import BadgeCertif from '@assets/Logo/certif/badgeCertif.png';
import { Check } from '@mui/icons-material';
import classNames from 'classnames';
import {
  selectAllBackgroundImages,
  setBackgroundImages,
} from 'domain/backgroundImage/store/BackgroundImageSlice';
import { selectAllNeons, setNeons } from 'domain/neon/store/NeonSlice';
import { uniqBy } from 'lodash';
import { ImageSelectable } from 'primary/Components/ImageSelectable/ImageSelectable';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { UnpyLogoUp } from 'primary/Components/UnpyLogo/UnpyLogoUp';
import { BoxNeonSelectable } from 'primary/Components/UserAppaearenceForms/NeonForm';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetchAllProducts } from 'primary/hooks/useFetchAllProducts';
import { useRetrieveFromDomainToContext } from 'primary/hooks/useRetrieveFromDomain';
import { UnpyUpOffers } from 'primary/Up/UnpyUpOffers';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { RootState } from 'store';

import { Section } from '../../Components/Section/Section';
import { useTranslate } from '../../hooks/useTranslate';
import { HomeSectionProps } from '../Home';

export const HomeUpSection = ({ className }: HomeSectionProps) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const products = useFetchAllProducts();
  const { backgroundImageRepostory, neonRepository, allProfilRepository } =
    useContextDependency();

  const [, , neons] = useRetrieveFromDomainToContext(
    () => neonRepository.getAllNeons(),
    undefined,
    true,
    setNeons,
    selectAllNeons,
  );

  const [, , backgrounds] = useRetrieveFromDomainToContext(
    () => backgroundImageRepostory.getAllBackgroundImages(),
    undefined,
    true,
    setBackgroundImages,
    (state: RootState) => selectAllBackgroundImages(state),
  );

  if (!products) return <LoadingScreen loading={true} />;
  return (
    <Section className={className}>
      <div className={'homeDescriptionSection'}>
        <div className={'-cosmetiqueDescription'}>
          <p className={'-description'}>{t('home.infos.description1')}</p>
          <p className={'-description'}>{t('home.infos.description2')}</p>
          <div className={'-neons'}>
            {uniqBy(neons, 'color')
              ?.sort((a) => (a.isUp ? 1 : -1))
              .map((neon) => (
                <BoxNeonSelectable
                  readonly
                  key={neon.code}
                  onClick={undefined}
                  neon={neon}
                  isSelected={false}
                  isLockUp={false}
                />
              ))}
          </div>
          <div className={'-backgrounds'}>
            {backgrounds
              ?.filter((bg) => bg.isUp)
              .map((background) => (
                <ImageSelectable
                  key={background.code}
                  onClick={undefined}
                  alt={background.code}
                  imageUrl={background.publicUrlSm}
                  isSelected={false}
                  isLockUp={false}
                />
              ))}
          </div>
          <div className={'-certif'}>
            <h3>{t('homeCertifLabel')}</h3>
            <img src={BadgeCertif} alt={'certif-icon'} width={40} height={40} />
          </div>
        </div>
        <div className={'-functionnalities'}>
          <h2 className={'-title'}>Unpy plateform e-sport</h2>
          <div className={'-containerFuncs'}>
            <div className={'-col'}>
              <div className={'-func --green'}>
                <Check />
                <span className={'-funcInfos'}>
                  {t('home.events.description.services.createTournaments')}
                </span>
              </div>
              <div className={'-func --green'}>
                <Check />
                <span className={'-funcInfos'}>
                  {t('home.events.description.services.cachprizeSecure')}
                </span>
              </div>
              <div className={'-func --green'}>
                <Check />
                <span className={'-funcInfos'}>
                  {t('home.events.description.services.registerPrice')}
                </span>
              </div>
              <div className={'-func --green'}>
                <Check />
                <span className={'-funcInfos'}>
                  {t('home.events.description.services.bracketGeneration')}
                </span>
              </div>
              <div className={'-func --green'}>
                <Check />
                <span className={'-funcInfos'}>
                  {t('home.events.description.services.scoreAndMatch')}
                </span>
              </div>
              <div className={'-func --green'}>
                <Check />
                <span className={'-funcInfos'}>
                  {t('home.events.description.services.typeEvent')}
                </span>
              </div>
            </div>
            <div className={'-col'}>
              <div className={'-func --blue'}>
                <Check />
                <span className={'-funcInfos'}>
                  {t('home.events.description.services.personnalizeProfil')}
                </span>
              </div>
              <div className={'-func --blue'}>
                <Check />
                <span className={'-funcInfos'}>
                  {t('home.events.description.services.statsDisplay')}
                </span>
              </div>
              <div className={'-func --blue'}>
                <Check />
                <span className={'-funcInfos'}>
                  {t('home.events.description.services.createStructure')}
                </span>
              </div>
              <div className={'-func --blue'}>
                <Check />
                <span className={'-funcInfos'}>
                  {t('home.events.description.services.memberGestion')}
                </span>
              </div>
              <div className={'-func --blue'}>
                <Check />
                <span className={'-funcInfos'}>
                  {t('home.events.description.services.subscriptionTournamentSquad')}
                </span>
              </div>
              <div className={'-func --blue'}>
                <Check />
                <span className={'-funcInfos'}>
                  {t('home.events.description.services.searchPlayerAndStructure')}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classNames('-content')}>
        <div className={'-offers'}>
          <UnpyUpOffers embedded products={products} />
        </div>
        <div className={'-labelContainer'}>
          <span className={'-explication'}>{t('home.up.labelChangeExp')}</span>
          <UnpyLogoUp size={'medium'} />
        </div>
        <div className={'-btnContainer'}>
          <Link className={'-linkAsButton -subscribeBtn'} to={routesConfig.up.to()}>
            {t('home.up.subscribe')}
          </Link>
        </div>
        <div className={'-labelContainer'}>
          <span className={'-goToPage'}>{t('home.up.goToPageLabel')}</span>
          <UnpyLogoUp size={'medium'} />
        </div>
      </div>
    </Section>
  );
};
