import './MenuActionTimeline.scss';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import ModalConfirmEvent from 'primary/classements/Ligue/Modal/ConfirmEvent/ModalConfirmEvent';
import ModalStartEvent from 'primary/classements/Ligue/Modal/StartEvent/ModalStartEvent';
import { sendEventToastMessage } from 'primary/Components/Toast/Toast.helper';
import { routesConfig } from 'primary/Configs/Routes.config';
import { ModalPublishEvent } from 'primary/events/modals/ModalPublishEvent';
import { ModalReopenSubscribe } from 'primary/events/modals/ModalReopenSubscribe';
import { useBooleanToggle } from 'primary/hooks/useBooleanToggle';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import ModalConfirm, { ModalConfirmChoiceProps } from 'primary/ModalConfirm/ModalConfirm';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import * as React from 'react';
import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ACTION_TIMELINE_EVENT_STATE,
  BegunActions,
  DraftActionTimelineEvent,
  getActionTimelineEventState,
  PublishSubscribeOpenActionTimelineEvent,
  WaitBegin,
} from './ActionsTimelineItems';

interface MenuActionTimelineProps {
  event: UnpyEvent | UnpyEventView;
  refreshEvent: () => void;
}

export default function MenuActionTimeline({
  event,
  refreshEvent,
}: MenuActionTimelineProps) {
  const { eventRepository } = useContextDependency();
  const t = useTranslate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const [isOpenModalGenerate, , openModalGenerate, closeModalGenerate] =
    useBooleanToggle();
  const [confModalConfirmChoice, setConfModalConfirmChoice] =
    useState<Partial<ModalConfirmChoiceProps>>();
  const [isOpenModalConfirm, , openModalConfirm, closeModalConfirm] = useBooleanToggle();
  const [isOpenModalPublish, , openModalPublish, closeModalPublish] = useBooleanToggle();
  const [isOpenCloseSubscribe, , openCloseSubscribe, closeCloseSubscribe] =
    useBooleanToggle();
  const [isOpenCancel, , openCancel, closeCancel] = useBooleanToggle();
  const [
    isOpenModalReopenSubscribe,
    ,
    openModalReopenSubscribe,
    closeModalReopenSubscribe,
  ] = useBooleanToggle();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCancelEvent = () => {
    setConfModalConfirmChoice({
      title: t('event.timeline.actions.cancel.titleModal'),
      action: () => {
        eventRepository
          .cancelEvent(event.id)
          .then(() => {
            sendEventToastMessage('toast.event.cancel.success', 'success');
            refreshEvent();
            navigate(routesConfig.activities.to());
          })
          .finally(() => setConfModalConfirmChoice(undefined));
      },
    });
  };

  const handleCloseSubscribe = () => {
    setConfModalConfirmChoice({
      title: t('event.timeline.actions.closeSubscribe.titleModal'),
      action: () => {
        eventRepository
          .closeSubscriptionEvent(event.id)
          .then(() => {
            sendEventToastMessage(t('general.edit.success'), 'success');
            refreshEvent();
          })
          .finally(() => setConfModalConfirmChoice(undefined));
      },
    });
  };

  const handleFinishEvent = () => {
    setConfModalConfirmChoice({
      title: t('event.timeline.actions.finish.titleModal'),
      action: () => {
        eventRepository
          .finishEvent(event.id)
          .then(() => {
            sendEventToastMessage(t('general.edit.success'), 'success');
            refreshEvent();
          })
          .finally(() => setConfModalConfirmChoice(undefined));
      },
    });
  };

  const navigate = useNavigate();
  const MenuComponentsTimelineActionEventByState: {
    [key in ACTION_TIMELINE_EVENT_STATE]: ReactNode;
  } = {
    DRAFT: (
      <DraftActionTimelineEvent
        handleCancel={handleCancelEvent}
        handlePublish={openModalPublish}
      />
    ),
    PUBLISH_SUBSCRIBE_OPEN: (
      <PublishSubscribeOpenActionTimelineEvent
        handleCancel={handleCancelEvent}
        handleCloseSubscribe={handleCloseSubscribe}
      />
    ),
    PUBLISH_SUBSCRIBE_CLOSE_WAITING_ASK_CONFIRM: (
      <WaitBegin
        handleCancel={handleCancelEvent}
        event={event}
        handleStart={openModalGenerate}
      />
    ),
    PUBLISH_SUBSCRIBE_CLOSE_WAITING_CONFIRM: (
      <WaitBegin
        handleCancel={handleCancelEvent}
        event={event}
        handleStart={openModalGenerate}
      />
    ),
    PUBLISH_SUBSCRIBE_CLOSE_WAITING_BEGIN: (
      <WaitBegin
        handleCancel={handleCancelEvent}
        event={event}
        handleStart={openModalGenerate}
      />
    ),
    BEGUN: (
      <BegunActions handleCancel={handleCancelEvent} handleFinish={handleFinishEvent} />
    ),
    FINISHED: <></>,
  };

  return (
    <div>
      <Button
        className={'-menuActionTimeline'}
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {t('event.timeline.actions.label')}
      </Button>
      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {MenuComponentsTimelineActionEventByState[getActionTimelineEventState(event)]}
      </Menu>
      <ModalContainer
        handleClose={closeModalGenerate}
        isOpen={isOpenModalGenerate}
        content={
          <ModalStartEvent
            handleClose={closeModalGenerate}
            event={event}
            onStarted={() => {
              refreshEvent();
              navigate(routesConfig.parametersDetailsEvent.scoreboard.to(event.id));
            }}
          />
        }
      />
      <ModalContainer
        handleClose={closeModalConfirm}
        isOpen={isOpenModalConfirm}
        content={
          <ModalConfirmEvent
            handleClose={closeModalConfirm}
            event={event}
            onConfirmed={() => {
              refreshEvent();
              sendEventToastMessage(t('general.edit.success'), 'success');
              closeModalConfirm();
            }}
          />
        }
      />
      <ModalContainer
        handleClose={closeModalPublish}
        isOpen={isOpenModalPublish}
        content={
          <ModalPublishEvent
            event={event}
            handleClose={closeModalPublish}
            onSubmitted={refreshEvent}
          />
        }
      />

      <ModalContainer
        handleClose={closeModalReopenSubscribe}
        isOpen={isOpenModalReopenSubscribe}
        content={
          <ModalReopenSubscribe
            event={event}
            handleClose={closeModalReopenSubscribe}
            onSubmitted={refreshEvent}
          />
        }
      />

      {confModalConfirmChoice && (
        <ModalConfirm
          title={confModalConfirmChoice.title as string}
          handleClose={() => setConfModalConfirmChoice(undefined)}
          isOpen={!!confModalConfirmChoice}
          action={confModalConfirmChoice.action as () => void}
        />
      )}
    </div>
  );
}
