import './MessageItem.scss';

import classNames from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';

import { Message } from '../../../../domain/message/Message';
import { useContextDependency } from '../../../hooks/useContextDependency';
import { useTranslate } from '../../../hooks/useTranslate';

interface MessageItemProps {
  message: Message;
  isPreview: boolean;
  className?: string;
}

export const MessageItem = ({ message, isPreview, className }: MessageItemProps) => {
  const { authRepository, messageRepository } = useContextDependency();
  const t = useTranslate();
  const dispatch = useDispatch();

  return (
    <div className={classNames('messageItem', className, { ['-isPreview']: isPreview })}>
      {isPreview && (
        <span className={'messageItem__senderLabel'}>
          {message.sender.id === authRepository.currentUser?.id
            ? t('message.item.owner')
            : message.sender.idPlayer}
          {' :'}
        </span>
      )}
      <span
        className={classNames('messageItem__content', {
          '-own': message.sender.id === authRepository.currentUser?.id,
        })}
      >
        {message.content}
      </span>
    </div>
  );
};
