import { UserProfilView } from 'domain/user/UserProfilView';

import { Conversation } from './Conversation';
import { MessageTypeEnum } from './MessageTypeEnum';

export interface MessageFilters {
  conversation: number;
  type?: MessageTypeEnum;
}

export class Message {
  constructor(
    public readonly id: number,
    public readonly creationDate: Date,
    public readonly sender: UserProfilView,
    public readonly type: MessageTypeEnum,
    public readonly viewed: boolean,
    public readonly content: string,
    public readonly conversation?: Conversation,
  ) {}
}
