import { ProfilTypeEnum } from 'domain/profil/ProfilTypeEnum';

export class UnpyStatistiquesProfil {
  constructor(
    public readonly id: number,
    public readonly idProfil?: number,
    public readonly typeProfil?: ProfilTypeEnum,
    public readonly nbEventAsParticipant?: number,
    public readonly nbEventAsCreator?: number,
    public readonly nbPodium?: number,
    public readonly nbVictory?: number,
  ) {}
}
