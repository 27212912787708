import './InputFile.scss';

import { Cancel, UploadFile } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import classNames from 'classnames';
import React, { forwardRef, Ref } from 'react';

import { InputProps } from './InputWrapper';

export const InputFile = forwardRef(function InputFileForwarded(
  props: InputProps & {
    hiddenInputProps?: InputProps;
    canDelete?: boolean;
    initialPath?: string;
  },
  ref: Ref<HTMLInputElement>,
) {
  const { hiddenInputProps, disabled, initialPath } = props;

  return (
    <div className={classNames('input-file', { disabled: disabled })}>
      <Button
        variant={'contained'}
        color={'primary'}
        className={'input-file__input'}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          document.getElementById(`input-file-${hiddenInputProps?.name}`)?.click();
        }}
        disabled={disabled}
      >
        <span>{props.fileName || 'Sélectionnez un fichier'}</span>
        <UploadFile />
        {props.fileName && props.canDelete && (
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              const eventName = { target: { value: undefined } };
              props.onChange && props.onChange(eventName);
              props.onFileUpload && props.onFileUpload(undefined);
            }}
          >
            <Cancel />
          </IconButton>
        )}
      </Button>
      <input
        name={props.name}
        id={`input-file-${hiddenInputProps?.name}`}
        type="file"
        ref={ref}
        hidden
        disabled={disabled}
        onChange={(event) => {
          event.stopPropagation();
          event.preventDefault();
          const file =
            event?.target?.files &&
            event?.target?.files[0] &&
            (event.target.files[0] as File);
          const eventName = { target: { value: file?.name } };
          props.onChange && props.onChange(eventName);
          props.onFileUpload && file && props.onFileUpload(file as File);
          return event;
        }}
      />
    </div>
  );
});
