import { EventConfirmationStateEnum } from 'domain/event/EventConfirmationStateEnum';
import { SystemPointsEnum } from 'domain/event/SystemPoints.enum';
import { RestPlatform, toPlatform } from 'secondary/platform/RestPlatform';

import { EventStatusEnum } from '../../domain/event/EventStatusEnum';
import { UnpyEvent } from '../../domain/event/UnpyEvent';
import { toEnum } from '../../helper/enum.helper';
import { RestGame, toGame } from '../game/RestGame';
import { RestUserProfil, toUserProfil } from '../userProfil/RestUserProfil';
import { RestTypeBo, toTypeBo } from './RestTypeBo';
import { RestTypeBracket, toTypeBracket } from './RestTypeBracket';
import { RestTypeEvent, toTypeEvent } from './RestTypeEvent';
import { RestTypeRonde, toTypeRonde } from './RestTypeRonde';
import { RestTypeTeam, toTypeTeam } from './RestTypeTeam';

export interface RestEvent {
  id: number;
  startDateTime: string;
  endSubscribeDate: string;
  name: string;
  creationDate: string;
  creator: RestUserProfil;
  freeRegister: boolean;
  platforms?: RestPlatform[];
  maxTeam: number;
  confirmationState: string;
  delayed: string;
  bracketTeamNumber: number;
  singleStructureTeam: boolean;
  game: RestGame;
  status: string;
  typeEvent: RestTypeEvent;
  typeTeam?: RestTypeTeam;
  rules?: string;
  description?: string;
  twitter?: string;
  facebook?: string;
  youtube?: string;
  instagram?: string;
  discord?: string;
  twitch?: string;
  image?: string;
  typeBracket?: RestTypeBracket;
  typeRonde?: RestTypeRonde;
  typeBo?: RestTypeBo;
  totalParticipants?: number;
  admins?: RestUserProfil[];
  pointSystem?: string;
  publicImageUrl?: string;
  defaultImage?: boolean;
  prizeText?: string;
}

export function toUnpyEvent(restEvent: RestEvent) {
  return new UnpyEvent(
    restEvent.id,
    new Date(restEvent.startDateTime),
    new Date(restEvent.endSubscribeDate),
    toEnum(EventStatusEnum, restEvent.status),
    restEvent.name,
    restEvent.freeRegister,
    restEvent.singleStructureTeam,
    new Date(restEvent.creationDate),
    toUserProfil(restEvent.creator),
    toGame(restEvent.game),
    toTypeEvent(restEvent.typeEvent),
    restEvent.typeTeam ? toTypeTeam(restEvent.typeTeam) : undefined,
    restEvent.rules,
    restEvent.platforms?.map((platform) => toPlatform(platform)),
    restEvent.description,
    restEvent.maxTeam,
    restEvent.confirmationState
      ? toEnum(EventConfirmationStateEnum, restEvent.confirmationState)
      : undefined,
    restEvent.delayed ? new Date(restEvent.delayed) : undefined,
    restEvent.bracketTeamNumber,
    restEvent.twitter,
    restEvent.facebook,
    restEvent.youtube,
    restEvent.instagram,
    restEvent.discord,
    restEvent.twitch,
    restEvent.image,
    restEvent.typeBracket ? toTypeBracket(restEvent.typeBracket) : undefined,
    restEvent.typeRonde ? toTypeRonde(restEvent.typeRonde) : undefined,
    restEvent.typeBo ? toTypeBo(restEvent.typeBo) : undefined,
    restEvent.admins ? restEvent.admins.map((admin) => toUserProfil(admin)) : undefined,
    undefined,
    restEvent.pointSystem ? toEnum(SystemPointsEnum, restEvent.pointSystem) : undefined,
    restEvent.publicImageUrl,
    restEvent.defaultImage,
    restEvent.prizeText,
    restEvent.totalParticipants,
  );
}
