import { InvitationLink } from 'domain/invitationLink/InvitationLink';
import { InvitationLinkTypeEnum } from 'domain/invitationLink/InvitationLinkTypeEnum';
import { RestUserView, toUserView } from 'secondary/user/RestUser';

import { toEnum } from '../../helper/enum.helper';
export interface RestInvitationLink {
  readonly id: number;
  readonly uuid: string;
  readonly idRessource: number;
  readonly typeInvitationLink: string;
  readonly createDate: string;
  readonly isVisited: boolean;
  readonly numberCompleted: number;
  readonly maxToComplete: number;
  readonly creator: RestUserView;
}
export function toInvitationLink(restInvitationLink: RestInvitationLink): InvitationLink {
  return new InvitationLink(
    restInvitationLink.id,
    restInvitationLink.uuid,
    restInvitationLink.idRessource,
    toEnum(InvitationLinkTypeEnum, restInvitationLink.typeInvitationLink),
    new Date(restInvitationLink.createDate),
    restInvitationLink.isVisited,
    restInvitationLink.numberCompleted,
    restInvitationLink.maxToComplete,
    toUserView(restInvitationLink.creator),
  );
}
