import '../../Utils/Forms/UnpyForm.scss';

import { Box, Grid, TextField } from '@mui/material';
import { addDays } from 'date-fns';
import { getInitialValuesPublish } from 'domain/event/UnpyEvent.func';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { CoupleButtonValidCancel } from 'primary/Components/CoupleButtonValidCancel/CoupleButtonValidCancel';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import React, { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { IEventRepository } from '../../../domain/event/Event.repository';
import { UnpyEvent } from '../../../domain/event/UnpyEvent';
import { Translate } from '../../../domain/translation/Translation.repository';
import { InputWrapper } from '../../Components/Input/InputWrapper';
import { sendEventToastMessage } from '../../Components/Toast/Toast.helper';
import { useTranslate, UseTranslateReturn } from '../../hooks/useTranslate';
import { useYupValidationResolver } from '../../hooks/useYupValidationResolver';

/*
  'pointSystem',
  'freeRegister',
  'singleStructureTeam',
  {
    key: 'maxTeam',
    condition: (event: UnpyEvent) => event.typeEvent.code === TypeEventCodeEnum.LIGUE,
  },
  {
    key: 'bracketTeamNumber',
    condition: (event: UnpyEvent) =>
      event.typeEvent.code === TypeEventCodeEnum.TOURNAMENT,
  },
  'typeTeam',
  'platformes',
 */
export type EventReopenSubscribeFormData = {
  newEndSubscribeDate: string;
};

const getValidationSchema = (limitDate: Date) => (t: UseTranslateReturn) =>
  yup.object({
    newEndSubscribeDate: yup.string().required(t('general.forms.errors.required')),
  });

export const useOnSubmit =
  (
    eventRepostory: IEventRepository,
    onSubmitted: (event: UnpyEvent) => void,
    idEvent: number,
    t: Translate,
  ) =>
  (data: EventReopenSubscribeFormData) => {
    eventRepostory.reopenSubscriptions(data, idEvent).then((event) => {
      sendEventToastMessage(t('general.edit.success'), 'success');
      onSubmitted(event);
    });
  };

interface EventReopenSubscribeFormProps {
  onSubmitted: (event: UnpyEvent | UnpyEventView) => void;
  event: UnpyEvent | UnpyEventView;
  closeModal: () => void;
}

export const EventReopenSubscribeForm: FC<EventReopenSubscribeFormProps> = ({
  onSubmitted,
  event,
  closeModal,
}) => {
  const t = useTranslate();
  const validationSchema = useMemo(
    () => getValidationSchema(addDays(event.startDateTime, -2)),
    [],
  );
  const resolver = useYupValidationResolver(validationSchema);
  const initValues = getInitialValuesPublish(
    t,
    event as UnpyEvent,
  ) as EventReopenSubscribeFormData;
  const form = useForm<EventReopenSubscribeFormData>({
    resolver,
    // @ts-ignore
    defaultValues: initValues,
  });
  const { eventRepository } = useContextDependency();

  const onSubmit = useOnSubmit(eventRepository, onSubmitted, event.id, t);

  return (
    <Box className={'eventRulesForm unpy-form'}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <InputWrapper
              className={'unpy-form__field'}
              label={t('event.form.labels.newEndSubscribeDate')}
              errors={form.formState.errors}
              isSubmitting={form.formState.isSubmitting}
              Input={TextField}
              inputProps={{
                placeholder: t('event.form.placeholders.endSubscribeDate'),
                disabled: form.formState.isSubmitting,
                ...form.register('newEndSubscribeDate', { required: true }),
                type: 'datetime-local',
                variant: 'filled',
              }}
              required
            />
          </Grid>
        </Grid>
        <CoupleButtonValidCancel isTypeSubmit={true} onClickCancel={closeModal} />
      </form>
    </Box>
  );
};
