import { Skeleton } from '@mui/lab';
import { Divider, Grid } from '@mui/material';
import classNames from 'classnames';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { formatDate } from 'helper/date.helper';
import { TimelineEventComp } from 'primary/Components/Event/TimelineEvent';
import { KeyValue } from 'primary/Components/KeyValue/KeyValue';
import { Title } from 'primary/Components/Title/Title';
import { EventViewPageContext } from 'primary/events/item/EventViewPage';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { useOutletContext } from 'react-router-dom';

type InfosWrapperProps = {
  event?: UnpyEvent | UnpyEventView;
  readOnly?: boolean;
};

type InfosWithContextWrapperProps = {
  readOnly?: boolean;
};
export const InfosWithContext = ({ readOnly }: InfosWithContextWrapperProps) => {
  const t = useTranslate();
  const { event } = useOutletContext<EventViewPageContext>();
  return <InfosContent event={event} readOnly={readOnly} />;
};

export const InfosWithProps = ({ event, readOnly }: InfosWrapperProps) => {
  return <InfosContent event={event} readOnly={readOnly} />;
};

export const InfosContent = ({ event, readOnly }: InfosWrapperProps) => {
  const t = useTranslate();
  return (
    <div className={'eventViewPageBloc eventViewPageInfos'}>
      <Title
        title={t('event.eventViewPage.infos.title')}
        level={1}
        threeQuarter
        bordered
      />
      <div className={'-keyValueContainer'}>
        {!event && <Skeleton animation="wave" height={'100%'} width="100%" />}
        {event && (
          <Grid container>
            <Grid item xs={12}>
              <TimelineEventComp
                readonly={readOnly}
                event={event}
                orientation={'horizontal'}
              />
              <Divider className={'-divider'} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <KeyValue
                alignStart
                keyValue={t('event.eventViewPage.infos.game')}
                value={t(`enum.typeEventCode.${event?.typeEvent.code}`)}
                className={'-keyValue'}
                accent
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <KeyValue
                alignStart
                keyValue={t('event.eventViewPage.infos.endSubscribeDate')}
                value={formatDate(event?.endSubscribeDate, 'readable(with hour)')}
                className={'-keyValue'}
                accent
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <KeyValue
                alignStart
                keyValue={t('event.eventViewPage.infos.startDateTime')}
                value={formatDate(event?.startDateTime, 'readable(with hour)')}
                className={'-keyValue'}
                accent
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <KeyValue
                alignStart
                keyValue={t('event.eventViewPage.infos.maxTeam')}
                value={event?.maxTeam?.toString() || event?.maxTeam?.toString()}
                className={'-keyValue'}
                accent
              />
            </Grid>
            <Grid xs={12}>
              <KeyValue
                alignStart
                keyValue={t('event.eventViewPage.description.title')}
                value={
                  <div
                    className={classNames('text-area', '-size__medium', {
                      '-noContent': !(event as UnpyEvent)?.description,
                    })}
                    dangerouslySetInnerHTML={{
                      __html:
                        (event as UnpyEvent)?.description ||
                        t('profil.details.description.noContent'),
                    }}
                  ></div>
                }
                className={'-keyValue'}
                accent
              />
            </Grid>
          </Grid>
        )}
      </div>
      <Title
        title={t('event.eventViewPage.rules.title')}
        level={1}
        threeQuarter
        bordered
      />
      <div className={'-backgroundedContent'}>
        {(event as UnpyEvent)?.rules && (
          <div
            className={'text-area'}
            dangerouslySetInnerHTML={{ __html: (event as UnpyEvent)?.rules ?? '' }}
          ></div>
        )}
        {!(event as UnpyEvent)?.rules && (
          <span>{t('event.eventViewPage.infos.rules.noRules')}</span>
        )}
      </div>
    </div>
  );
};

export const Infos = ({ event, readOnly }: InfosWrapperProps) => {
  if (event) {
    return <InfosWithProps event={event} readOnly />;
  } else {
    return <InfosWithContext readOnly />;
  }
};
