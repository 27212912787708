import './AnnonceListWithActions.scss';

import { Annonce, AnnonceFilters } from 'domain/annonce/Annonce';
import { AnnonceTypeEnum } from 'domain/annonce/AnnonceTypeEnum';
import { ActionButtonCreateEditAnnonce } from 'primary/annonce/ActionButton/ActionButtonCreateEditAnnonce';
import { AnnoncesList } from 'primary/annonce/AnnoncesList';
import { InfiniteScroll } from 'primary/Components/InfiniteScroll/InfiniteScroll';
import { NoContentBloc } from 'primary/Components/NoContentBloc/NoContentBloc';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetchWithFilterPage } from 'primary/hooks/useFetchWithFilterPage';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
interface AnnonceListEventWithActionsProps {
  idEvent: number;
}

export const AnnonceListEventWithActions = ({
  idEvent,
}: AnnonceListEventWithActionsProps) => {
  const { annonceRepository } = useContextDependency();
  const t = useTranslate();
  const {
    fetchedResource,
    goToPage,
    loading,
    activeFilters,
    setFilters,
    fetch,
    nextPage,
    retry,
    allContents,
  } = useFetchWithFilterPage<Annonce, Partial<AnnonceFilters>>({
    filters: {},
    callApi: (filters, page) =>
      annonceRepository.searchAnnonceEvent(idEvent, filters, page, 30),
    keepPageResult: true,
  });

  return (
    <div className={'annonceListWithActions'}>
      <div className={'-actions'}>
        {idEvent && (
          <ActionButtonCreateEditAnnonce
            type={AnnonceTypeEnum.EVENT}
            withIcon
            eventId={idEvent?.toString()}
            onSubmitted={(annonce: Annonce) => {
              retry();
            }}
          />
        )}
      </div>
      <div className={'-list'}>
        {!loading && !allContents?.length && (
          <NoContentBloc text={t('general.noContentList.annonceStructure')} />
        )}
        <InfiniteScroll onScrollBottom={() => nextPage()} isLoading={loading}>
          {(scrollToTop, scrollToBottom) => (
            <AnnoncesList annonces={allContents} scrollToTop={scrollToTop} />
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};
