import './EventFilters.scss';

import { EventStatusEnum } from 'domain/event/EventStatusEnum';
import { InputSelectMui } from 'primary/Components/Input/InputSelectMui';
import {
  ChangeHandlerTypeMeta,
  InputWrapper,
} from 'primary/Components/Input/InputWrapper';
import { TypeSearchEvent } from 'primary/events/list/EventList';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

type FilterStatusProps = {
  onSelectStatus: (value?: string) => void;
  selectedStatus?: string;
  typeSearch: TypeSearchEvent;
};

const OptByTypeSearch = {
  ['OWN']: Object.values(EventStatusEnum),
  ['PUBLIC']: Object.values(EventStatusEnum).filter(
    (status) => status !== EventStatusEnum.DRAFT,
  ),
  ['REGISTERED']: Object.values(EventStatusEnum).filter(
    (status) => status !== EventStatusEnum.DRAFT,
  ),
};

export const FilterStatus = ({
  onSelectStatus,
  selectedStatus,
  typeSearch,
}: FilterStatusProps) => {
  const t = useTranslate();
  return (
    <div>
      <InputWrapper
        inline
        className={'unpy-form__field'}
        label={t('event.filters.status')}
        errors={{}}
        Input={InputSelectMui}
        key={selectedStatus}
        isSubmitting={false}
        inputProps={{
          onChange: (event: ChangeHandlerTypeMeta) => {
            onSelectStatus(event.target.value as string);
          },
          value: selectedStatus,
          options: OptByTypeSearch[typeSearch].map((status) => ({
            value: status,
            label: t(`enum.eventStatus.${status}`),
          })),
        }}
      />
    </div>
  );
};
