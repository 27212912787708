import './PreviewCardProfilWithRole.scss';

import { Edit } from '@mui/icons-material';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { UserProfil } from 'domain/profil/UserProfil';
import { RoleStructure } from 'domain/roleStructure/RoleStructure';
import { Structure } from 'domain/structure/Structure';
import { StructureMember } from 'domain/structureMember/StructureMember';
import { UserProfilView } from 'domain/user/UserProfilView';
import { CardMercatoPlayerAnnouncementOverview } from 'primary/Components/CardMercatoAnnouncementOverview/CardMercatoPlayerAnnouncementOverview';
import { ModalEditRole } from 'primary/Components/StructureProfil/ModalEditRole';
import { useBooleanToggle } from 'primary/hooks/useBooleanToggle';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

interface PreviewCardProfilWithRoleProps {
  profil: UserProfil | UserProfilView;
  userId?: number;
  structure?: Structure;
  onSubmitted?: (structureMember: StructureMember) => void;
  role: RoleStructure;
  isTemp?: boolean;
  className?: string;
  readonly?: boolean;
  onClickEditMember?: () => void;
}

export const PreviewCardProfilWithRole = ({
  profil,
  userId,
  structure,
  onSubmitted,
  role,
  isTemp,
  className,
  readonly,
  onClickEditMember,
}: PreviewCardProfilWithRoleProps) => {
  const t = useTranslate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isOpen, toggleOpen, openModal, closeModal] = useBooleanToggle();

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classNames('previewCardProfilWithRole', className)}>
      {isTemp && !readonly && (
        <div className={'previewCardProfilWithRole__temp'}>
          {t('structure.inviteResponseWaiting')}
        </div>
      )}
      <div className={'previewCardProfilWithRole__header'}>
        <Button
          onClick={() =>
            readonly ? (onClickEditMember ? onClickEditMember() : toggleOpen) : undefined
          }
          disabled={readonly}
          className={'-button'}
        >
          <span>{t(`structure.roles.${role.code}`)}</span>
          {!readonly && <Edit fontSize={'small'} />}
        </Button>
      </div>
      <CardMercatoPlayerAnnouncementOverview
        className={'-cardMember'}
        key={profil.id}
        profil={profil}
      />
      {onSubmitted && (
        <ModalEditRole
          isOpen={isOpen}
          handleClose={closeModal}
          userId={userId}
          structure={structure}
          roleId={role.id}
          onSubmitted={onSubmitted}
          name={profil.idPlayer || ''}
        />
      )}
    </div>
  );
};
