import { Grid } from '@mui/material';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import {
  toEventViewFromEvent,
  useGenerateDraftFromAppearence,
} from 'domain/event/UnpyEvent.func';
import { EventCard } from 'primary/Components/Event/EventCard';
import { EventAppearenceFormData } from 'primary/events/forms/EventAppearenceForm';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

type PreviewEditEventAppearenceProps = {
  event?: UnpyEvent;
  draftedValues: EventAppearenceFormData;
  draftImage?: File;
};
export const PreviewEditEventAppearence = ({
  event,
  draftedValues,
  draftImage,
}: PreviewEditEventAppearenceProps) => {
  const draft = useGenerateDraftFromAppearence(draftedValues, event);
  const t = useTranslate();
  if (!draft) return null;
  return (
    <div className={'editStructureProfil'}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3>{t('event.form.previewAppearence.listView')}</h3>
          <EventCard event={toEventViewFromEvent(draft)} draftImage={draftImage} />
        </Grid>
      </Grid>
    </div>
  );
};
