import './EventFilters.scss';

import { insertEmptyOpt } from 'helper/options.helper';
import { InputSelectMui } from 'primary/Components/Input/InputSelectMui';
import {
  ChangeHandlerTypeMeta,
  InputWrapper,
} from 'primary/Components/Input/InputWrapper';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetch } from 'primary/hooks/useFetch';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

type FilterTypeTeamProps = {
  onSelectTypeTeam: (value?: string) => void;
  selectedTypeTeam?: string;
};

export const FilterTypeTeam = ({
  onSelectTypeTeam,
  selectedTypeTeam,
}: FilterTypeTeamProps) => {
  const { eventRepository } = useContextDependency();
  const [typeTeam] = useFetch(() => eventRepository.getAllTypeTeam(), undefined);
  if (!typeTeam) return null;
  const t = useTranslate();
  return (
    <div>
      <InputWrapper
        inline
        className={'unpy-form__field'}
        label={t('event.filters.typeTeam')}
        errors={{}}
        Input={InputSelectMui}
        isSubmitting={false}
        key={selectedTypeTeam}
        inputProps={{
          placeholder: t('event.filters.typeTeam'),
          onChange: (event: ChangeHandlerTypeMeta) => {
            onSelectTypeTeam(event.target.value as string);
          },
          value: selectedTypeTeam,
          options: insertEmptyOpt(
            typeTeam.map((typeTeam) => ({
              value: typeTeam.code,
              label: t(`enum.typeTeamCode.${typeTeam.code}`),
            })),
          ),
        }}
      />
    </div>
  );
};
