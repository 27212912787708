import { StructureMember } from '../../domain/structureMember/StructureMember';
import { RestRoleStructure, toRoleStructure } from '../roleStructure/RestRoleStructure';
import { RestStructure, toStructure } from '../structure/RestStructure';
import { RestUserView, toUserView } from '../user/RestUser';

export interface RestStructureMember {
  user: RestUserView;
  structure: RestStructure;
  role: RestRoleStructure;
  accepted: boolean | null;
}

export function toStructureMember(restStructureMember: RestStructureMember) {
  return new StructureMember(
    toUserView(restStructureMember.user),
    toRoleStructure(restStructureMember.role),
    restStructureMember.accepted,
    restStructureMember.structure
      ? toStructure(restStructureMember.structure)
      : undefined,
  );
}
