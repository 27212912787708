import './CardMercatoPlayerAnnouncementOverview.scss';

import { Add } from '@mui/icons-material';
import { Skeleton } from '@mui/lab';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { getNeonBorderClassName, getNeonVariant } from 'domain/profil/Profil.func';
import { UserProfil } from 'domain/profil/UserProfil';
import { UserProfilView } from 'domain/user/UserProfilView';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { routesConfig } from '../../Configs/Routes.config';
import { useTranslate } from '../../hooks/useTranslate';
import { Icon } from '../Icon/Icon';
import { IconFlag } from '../Icon/IconFlag';
import { Neon } from '../Neon/Neon';
import { UnpyLogoUp } from '../UnpyLogo/UnpyLogoUp';
import { WrapperOtherProfilPhotoOverview } from '../User/WrapperOtherProfilPhotoOverview';

interface RowCardProps {
  profil: UserProfil | UserProfilView;
  className?: string;
  draftPhoto?: File;
}

interface RowCardPropsSkeletonProps {
  className?: string;
}

export const CardMercatoSkeleton = ({ className }: RowCardPropsSkeletonProps) => {
  const t = useTranslate();
  return (
    <div className={classNames('overview-announcement-mercato-player-card', className)}>
      <Skeleton animation="wave" height={'100%'} width="100%" />
      <div className={'-contentOverBackground'}>
        <div className={'-topBar'}>
          <Skeleton animation="wave" height={'100%'} width="100%" />
        </div>

        <div className={'-content'}>
          <div className={'-photo'}>
            <Skeleton animation="wave" height={'100%'} width="100%" />
          </div>
          <div className={'-infos'}>
            <div className={'-name'}>
              <h4 className={'-nameValue'}>XXXXX</h4>
            </div>
            <div className={'-langs'}>
              <Skeleton animation="wave" height={'100%'} width="100%" />
            </div>
            <div className={'-neon'}>
              <Skeleton animation="wave" height={'100%'} width="100%" />
            </div>
            <div className={'-platforms'}>
              <Skeleton animation="wave" height={'100%'} width="100%" />
            </div>
          </div>
          <div className={'-overlayAdd'}>
            <Button
              color={'primary'}
              style={{ justifySelf: 'flex-end' }}
              variant={'contained'}
            >
              <Add />
              {t('mercato.card.skeleton.addOwn')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CardMercatoPlayerAnnouncementOverview: FC<RowCardProps> = ({
  className,
  profil,
  draftPhoto,
}) => {
  const neonBorderClassName = getNeonBorderClassName(profil);

  const navigation = useNavigate();

  const handleClick = () => {
    const id = profil instanceof UserProfilView ? profil.idProfil : profil.id;
    navigation(routesConfig.mercatoUserProfil.to(id));
  };

  const t = useTranslate();

  return (
    <div
      className={classNames('overview-announcement-mercato-player-card', className)}
      onClick={handleClick}
    >
      <img
        src={profil.backgroundImage?.publicUrl}
        className={'-imageBackground'}
        alt={'backgroundImage-' + profil.id}
      />
      <div className={'-contentOverBackground'}>
        <div className={'-topBar'}>
          <UnpyLogoUp withoutLink size={'filled'} />
        </div>

        <div className={'-content'}>
          <div className={'-photo'}>
            <WrapperOtherProfilPhotoOverview
              size={'small'}
              displayName={profil.idPlayer}
              photo={
                draftPhoto
                  ? URL.createObjectURL(draftPhoto)
                  : profil.publicPhotoUrl || profil.publicPhotoUrl
              }
              className={classNames('-photoItem', neonBorderClassName)}
              idUser={profil.id as number}
            />
          </div>
          <div className={'-infos'}>
            <div className={'-name'}>
              <h4 className={'-nameValue'}>{profil.idPlayer}</h4>
            </div>
            <div className={'-langs'}>
              {profil.country && (
                <div className={'flagIcon'} title={profil.country.libelle}>
                  <IconFlag codeISO={profil.country?.code} className={'-big'} />
                </div>
              )}
            </div>
            <div className={'-neon'}>
              <Neon variant={getNeonVariant(profil?.neon)} />
            </div>
            <div className={'-platforms'}>
              {profil.platforms?.map((platform) => (
                <Icon
                  key={platform.code}
                  svg={platform.code}
                  className={'-big'}
                  title={t(`enum.platforms.${platform.code}`)}
                />
              ))}
              {!profil.platforms ||
                (profil.platforms.length === 0 && (
                  <span className={'-noContent'}>Aucune platformes</span>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
