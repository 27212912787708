import './NoContentBloc.scss';

import { Cancel } from '@mui/icons-material';
import classNames from 'classnames';
import React from 'react';
type NoContentBlocProps = {
  className?: string;
  text?: string;
  icon?: React.ReactNode;
};
export const NoContentBloc = ({ className, text, icon }: NoContentBlocProps) => {
  return (
    <div className={classNames(className, 'noContentBloc')}>
      <div className={'-noContentBloc'}>
        {icon ?? <Cancel />}
        <p>{text ?? 'Aucun résultat'}</p>
      </div>
    </div>
  );
};
