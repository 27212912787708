import { EventParticipant } from 'domain/event/participants/EventParticipant';

export type ClassementFilters = {
  eventId?: number;
  participantIds?: number;
  textValue?: string;
};
export class ClassementParticipant {
  constructor(
    public readonly id: number,
    public readonly participant?: EventParticipant,
    public readonly victories?: number,
    public readonly defeats?: number,
    public readonly nulls?: number,
    public readonly top1?: number,
    public readonly top3?: number,
    public readonly topHalf?: number,
    public readonly score?: number,
    public readonly finalPlace?: number,
  ) {}
}
